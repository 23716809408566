import { InternalVariables } from '../Form/types/FormData';

export const parseQuestions = (questions: any[]) => {
  const singleTabQuestions = questions
    .filter((question: any) => !question.childOf)
    .map(({ wId, answer, type }: any) => ({
      wId,
      shouldBeIgnored: ['DOWNLOAD', 'PANEL'].includes(type),
      ...(type === 'PANEL'
        ? {
            panel: true,
            answers: questions
              .filter((question: any) => question.childOf === wId)
              .map((q: any) => ({
                wId: q.wId,
                answer: q.answer,
                shouldBeIgnored: ['DOWNLOAD', 'PANEL'].includes(q.type),
              })),
          }
        : { answer }),
    }));
  return singleTabQuestions;
};
export const parseMultiformQuestions = (questions: any[]) => questions.map(parseQuestions) as any[];

const getRefWhenChildOF = (panelOrQuestion: any, foundQuestion: any, rule: any, panelIndex: number, tabId?: any) => {
  let questionIndex = -1;
  (panelOrQuestion.answers as any[]).find((answer, index) => {
    if (answer.wId === foundQuestion.wId) {
      questionIndex = index;
      return answer;
    }
    return false;
  });
  return {
    ...rule,
    ref: `answers${tabId !== undefined ? `[${tabId}]` : ''}[${panelIndex}].answers[${questionIndex}]`,
  };
};

const getPanelOrQuestion = (answers: any[], foundQuestion: any) => {
  let panelIndex = -1;
  const panelOrQuestion = answers.find((answer, index) => {
    if (answer.wId === foundQuestion.wId || answer.wId === foundQuestion.childOf) {
      panelIndex = index;
      return answer;
    }
    return false;
  });

  return {
    panelIndex,
    panelOrQuestion,
  };
};

const mapRuleToFrontDependency = (rulesDependenciesKeys: string[], rule: any, questions: any[], answers: any[]) => {
  const externalReference = rulesDependenciesKeys.includes(rule.id);
  if (externalReference) {
    return {
      ...rule,
      ref: 'external',
    };
  }
  const foundQuestion = questions.find((q: any) => q.wId === rule.id);
  const { panelIndex, panelOrQuestion } = getPanelOrQuestion(answers, foundQuestion);

  if (foundQuestion.childOf) {
    return getRefWhenChildOF(panelOrQuestion, foundQuestion, rule, panelIndex);
  }

  return {
    ...rule,
    ref: `answers[${panelIndex}]`,
  };
};

const mapRuleToFrontDependencyMulti = (
  rulesDependenciesKeys: string[],
  rule: any,
  questions: any[],
  answers: any[],
  tabId: any
) => {
  const externalReference = rulesDependenciesKeys.includes(rule.id);
  if (externalReference) {
    return {
      ...rule,
      ref: 'external',
    };
  }
  const foundQuestion = questions.find((q: any) => q.wId === rule.id);
  const { panelIndex, panelOrQuestion } = getPanelOrQuestion(answers, foundQuestion);

  if (foundQuestion.childOf) {
    return getRefWhenChildOF(panelOrQuestion, foundQuestion, rule, panelIndex, tabId);
  }

  return {
    ...rule,
    ref: `answers[${tabId}][${panelIndex}]`,
  };
};

const parseRulesDependency = (questions: any[], answers: any[], rulesDependenciesAnswers: any) => {
  const externalRulesDependenciesKeys = Object.keys(rulesDependenciesAnswers ?? {});
  return questions
    .filter((question: any) => question.rules?.rulesList?.length || question.parentRules?.rulesList?.length)
    .reduce(
      (acc: any, question: any) => ({
        ...acc,
        [`internalDependency_${question.wId}`]: {
          rulesList: [...(question.rules?.rulesList ?? []), ...(question.parentRules?.rulesList ?? [])]
            .reduce((flatRules: any[], cur: any) => [...flatRules, ...(Array.isArray(cur) ? cur : [cur])], [])
            .map((rule: any) => mapRuleToFrontDependency(externalRulesDependenciesKeys, rule, questions, answers)),
        },
      }),
      {}
    );
};

const parseRulesDependencyMulti = (questions: any[], answers: any[], rulesDependenciesAnswers: any, tabId: any) => {
  const externalRulesDependenciesKeys = Object.keys(rulesDependenciesAnswers ?? {});
  return questions
    .filter((question: any) => question.rules?.rulesList?.length || question.parentRules?.rulesList?.length)
    .reduce(
      (acc: any, question: any) => ({
        ...acc,
        [`internalDependency_[${tabId}]${question.wId}`]: {
          rulesList: [...(question.rules?.rulesList ?? []), ...(question.parentRules?.rulesList ?? [])]
            .reduce((flatRules: any[], cur: any) => [...flatRules, ...(Array.isArray(cur) ? cur : [cur])], [])
            .map((rule: any) =>
              mapRuleToFrontDependencyMulti(externalRulesDependenciesKeys, rule, questions, answers, tabId)
            ),
        },
      }),
      {}
    );
};

const parseInternalVariables = (questions: any[], answers: any[], internalVariables: InternalVariables = []) =>
  internalVariables.reduce(
    (acc, variable) => ({
      ...acc,
      [`internal_variable_${variable.code}`]: mapRuleToFrontDependency([], { id: variable.wId }, questions, answers),
    }),
    {}
  );

export const parseReceivedData = (receivedData: any) => {
  if (receivedData?.formType === 'BRANCH') {
    const branches = receivedData.branches.map((branch: any) => {
      const answers = parseQuestions(branch.questions);
      return {
        branchId: branch.branchId,
        answers,
      };
    });
    return {
      ...parseInternalVariables(
        receivedData.branches[0].questions,
        branches[0].answers,
        receivedData.internalVariables
      ),
      ...parseRulesDependency(
        receivedData.branches[0].questions,
        branches[0].answers,
        receivedData.rulesDependenciesAnswers
      ),
      branches,
    };
  }
  if (receivedData?.repeating === true) {
    const entries = (receivedData.entries ?? []).map((entry: any) => {
      const answers = parseQuestions(entry.questions);
      return {
        entry: entry.entry,
        answers,
      };
    });
    const firstEntry = entries[0];
    const defaultEntry = { answers: parseQuestions(receivedData.questions) };
    return {
      ...parseInternalVariables(
        (receivedData.entries?.[0] ?? receivedData).questions,
        (firstEntry ?? defaultEntry)?.answers,
        receivedData.internalVariables
      ),

      ...parseRulesDependency(
        (receivedData.entries?.[0] ?? receivedData).questions,
        (firstEntry ?? defaultEntry)?.answers,
        receivedData.rulesDependenciesAnswers
      ),
      entries,
    };
  }
  if (
    (receivedData.multipleForm === 1 || receivedData.multipleForm === '1') &&
    receivedData.status !== 'NOT_INITIATED'
  ) {
    const answers: any[] = parseMultiformQuestions(receivedData.questions);

    const parseDataMultipleForm = receivedData.questions.reduce(
      (acc: any, tabQuestions: any, index: any) => ({
        ...acc,
        ...parseInternalVariables(tabQuestions, answers[index], receivedData.internalVariables),
        ...parseRulesDependencyMulti(tabQuestions, answers[index], receivedData.rulesDependenciesAnswers, index),
      }),
      {}
    );
    return {
      ...parseDataMultipleForm,
      answers,
    };
  }

  // eslint-disable-next-line
  if (receivedData.multipleForm == '1') {
    const answers: any[] = parseMultiformQuestions(receivedData.questions);
    const parseDataMultipleForm = receivedData.questions.map((tabQuestions: any, index: any) => ({
      ...parseInternalVariables(tabQuestions, answers[index], receivedData.internalVariables),
      ...parseRulesDependencyMulti(tabQuestions, answers[index], receivedData.rulesDependenciesAnswers, index),
    }));
    return {
      ...parseDataMultipleForm[0],
      answers,
    };
  }
  const answers: any[] = parseQuestions(receivedData.questions);

  return {
    ...parseInternalVariables(receivedData.questions, answers, receivedData.internalVariables),
    ...parseRulesDependency(receivedData.questions, answers, receivedData.rulesDependenciesAnswers),
    answers,
  };
};

export default function parseTabRules(answers: any, receivedData: any, index: any) {
  const parseDataMultipleForm = receivedData.questions.map((tabQuestions: any) => ({
    ...parseRulesDependencyMulti(tabQuestions, answers[index], receivedData.rulesDependenciesAnswers, index),
  }));

  return {
    ...parseDataMultipleForm[0],
  };
}
