import React from 'react';

interface UnblockFormIconProps {
  width?: number;
  height?: number;
  fill?: string;
}

const UnblockFormIcon = ({ width = 16, height = 16, fill = 'none' }: UnblockFormIconProps) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.1667 3.33334V1.66667H5.83333V3.33334H3.3275C2.87083 3.33334 2.5 3.70417 2.5 4.16084V17.5058C2.5 17.9625 2.87083 18.3333 3.3275 18.3333H11.556C11.09 17.854 10.7107 17.29 10.4432 16.6667H4.16667V5H5.83333V6.66667H14.1667V5H15.8333V9.00992C16.4182 9.04488 16.9785 9.17125 17.5 9.37493V4.16084C17.5 3.70417 17.1292 3.33334 16.6725 3.33334H14.1667ZM7.5 15V13.3333H5.83333V15H7.5ZM7.5 12.5V10.8333H5.83333V12.5H7.5ZM7.5 10V8.33334H5.83333V10H7.5ZM7.5 3.33334H12.5V5H7.5V3.33334Z"
      fill="#262321"
    />
    <path
      d="M17.8943 11.5302L14.182 15.2429L11.8838 12.9447L11.707 12.7679L11.5302 12.9447L10.8232 13.6517L10.6464 13.8285L10.8232 14.0053L14.0052 17.1873L14.182 17.364L14.3588 17.1873L18.9554 12.5908L19.1322 12.4139L18.9553 12.2372L18.2478 11.5302L18.071 11.3535L17.8943 11.5302Z"
      fill="#262321"
      stroke="#262321"
      strokeWidth="0.5"
    />
  </svg>
);

export default UnblockFormIcon;
