import React from 'react';

export interface Ellipse21Props {
  width?: number;
  height?: number;
  className?: string;
}

export const Ellipse21 = ({ width = 99, height = 94, className }: Ellipse21Props) => (
  <svg
    id="el21"
    className={className}
    width={width}
    height={height}
    viewBox="0 0 99 94"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g style={{ mixBlendMode: 'soft-light' }}>
      <ellipse cx="49.5" cy="47" rx="47" ry="49.5" transform="rotate(90 49.5 47)" fill="#FAAC82" fillOpacity="0.4" />
    </g>
  </svg>
);
