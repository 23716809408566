/* eslint-disable no-restricted-globals */
import { useContext, useEffect, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import ReplyFormContext from '../../context';
import { getBranchStatement, getHiddenReference } from '../../misc';
import { isNotEmptyArray } from '../../../../utils/array';
import formatDate from '../../../../utils/formatDate';

const ruleMatch = (dateFormat: string, otherAnswers: { [index: string]: any }) => (rule: any) => {
  const answer = otherAnswers[rule.id];
  const answered = () => {
    if (answer.answered) return true;
    if (typeof answer.answer === 'string') return answer.answer !== '';
    if (answer.answer instanceof Date && !isNaN(answer.answer)) {
      const answerValue = formatDate(answer.answer, dateFormat);
      return answerValue !== '';
    }
    return isNotEmptyArray(answer.answer);
  };
  const answerIs = () => {
    if (typeof answer.answer === 'string') return answer.answer.toLowerCase() === rule.value.toLowerCase();
    if (answer.answer instanceof Date && !isNaN(answer.answer)) {
      const answerValue = formatDate(answer.answer, dateFormat);
      return answerValue.toLowerCase() === rule.value.toLowerCase();
    }
    if (isNotEmptyArray(answer.answer))
      return (answer.answer as string[]).some((ans) => ans.toLowerCase() === rule.value.toLowerCase());
    return false;
  };
  const answerContains = () => {
    if (answer.answer === 'string') return answer.answer.toLowerCase().includes(rule.value.toLowerCase());
    if (answer.answer instanceof Date && !isNaN(answer.answer)) {
      const answerValue = formatDate(answer.answer, dateFormat);
      return answerValue.toLowerCase().includes(rule.value.toLowerCase());
    }
    if (isNotEmptyArray(answer.answer))
      return (answer.answer as string[]).some((ans) => ans.toLowerCase().includes(rule.value.toLowerCase()));
    return false;
  };
  const answerStartsWith = () => {
    if (typeof answer.answer === 'string') return answer.answer.toLowerCase().startsWith(rule.value.toLowerCase());
    if (answer.answer instanceof Date && !isNaN(answer.answer)) {
      const answerValue = formatDate(answer.answer, dateFormat);
      return answerValue.toLowerCase().startsWith(rule.value.toLowerCase());
    }
    if (isNotEmptyArray(answer.answer))
      return (answer.answer as string[]).some((ans) => ans.toLowerCase().startsWith(rule.value.toLowerCase()));
    return false;
  };
  switch (rule.condition) {
    case 'ANSWERED':
      return answered();
    case 'NOT_ANSWERED':
      return !answered();
    case 'ANSWER_IS':
      return answerIs();
    case 'ANSWER_IS_NOT':
      return !answerIs();
    case 'ANSWER_CONTAINS':
      return answerContains();
    case 'ANSWER_NOT_CONTAINS':
      return !answerContains();
    case 'ANSWER_STARTS_WITH':
      return answerStartsWith();
    case 'ANSWER_NOT_STARTS_WITH':
      return !answerStartsWith();
    default:
      return !answered();
  }
};
function parentIsShown(question: any, dateFormat: string, allOtherAnswers: { [index: string]: any }) {
  if (!question.parentRules) return true;

  const orGroupResults = question.parentRule.rulesList.map((orGroup: any) => {
    const results = orGroup.map(ruleMatch(dateFormat, allOtherAnswers));
    return results.some((p: boolean) => p === true);
  });
  const requirementsMeet = orGroupResults.every((p: boolean) => p === true);
  return (
    (requirementsMeet && question.parentRule.whenRequirementMet === 'show') ||
    (!requirementsMeet && question.parentRule.whenRequirementMet === 'hide')
  );
}
// @ts-ignore
function shouldShowQuestion(question: any, dateFormat: string, allOtherAnswers: { [index: string]: any }) {
  if (!question.rules) return true;
  const orGroupResults = question.rules.rulesList.map((orGroup: any) => {
    const results = orGroup.map(ruleMatch(dateFormat, allOtherAnswers));
    return results.some((p: boolean) => p === true);
  });
  const requirementsMeet = orGroupResults.every((p: boolean) => p === true);
  return (
    (requirementsMeet && question.rules.whenRequirementMet === 'show') ||
    (!requirementsMeet && question.rules.whenRequirementMet === 'hide')
  );
}

const reduceExternalAnswers = (external: any, branchIndex?: number) =>
  Object.keys(external).reduce(
    (acc, key) => ({
      ...acc,
      [key]: {
        ...external[key],
        answer: branchIndex !== undefined ? external[key]?.branchesAnswer?.[branchIndex] : external[key].questionAnswer,
      },
    }),
    {}
  );

export const useFormCustomRerender = (
  item: any,
  accessFieldsName: string,
  dateFormat: string = 'dd/MM/yyyy',
  { branchIndex, entryIndex }: { branchIndex?: number; entryIndex?: number } = {}
) => {
  const { control, watch, data, setValue, hiddenFieldsRefs, formDisabled, getValues } = useContext(ReplyFormContext);
  const isPanel = item.type === 'PANEL';
  const externalReduced = useMemo(
    () => reduceExternalAnswers(data?.rulesDependenciesAnswers ?? {}, branchIndex),
    [data?.rulesDependenciesAnswers]
  );
  const dependency = useWatch({
    control,
    name: `internalDependency_${item.wId}`,
  });

  useEffect(() => {
    if (!dependency)
      hiddenFieldsRefs.current[getHiddenReference(item, branchIndex !== undefined, entryIndex !== undefined)] = false;
    return () => {
      hiddenFieldsRefs.current[getHiddenReference(item, branchIndex !== undefined, entryIndex !== undefined)] = true;
    };
  }, []);

  const toWatch = useMemo(
    () =>
      dependency?.rulesList
        .filter((rule: any) => rule.ref !== 'external')
        .map((rule: any) => `${getBranchStatement(branchIndex, entryIndex)}${rule.ref}`) ?? [],
    [dependency]
  );

  if (dependency) {
    const watched = toWatch.length === 0 ? [] : watch(toWatch);
    const allOtherQuestions = {
      ...externalReduced,
      ...watched.reduce(
        (acc: any, cur: any) => ({
          ...acc,
          [cur.wId]: cur,
        }),
        {}
      ),
    };
    const isParentShown = parentIsShown(item, dateFormat, allOtherQuestions);
    const shouldShow = isParentShown && shouldShowQuestion(item, dateFormat, allOtherQuestions);
    const isFieldCurrentlyHidden = watch(`${accessFieldsName}.hidden`);
    const ref = getHiddenReference(item, branchIndex !== undefined, entryIndex !== undefined);

    if (hiddenFieldsRefs.current[ref] !== !shouldShow) hiddenFieldsRefs.current[ref] = !shouldShow;
    if (isFieldCurrentlyHidden === undefined || shouldShow === isFieldCurrentlyHidden) {
      // TODO fix Cannot update a component (`ReplyForm`) while rendering a different component (`Question`)
      // TODO fix Cannot update a component (`ReplyForm`) while rendering a different component (`PanelItem`)
      setValue(`${accessFieldsName}.hidden`, !shouldShow);

      if (!formDisabled && !shouldShow) {
        if (isPanel) {
          const internalQuestions = getValues(`${accessFieldsName}.answers`);
          internalQuestions?.forEach((q: any, index: number) =>
            setValue(`${accessFieldsName}.answers[${index}].answer`, '')
          );
        }

        setValue(`${accessFieldsName}.answer`, '');
      }
    }
    if (!shouldShow) return false;
  }
  return true;
};

export const useFormCustomRerenderMultipleForm = (
  item: any,
  accessFieldsName: string,
  tabId: any,
  dateFormat: string = 'dd/MM/yyyy',
  { branchIndex, entryIndex }: { branchIndex?: number; entryIndex?: number } = {}
) => {
  const { control, watch, data, setValue, formDisabled, getValues } = useContext(ReplyFormContext);
  const isPanel = item.type === 'PANEL';
  const externalReduced = useMemo(
    () => reduceExternalAnswers(data?.rulesDependenciesAnswers ?? {}, branchIndex),
    [data?.rulesDependenciesAnswers]
  );

  const dependency = useWatch({
    control,
    name: `internalDependency_[${tabId}]${item.wId}`,
  });

  const toWatch = useMemo(
    () =>
      dependency?.rulesList
        .filter((rule: any) => rule.ref !== 'external')
        .map((rule: any) => `${getBranchStatement(branchIndex, entryIndex)}${rule.ref}`) ?? [],
    [dependency]
  );

  if (dependency) {
    const watched = toWatch.length === 0 ? [] : watch(toWatch);
    const allOtherQuestions = {
      ...externalReduced,
      ...watched.reduce(
        (acc: any, cur: any) => ({
          ...acc,
          [cur.wId]: cur,
        }),
        {}
      ),
    };
    const isParentShown = parentIsShown(item, dateFormat, allOtherQuestions);
    const shouldShow = isParentShown && shouldShowQuestion(item, dateFormat, allOtherQuestions);
    const isFieldCurrentlyHidden = watch(`${accessFieldsName}.hidden`);

    if (isFieldCurrentlyHidden === undefined || shouldShow === isFieldCurrentlyHidden) {
      // TODO fix Cannot update a component (`ReplyForm`) while rendering a different component (`Question`)
      // TODO fix Cannot update a component (`ReplyForm`) while rendering a different component (`PanelItem`)
      setValue(`${accessFieldsName}.hidden`, !shouldShow);

      if (!formDisabled && !shouldShow) {
        if (isPanel) {
          const internalQuestions = getValues(`${accessFieldsName}.answers`);
          internalQuestions?.forEach((q: any, index: number) => {
            setValue(`${accessFieldsName}.answers[${index}].answer`, '');
            setValue(`${accessFieldsName}.answers[${index}].hidden`, true);
          });
        }

        setValue(`${accessFieldsName}.answer`, '');
      }
    }
    if (!shouldShow) return false;
  }
  return true;
};
