import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, FooterWrapper } from './style';
import { CreateIcon } from '../../../assets/svgs/CreateIcon';
import TextButton from '../../../components/TextButton';
import Button from '../../../components/Button';
import { Creators as modalActions } from '../../../redux/reducers/modal-reducer';
import HistoricModal from './components/HistoricModal';
import { INITIAL_VALUE_QUESTION } from '../constants';
import GenericFormFields from '../GenericFormFields';
import { IFormData } from '../types/FormData';
import {
  deleteDraftRequest,
  findFormDraftWithFallbackByIdRequest,
  publishForm,
  updateFormRequest,
} from '../../../requests/forms';
import formatReceivedFormData from '../utils/formatReceivedFormData';
import formatFormData from '../utils/formatFormData';
import Alert from '../../../components/Toast/toast';
import Loader from '../../../components/Loader';
import Footer from '../../../components/Form/Footer';
import { LoaderWrapper } from '../../../components/Loader/style';
import { Breadcrumbs } from '../../../components/BreadCrumb';
import { BreadcrumbWrapper } from '../../../components/BreadCrumb/style';
import { Tooltip } from '../../../components/Tooltip';
import { Badge } from '../FormList/style';
import DeleteModal from '../../../components/DefaultModalContent';
import { TableData } from '../../../types/TableData';
import i18n from '../../../config/i18n/config';
import { omitFromObject } from '../../../utils/omitFromObject';

type QueryParams = {
  id: string;
};

function getFormHistory(ownerId: string | undefined, historicHandler: () => Promise<void>) {
  if (ownerId) return <Badge>{i18n.t('form.draft')}</Badge>;
  return (
    <TextButton onClick={historicHandler}>
      <p>{i18n.t('form.historic') as string}</p>
      <CreateIcon />
    </TextButton>
  );
}

const FormEdit = () => {
  const { id } = useParams<QueryParams>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { openModal } = modalActions;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [tested, setTested] = useState(false);
  const [ownerId, setOwnerId] = useState<string | undefined>();
  const {
    handleSubmit,
    control,
    getValues,
    getFieldState,
    formState,
    trigger,
    watch,
    register,
    reset,
    setError,
    setValue,
  } = useForm<IFormData>({
    mode: 'onChange',
  });

  const { fields, append, remove, move, update } = useFieldArray({
    control,
    name: 'questions',
  });

  const fetchFormDataById = async () => {
    const data = await findFormDraftWithFallbackByIdRequest(id);
    if (data) {
      const formattedData = await formatReceivedFormData(data);
      reset(formattedData);
      setTested(data.tested);
      setOwnerId(data.ownerId);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchFormDataById();
  }, []);

  const historicHandler = async () => {
    const historicSubmit = (version: number, date: string) => {
      navigate(`/forms/historic/${id}/${version}`, { state: { date } });
    };

    dispatch(
      openModal({
        title: t('form.historic'),
        content: <HistoricModal id={id} onSubmit={historicSubmit} />,
      })
    );
  };

  const subType = watch('subType');
  const formHasSubType = String(subType) === 'USER_REGISTRATION' || String(subType) === 'FORM_LINKING';
  const onSubmit = (navigateToTest: boolean) => async (data: any) => {
    setLoadingButton(true);
    const dataWithoutUnnecessaryFields = omitFromObject(data, [
      '__v',
      'createdAt',
      'updatedAt',
      'latest',
      'version',
      'ownerId',
      'versionReference',
      'tested',
      'newForm',
      'invalidated',
      'dependsOn',
      '_id',
      'countryId',
      'oldVersionNumber',
    ]);
    const formattedData = formatFormData(dataWithoutUnnecessaryFields);
    try {
      await updateFormRequest(formattedData);
      Alert.SUCCESS(t('form.edit'));
      navigate(navigateToTest ? `/forms/test/${data.wId}` : '/forms');
    } catch (e: any) {
      Alert.ERROR(e?.response?.data?.message);
    } finally {
      setLoadingButton(false);
    }
  };

  const deleteDraftHandler =
    ownerId &&
    (() => {
      const handler = async () => {
        try {
          await deleteDraftRequest([{ wId: id }] as unknown as TableData[]);
          Alert.SUCCESS(t('form.draftDeleted'));
          navigate('/forms');
        } catch (e: any) {
          Alert.ERROR(e?.response?.data?.message);
        }
      };

      dispatch(
        openModal({
          content: (
            <DeleteModal onSubmit={handler} title={t('form.alertDraft')} submitButtonTitle={t('common.delete')} />
          ),
        })
      );
    });

  const publish = async () => {
    setLoadingButton(true);
    try {
      await publishForm(id);
      Alert.SUCCESS(t('form.formPublish'));
      navigate('/forms');
    } catch (e: any) {
      Alert.ERROR(e?.response?.data?.message);
    } finally {
      setLoadingButton(false);
    }
  };

  const openVariableCreatorModal = useRef<any>();
  const setOpenVariableCreatorModal = (val: any) => {
    openVariableCreatorModal.current = val;
  };

  return loading ? (
    <LoaderWrapper>
      <Loader text={t('form.search')} />
    </LoaderWrapper>
  ) : (
    <Container>
      <BreadcrumbWrapper>
        <Breadcrumbs />
      </BreadcrumbWrapper>
      <GenericFormFields
        {...{
          control,
          getValues,
          setValue,
          getFieldState,
          formState,
          trigger,
          watch,
          register,
          fields,
          append,
          remove,
          move,
          update,
          onSubmit,
          formHasSubType,
          deleteDraftHandler,
          setError,
          enableMapToDefaultValues: true,
          setOpenVariableCreatorModal,
        }}
      />
      <Footer>
        <FooterWrapper>
          {getFormHistory(ownerId, historicHandler)}
          <FooterWrapper>
            <TextButton onClick={() => openVariableCreatorModal.current()}>
              <p>Variáveis internas</p>
              <CreateIcon />
            </TextButton>
            <TextButton onClick={() => append(INITIAL_VALUE_QUESTION())}>
              <p>{t('form.newQuestion')}</p>
              <CreateIcon />
            </TextButton>
            <Button onClick={handleSubmit(onSubmit(true))} loading={loadingButton}>
              {t('form.saveDraftTest')}
            </Button>
            <Button onClick={handleSubmit(onSubmit(false))} loading={loadingButton}>
              {t('form.saveDraft')}
            </Button>
            <Tooltip
              disabled={tested}
              content={
                <>
                  {t('form.toolTipOrder')} <br /> {t('form.toolTipTest')}
                </>
              }
            >
              <Button onClick={publish} disabled={!tested} loading={loadingButton && tested} primary>
                {t('form.publish')}
              </Button>
            </Tooltip>
          </FooterWrapper>
        </FooterWrapper>
      </Footer>
    </Container>
  );
};

export default FormEdit;
