import React from 'react';
import EmptyStateIcon from '../../assets/svgs/EmptyStateIcon';
import { StyledEmptyState } from './style';

interface EmptyStateProps {
  text: string;
}

const EmptyState = ({ text }: EmptyStateProps) => (
  <StyledEmptyState>
    <EmptyStateIcon />
    <p>{text}</p>
  </StyledEmptyState>
);

export default EmptyState;
