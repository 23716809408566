import styled, { css } from 'styled-components';
import { MAX_WIDTHS } from '../../constants/screen';

export const Title = styled.h1`
  font-family: 'Circular20', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0;
  text-align: left;
  margin-bottom: 36px;
`;

export const Advice = styled.p`
  font-family: 'Circular20', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #979797;
`;

export const Container = styled.div`
  width: 100%;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TableWrapper = styled.table`
  margin-top: 16px;
  margin-bottom: 24px;
  font-family: Circular20, sans-serif;
  border-collapse: collapse;
  width: 100%;
  position: relative;
  table-layout: fixed;
`;

export const TableHead = styled.thead`
  background: #f2f0ed;
  font-size: 16px;

  @media (max-width: ${MAX_WIDTHS.sm}px) {
    font-size: 14px;
  } ;
`;

export const TableBody = styled.tbody`
  transition: all 1s ease;
`;

export const TableFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${MAX_WIDTHS.sm}px) {
    flex-direction: column-reverse;
    gap: 44px;
    text-align: center;
  } ;
`;

interface TableRowProps {
  selected?: boolean;
}

export const TableRow = styled.tr<TableRowProps>`
  height: 54px;
  background-color: ${({ selected }) => selected && '#E8EFFA'};

  td:first-child,
  th:first-child {
    padding-right: 0;
  }
`;

export const TableHeadData = styled.th`
  padding: 16px;
  text-align: left;

  &:first-child {
    border-top-left-radius: 12px;
    width: 20px;
  }

  &:last-child {
    text-align: right;
    border-top-left-radius: 0;
    border-top-right-radius: 12px;
  }
`;

export const TableData = styled.td`
  padding: 0 16px;
  border-top: 1px solid #e3dfda;
  max-width: 500px;

  &:first-child {
    width: 20px;
  }

  text-align: left;

  &:last-child {
    text-align: right;
  }

  @media (max-width: ${MAX_WIDTHS.sm}px) {
    padding: 10px 16px;
  } ;
`;

interface TableDataWrapperProps {
  gap?: number;
  justify?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
}

export const TableDataWrapper = styled.div<TableDataWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: ${({ justify = 'flex-start' }) => justify};
  gap: ${({ gap = 16 }) => gap}px;
  padding: 8px 0;
`;

export const ActionsButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

interface ActionIconProps {
  disabled?: boolean;
}

export const ActionIcon = styled.div<ActionIconProps>`
  cursor: pointer;
  path {
    fill: #262321;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      user-select: none;
      pointer-events: none;

      // Color for path of svg
      path {
        fill: #979797;
      }
    `}
`;

interface OrderIconProps {
  active?: boolean;
}

export const OrderIconWrapper = styled(ActionIcon)<OrderIconProps>`
  path {
    fill: #262321;
  }

  ${({ active }) =>
    active &&
    css`
      path {
        fill: #476bc3;
      }
    `}
`;

export const SearchBarWrapper = styled.div`
  color: #324fa5;

  .search_icon > path {
    fill: #324fa5;
  }
`;
