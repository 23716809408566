import styled from 'styled-components';
import { BREAKPOINTS } from '../../constants/screen';

export const ProjectOption = styled.div<{ last: boolean }>`
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
  align-self: stretch;
  margin-bottom: 8px;
  padding: 14px;
  @media (min-width: ${BREAKPOINTS.sm}px) {
    margin-left: 16px;
  }
  ${({ last }) => !last && 'border-bottom: rgba(227, 223, 218, 1) solid 1px'}
`;
export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  //gap: 64px;

  width: 78%;
  margin: auto;

  border: 1px solid #e3dfda;
  border-radius: 8px;
`;
