import React from 'react';

export interface Ellipse17Props {
  width?: number;
  height?: number;
  className?: string;
}

export const Ellipse17 = ({ width = 160, height = 109, className }: Ellipse17Props) => (
  <svg
    id="el17"
    className={className}
    width={width}
    height={height}
    viewBox="0 0 160 109"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g style={{ mixBlendMode: 'soft-light' }}>
      <ellipse cx="80" cy="32" rx="77" ry="80" transform="rotate(90 80 32)" fill="#F1615D" />
    </g>
  </svg>
);
