import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { styles } from '../../style';
import QuestionPanelPdf from './components/QuestionPanelPdf';

interface PanelProps {
  title: string;
  questions: any[];
}

const Panel = ({ title, questions }: PanelProps) => (
  <View>
    <View style={styles.panel}>
      <View>
        <Text style={styles.titlePanel}>{title}</Text>
      </View>
      {questions?.map(({ type, ...props }) => (
        <QuestionPanelPdf fieldType={type?.value || type} {...props} />
      ))}
    </View>
  </View>
);

export default Panel;
