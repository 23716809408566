/* eslint-disable no-nested-ternary */
import styled, { keyframes } from 'styled-components';

const BUBBLE_NORMAL_SIZE = 20;
const BUBBLE_EXPANDED_SIZE = 30;
const WHITE_COLOR = '#ffffff';
const SOLID_COLOR = '#0061db';
const FADED_COLOR = '#c0d8f6';
interface LoaderStyledProps {
  scale?: number;
  white?: boolean;
  solid?: boolean;
}

const expand = (scale: number, solid?: boolean, white?: boolean) => keyframes`

    0% {
      width: ${scale * BUBBLE_NORMAL_SIZE}px;
      height: ${scale * BUBBLE_NORMAL_SIZE}px;
      background: ${solid ? SOLID_COLOR : white ? WHITE_COLOR : FADED_COLOR};
    }
    15% {
      width: ${scale * BUBBLE_EXPANDED_SIZE}px;
      height: ${scale * BUBBLE_EXPANDED_SIZE}px;
      margin-bottom: ${scale * 10}px;
      background: ${solid ? SOLID_COLOR : white ? WHITE_COLOR : SOLID_COLOR};
    }
    35% {
      width: ${scale * BUBBLE_NORMAL_SIZE}px;
      height: ${scale * BUBBLE_NORMAL_SIZE}px;
      margin-bottom: 0;
      background: ${solid ? SOLID_COLOR : white ? WHITE_COLOR : FADED_COLOR};
  }
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

export const Text = styled.p<LoaderStyledProps>`
  font-style: normal;
  font-weight: 500;
  font-size: ${({ scale = 1 }) => scale * 20}px;
  line-height: ${({ scale = 1 }) => scale * 16}px;
  text-align: center;
`;

export const StyledLoader = styled.div`
  display: flex;
`;

export const BubbleWrapper = styled.div<LoaderStyledProps>`
  width: ${({ scale = 1 }) => scale * BUBBLE_EXPANDED_SIZE}px;
  height: ${({ scale = 1 }) => scale * (BUBBLE_EXPANDED_SIZE + scale * 10)}px;
  margin: 0 ${({ scale = 1 }) => scale * 3}px;
  display: flex;
  justify-content: center;
  align-items: end;

  &:nth-child(2) > div {
    animation-delay: 180ms;
  }

  &:nth-child(3) > div {
    animation-delay: 360ms;
  }

  &:nth-child(4) > div {
    animation-delay: 540ms;
  }
`;

export const Bubble = styled.div<LoaderStyledProps>`
  animation: ${({ scale = 1, solid, white }) => expand(scale, solid, white)} 2s ease-in-out infinite;
  border-radius: 20px;
  display: inline-block;
  transform-origin: center center;
  width: ${({ scale = 1 }) => scale * BUBBLE_NORMAL_SIZE}px;
  height: ${({ scale = 1 }) => scale * BUBBLE_NORMAL_SIZE}px;
  background: ${({ solid, white }: LoaderStyledProps) => (solid ? SOLID_COLOR : white ? WHITE_COLOR : FADED_COLOR)};
`;
