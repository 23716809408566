import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Title, Content, ButtonWrapper } from './style';
import { Creators as modalActions } from '../../redux/reducers/modal-reducer';
import Button from '../Button';

interface DeleteModalProps {
  title?: string;
  deleteSubmit?: any;
}

const DeleteModal = ({ title, deleteSubmit }: DeleteModalProps) => {
  const dispatch = useDispatch();
  const { closeModal } = modalActions;
  const { t } = useTranslation();
  const closeHandler = () => dispatch(closeModal());

  return (
    <Content>
      <Title>{title}</Title>
      <ButtonWrapper>
        <Button onClick={closeHandler}>{t('common.back')}</Button>
        <Button onClick={deleteSubmit} primary>
          {t('common.delete')}
        </Button>
      </ButtonWrapper>
    </Content>
  );
};

export default DeleteModal;
