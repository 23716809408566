import PasswordValidator from 'password-validator';

const hasNoRepeatOrSequence = (password: string = '') => {
  // Check for sequential numerical characters

  const isSequential = (arr: number[]) =>
    arr.some((num, index, array) => num === array[index + 1] + 1 && num === array[index + 2] + 2);

  const withoutSpecialCaracters = password.replace(/[^a-z0-9]/gi, '');
  const alphabeticalArray = withoutSpecialCaracters.split('').map((char) => char.charCodeAt(0));
  const hasSequentialAlphabetical = isSequential(alphabeticalArray) || isSequential(alphabeticalArray.reverse());

  // Check for three consecutive repeated characters
  const hasConsecutiveRepeats = password
    .split('')
    .some((char, index, array) => char === array[index + 1] && char === array[index + 2]);

  // return !(hasSequentialNumerical || hasSequentialAlphabetical || hasConsecutiveRepeats || isReverseEqualSequence);
  return !(hasSequentialAlphabetical || hasConsecutiveRepeats);
};

export const validatePasswordStrength = (password: string) => {
  const validator = new PasswordValidator();
  validator.is().min(8);
  validator.has().uppercase();
  validator.has().lowercase();
  validator.has().digits();
  validator.has().symbols();
  validator.usingPlugin(hasNoRepeatOrSequence);

  const validatedPassword = validator.validate(password);
  return validatedPassword;
};
