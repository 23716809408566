import styled from 'styled-components';
import { IconArrow } from '../../assets/svgs/IconArrow';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Image = styled.div`
  position: relative;
  & > * > .image-map__content__img {
    @media (min-width: 590px) {
      max-width: min(455px, 55.9vw);
      margin: auto;
    }
    max-width: 90%;
    margin: auto;
  }
`;

export const Wrapper = styled.div`
  height: 455px;
  width: 455px;
  left: 52%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Subtitle = styled.div`
  width: 235px;
  height: 19px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #5f86d8;
`;

export const StyledIconArrow = styled(IconArrow)`
  margin: 0 0 -3px 10px;
  left: 16.67%;
  right: 18.41%;
  top: 16.67%;
  bottom: 20.06%;
`;

export const ButtonWrapper = styled.div`
  width: 239px;
  height: 40px;
`;
