import React, { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container } from '../FormCreate/style';
import { FORM_DEFAULT_VALUES } from '../constants';
import GenericFormFields from '../GenericFormFields';
import { IFormData } from '../types/FormData';
import { FormViewContext } from './context';
import { findFormByIdRequest } from '../../../requests/forms';
import formatReceivedFormData from '../utils/formatReceivedFormData';
import { LoaderWrapper } from '../../../components/Loader/style';
import Loader from '../../../components/Loader';
import HistoricModal from '../FormEdit/components/HistoricModal';
import { Creators as modalActions } from '../../../redux/reducers/modal-reducer';
import TextButton from '../../../components/TextButton';
import { CreateIcon } from '../../../assets/svgs/CreateIcon';
import { FooterWrapper } from '../FormEdit/style';
import Footer from '../../../components/Form/Footer';
import Button from '../../../components/Button';

const FormView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { openModal } = modalActions;
  const [loading, setLoading] = useState(true);
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  const { control, getValues, getFieldState, formState, trigger, watch, register, setValue, reset } =
    useForm<IFormData>({
      defaultValues: FORM_DEFAULT_VALUES(),
      mode: 'onChange',
    });

  const { fields, append, remove, move, update } = useFieldArray({
    control,
    name: 'questions',
  });

  const fetchFormDataById = async () => {
    const data = await findFormByIdRequest(id);
    if (data) {
      const formattedData = await formatReceivedFormData(data);
      reset(formattedData);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchFormDataById();
  }, []);

  const historicHandler = async () => {
    const historicSubmit = (version: number, date: string) => {
      navigate(`/forms/historic/${id}/${version}`, { state: { date } });
    };

    dispatch(
      openModal({
        title: t<string>('form.historic'),
        content: <HistoricModal id={id} onSubmit={historicSubmit} />,
      })
    );
  };
  const backHandler = () => {
    navigate(`/forms`);
  };
  const deleteSubmit = () => {};
  const context = useMemo(() => ({ viewMode: true }), []);
  return loading ? (
    <LoaderWrapper>
      <Loader text={t('form.search')} />
    </LoaderWrapper>
  ) : (
    <FormViewContext.Provider value={context}>
      <Container>
        <GenericFormFields
          {...{
            control,
            getValues,
            getFieldState,
            formState,
            trigger,
            watch,
            register,
            fields,
            append,
            remove,
            move,
            update,
            setValue,
            deleteSubmit,
          }}
        />
        <Footer>
          <FooterWrapper>
            <TextButton onClick={historicHandler}>
              <p>{t('form.historic')}</p>
              <CreateIcon />
            </TextButton>
            <FooterWrapper>
              <Button onClick={backHandler}>{t('common.cancel')}</Button>
              {t('form.previewMode')}
            </FooterWrapper>
          </FooterWrapper>
        </Footer>
      </Container>
    </FormViewContext.Provider>
  );
};

export default FormView;
