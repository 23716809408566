import React from 'react';

export interface IconPieChartProps {
  width?: number;
  height?: number;
  fill?: string;
}

export const IconPieChart = ({ width = 28, height = 28, fill = '#fff' }: IconPieChartProps) => (
  <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.0001 27.3333C6.63608 27.3333 0.666748 21.364 0.666748 14C0.666748 8.02932 4.59075 2.97599 10.0001 1.27732V4.10932C7.70798 5.04005 5.81048 6.73827 4.63216 8.91348C3.45384 11.0887 3.06792 13.6057 3.54042 16.034C4.01292 18.4624 5.31447 20.6511 7.22243 22.2258C9.1304 23.8004 11.5262 24.6633 14.0001 24.6667C16.1251 24.6666 18.2017 24.032 19.9639 22.8443C21.726 21.6566 23.0934 19.9698 23.8907 18H26.7227C25.0241 23.4093 19.9707 27.3333 14.0001 27.3333ZM27.2667 15.3333H12.6667V0.733323C13.1054 0.689323 13.5507 0.666656 14.0001 0.666656C21.3641 0.666656 27.3334 6.63599 27.3334 14C27.3334 14.4493 27.3107 14.8947 27.2667 15.3333ZM15.3334 3.41599V12.6667H24.5841C24.2873 10.3168 23.2172 8.13244 21.5424 6.45764C19.8676 4.78284 17.6833 3.71277 15.3334 3.41599Z"
      fill={fill}
    />
  </svg>
);
