import React from 'react';
import { Document, Image, Line, Page, Rect, Svg, Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { styles } from './style';
import { FieldType } from '../../pages/Form/types/FieldType';
import QuestionPdf from './components/QuestionPdf';
import formatPdfQuestions from './utils/formatPdfQuestions';
import { useJwtContext } from '../../JwtContext';
import logo from '../../assets/images/logo.jpg';

type Question =
  | { wId: string; title: string; type: FieldType } // PANEL
  | { wId: string; answer: string; childOf?: string; title: string; type: FieldType } // Todos os outros
  | {
      wId: string;
      files: { size: number; name: string }[];
      title: string;
      type: FieldType;
    } // UPLOAD
  | { wId: string; links: string[]; title: string; type: FieldType } // DOWNLOAD
  | {
      wId: string;
      answer: string[] | string;
      title: string;
      type: FieldType;
      choices?: string[] | { title: string }[];
    };

export interface FormData {
  companyName?: string;
  title: string;
  productType: string | null | undefined;
  businessUnit: string | null | undefined;
  serviceOffer: string | null | undefined;
  formType?: string;
  repeating?: boolean;
  multipleForm?: boolean;
  qtdTabs?: number;
  tabId?: number;
  senderEmail?: string;
  senderName?: string;
  sendTimeStamp?: Date;
  branches?: {
    questions: Question[];
  }[];
  entries?: {
    questions: Question[];
  }[];
  projectType: string | null | undefined;
  questions: Question[];
}

const date = new Date();
const day = String(date.getDate()).padStart(2, '0');
const month = String(date.getMonth() + 1).padStart(2, '0');
const year = date.getFullYear();
const currentDate = `${day}/${month}/${year}`;

const FinisherInfo = ({ data }: any) => {
  const { t } = useTranslation();
  if (!data.sendTimeStamp) {
    return null;
  }

  return (
    <View>
      <Text style={styles.copyright}>
        {t('generatePdf.senderName')} {data.senderName} {t('generatePdf.senderEmail')} {data.senderEmail}{' '}
        {t('generatePdf.sendTimeStamp')}
        {format(parseISO(data.sendTimeStamp), 'dd/MM/yyyy HH:mm')}
      </Text>
    </View>
  );
};

export const GeneratePdf = ({ data }: { data: FormData }) => {
  const decodedJwt = useJwtContext();
  const greeting = decodedJwt?.name;

  const { t } = useTranslation();

  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <View style={styles.header} fixed>
          <Image style={styles.image} src={logo} />
          <Text style={styles.title}>{t('generatePdf.title')}</Text>
        </View>
        <View style={styles.svgY} fixed>
          <Svg height="10" width="150">
            <Line x1="0" y1="0" x2="500" y2="0" strokeWidth={4} stroke="#ffd5cb" />
          </Svg>
        </View>
        <View style={styles.svgX} fixed>
          <Svg height="200" width="20">
            <Line x1="20" y1="1" x2="20" y2="150" strokeWidth={4} stroke="#ffd5cb" />
          </Svg>
        </View>
        <View style={styles.pageNumber} fixed>
          <Text render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} fixed />
        </View>
        <View>
          <Text style={styles.subtitle}>{t('generatePdf.subtitle')}</Text>
        </View>
        {!!data?.companyName && (
          <View style={{ ...styles.companyData, paddingBottom: 0 }}>
            <Text style={styles.badgeCompany}>{data.companyName}</Text>
          </View>
        )}
        <View style={styles.companyData}>
          {!!data?.projectType && <Text style={styles.badge}>{data.projectType}</Text>}
          {!!data?.businessUnit && <Text style={styles.badge}>{data.businessUnit}</Text>}
          {!!data?.productType && <Text style={styles.badge}>{data.productType}</Text>}
          {!!data?.serviceOffer && <Text style={styles.badge}>{data.serviceOffer}</Text>}
        </View>
        <FinisherInfo data={data} />
        <View style={styles.body}>
          {data.repeating &&
            data?.entries?.flatMap((entry) => {
              const formattedData = formatPdfQuestions(entry.questions);
              return (
                <View style={styles.questionsWrapper}>
                  {formattedData.map((item: any) => (
                    <QuestionPdf fieldType={item.type} {...item} />
                  ))}
                </View>
              );
            })}
          {!data.repeating &&
            data.formType === 'BRANCH' &&
            data?.branches?.flatMap((branch, index) => {
              const formattedData = formatPdfQuestions(branch.questions);
              return (
                <View style={styles.questionsWrapper}>
                  <Text style={{ marginBottom: 10 }}>
                    {t('generatePdf.branch')} {index + 1}
                  </Text>
                  {formattedData.map((item: any) => (
                    <QuestionPdf fieldType={item.type} {...item} />
                  ))}
                </View>
              );
            })}
          {!data.repeating &&
            !(data.formType === 'BRANCH') &&
            formatPdfQuestions(data.questions).map((item: any) => <QuestionPdf fieldType={item.type} {...item} />)}
        </View>
        <View style={styles.footer} fixed>
          <View style={styles.item_wrapper}>
            <Text style={styles.copyright} fixed>
              Copyright {year} ADP. Inc ADP Confidential
            </Text>
            <View style={styles.infos}>
              <Text style={styles.user} fixed>
                {greeting}
              </Text>
              <Text style={styles.date} fixed>
                {currentDate}
              </Text>
            </View>
          </View>
          <Svg viewBox="0 0 220 10">
            <Rect width="70" height="10" fill="#f06255" x="0" y="0" />
            <Rect width="40" height="10" fill="#fecbbe" x="110" y="0" />
            <Rect width="100" height="10" fill="#4950a2" x="140" y="0" />
          </Svg>
        </View>
      </Page>
    </Document>
  );
};

export const GeneratePdfMultipleForm = ({
  data,
  tabId,
  qtdTabs,
}: {
  data: FormData;
  tabId: number;
  qtdTabs: number;
}) => {
  const decodedJwt = useJwtContext();
  const greeting = decodedJwt?.name;

  const { t } = useTranslation();

  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <View style={styles.header} fixed>
          <Image style={styles.image} src={logo} />
          <Text style={styles.title}>{t('generatePdf.title')}</Text>
        </View>
        <View style={styles.svgY} fixed>
          <Svg height="10" width="150">
            <Line x1="0" y1="0" x2="500" y2="0" strokeWidth={4} stroke="#ffd5cb" />
          </Svg>
        </View>
        <View style={styles.svgX} fixed>
          <Svg height="200" width="20">
            <Line x1="20" y1="1" x2="20" y2="150" strokeWidth={4} stroke="#ffd5cb" />
          </Svg>
        </View>
        <View style={styles.pageNumber} fixed>
          <Text render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} fixed />
        </View>
        <View>
          <Text style={styles.subtitle}>{t('generatePdf.subtitle')}</Text>
        </View>
        {!!data?.companyName && (
          <View style={{ ...styles.companyData, paddingBottom: 0 }}>
            <Text style={styles.badgeCompany}>{data.companyName}</Text>
          </View>
        )}
        <View style={{ ...styles.companyData, paddingBottom: 0 }}>
          {!!data?.projectType && <Text style={styles.badge}>{data.projectType}</Text>}
          {!!data?.businessUnit && <Text style={styles.badge}>{data.businessUnit}</Text>}
          {!!data?.productType && <Text style={styles.badge}>{data.productType}</Text>}
          {!!data?.serviceOffer && <Text style={styles.badge}>{data.serviceOffer}</Text>}
        </View>
        <View style={styles.companyData}>
          <Text style={styles.badge}>{`${data.title} - ${tabId + 1}/${qtdTabs}`}</Text>
        </View>
        <FinisherInfo data={data} />
        <View style={styles.body}>
          {data.repeating &&
            data?.entries?.flatMap((entry) => {
              const formattedData = formatPdfQuestions(entry.questions);
              return (
                <View style={styles.questionsWrapper}>
                  {formattedData.map((item: any) => (
                    <QuestionPdf fieldType={item.type} {...item} />
                  ))}
                </View>
              );
            })}
          {!data.repeating &&
            data.formType === 'BRANCH' &&
            data?.branches?.flatMap((branch, index) => {
              const formattedData = formatPdfQuestions(branch.questions);
              return (
                <View style={styles.questionsWrapper}>
                  <Text style={{ marginBottom: 10 }}>
                    {t('generatePdf.branch')} {index + 1}
                  </Text>
                  {formattedData.map((item: any) => (
                    <QuestionPdf fieldType={item.type} {...item} />
                  ))}
                </View>
              );
            })}
          {!data.repeating &&
            !(data.formType === 'BRANCH') &&
            formatPdfQuestions(data.questions[tabId]).map((item: any) => (
              <QuestionPdf fieldType={item.type} {...item} />
            ))}
        </View>
        <View style={styles.footer} fixed>
          <View style={styles.item_wrapper}>
            <Text style={styles.copyright} fixed>
              Copyright {year} ADP. Inc ADP Confidential
            </Text>
            <View style={styles.infos}>
              <Text style={styles.user} fixed>
                {greeting}
              </Text>
              <Text style={styles.date} fixed>
                {currentDate}
              </Text>
            </View>
          </View>
          <Svg viewBox="0 0 220 10">
            <Rect width="70" height="10" fill="#f06255" x="0" y="0" />
            <Rect width="40" height="10" fill="#fecbbe" x="110" y="0" />
            <Rect width="100" height="10" fill="#4950a2" x="140" y="0" />
          </Svg>
        </View>
      </Page>
    </Document>
  );
};
