import React from 'react';

interface UnblockUserIconProps {
  width?: number;
  height?: number;
  fill?: string;
}

const UnblockUserIcon = ({ width = 16, height = 18, fill = 'none' }: UnblockUserIconProps) => (
  <svg width={width} height={height} viewBox="0 0 16 19" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.66667 11.8767V13.6183C7.91244 13.3517 7.10523 13.2699 6.31284 13.3798C5.52045 13.4898 4.766 13.7882 4.11286 14.2502C3.45972 14.7121 2.92696 15.324 2.5593 16.0345C2.19165 16.745 1.99985 17.5334 2 18.3333L0.333336 18.3325C0.333077 17.3149 0.56577 16.3108 1.01358 15.397C1.4614 14.4832 2.11245 13.6841 2.91685 13.0609C3.72125 12.4376 4.65765 12.0068 5.6543 11.8014C6.65094 11.596 7.68139 11.6214 8.66667 11.8758V11.8767ZM7 10.8333C4.2375 10.8333 2 8.59584 2 5.83334C2 3.07084 4.2375 0.833344 7 0.833344C9.7625 0.833344 12 3.07084 12 5.83334C12 8.59584 9.7625 10.8333 7 10.8333ZM7 9.16668C8.84167 9.16668 10.3333 7.67501 10.3333 5.83334C10.3333 3.99168 8.84167 2.50001 7 2.50001C5.15834 2.50001 3.66667 3.99168 3.66667 5.83334C3.66667 7.67501 5.15834 9.16668 7 9.16668ZM11.8275 16.595L14.7733 13.6492L15.9525 14.8275L11.8275 18.9525L8.88084 16.0058L10.06 14.8275L11.8267 16.595H11.8275Z"
      fill="#262321"
    />
  </svg>
);

export default UnblockUserIcon;
