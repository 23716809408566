import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ButtonWrapper, Container, Content, Footer } from './style';
import { Creators as modalActions } from '../../../redux/reducers/modal-reducer';
import Button from '../../../components/Button';

const ModalContent = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { closeModal } = modalActions;

  const closeHandler = () => dispatch(closeModal());

  return (
    <Container>
      <Content>
        <div>
          <em>Effective Date: May 8, 2018</em>
          <address>Last Update: January 24, 2022</address>
          <br />
          <p>
            ADP has an internal Global Data Privacy Policy that applies to all affiliates and associates worldwide. The
            Global Data Privacy Policy helps us ensure that personal data is handled properly. The Global Data Privacy
            Policy governs personal data collected by ADP for its own purposes as well as information provided to us as
            a processor for our Clients. It protects information collected online as well as offline. ADP is committed
            to protecting the privacy and security of personal data that we process in order to provide services to our
            Clients. We receive personal data from our Clients about their current, prospective and former employees as
            well as employee dependents and family members, as needed to provide benefits. This Privacy Statement
            explains our practices with regard to the personal data we receive from our Clients as a processor.
          </p>
          <br />
          <p>
            ADP will collect and process your personal data only as instructed or permitted by our Client (your employer
            or prospective employer) or you. ADP maintains appropriate security controls to protect your information.
          </p>
          <br />
          <p>
            For our Client employees located in the European Economic Area and in Switzerland, ADP has established
            Binding Corporate Rules (BCR) Privacy Code for Client Data Processing Services which have been approved by
            the European Union Data Protection Authorities.
          </p>
          <br />
          <p>
            ADP will disclose your personal data to your employer and to other entities when instructed by your
            employer. We may disclose your personal data to our affiliates and third party processors as needed to
            provide the services that you and your employer have requested. These entities are contractually bound to
            limit the use of your personal data as needed to perform the services. We will also disclose personal data
            when required to do so by law, such as in response to a subpoena, including to law enforcement agencies and
            courts in the United States and other countries where we operate.
          </p>
          <br />
          <p>
            If you have questions about your privacy rights, please contact your employer&apos;s human resources
            department.
          </p>
          <br />
          <h3>International Data Transfers</h3>
          <br />
          <p>
            Where authorized by your employer, ADP will transfer personal data pertaining to individuals located outside
            of the United States to our affiliates and suppliers in the United States and elsewhere in the world,
            pursuant to applicable data protection laws. We will only transfer personal data pertaining to individuals
            located in the European Economic Area as permitted by the ADP Privacy Code for Client Data Processing
            Services. For an overview of the ADP Privacy Code for Client Data Processing Services, which includes the
            list of our affiliates bound by the ADP Privacy Code for Client Data Processing Services, please click&nbsp;
            <a href="https://www.adp.com/-/media/adp/privacy/pdf/bcrpc_en.pdf" target="_blank" rel="noreferrer">
              www.adp.com/-/media/adp/privacy/pdf/bcrpc_en.pdf.
            </a>
          </p>
          <br />
          <h3>Sensitive Personal Data</h3>
          <br />
          <p>
            In the ordinary course of its business, ADP processes sensitive personal data on behalf of your employer,
            such as social security numbers. ADP has implemented reasonable technical, physical and administrative
            safeguards to help protect the sensitive personal data from unlawful use and unauthorized disclosure. All
            ADP associates and contingent workers are required to follow these established procedures, both online and
            offline. Access to sensitive personal data is limited to those associates and contingent workers who have a
            need to access the information to perform tasks for ADP. ADP will only disclose sensitive personal data to
            those service providers, auditors, and/or advisors who are legally or contractually obligated to protect
            them or as required or permitted by law.
          </p>
          <br />
          <h3>Anti-Money Laundering</h3>
          <br />
          <p>
            If your employer has elected to receive services such as money movement services from ADP, ADP may be
            required by applicable laws to process Client employee data for monitoring and other controls needed to
            safeguard the security and integrity of financial transactions including for due diligence, such as
            verifying the identifying of the individual, and the individual’s eligibility to receive products or
            services, such as verifying employment or account status.
          </p>
          <br />
          <h3>How to Lodge a Complaint (European Economic Area (EEA) and Switzerland Client Employees only)</h3>
          <br />
          <p>
            Client employees located in the EEA and Switzerland, as a third party beneficiary, may file a complaint in
            respect of a claim they have for violation of the ADP Privacy Code for Client Data Processing Services or
            applicable law, by contacting the Global Data Privacy and Governance Team at&nbsp;
            <a href="mailto:Privacy@ADP.com">Privacy@ADP.com</a>.
          </p>
          <br />
          <p>
            ADP
            <br />
            Global Data Privacy and Governance Team
            <br />
            MS 325
            <br />
            One ADP Boulevard
            <br />
            Roseland, NJ 07068-1728 USA
          </p>
          <br />
          <p>
            If ADP’s response to your complaint is unsatisfactory, you may file a complaint or claim with the relevant
            regulatory authorities or the courts, in accordance with the provisions of the ADP Privacy Code for Client
            Data Processing Services.
          </p>
          <br />
          <h3>Questions about Your Personal Data</h3>
          <br />
          <p>
            ADP is committed to transparency. We want individuals to understand how we collect and use personal data so
            they may interact with ADP with confidence. The materials provided on this webpage may help you find the
            information you need about privacy at ADP. If you have questions related to the content of this webpage,
            please contact us at&nbsp;
            <a href="mailto:Privacy@ADP.com">Privacy@ADP.com</a>. As an employee of an ADP Client, please reach out to
            your employer for more information regarding the collection and processing of your personal data.
          </p>
          <br />
        </div>
      </Content>
      <Footer>
        <ButtonWrapper>
          <Button primary onClick={closeHandler}>
            {t('modalContent.close')}
          </Button>
        </ButtonWrapper>
      </Footer>
    </Container>
  );
};

export default ModalContent;
