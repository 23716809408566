import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;

  margin-top: 26px;
  width: 100%;
  min-width: 800px;
  flex-direction: column;
  gap: 54px;

  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #262321;
`;

export const Footer = styled.div`
  justify-content: center;
  display: flex;
  justify-content: center;
  gap: 14px;
`;

export const StyledHistoricData = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const HistoricInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
`;
