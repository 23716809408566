import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Container, Footer } from '../../../FormEdit/components/HistoricModal/style';
import Button from '../../../../../components/Button';
import { Creators as modalActions } from '../../../../../redux/reducers/modal-reducer';

interface SubmitModalProps {
  onSubmit: () => void;
  historicDate: string;
}

const SubmitModal = ({ onSubmit, historicDate }: SubmitModalProps) => {
  const dispatch = useDispatch();
  const { closeModal } = modalActions;
  const close = () => dispatch(closeModal());
  const { t } = useTranslation();

  const submitHistoric = () => {
    onSubmit();
    close();
  };

  const message = `${t('form.message')} ${historicDate}?`;

  return (
    <Container>
      <p>{message}</p>
      <Footer>
        <Button onClick={close}>{t('common.back')}</Button>
        <Button onClick={submitHistoric} primary>
          {t('form.restore')}
        </Button>
      </Footer>
    </Container>
  );
};

export default SubmitModal;
