import styled from 'styled-components';
import { MarkableLabel } from '../style';
import { disabledOrDefault } from '../../../utils/a';

export const RadioButtonInput = styled.input.attrs({
  type: 'radio',
  className: 'radio-btn',
})`
  display: none;
  pointer-events: none;
`;

export const RadioButtonLabel = styled(MarkableLabel)<{ disabled?: boolean }>`
  &:before {
    border-radius: 50%;
  }

  .radio-btn:checked + &:before {
    background: ${disabledOrDefault('#476bc3')};
    border-color: ${disabledOrDefault('#476bc3')};
  }

  .radio-btn:checked + & {
    .check {
      stroke-dashoffset: 0;
    }
  }
`;
