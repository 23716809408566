import styled, { css } from 'styled-components';

interface QuestionPreviewProps {
  hover: boolean;
}

export const StyledQuestionPreview = styled.div<QuestionPreviewProps>`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 30px;
  padding: 24px;

  ${({ hover }) =>
    hover &&
    css`
      margin-left: 0;
      background: #f2f5ff;
      border-radius: 16px;
    `}
`;
