import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Creators as modalActions } from '../../redux/reducers/modal-reducer';
import { ActionsWrapper, Container, Title } from './style';
import { ActionsKind, toggleReducer } from './reducers';
import { deleteProductRequest, findAllProductRequest } from '../../requests/product';
import { deleteBusinessUnitRequest, findAllBusinessUnitRequest } from '../../requests/businessUnit';
import { deleteProjectTypeRequest, findAllProjectTypeRequest } from '../../requests/projectType';
import { deleteServiceOfferRequest, findAllServiceOfferRequest } from '../../requests/serviceOffer';
import { Tab, TabPanel, Tabs } from '../../components/Tabs';
import SettingsFormsContext from './context';
import { TableData } from '../../types/TableData';
import { EditIcon } from '../../assets/svgs/EditIcon';
import { DeleteIcon } from '../../assets/svgs/DeleteIcon';
import { ActionIcon } from '../Login/LoginForm/style';
import Table from '../../components/Table';
import Alert from '../../components/Toast/toast';
import ModalContent from '../../components/DeleteModal';
import { Breadcrumbs } from '../../components/BreadCrumb';
import TextButton from '../../components/TextButton';
import Loader from '../../components/Loader';
import { LoaderWrapper } from '../../components/Loader/style';
import { BreadcrumbWrapper } from '../../components/BreadCrumb/style';

export const ProductContent = () => {
  const [products, setProducts] = useState<TableData[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { openModal, closeModal } = modalActions;
  const closeHandler = () => dispatch(closeModal());

  const getProducts = async () => {
    setLoading(true);
    const data = await findAllProductRequest();
    if (data) {
      setProducts(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getProducts();
    return () => {
      setProducts([]);
    };
  }, []);

  const createHandler = () => {
    navigate('/product');
  };

  const editHandler = (data: any) => {
    navigate('/product/edit', { state: { data } });
  };

  const deleteHandler = async (...data: TableData[]) => {
    const deleteSubmit = async () => {
      await deleteProductRequest(data);
      Alert.SUCCESS(t('product.delete'));
      closeHandler();
      getProducts();
    };
    dispatch(
      openModal({
        content: <ModalContent title={t('product.alert')} deleteSubmit={deleteSubmit} />,
      })
    );
  };

  const PRODUCT_ADVICE = t('product.advice');
  const PRODUCT_EMPTY_STATE_TEXTS = {
    EMPTY_FILTER: t('product.emptyFilter'),
    EMPTY_LIST: t('product.emptyList'),
  };

  const render = (element: any) => [
    element.name,
    element.code,
    <ActionsWrapper>
      <ActionIcon
        onClick={() => {
          editHandler(element);
        }}
      >
        <EditIcon />
      </ActionIcon>
      <ActionIcon onClick={() => deleteHandler(element)}>
        <DeleteIcon />
      </ActionIcon>
    </ActionsWrapper>,
  ];

  const headers = [
    {
      head: t('product.registered'),
      key: 'name',
    },
    {
      head: t('product.code'),
      key: 'code',
    },
  ];

  const renderActions = (selectedData: any[]) => [
    <TextButton onClick={() => deleteHandler(...selectedData)} disabled={selectedData.length === 0}>
      <span>{t('common.buttonDelete')}</span>
      <DeleteIcon fill={selectedData.length > 0 ? '#4C65B0' : '#979797'} />
    </TextButton>,
  ];

  return loading ? (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  ) : (
    <Table
      searchKey="name"
      renderActions={renderActions}
      render={render}
      headers={headers}
      emptyStateTexts={PRODUCT_EMPTY_STATE_TEXTS}
      data={products}
      advice={PRODUCT_ADVICE}
      createHandler={createHandler}
    />
  );
};

export const ProjectTypeContent = () => {
  const [projectTypes, setProjectTypes] = useState<TableData[]>([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { openModal, closeModal } = modalActions;
  const closeHandler = () => dispatch(closeModal());

  const getProjectTypes = async () => {
    setLoading(true);
    const data = await findAllProjectTypeRequest();
    if (data) {
      setProjectTypes(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getProjectTypes();
    return () => {
      setProjectTypes([]);
    };
  }, []);

  const createHandler = () => {
    navigate('/project-type');
  };

  const editHandler = (data: any) => {
    navigate('/project-type/edit', { state: { data } });
  };

  const deleteHandler = async (...data: TableData[]) => {
    const deleteSubmit = async () => {
      await deleteProjectTypeRequest(data);
      Alert.SUCCESS(t('project.delete'));
      closeHandler();
      getProjectTypes();
    };
    dispatch(
      openModal({
        content: <ModalContent title={t('project.alert')} deleteSubmit={deleteSubmit} />,
      })
    );
  };

  const PROJECT_TYPE_ADVICE = t('project.advice');
  const PROJECT_TYPE_EMPTY_STATE_TEXTS = {
    EMPTY_FILTER: t('project.emptyFilter'),
    EMPTY_LIST: t('project.emptyList'),
  };

  const render = (element: any) => [
    element.name,
    <ActionsWrapper>
      <ActionIcon
        onClick={() => {
          editHandler(element);
        }}
      >
        <EditIcon />
      </ActionIcon>
      <ActionIcon onClick={() => deleteHandler(element)}>
        <DeleteIcon />
      </ActionIcon>
    </ActionsWrapper>,
  ];

  const headers = [
    {
      head: t('project.registered'),
      key: 'name',
    },
  ];

  const renderActions = (selectedData: any[]) => [
    <TextButton onClick={() => deleteHandler(...selectedData)} disabled={selectedData.length === 0}>
      <span>{t('common.buttonDelete')}</span>
      <DeleteIcon fill={selectedData.length > 0 ? '#4C65B0' : '#979797'} />
    </TextButton>,
  ];

  return loading ? (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  ) : (
    <Table
      searchKey="name"
      renderActions={renderActions}
      render={render}
      headers={headers}
      emptyStateTexts={PROJECT_TYPE_EMPTY_STATE_TEXTS}
      data={projectTypes}
      advice={PROJECT_TYPE_ADVICE}
      createHandler={createHandler}
    />
  );
};

export const BusinessUnitContent = () => {
  const [businessUnits, setBusinessUnits] = useState<TableData[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { openModal, closeModal } = modalActions;
  const closeHandler = () => dispatch(closeModal());

  const getBusinessUnits = async () => {
    setLoading(true);
    const data = await findAllBusinessUnitRequest();
    if (data) {
      setBusinessUnits(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getBusinessUnits();
    return () => {
      setBusinessUnits([]);
    };
  }, []);

  const createHandler = () => {
    navigate('/business-unit');
  };

  const editHandler = (data: any) => {
    navigate('/business-unit/edit', { state: { data } });
  };

  const deleteHandler = async (...data: TableData[]) => {
    const deleteSubmit = async () => {
      await deleteBusinessUnitRequest(data);
      Alert.SUCCESS(t('business.delete'));
      closeHandler();
      getBusinessUnits();
    };
    dispatch(
      openModal({
        content: <ModalContent title={t('business.alert')} deleteSubmit={deleteSubmit} />,
      })
    );
  };

  const BUSINESS_UNIT_ADVICE = t('business.advice');
  const BUSINESS_UNIT_EMPTY_STATE_TEXTS = {
    EMPTY_FILTER: t('business.emptyFilter'),
    EMPTY_LIST: t('business.emptyList'),
  };

  const render = (element: any) => [
    element.name,
    <ActionsWrapper>
      <ActionIcon
        onClick={() => {
          editHandler(element);
        }}
      >
        <EditIcon />
      </ActionIcon>
      <ActionIcon onClick={() => deleteHandler(element)}>
        <DeleteIcon />
      </ActionIcon>
    </ActionsWrapper>,
  ];

  const headers = [
    {
      head: t('business.registered'),
      key: 'name',
    },
  ];

  const renderActions = (selectedData: any[]) => [
    <TextButton onClick={() => deleteHandler(...selectedData)} disabled={selectedData.length === 0}>
      <span>{t('common.buttonDelete')}</span>
      <DeleteIcon fill={selectedData.length > 0 ? '#4C65B0' : '#979797'} />
    </TextButton>,
  ];

  return loading ? (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  ) : (
    <Table
      searchKey="name"
      render={render}
      headers={headers}
      emptyStateTexts={BUSINESS_UNIT_EMPTY_STATE_TEXTS}
      data={businessUnits}
      advice={BUSINESS_UNIT_ADVICE}
      createHandler={createHandler}
      renderActions={renderActions}
    />
  );
};

export const ServiceOfferContent = () => {
  const [serviceOffers, setServiceOffers] = useState<TableData[]>([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { openModal, closeModal } = modalActions;
  const closeHandler = () => dispatch(closeModal());

  const getServiceOffers = async () => {
    setLoading(true);
    const data = await findAllServiceOfferRequest();
    if (data) {
      setServiceOffers(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getServiceOffers();
    return () => {
      setServiceOffers([]);
    };
  }, []);

  const createHandler = () => {
    navigate('/service-offer');
  };

  const editHandler = (data: any) => {
    navigate('/service-offer/edit', { state: { data } });
  };

  const deleteHandler = async (...data: TableData[]) => {
    const deleteSubmit = async () => {
      await deleteServiceOfferRequest(data);
      Alert.SUCCESS(t('serviceOffer.delete'));
      closeHandler();
      getServiceOffers();
    };
    dispatch(
      openModal({
        content: <ModalContent title={t('serviceOffer.alert')} deleteSubmit={deleteSubmit} />,
      })
    );
  };

  const SERVICE_OFFER_ADVICE = t('serviceOffer.advice');
  const SERVICE_OFFER_EMPTY_STATE_TEXTS = {
    EMPTY_FILTER: t('serviceOffer.emptyFilter'),
    EMPTY_LIST: t('serviceOffer.emptyList'),
  };

  const render = (element: any) => [
    element.name,
    <ActionsWrapper>
      <ActionIcon
        onClick={() => {
          editHandler(element);
        }}
      >
        <EditIcon />
      </ActionIcon>
      <ActionIcon onClick={() => deleteHandler(element)}>
        <DeleteIcon />
      </ActionIcon>
    </ActionsWrapper>,
  ];

  const headers = [
    {
      head: t('serviceOffer.registered'),
      key: 'name',
    },
  ];

  const renderActions = (selectedData: any[]) => [
    <TextButton onClick={() => deleteHandler(...selectedData)} disabled={selectedData.length === 0}>
      <span>{t('common.buttonDelete')}</span>
      <DeleteIcon fill={selectedData.length > 0 ? '#4C65B0' : '#979797'} />
    </TextButton>,
  ];

  return loading ? (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  ) : (
    <Table
      searchKey="name"
      render={render}
      headers={headers}
      emptyStateTexts={SERVICE_OFFER_EMPTY_STATE_TEXTS}
      data={serviceOffers}
      advice={SERVICE_OFFER_ADVICE}
      createHandler={createHandler}
      renderActions={renderActions}
    />
  );
};

const SettingsForms = () => {
  const [editState, dispatchEdit] = useReducer(toggleReducer, {
    open: false,
  });
  const [createState, dispatchCreate] = useReducer(toggleReducer, {
    open: false,
  });
  const [searchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState(Number(searchParams.get('selectedTab')) || 0);
  const { t } = useTranslation();

  const context = useMemo(
    () => ({
      editState,
      dispatchEdit,
      createState,
      dispatchCreate,
    }),
    [editState, dispatchEdit, createState, dispatchCreate]
  );

  useEffect(() => {}, [searchParams]);

  useEffect(() => {
    dispatchCreate({ type: ActionsKind.CLOSE });
    dispatchEdit({ type: ActionsKind.CLOSE });
  }, [selectedTab]);

  const handleChangeTabs = (event: MouseEvent, newSelectedTab: number) => {
    setSelectedTab(newSelectedTab);
  };

  return (
    <SettingsFormsContext.Provider value={context}>
      <Container>
        <BreadcrumbWrapper>
          <Breadcrumbs />
        </BreadcrumbWrapper>
        <Title>{t('settingsForms.title')}</Title>
        <Tabs selectedTab={selectedTab} onChange={handleChangeTabs}>
          <Tab label={t('settingsForms.products')} />
          <Tab label={t('settingsForms.projectTypes')} />
          <Tab label={t('settingsForms.businessUnits')} />
          <Tab label={t('settingsForms.serviceOffers')} />
        </Tabs>
        <TabPanel selectedTab={selectedTab} index={0}>
          <ProductContent />
        </TabPanel>
        <TabPanel selectedTab={selectedTab} index={1}>
          <ProjectTypeContent />
        </TabPanel>
        <TabPanel selectedTab={selectedTab} index={2}>
          <BusinessUnitContent />
        </TabPanel>
        <TabPanel selectedTab={selectedTab} index={3}>
          <ServiceOfferContent />
        </TabPanel>
      </Container>
    </SettingsFormsContext.Provider>
  );
};

export default SettingsForms;
