import React, { useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AnimatedTableRow, DisabledCheckbox, InlineFormButton, InlineFormInput, InlineFormWrapper } from './style';
import Alert from '../../../../components/Toast/toast';
import { ActionIcon, TableData, TableDataWrapper } from '../../../../components/Table/style';
import { EditIcon } from '../../../../assets/svgs/EditIcon';
import { DeleteIcon } from '../../../../assets/svgs/DeleteIcon';

interface InlineFormProps {
  defaultValue?: string;
  submitRequest: (email: string) => void;
}

interface InlineEditProps {
  defaultValue: string;
  handler: (data: any, prevData: any) => void;
  open: boolean;
}

interface InlineCreateProps {
  createHandler: (email: string) => void;
  setCreateState: (state: boolean) => void;
}

const InlineForm = ({ defaultValue = '', submitRequest }: InlineFormProps) => {
  const inputEl = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: defaultValue,
    },
  });

  const submit = (data: any) => {
    const { email } = data;
    submitRequest(email);
  };

  useEffect(() => {
    inputEl?.current?.focus();
  }, []);

  return (
    <form onSubmit={handleSubmit(submit)}>
      <InlineFormWrapper>
        <Controller
          name="email"
          rules={{
            required: 'Campo obrigatório',
          }}
          control={control}
          render={({ field }) => <InlineFormInput errors={Boolean(errors.email)} {...field} ref={inputEl} />}
        />
        <InlineFormButton>{t('common.save')}</InlineFormButton>
      </InlineFormWrapper>
    </form>
  );
};

export const InlineCreate = ({ createHandler, setCreateState }: InlineCreateProps) => {
  const closeCreateAction = () => {
    setCreateState(false);
  };

  const submitRequest = async (value: string) => {
    try {
      await createHandler(value);
      closeCreateAction();
    } catch (e: any) {
      Alert.SUCCESS(e.message);
    }
  };

  return (
    <AnimatedTableRow>
      <TableData>
        <TableDataWrapper>
          <DisabledCheckbox />
        </TableDataWrapper>
      </TableData>
      <TableData>
        <TableDataWrapper>
          <InlineForm submitRequest={submitRequest} />
        </TableDataWrapper>
      </TableData>
      <TableData>
        <TableDataWrapper justify="flex-end">
          <ActionIcon disabled>
            <EditIcon />
          </ActionIcon>
          <ActionIcon onClick={closeCreateAction}>
            <DeleteIcon />
          </ActionIcon>
        </TableDataWrapper>
      </TableData>
    </AnimatedTableRow>
  );
};

export const InlineEdit = ({ open, defaultValue, handler }: InlineEditProps) => {
  const submitRequest = async (data: any) => {
    try {
      await handler(data, defaultValue);
    } catch (e: any) {
      Alert.ERROR(e.message);
    }
  };

  return open ? <InlineForm defaultValue={defaultValue} submitRequest={submitRequest} /> : <span>{defaultValue}</span>;
};
