import { createGlobalStyle } from 'styled-components';
// @ts-ignore
import Circular20 from '../assets/fonts/Circular20.otf';

export const FontStyles = createGlobalStyle`
@font-face {
  font-family: 'Circular20';
  src: url(${Circular20}) format('opentype'),
  url(${Circular20}) format('opentype');
}
`;
