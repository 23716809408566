import React, { useContext } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReplyFormContext from '../../../../context';
import { AnswerContainer, FieldTitle } from '../../style';
import { InputWrapper } from '../../../../../Form/components/QuestionForm/style';
import Input from '../../../../../../components/Input';
import FormError from '../../../../../../components/FormError';

const InputEmail = ({ errors, wId, register, type, title, control, accessFieldsName }: any) => {
  const { formDisabled, allowListEmail, blockListEmail } = useContext(ReplyFormContext);
  const REGEX_EMAIL = /\S+@\S+\.\S+/;
  const { t } = useTranslation();

  const validateEmail = (email: string) => REGEX_EMAIL.test(email);

  const validateIfEmailIsValid = (email: string) => {
    if (!validateEmail(email) && !email.split('@')[1]) {
      return t('questions.fillValidEmail');
    }

    const provider = `@${email.split('@')[1]}`;

    if (allowListEmail.includes(email)) return true;
    const matchBlockList = blockListEmail.some((str: string) =>
      str === provider ? true : str.endsWith('*') && provider.includes(str.replace('*', ''))
    );

    if (matchBlockList) {
      return t('questions.emailNotMatchRules');
    }

    return true;
  };

  return (
    <AnswerContainer>
      <FieldTitle>{title}</FieldTitle>
      <input type="hidden" name={`${accessFieldsName}.wId`} {...register(`${accessFieldsName}.wId`)} value={wId} />
      <InputWrapper>
        <Controller
          name={`${accessFieldsName}.answer`}
          control={control}
          rules={{
            required: t<string>('common.required'),
            validate: (value) => validateIfEmailIsValid(value),
          }}
          render={({ field }) => <Input {...field} type={type} disabled={formDisabled} />}
        />
        <FormError errors={errors} name={`${accessFieldsName}.answer`} />
      </InputWrapper>
    </AnswerContainer>
  );
};

export default InputEmail;
