import api from '../http/api';
import { TableData } from '../types/TableData';

const VARIABLES_PATH = '/variable';

export const findAllVariablesRequest = async () => {
  const { data } = await api.get(VARIABLES_PATH);
  return data;
};

export const findVariableTypeByIdRequest = async (id: number | string) => {
  const { data } = await api.get(`${VARIABLES_PATH}/${id}`);
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const template = {
    formWId: 'wid_8cccae14-c816-43bd-bd1b-4116b3563ef4',
    questionWId: 'wid_816512df-9c11-482d-a035-2ee137a0a4da',
    name: 'rwe',
    code: 'rwe',
    productType: null,
    projectType: '628fd83e515ed40009496bff',
    businessUnit: null,
    serviceOffer: null,
    formType: 'COMPANY',
  };
  // return template;
  return data;
};

export const addVariableRequest = async (data: TableData) => (await api.post(VARIABLES_PATH, data)).data;

export const updateVariableRequest = async (data: TableData, id: string) => {
  await api.put(`${VARIABLES_PATH}/${id}`, data);
};

export const deleteVariableRequest = async (data: TableData[]) => {
  await api.patch(VARIABLES_PATH, data);
};
