const getEnvironment = () => {
  let baseUrl = process.env.REACT_APP_API;

  // @ts-ignore
  if (window?.env?.REACT_APP_API) {
    // @ts-ignore
    baseUrl = `${window.env.REACT_APP_API}`;
  }

  return baseUrl;
};

export default getEnvironment;
