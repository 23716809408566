import styled from 'styled-components';

export const StyledLinkItem = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  svg > path {
    background: #476bc3;
  }
`;

export const StyledLink = styled.a`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #476bc3;
`;
