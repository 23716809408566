import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Title, Subtitle, StyledIconArrow } from './style';
import Button from '../../../components/Button';

interface RequestProps {
  setForm?: any;
}
const RequestSent = ({ setForm }: RequestProps) => {
  const { t } = useTranslation();

  return (
    <Form>
      <Title>
        {t('login.requestTitle')}
        <Subtitle>{t('login.requestSubtitle')}</Subtitle>
      </Title>
      <Button primary full mt={20} onClick={() => setForm('login')}>
        {t('login.requestButton')}
        <StyledIconArrow />
      </Button>
    </Form>
  );
};

export default RequestSent;
