import React from 'react';

export interface Ellipse18Props {
  width?: number;
  height?: number;
  className?: string;
}

export const Ellipse18 = ({ width = 106, height = 102, className }: Ellipse18Props) => (
  <svg
    id="el18"
    className={className}
    width={width}
    height={height}
    viewBox="0 0 106 102"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g style={{ mixBlendMode: 'soft-light' }}>
      <ellipse cx="53" cy="51" rx="51" ry="53" transform="rotate(90 53 51)" fill="#FAAC82" fillOpacity="0.4" />
    </g>
  </svg>
);
