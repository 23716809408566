import styled from 'styled-components';

export const StyledSubTitleDanger = styled.div`
  background-color: #f1615d;
  align-self: center;
  width: 2px;
  height: 2px;
  border-radius: 15px;
  padding: 10px;
`;

export const StyledSubTitleWarning = styled.div`
  background-color: #577ef4;
  align-self: center;
  width: 2px;
  height: 2px;
  border-radius: 15px;
  padding: 10px;
`;

export const StyledSubTitleSuccess = styled.div`
  background-color: #99e596;
  align-self: center;
  width: 2px;
  height: 2px;
  border-radius: 15px;
  padding: 10px;
`;

export const StyledSubTitle = styled.div`
  width: 100%;
  margin-bottom: 15px;
`;

export const DangerWrapper = styled.div`
  padding: 10px 0;
  border-bottom: 1px solid #d8d8d8;
  display: flex;
  gap: 12px;
`;

export const WarningWrapper = styled.div`
  padding: 10px 0;
  border-bottom: 1px solid #d8d8d8;
  display: flex;
  gap: 12px;
`;

export const SuccessWrapper = styled.div`
  padding: 10px 0;
  display: flex;
  gap: 12px;
`;

export const Text = styled.div``;

export const Value = styled.div`
  width: 299px;
  height: 40px;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  letter-spacing: -1px;
`;

export const SubTitleWrapper = styled.div`
  width: 100%;
  color: #000;
`;
