import React from 'react';

interface CloseIconProps {
  width?: number;
  height?: number;
}

export const CloseIcon = ({ width = 16, height = 16 }: CloseIconProps) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.2929 2.31811C15.6834 1.92758 15.6834 1.29442 15.2929 0.903893L15.0961 0.707107C14.7056 0.316583 14.0724 0.316583 13.6819 0.707107L8 6.389L2.31811 0.707108C1.92758 0.316583 1.29442 0.316583 0.903893 0.707107L0.707107 0.903893C0.316583 1.29442 0.316582 1.92758 0.707107 2.31811L6.389 8L0.707108 13.6819C0.316583 14.0724 0.316583 14.7056 0.707107 15.0961L0.903893 15.2929C1.29442 15.6834 1.92758 15.6834 2.31811 15.2929L8 9.611L13.6819 15.2929C14.0724 15.6834 14.7056 15.6834 15.0961 15.2929L15.2929 15.0961C15.6834 14.7056 15.6834 14.0724 15.2929 13.6819L9.611 8L15.2929 2.31811Z"
      fill="#262321"
    />
  </svg>
);
