import React, { useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ActionsWrapper, Container, TextWrapper, Title } from './style';
import UserManagementContext from './context';
import { ActionsKind, toggleReducer } from './reducers';
import { Creators as modalActions } from '../../redux/reducers/modal-reducer';
import {
  addAdminUserRequest,
  blockUsersRequest,
  changeAdminUserType,
  findAllUserManagementRequest,
  unblockUsersRequest,
} from '../../requests/userManagement';
import Alert from '../../components/Toast/toast';
import Table from '../../components/Table';
import { TabPanel } from '../../components/Tabs';
import { EditIcon } from '../../assets/svgs/EditIcon';
import { ActionIcon } from '../../components/Table/style';
import { TableData } from '../../types/TableData';
import { Breadcrumbs } from '../../components/BreadCrumb';
import TextButton from '../../components/TextButton';
import BlockUserIcon from '../../assets/svgs/BlockUserIcon';
import { LoaderWrapper } from '../../components/Loader/style';
import Loader from '../../components/Loader';
import UnblockUserIcon from '../../assets/svgs/UnblockUserIcon';
import DefaultModalContent from '../../components/DefaultModalContent';
import { BreadcrumbWrapper } from '../../components/BreadCrumb/style';
import { SelectInlineCreate, SelectInlineEdit } from './components/InlineForm';
import { UserModalContent } from './components/UserModalContent';
import { findAllUserNoFilter, saveUserFilter } from '../../requests/userFilterRestriction';
import FilterIconDashboard from '../../assets/svgs/FilterIconDashboard';

export const UserManagementContent = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<TableData[]>([]);
  const [defaultFilters, setDefaultFilters] = useState<any>({});
  const { editState, dispatchEdit } = useContext(UserManagementContext);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { openModal } = modalActions;

  const getUsers = async () => {
    setLoading(true);
    const data = await findAllUserManagementRequest();
    const dataFilter = await findAllUserNoFilter();
    if (data) {
      setUsers(data);
    }
    if (dataFilter) {
      setDefaultFilters(dataFilter);
    }
    setLoading(false);
  };

  useEffect(() => {
    getUsers();
  }, []);

  const BLOCK_LIST_ADVICE = t('userManagement.advice');
  const EMPTY_STATE_TEXTS = {
    EMPTY_FILTER: t('userManagement.emptyFilter'),
    EMPTY_LIST: t('userManagement.emptyList'),
  };

  const createHandler = async (receivedData: any) => {
    const data = {
      email: receivedData.email,
      type: receivedData.type.value,
    };

    try {
      await addAdminUserRequest(data);
      await getUsers();
      Alert.SUCCESS(t('userManagement.add'));
    } catch (e: any) {
      Alert.ERROR(e.response.data.message);
    }
  };

  const editUserTypeHandler = async (type: string, id: string) => {
    try {
      await changeAdminUserType(type, id);
      await getUsers();
      dispatchEdit({ type: ActionsKind.CLOSE });
      Alert.SUCCESS(t('userManagement.edit'));
    } catch (e: any) {
      Alert.ERROR(e.response.data.message);
    }
  };

  const toggleEditHandler = async (data: TableData) => {
    if (editState.open) {
      return dispatchEdit({ type: ActionsKind.CLOSE });
    }
    return dispatchEdit({ type: ActionsKind.OPEN, payload: data._id });
  };

  const blockHandler = async (...data: TableData[]) => {
    const blockSubmit = () => {
      const filteredData = data.filter((item) => item.status === 'ACTIVE');

      try {
        blockUsersRequest(filteredData);
        const ids = filteredData.map((item) => item._id);
        setUsers((prevState) =>
          prevState.map((customUsers) =>
            ids.includes(customUsers._id) ? { ...customUsers, status: 'INACTIVE' } : customUsers
          )
        );
        const alert = filteredData.length > 1 ? t('userManagement.usersBlocked') : t('userManagement.userBlock');
        Alert.SUCCESS(alert);
      } catch (e: any) {
        Alert.ERROR(e?.response?.data?.message);
      }
    };

    const message = data.length > 1 ? t('userManagement.pluralMessageBlock') : t('userManagement.singleMessageBlock');

    dispatch(
      openModal({
        content: <DefaultModalContent onSubmit={blockSubmit} title={message} submitButtonTitle={t('common.block')} />,
      })
    );
  };

  const unblockHandler = async (...data: TableData[]) => {
    const blockSubmit = () => {
      const filteredData = data.filter((item) => item.status === 'INACTIVE');

      try {
        unblockUsersRequest(filteredData);
        const ids = filteredData.map((item) => item._id);
        setUsers((prevState) =>
          prevState.map((customUsers) =>
            ids.includes(customUsers._id) ? { ...customUsers, status: 'ACTIVE' } : customUsers
          )
        );
        const alert = filteredData.length > 1 ? t('userManagement.usersUnlocked') : t('userManagement.userUnlock');
        Alert.SUCCESS(alert);
      } catch (e: any) {
        Alert.ERROR(e?.response?.data?.message);
      }
    };

    const message = data.length > 1 ? t('userManagement.pluralMessageUnlock') : t('userManagement.singleMessageUnlock');

    dispatch(
      openModal({
        content: <DefaultModalContent onSubmit={blockSubmit} title={message} submitButtonTitle={t('common.unlock')} />,
      })
    );
  };

  const updateUserFilters = async (id: string, data: any) => {
    await saveUserFilter(id, data);
    getUsers();
  };

  const render = (element: any) => [
    <TextWrapper disabled={element.status === 'INACTIVE'}>{element?.name}</TextWrapper>,
    <SelectInlineEdit
      open={editState.open && element._id === editState.data}
      disabled={element.status === 'INACTIVE'}
      handler={editUserTypeHandler}
      value={element.type}
      id={element._id}
      label={t(`constants.${element.type}`)}
    />,
    <ActionsWrapper>
      <ActionIcon disabled={element.status === 'INACTIVE'} onClick={() => toggleEditHandler(element)}>
        <EditIcon />
      </ActionIcon>
      {element.status === 'INACTIVE' ? (
        <ActionIcon onClick={() => unblockHandler(element)}>
          <BlockUserIcon />
        </ActionIcon>
      ) : (
        <ActionIcon onClick={() => blockHandler(element)}>
          <UnblockUserIcon />
        </ActionIcon>
      )}
      <ActionIcon
        onClick={() =>
          dispatch(
            openModal({
              title: t('dashboardAdmin.filter'),
              content: <UserModalContent user={element} defaultFilters={defaultFilters} onSubmit={updateUserFilters} />,
            })
          )
        }
      >
        <FilterIconDashboard />
      </ActionIcon>
    </ActionsWrapper>,
  ];

  const headers = [
    {
      head: t('userManagement.usersRegistered'),
      key: 'name',
    },
    {
      head: t('userManagement.type'),
      key: 'type',
    },
  ];

  const renderActions = (selectedData: any[]) => [
    <TextButton
      key="BlockForm"
      onClick={() => blockHandler(...selectedData)}
      disabled={
        selectedData.length === 0 ||
        !selectedData.some((selectedElement) => !selectedElement.status || selectedElement.status === 'ACTIVE')
      }
    >
      <span>{t('common.block')}</span>
      <UnblockUserIcon fill={selectedData.length > 0 ? '#4C65B0' : '#979797'} />
    </TextButton>,
    <TextButton
      key="DesblockForm"
      onClick={() => unblockHandler(...selectedData)}
      disabled={
        selectedData.length === 0 || !selectedData.some((selectedElement) => selectedElement.status === 'INACTIVE')
      }
    >
      <span>{t('common.unlock')}</span>
      <BlockUserIcon fill={selectedData.length > 0 ? '#4C65B0' : '#979797'} />
    </TextButton>,
  ];

  return loading ? (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  ) : (
    <Table
      InlineCreate={SelectInlineCreate}
      emptyStateTexts={EMPTY_STATE_TEXTS}
      render={render}
      data={users}
      createHandler={createHandler}
      headers={headers}
      advice={BLOCK_LIST_ADVICE}
      renderActions={renderActions}
      hasInlineCreate
      searchKey="name"
    />
  );
};

const UserManagement = () => {
  const [editState, dispatchEdit] = useReducer(toggleReducer, { open: false });
  const [createState, dispatchCreate] = useReducer(toggleReducer, { open: false });
  const [selectedTab] = useState(0);
  const { t } = useTranslation();

  const context = useMemo(
    () => ({
      editState,
      dispatchEdit,
      createState,
      dispatchCreate,
    }),
    [editState, dispatchEdit, createState, dispatchCreate]
  );

  useEffect(() => {
    dispatchCreate({ type: ActionsKind.CLOSE });
    dispatchEdit({ type: ActionsKind.CLOSE });
  }, [selectedTab]);

  return (
    <UserManagementContext.Provider value={context}>
      <Container>
        <BreadcrumbWrapper>
          <Breadcrumbs />
        </BreadcrumbWrapper>
        <Title>{t('userManagement.usersRegistered')}</Title>
        <TabPanel>
          <UserManagementContent />
        </TabPanel>
      </Container>
    </UserManagementContext.Provider>
  );
};

export default UserManagement;
