import React, { createElement, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import FormContext from '../../../../context';
import { FieldType } from '../../../../../types/FieldType';
import { Box, BoxActions } from '../../../../style';
import { EditIcon } from '../../../../../../../assets/svgs/EditIcon';
import ButtonGroup from '../../../../../../../components/ButtonGroup';
import { ButtonGroupItem } from '../../../../../../../components/ButtonGroup/style';
import { DeleteIcon } from '../../../../../../../assets/svgs/DeleteIcon';
import CreateQuestionIcon from '../../../../../../../assets/svgs/CreateQuestionIcon';
import RuleIcon from '../../../../../../../assets/svgs/RuleIcon';
import { Creators as modalActions } from '../../../../../../../redux/reducers/modal-reducer';
import RulesModal from '../../../../../components/RulesModal';
import QuestionForm from '../../../../../components/QuestionForm';
import { INITIAL_CONDITION_VALUE, questionTypesWithPanel } from '../../../../../constants';
import MultipleChoice from '../../../../../../../components/MultipleChoice';
import SingleChoice from '../../../../../../../components/SingleChoice';
import Panel from '../../../../../components/CreatableField/components/Panel';
import { RuleButton } from '../../../../../style';
import Download from '../../../../../components/CreatableField/components/Download';
import { QuestionFields } from '../../../../../types/Question';

const creatableFields = new Map();
creatableFields.set(FieldType.MULTIPLE_CHOICE, createElement(MultipleChoice));
creatableFields.set(FieldType.SINGLE_CHOICE, createElement(SingleChoice));
creatableFields.set(FieldType.DOWNLOAD, createElement(Download));
creatableFields.set(FieldType.PANEL, createElement(Panel));

const QuestionBox = ({ id, deleteHandler, index, blockTypeChange, blockDeletion, blockRulesChange }: any) => {
  const { getValues, update, watch, trigger, enableMapToDefaultValues } = useContext(FormContext);
  const watchSelectedField = watch(`questions[${index}].type`);
  const watchRules = watch(`questions[${index}].rules`);
  const dispatch = useDispatch();
  const { openModal } = modalActions;
  const childPanelRef = React.useRef(null);
  const { t } = useTranslation();

  const moldableFieldTypes = [FieldType.MULTIPLE_CHOICE, FieldType.SINGLE_CHOICE, FieldType.PANEL, FieldType.DOWNLOAD];
  const selectedFieldIsMoldable = watchSelectedField?.value && moldableFieldTypes.includes(watchSelectedField?.value);
  const selectedFieldIsPanel = watchSelectedField?.value && watchSelectedField?.value === FieldType.PANEL;

  const hasRules = Boolean(watchRules);

  const openRulesModal = () => {
    const defaultValues = {
      booleanConditions: watchRules ? watchRules.booleanConditions : [],
      conditions: watchRules ? watchRules.conditions : [INITIAL_CONDITION_VALUE],
      action: watchRules ? watchRules.action : null,
    };

    const values = getValues(`questions[${index}]`);
    const handler = async (data: any) => {
      const updatedValues = {
        ...values,
        rules: data,
      };
      await update(index, updatedValues);
    };
    const flatQuestions = (data: QuestionFields[]) =>
      data.flatMap((question) => (Array.isArray(question.questions) ? [question, ...question.questions] : question));

    const flattedQuestions = flatQuestions(getValues('questions'));
    dispatch(
      openModal({
        title: t('form.panelTitle'),
        content: (
          <RulesModal
            handler={handler}
            defaultValues={defaultValues}
            questionId={id}
            flattedQuestions={flattedQuestions}
            enableMapToDefaultValues={enableMapToDefaultValues}
          />
        ),
      })
    );
  };

  const submit = async () => {
    const isValidEntries = await trigger(`questions[${index}]`);
    if (!isValidEntries) return;
    const values = getValues(`questions[${index}]`);

    const appendSavedPropertyToChildQuestion = (data: any[]) => data.map((item) => ({ ...item, saved: true }));

    const type = values.type.value;
    const { questions } = values;

    const newValues = {
      ...values,
      saved: true,
      questions: type === FieldType.PANEL ? appendSavedPropertyToChildQuestion(questions) : questions,
    };

    await update(index, newValues);
  };

  const accessFieldsName = `questions[${index}]`;

  return (
    <Box>
      <QuestionForm
        accessFieldsName={accessFieldsName}
        selectedFieldIsMoldable={selectedFieldIsMoldable}
        selectedField={watchSelectedField}
        nestIndex={index}
        childPanelRef={childPanelRef}
        id={id}
        creatableFields={creatableFields}
        fieldTypes={questionTypesWithPanel()}
        {...{ blockTypeChange }}
      />
      <BoxActions>
        <ButtonGroup>
          <ButtonGroupItem onClick={submit}>
            <p>{t('common.save')}</p>
            <EditIcon />
          </ButtonGroupItem>
          {!blockRulesChange && (
            <RuleButton hasRules={hasRules} onClick={() => openRulesModal()}>
              <p>{t('form.input')}</p>
              <RuleIcon />
            </RuleButton>
          )}
          {selectedFieldIsPanel && (
            <ButtonGroupItem ref={childPanelRef}>
              <p>{t('form.new')}</p>
              <CreateQuestionIcon />
            </ButtonGroupItem>
          )}
          {!blockDeletion && (
            <ButtonGroupItem onClick={() => deleteHandler(index)}>
              <p>{t('common.delete')}</p>
              <DeleteIcon />
            </ButtonGroupItem>
          )}
        </ButtonGroup>
      </BoxActions>
    </Box>
  );
};

export default QuestionBox;
