import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ChoiceContainer, Container, DeleteIconWrapper, Title } from './style';
import { NodeTreeIcon } from '../../assets/svgs/NodeTreeIcon';
import TextButton from '../TextButton';
import Input from '../Input';
import Checkbox from '../MarkableButton/Checkbox';
import { DeleteIcon } from '../../assets/svgs/DeleteIcon';
import FormError from '../FormError';
import Alert from '../Toast/toast';

interface ChoiceProps {
  deleteHandler: (index: number) => void;
  id: string;
  index: number;
  accessFieldsName: string;
  control: any;
  errors: any;
}

const Choice = ({ deleteHandler, id, errors, index, control, accessFieldsName }: ChoiceProps) => {
  const [checked, setChecked] = useState<boolean>(false);
  const name = accessFieldsName ? `${accessFieldsName}.choices[${index}].title` : `choices[${index}].title`;
  const { t } = useTranslation();

  return (
    <>
      <ChoiceContainer>
        <Checkbox id={id} checked={checked} onChange={() => setChecked(!checked)} />
        <Controller
          name={name}
          control={control}
          rules={{
            required: t<string>('multipleChoice.completeOption'),
          }}
          render={({ field }) => <Input {...field} type="text" />}
        />
        <DeleteIconWrapper onClick={() => deleteHandler(index)}>
          <DeleteIcon />
        </DeleteIconWrapper>
      </ChoiceContainer>
      <FormError errors={errors} name={name} />
    </>
  );
};

const MultipleChoice = ({ control, errors, accessFieldsName, hasLabel = true }: any) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: accessFieldsName ? `${accessFieldsName}.choices` : 'choices',
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (fields.length === 0) {
      append({ title: '' });
    }
  }, []);

  const deleteHandler = (index: number) => {
    if (fields.length > 1) return remove(index);

    Alert.WARNING(t('questions.choiceDeleteWarn'));
  };

  return (
    <Container>
      {hasLabel && <Title>{t('multipleChoice.title')}</Title>}
      {fields.map((item, index) => (
        <Choice
          key={item.id}
          id={item.id}
          index={index}
          deleteHandler={deleteHandler}
          control={control}
          accessFieldsName={accessFieldsName}
          errors={errors}
        />
      ))}
      <TextButton style={{ justifyContent: 'left' }} onClick={() => append({ title: '' })}>
        <p>{t('multipleChoice.addChoice')}</p>
        <NodeTreeIcon />
      </TextButton>
    </Container>
  );
};

export default MultipleChoice;
