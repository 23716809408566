import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import { Container, FooterWrapper, HistoricInfo } from './style';
import HistoricIcon from '../../../assets/svgs/HistoricIcon';
import { findFormByIdAndVersionRequest, restoreVersionRequest } from '../../../requests/forms';
import formatReceivedFormData from '../utils/formatReceivedFormData';
import Alert from '../../../components/Toast/toast';
import HistoricFormFields from './components/HistoricFormFields';
import { Creators as modalActions } from '../../../redux/reducers/modal-reducer';
import SubmitModal from './components/SubmitModal';
import Footer from '../../../components/Form/Footer';
import { Breadcrumbs } from '../../../components/BreadCrumb';
import { BreadcrumbWrapper } from '../../../components/BreadCrumb/style';
import { LoaderWrapper } from '../../../components/Loader/style';
import Loader from '../../../components/Loader';

type QueryParams = {
  id: string;
  version: string;
};

const FormHistoric = () => {
  const [formData, setFormData] = useState();
  const [loading, setLoading] = useState(true);
  const { id, version: versionNumber } = useParams<QueryParams>();
  const navigate = useNavigate();
  const location = useLocation();
  const { date } = location.state as { date: string };
  const dispatch = useDispatch();
  const { openModal } = modalActions;
  const { t } = useTranslation();

  const fetchFormDataById = async () => {
    const data = await findFormByIdAndVersionRequest(id, versionNumber);

    if (data) {
      const formattedData = await formatReceivedFormData(data);
      setFormData(formattedData);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchFormDataById();
  }, []);

  const historicDate = useMemo(() => {
    if (!date) return '';

    return date.split('T')[0].split('-').reverse().join('/');
  }, [date]);

  const onSubmit = async () => {
    const historicSubmit = async () => {
      try {
        if (id && versionNumber) await restoreVersionRequest(id, versionNumber);
        Alert.SUCCESS(t('form.published'));
      } catch (e: any) {
        Alert.ERROR(e.message);
      }
      navigate(`/forms/edit/${id}`);
    };

    dispatch(
      openModal({
        content: <SubmitModal onSubmit={historicSubmit} historicDate={historicDate} />,
      })
    );
  };

  const backHandler = () => {
    navigate(`/forms/edit/${id}`);
  };

  return loading ? (
    <LoaderWrapper>
      <Loader text={t('form.search')} />
    </LoaderWrapper>
  ) : (
    <Container>
      <BreadcrumbWrapper>
        <Breadcrumbs />
      </BreadcrumbWrapper>
      <HistoricFormFields formData={formData} />
      <Footer>
        <FooterWrapper>
          <HistoricInfo>
            <p>
              {t('form.showing')} {historicDate}
            </p>
            <HistoricIcon />
          </HistoricInfo>
          <FooterWrapper>
            <Button onClick={backHandler}>{t('common.cancel')}</Button>
            <Button onClick={onSubmit} primary>
              {t('form.restore')}
            </Button>
          </FooterWrapper>
        </FooterWrapper>
      </Footer>
    </Container>
  );
};

export default FormHistoric;
