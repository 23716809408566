export const getHiddenReference = (answer: any, branch?: boolean, entry?: boolean) => {
  if (entry) return `entry[${answer.entry}][${answer.wId}]`;
  return branch ? `branch[${answer.branchId}][${answer.wId}]` : `questions[${answer.wId}]`;
};

export function getBranchStatement(branchIndex: number | undefined, entryIndex: number | undefined) {
  if (entryIndex !== undefined) return `entries[${entryIndex}].`;
  if (branchIndex !== undefined) return `branches[${branchIndex}].`;
  return '';
}
