import React, { createElement, useContext, useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FieldType } from '../../../../types/FieldType';
import ButtonGroup from '../../../../../../components/ButtonGroup';
import { ButtonGroupItem } from '../../../../../../components/ButtonGroup/style';
import { DeleteIcon } from '../../../../../../assets/svgs/DeleteIcon';
import { defaultQuestionTypes, INITIAL_CONDITION_VALUE, INITIAL_VALUE_QUESTION } from '../../../../constants';
import { PanelLine } from './style';
// import MoveIcon from '../../../../../../assets/svgs/MoveIcon';
import FormContext from '../../../../GenericFormFields/context';
import { Box, BoxActions } from '../../../../GenericFormFields/style';
import RuleIcon from '../../../../../../assets/svgs/RuleIcon';
import RulesModal from '../../../RulesModal';
import { Creators as modalActions } from '../../../../../../redux/reducers/modal-reducer';
import QuestionForm from '../../../QuestionForm';
import MultipleChoice from '../../../../../../components/MultipleChoice';
import SingleChoice from '../../../../../../components/SingleChoice';
import { RuleButton } from '../../../../style';
import Download from '../Download';
import { QuestionFields } from '../../../../types/Question';
import Alert from '../../../../../../components/Toast/toast';
import { DownIcon } from '../../../../../../assets/svgs/DownIcon';
import { UpIcon } from '../../../../../../assets/svgs/UpIcon';

const creatableFields = new Map();
creatableFields.set(FieldType.DOWNLOAD, createElement(Download));
creatableFields.set(FieldType.MULTIPLE_CHOICE, createElement(MultipleChoice));
creatableFields.set(FieldType.SINGLE_CHOICE, createElement(SingleChoice));

interface PanelProps {
  remove: (index: number) => void;
  id: string;
  wId: string;
  index: number;
  nestIndex: number;
  length: number;
  dragHandleProps: any;
}

const PanelBox = ({ length, id, wId, remove, index, nestIndex, dragHandleProps }: PanelProps) => {
  const { watch, getValues, update, enableMapToDefaultValues } = useContext(FormContext);
  const dispatch = useDispatch();
  const { openModal } = modalActions;
  const watchSelectedField = watch(`questions[${nestIndex}].questions[${index}].type`);
  const selectedFieldIsMoldable = watchSelectedField?.value && creatableFields.has(watchSelectedField?.value);
  const { t } = useTranslation();

  const accessFieldsName = `questions[${nestIndex}].questions[${index}]`;
  const watchRules = watch(`${accessFieldsName}.rules`);

  const hasRules = Boolean(watchRules);

  const openRulesModal = () => {
    const defaultValues = {
      booleanConditions: watchRules ? watchRules.booleanConditions : [],
      conditions: watchRules ? watchRules.conditions : [INITIAL_CONDITION_VALUE],
      action: watchRules ? watchRules.action : null,
    };

    const handler = async (data: any) => {
      const panelFields = getValues(`questions[${nestIndex}]`);
      const panelQuestions = getValues(`questions[${nestIndex}].questions`);

      panelQuestions[index].rules = data;

      const updatedValues = {
        ...panelFields,
        questions: panelQuestions,
      };

      await update(nestIndex, updatedValues);
    };
    const flatQuestions = (data: QuestionFields[]) =>
      data.flatMap((question) => (Array.isArray(question.questions) ? [question, ...question.questions] : question));

    const flattedQuestions = flatQuestions(getValues('questions'));
    dispatch(
      openModal({
        title: t('form.panelTitle'),
        content: (
          <RulesModal
            handler={handler}
            defaultValues={defaultValues}
            questionId={wId}
            flattedQuestions={flattedQuestions}
            enableMapToDefaultValues={enableMapToDefaultValues}
          />
        ),
      })
    );
  };

  const deleteHandler = () => {
    if (length === 1) {
      Alert.WARNING(t('questions.panelDeleteWarn'));
      return;
    }

    const question = getValues(accessFieldsName);
    const questionId = question?.wId ?? question?.id;

    const flatQuestions = (data: QuestionFields[]) =>
      data.flatMap((q) => (Array.isArray(q.questions) ? [q, ...q.questions] : q));

    const flattedQuestions = flatQuestions(getValues('questions'));
    const hasDependentsQuestions = flattedQuestions.some((q: any) => {
      const ids = q?.rules?.conditions.map((condition: any) => condition?.id?.value ?? condition?.id);
      return ids?.includes(questionId);
    });

    if (hasDependentsQuestions) {
      Alert.INFO(t('questions.removeAssociatedQuestions'));
      return;
    }

    const internalVariables = getValues?.('internalVariables');
    const dependentInternalVariable = internalVariables?.find((iv: { wId: string }) => iv.wId === questionId);

    if (dependentInternalVariable) {
      Alert.INFO(`${t('questions.questionIsAssociatedToInternalVar')}${dependentInternalVariable.code}`);
      return;
    }

    return remove(index);
  };

  return (
    <Box>
      <QuestionForm
        accessFieldsName={accessFieldsName}
        selectedFieldIsMoldable={selectedFieldIsMoldable}
        selectedField={watchSelectedField}
        nestIndex={index}
        id={id}
        fieldTypes={defaultQuestionTypes()}
        creatableFields={creatableFields}
      />
      <BoxActions>
        <ButtonGroup>
          <ButtonGroupItem onClick={deleteHandler}>
            <p>{t('common.delete')}</p>
            <DeleteIcon />
          </ButtonGroupItem>
          <RuleButton hasRules={hasRules} onClick={() => openRulesModal()}>
            <p>{t('form.input')}</p>
            <RuleIcon />
          </RuleButton>
          {dragHandleProps.up.show && (
            <ButtonGroupItem {...dragHandleProps.up}>
              <p>{t('common.up')}</p>
              <UpIcon />
            </ButtonGroupItem>
          )}
          {dragHandleProps.down.show && (
            <ButtonGroupItem {...dragHandleProps.down}>
              <p>{t('common.down')}</p>
              <DownIcon />
            </ButtonGroupItem>
          )}
        </ButtonGroup>
      </BoxActions>
      <PanelLine />
    </Box>
  );
};

const Panel = ({ control, nestIndex, childPanelRef }: any) => {
  const { fields, remove, append, move } = useFieldArray({
    control,
    name: `questions[${nestIndex}].questions`,
  });

  useEffect(() => {
    if (fields.length === 0) {
      append(INITIAL_VALUE_QUESTION());
    }

    if (childPanelRef && childPanelRef.current) {
      childPanelRef.current.addEventListener('click', () => append(INITIAL_VALUE_QUESTION()));
    }
  }, []);

  return (
    <div>
      {fields.map((item, index) => (
        <PanelBox
          key={item.id}
          id={item.id}
          wId={(item as any).wId}
          remove={remove}
          index={index}
          nestIndex={nestIndex}
          length={fields.length}
          dragHandleProps={{
            up: {
              onClick: () => {
                move(index, index - 1);
              },
              show: index !== 0,
            },
            down: {
              onClick: () => {
                move(index, index + 1);
              },
              show: index !== fields.length - 1,
            },
          }}
        />
      ))}
    </div>
  );
};

export default Panel;
