import React from 'react';

interface SearchIconProps {
  width?: number;
  height?: number;
  className?: string;
}

export const SearchIcon = ({ width = 13, height = 14, className }: SearchIconProps) => {
  const viewBox = `0 0 ${width} ${height}`;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.58549 0.333252C2.5928 0.333252 0.166748 2.75786 0.166748 5.74876C0.166748 8.73967 2.5928 11.1643 5.58549 11.1643C6.58451 11.1643 7.52038 10.8941 8.32395 10.4228L10.9371 13.3737L11.0087 13.4457C11.3326 13.7358 11.8299 13.7436 12.1635 13.4485C12.5229 13.1307 12.5563 12.5819 12.2383 12.2228L9.66339 9.31514C10.4982 8.36245 11.0042 7.11466 11.0042 5.74876C11.0042 2.75786 8.57817 0.333252 5.58549 0.333252ZM5.58549 2.06982C7.61852 2.06982 9.26662 3.71694 9.26662 5.74876C9.26662 7.78059 7.61852 9.42771 5.58549 9.42771C3.55245 9.42771 1.90435 7.78059 1.90435 5.74876C1.90435 3.71694 3.55245 2.06982 5.58549 2.06982Z"
        fill="#fff"
      />
    </svg>
  );
};
