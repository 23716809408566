import React from 'react';

interface CreateQuestionIconProps {
  width?: number;
  height?: number;
}

const CreateQuestionIcon = ({ width = 16, height = 16 }: CreateQuestionIconProps) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.32225 11.3062H14V2.30615H2V12.3449L3.32225 11.3062ZM3.84125 12.8062L0.5 15.4312V1.55615C0.5 1.35724 0.579018 1.16647 0.71967 1.02582C0.860322 0.88517 1.05109 0.806152 1.25 0.806152H14.75C14.9489 0.806152 15.1397 0.88517 15.2803 1.02582C15.421 1.16647 15.5 1.35724 15.5 1.55615V12.0562C15.5 12.2551 15.421 12.4458 15.2803 12.5865C15.1397 12.7271 14.9489 12.8062 14.75 12.8062H3.84125ZM7.25 9.05615H8.75V10.5562H7.25V9.05615ZM5.42525 5.1659C5.52269 4.67825 5.75661 4.22829 6.0998 3.86838C6.44298 3.50848 6.88131 3.25343 7.36378 3.13292C7.84626 3.01241 8.35303 3.03138 8.82514 3.18764C9.29725 3.3439 9.71528 3.63101 10.0306 4.01557C10.3459 4.40013 10.5455 4.86632 10.6062 5.3599C10.6669 5.85347 10.5862 6.35414 10.3735 6.80364C10.1607 7.25314 9.82474 7.63298 9.40455 7.89898C8.98437 8.16497 8.4973 8.30617 8 8.30615H7.25V6.80615H8C8.21306 6.80614 8.42173 6.74562 8.60174 6.63165C8.78175 6.51767 8.9257 6.35493 9.01683 6.16234C9.10796 5.96975 9.14253 5.75525 9.11651 5.54378C9.0905 5.33232 9.00497 5.13259 8.86987 4.96784C8.73478 4.80308 8.55568 4.68007 8.35342 4.61313C8.15115 4.54618 7.93403 4.53805 7.72732 4.58968C7.52061 4.64131 7.33281 4.75058 7.18577 4.90476C7.03874 5.05895 6.93851 5.25173 6.89675 5.46065L5.42525 5.1659Z"
      fill="white"
    />
  </svg>
);

export default CreateQuestionIcon;
