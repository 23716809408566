import React, { useState } from 'react';

import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Title,
  Subtitle,
  StyledIconArrow,
  Label,
  InputContainer,
  ActionIcon,
  StyledIconEye,
  StyledIconEyeSlash,
} from './style';

import { doRegister, doRequestFirstAccessToken } from '../../../services/user.service';
import Input from '../../../components/Input';
import ErrorMessage from '../../../components/ErrorMessage';
import Button from '../../../components/Button';
import { validatePasswordStrength } from '../../../utils/passwordValidate';

interface RegisterProps {
  label?: any;
  type?: 'text' | 'password';
  className?: string;
  setForm?: any;
  firstAccess?: boolean;
  setProvidedPassword?: (pass: string | undefined) => any;
}

const RegisterPassword = ({
  label,
  type,
  className,
  setForm,
  firstAccess = false,
  setProvidedPassword = (str: any) => str,
}: RegisterProps) => {
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordShown, setPasswordShown] = useState(type !== 'password');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [params] = useSearchParams();
  const passwordChangeToken = params.get('passwordChangeToken');
  const { t } = useTranslation();

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const requestFirstAccess = async () => {
    setLoading(true);
    try {
      const firstAccessTokenRequest = await doRequestFirstAccessToken();
      if (firstAccessTokenRequest) {
        setForm('validate');
        setProvidedPassword(password);
      }
    } catch (err: any) {
      setError(err.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const register = async () => {
    if (!passwordChangeToken) {
      setError(t('login.invalidToken'));
      return;
    }
    setLoading(true);
    try {
      setError('');
      const navigateValidate = await doRegister(password, passwordChangeToken);
      if (navigateValidate) {
        setForm('success');
      }
    } catch (err: any) {
      setError(err.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const validatedPassword = validatePasswordStrength(password);

    if (password !== passwordConfirm) {
      setError(t('login.enterSamePassword'));
      return;
    }
    if (!validatedPassword) {
      setError(t('login.validatePassword'));
      return;
    }
    if (firstAccess) {
      await requestFirstAccess();
      return;
    }
    await register();
  };

  return (
    <Form className={className}>
      <Title>
        {t('login.registerPassword')}
        <Subtitle>{t('login.registerSubTitle')}</Subtitle>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Title>
      <Label>{label}</Label>
      <InputContainer>
        <Input
          error={error}
          label={t('login.labelNewPassword')}
          id="password"
          type={passwordShown ? 'password' : 'text'}
          name="password"
          placeholder={t('login.inputPassword')}
          onChange={(event) => setPassword(event.target.value)}
          value={password}
        />
        <ActionIcon onClick={togglePassword}>{passwordShown ? <StyledIconEye /> : <StyledIconEyeSlash />}</ActionIcon>
      </InputContainer>
      <Label>{label}</Label>
      <InputContainer>
        <Input
          error={error}
          label={t('login.repeatPassword')}
          id="passwordConfirm"
          type={passwordShown ? 'password' : 'text'}
          name="passwordConfirm"
          placeholder={t('login.typeAgain')}
          onChange={(event) => setPasswordConfirm(event.target.value)}
          value={passwordConfirm}
        />
        <ActionIcon onClick={togglePassword}>{passwordShown ? <StyledIconEye /> : <StyledIconEyeSlash />}</ActionIcon>
      </InputContainer>
      <Button primary full mt={20} onClick={handleSubmit} loading={loading}>
        {t('login.registerPassword')}
        <StyledIconArrow />
      </Button>
    </Form>
  );
};

export default RegisterPassword;
