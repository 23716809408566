import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  DangerWrapper,
  StyledSubTitleDanger,
  StyledSubTitleSuccess,
  StyledSubTitleWarning,
  StyledSubTitle,
  SuccessWrapper,
  WarningWrapper,
  Text,
  Value,
  SubTitleWrapper,
} from './style';

interface SubTitleBarChartProps {
  data: any;
}

export const SubTitleBarChart = ({ data }: SubTitleBarChartProps) => {
  const { t } = useTranslation();

  return (
    <StyledSubTitle>
      <DangerWrapper>
        <StyledSubTitleDanger />
        <SubTitleWrapper>
          <Value>{data?.pending}</Value>
          <Text>{t('chart.pending')}</Text>
        </SubTitleWrapper>
      </DangerWrapper>
      <WarningWrapper>
        <StyledSubTitleWarning />
        <SubTitleWrapper>
          <Value>{data?.inProgress}</Value>
          <Text>{t('chart.inProgress')}</Text>
        </SubTitleWrapper>
      </WarningWrapper>
      <SuccessWrapper>
        <StyledSubTitleSuccess />
        <SubTitleWrapper>
          <Value>{data?.finished}</Value>
          <Text>{t('chart.finished')}</Text>
        </SubTitleWrapper>
      </SuccessWrapper>
    </StyledSubTitle>
  );
};
