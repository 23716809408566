import styled, { css } from 'styled-components';
import Grid from '../../components/Grid';
import { MAX_WIDTHS } from '../../constants/screen';

export const Container = styled(Grid).attrs({
  container: true,
})`
  flex-direction: column;
  padding: 0 40px;
  margin-top: 80px;
  margin-bottom: 40px;

  @media (max-width: ${MAX_WIDTHS.sm}px) {
    padding: 7%;
  } ;
`;

export const Title = styled.h1`
  font-family: 'Circular20', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0;
  text-align: left;
  margin-bottom: 24px;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: right;
  width: 100%;
  gap: 16px;
  align-items: center;
`;
export const Badge = styled.div<{ bg?: string }>`
  padding: 4px 8px;
  border-radius: 4px;
  text-align: center;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  background: ${({ bg }) => bg || '#D8D8D8'};
`;
export const TextWrapper = styled.div<{
  disabled?: boolean;
  isDraft?: boolean;
}>`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 10px;
  ${({ disabled, isDraft }) => !disabled && !isDraft && `cursor: pointer`}
  ${({ disabled }) =>
    disabled &&
    css`
      user-select: none;
      pointer-events: none;

      color: #979797;
    `}
`;
export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin: 24px 0 0 0;
  align-items: center;
  justify-content: flex-end;
`;
