import { Dispatch } from 'redux';
import i18n from '../../config/i18n/config';
import { findAllFormsRequest, findQuestionsInIndex } from '../../requests/forms';
import { Creators as dataActions } from '../reducers/dataReducer';
import Alert from '../../components/Toast/toast';

export const fetchOtherFormsQuestionsToUseOnRules = async (
  dispatch: Dispatch,
  props: { projectType: any; businessUnit: any; serviceOffer: any; productType: any; wId: any; formType: any }
) => {
  const getProp = (val: any) => val?.value ?? val ?? null;
  const projectType = getProp(props.projectType);
  const businessUnit = getProp(props.businessUnit);
  const serviceOffer = getProp(props.serviceOffer);
  const productType = getProp(props.productType);
  const formType = getProp(props.formType);
  try {
    const forms = await findAllFormsRequest({
      projectType,
      businessUnit,
      serviceOffer,
      productType,
      formType,
    });

    const wIdList = forms.map((form: any) => form.wId);
    const fromIndex = wIdList.length !== 0 ? await findQuestionsInIndex(wIdList) : [];
    const isCurrent = (value: any) => (value === props.wId ? 'CURRENT_FORM' : value);
    const questionsIndexedByForm = forms.reduce(
      (acc: any, cur: any) => ({
        ...acc,
        [isCurrent(cur.wId)]: fromIndex
          .filter((question: any) => question.formWId === cur.wId)
          .map((question: any) => ({
            value: question.wId,
            label: question.title,
          })),
      }),
      {}
    );

    const selectableForms = [
      { label: i18n.t('forms.currentForm'), value: 'CURRENT_FORM' },
      ...forms
        .filter(({ status }: any) => status === 'ACTIVE')
        .map((form: any) => ({ label: form.title, value: form.wId })),
    ];

    const formsById = selectableForms
      .filter((form) => form.value !== props.wId)
      .reduce((acc, cur) => ({ ...acc, [cur.value]: cur }), {});

    const reverseIndex = fromIndex.reduce(
      (acc: any, cur: any) => ({
        ...acc,
        [cur.wId]: formsById[isCurrent(cur.formWId)],
      }),
      {}
    );
    dispatch(dataActions.setOtherFormsQuestionsToUseOnRules(Object.values(formsById)));
    dispatch(dataActions.setQuestionsFromOtherFormsQuestionsToUseOnRules({ questionsIndexedByForm, reverseIndex }));
    return [selectableForms.filter((form) => form.value !== props.wId), { questionsIndexedByForm, reverseIndex }];
  } catch (err: any) {
    Alert.ERROR(i18n.t('fetch.error'));
    return [];
  }
};
