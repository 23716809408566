import styled from 'styled-components';

export const StyledDivider = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 6px;
`;

export const DividerWrapper = styled.div`
  display: flex;
  gap: 20px;

  .search_icon > path {
    fill: #324fa5;
  }
`;

export const QuestionAmount = styled.p`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #324fa5;
`;
