const reduceAnswer = (acc: any[], answer: any) => [
  ...acc,
  ...(Array.isArray(answer.answers) ? answer.answers : [answer]),
];

const getAnswers = (submitData: any, formTypeRepeating: boolean, formTypeIsBranch: boolean) => {
  if (formTypeRepeating) {
    return submitData.entries.reduce(
      (acc: any[], entry: any) => [
        ...acc,
        ...entry.answers.reduce(reduceAnswer, []).map((answer: any) => ({
          ...answer,
          entry: entry.entry,
        })),
      ],
      []
    );
  }
  if (formTypeIsBranch) {
    return submitData.branches.reduce(
      (acc: any[], branch: any) => [
        ...acc,
        ...branch.answers.reduce(reduceAnswer, []).map((answer: any) => ({
          ...answer,
          branchId: branch.branchId,
        })),
      ],
      []
    );
  }
  return submitData.answers.reduce(reduceAnswer, []);
};

export const parseSubmitData = (submitData: any, data: any) => {
  const formTypeIsBranch = data?.formType === 'BRANCH';
  const formTypeRepeating = data?.repeating === true;

  const answers: any[] = getAnswers(submitData, formTypeRepeating, formTypeIsBranch);
  // const answers: any[] = (data?.multipleForm) ? submitData.answers : getAnswers(submitData, formTypeRepeating, formTypeIsBranch);

  const tabsAnswers: Array<any> = [];

  if (Number(data?.multipleForm)) {
    answers.forEach((tab: any) => {
      const tabContent: Array<Object> = [];
      if (tab && tab['0']) {
        Object.keys(tab).forEach((key: any) => {
          if (!Number.isNaN(key) && !Number.isNaN(parseInt(key, 10))) {
            tabContent.push({ ...tab[key], shouldBeIgnored: tab.shouldBeIgnored });
          }
        });
        tabsAnswers.push(tabContent.reduce(reduceAnswer, []));
      }
    });
  } else {
    tabsAnswers.push(...answers);
  }

  const parsedAnswers = Number(data?.multipleForm)
    ? tabsAnswers.map((tab: any[]) =>
        tab
          .filter((answer) => !answer.shouldBeIgnored && !answer.hidden)
          .map((answer: any) => {
            if (Array.isArray(answer.answers)) {
              return {
                answer: answer.answers,
                ...answer,
              };
            }

            return {
              answer: answer.answer,
              wId: answer.wId,
              ...(answer.branchId && { branchId: answer.branchId }),
              ...(answer.entry && { entry: answer.entry }),
            };
          })
      )
    : tabsAnswers
        .filter((answer) => !answer.shouldBeIgnored && !answer.hidden)
        .map((answer: any) => ({
          answer: answer.answer,
          wId: answer.wId,
          ...(answer.branchId && { branchId: answer.branchId }),
          ...(answer.entry && { entry: answer.entry }),
        }));

  return {
    answers: parsedAnswers,
  };
};
