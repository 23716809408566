import React from 'react';

interface CreateIconProps {
  width?: number;
  height?: number;
}

export const CreateIcon = ({ width = 12, height = 12 }: CreateIconProps) => {
  const viewBox = `0 0 ${width} ${height}`;

  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 6C12 6.47349 11.6162 6.85733 11.1427 6.85733H6.85733V11.1427C6.85733 11.6162 6.47349 12 6 12C5.52651 12 5.14267 11.6162 5.14267 11.1427V6.85733H0.857333C0.383841 6.85733 0 6.47349 0 6C0 5.52651 0.383841 5.14267 0.857334 5.14267H5.14267V0.857333C5.14267 0.383841 5.52651 0 6 0C6.47349 0 6.85733 0.383841 6.85733 0.857334V5.14267H11.1427C11.6162 5.14267 12 5.52651 12 6Z"
        fill="#324FA5"
      />
    </svg>
  );
};
