import React, { Children, cloneElement, ReactElement } from 'react';
import { StyledTab, StyledTabEmpty, StyledTabs } from './style';

interface TabsProps {
  selectedTab: number;
  children: ReactElement | ReactElement[];
  onChange: any;
}

interface TabProps {
  label: string;
  onClick?: any;
  tabIndex?: number;
  isActive?: boolean;
}

interface TabPanelProps {
  selectedTab?: number;
  index?: number;
  children?: ReactElement | ReactElement[];
}

export const Tab = ({ label, onClick, tabIndex, isActive }: TabProps) => (
  <StyledTab tabIndex={tabIndex} isActive={isActive} onClick={onClick}>
    {label}
  </StyledTab>
);

export const TabEmpty = () => <StyledTabEmpty />;

export const Tabs = ({ selectedTab, onChange, children }: TabsProps) => {
  const handleTabClick = (event: MouseEvent, index: number) => {
    onChange(event, index);
  };

  return (
    <StyledTabs>
      {Children.map(children, (child, index) =>
        cloneElement(child, {
          onClick: (e: MouseEvent) => handleTabClick(e, index),
          tabIndex: index,
          isActive: index === selectedTab,
        })
      )}
    </StyledTabs>
  );
};

export const TabPanel = ({ children, selectedTab, index }: TabPanelProps) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>{selectedTab === index && children}</>
);
