import styled from 'styled-components';

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 40px;
`;

export const InlineInputs = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  flex-wrap: wrap;
`;

interface InputWrapperProps {
  grow?: number;
}

export const InputWrapper = styled.div<InputWrapperProps>`
  flex-grow: ${({ grow }) => grow || 1};
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Line = styled.div`
  width: 100%;
  border: 1px solid #a8c1ff;
  transform: rotate(-0.1deg);
`;
