import styled from 'styled-components';

export const Breadcrumb = styled.div`
  display: flex;
  gap: 12px;
  height: 14px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  a {
    text-decoration: none;
    color: #324fa5;
  }
`;

export const BreadLink = styled.a`
  cursor: pointer;
`;

export const BreadcrumbWrapper = styled.div`
  margin-bottom: 25px;
`;
