import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Actions,
  ActionsButtonsWrapper,
  Advice,
  Container,
  OrderIconWrapper,
  SearchBarWrapper,
  TableBody,
  TableData,
  TableDataWrapper,
  TableFooter,
  TableHead,
  TableHeadData,
  TableRow,
  TableWrapper,
} from './style';

import Pagination from '../Pagination';
import Checkbox from '../MarkableButton/Checkbox';
import { CreateIcon } from '../../assets/svgs/CreateIcon';
import SearchBar from '../SearchBar';
import { OrderIcon } from '../../assets/svgs/OrderIcon';
import TextButton from '../TextButton';
import { TableData as Data } from '../../types/TableData';
import EmptyState from '../EmptyState';

enum SortingOptions {
  ASCENDING,
  DESCENDING,
}

const PAGE_SIZE = 10;

interface SortConfig {
  order: SortingOptions;
  key: string;
}

interface HeaderItem {
  head: string;
  key: string;
}

interface TableProps {
  searchKey: string;
  headers: HeaderItem[];
  renderActions: (selectedData: any) => any[];
  advice: string;
  emptyStateTexts: {
    EMPTY_FILTER: string;
    EMPTY_LIST: string;
  };
  data: Data[];
  render: (element: any) => any[];
  createHandler: (data?: any) => void;
  hasInlineCreate?: boolean;
  InlineCreate?: any;
}

interface TableContentProps {
  element: Data;
  render: (element: any) => any[];
  selectedData: any[];
  handleSelectData: (event: ChangeEvent<HTMLInputElement>) => void;
}

interface TableHeadProps {
  headers: HeaderItem[];
  allDataAreSelected: boolean;
  handleSelectAll: () => void;
  requestSort: (key: string) => void;
  sortConfig: SortConfig | null;
}

const TableContent = ({ element, render, selectedData, handleSelectData }: TableContentProps) => {
  const rendered = render(element);
  const isChecked = selectedData.some((e) => e._id === element._id);
  return (
    <TableRow key={`TableRow${element._id}`}>
      <TableData>
        <TableDataWrapper>
          <Checkbox id={element._id} value={JSON.stringify(element)} checked={isChecked} onChange={handleSelectData} />
        </TableDataWrapper>
      </TableData>
      {rendered.map((field: any, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <TableData key={`TableData${element._id}${index}`}>
          <TableDataWrapper>{field}</TableDataWrapper>
        </TableData>
      ))}
    </TableRow>
  );
};

const TableHeader = ({ headers, allDataAreSelected, handleSelectAll, requestSort, sortConfig }: TableHeadProps) => {
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        <TableHeadData>
          <Checkbox id="select-all" checked={allDataAreSelected} onChange={handleSelectAll} />
        </TableHeadData>
        {headers.map(({ head, key }) => (
          <TableHeadData key={key}>
            <TableDataWrapper>
              <span>{head}</span>
              <OrderIconWrapper active={sortConfig?.key === key} onClick={() => requestSort(key)}>
                <OrderIcon />
              </OrderIconWrapper>
            </TableDataWrapper>
          </TableHeadData>
        ))}
        <TableHeadData>{t('table.actions')}</TableHeadData>
      </TableRow>
    </TableHead>
  );
};

const Table = ({
  data,
  render,
  headers,
  advice,
  emptyStateTexts,
  createHandler,
  renderActions,
  hasInlineCreate,
  searchKey,
  InlineCreate,
}: TableProps) => {
  const [createState, setCreateState] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedData, setSelectedData] = useState<any[]>([]);
  const [sortConfig, setSortConfig] = useState<SortConfig | null>(null);
  const [filter, setFilter] = useState<string>('');
  const { t } = useTranslation();

  useEffect(() => {
    if (currentPage > 1 && data.length <= PAGE_SIZE) {
      setCurrentPage(1);
    }
    setSelectedData((prevState) => prevState.filter((prevSelectedData) => data.includes(prevSelectedData)));
  }, [data]);

  const filteredData = useMemo(() => {
    const lowerCaseFilter = filter.toLowerCase();
    return data.filter((text) => text[searchKey].toLowerCase().includes(lowerCaseFilter));
  }, [filter, data]);

  const sortedData = useMemo(() => {
    const sortableItems = [...filteredData];
    if (!sortConfig) return sortableItems;

    const isAscendingOrder = sortConfig.order === SortingOptions.ASCENDING;

    return sortableItems.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return isAscendingOrder ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return isAscendingOrder ? 1 : -1;
      }
      return 0;
    });
  }, [sortConfig, filteredData]);

  const paginatedData = useMemo(() => {
    if (currentPage > 1 && sortedData.length <= PAGE_SIZE) {
      setCurrentPage(1);
    }
    const firstPageIndex = (currentPage - 1) * PAGE_SIZE;
    const lastPageIndex = firstPageIndex + PAGE_SIZE;
    return sortedData.slice(firstPageIndex, lastPageIndex);
  }, [sortedData, currentPage]);

  const dataListAreFilled = data.length > 0;
  const allDataAreSelected = dataListAreFilled && selectedData.length === data.length;
  const nothingFoundWithFilter = filteredData.length === 0;

  const handleSelectAll = () => {
    if (nothingFoundWithFilter) return;

    if (allDataAreSelected) {
      setSelectedData([]);
      return;
    }

    setSelectedData(data);
  };

  const handleSelectData = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const parsed = JSON.parse(value);

    if (!selectedData.some((e) => e._id === parsed._id)) {
      return setSelectedData((prevState) => [...prevState, parsed]);
    }

    return setSelectedData(selectedData.filter((selectedDataValue) => selectedDataValue._id !== parsed._id));
  };

  const requestSort = (key: string) => {
    if (!sortConfig) {
      return setSortConfig({ order: SortingOptions.ASCENDING, key });
    }

    if (sortConfig.key !== key) {
      return setSortConfig({ order: SortingOptions.ASCENDING, key });
    }

    if (sortConfig.order === SortingOptions.ASCENDING) {
      return setSortConfig({ order: SortingOptions.DESCENDING, key });
    }

    return setSortConfig(null);
  };

  const onClickCreate = () => {
    if (hasInlineCreate) {
      setCreateState(true);
    } else {
      createHandler();
    }
  };

  const renderedActions = renderActions(selectedData);

  return (
    <Container>
      <Actions>
        <ActionsButtonsWrapper>
          {renderedActions}
          <TextButton onClick={onClickCreate}>
            <span>{t('table.add')}</span>
            <CreateIcon />
          </TextButton>
        </ActionsButtonsWrapper>
        <SearchBarWrapper>
          <SearchBar value={filter} setValue={setFilter} />
        </SearchBarWrapper>
      </Actions>
      <TableWrapper>
        <TableHeader
          headers={headers}
          allDataAreSelected={allDataAreSelected}
          handleSelectAll={handleSelectAll}
          requestSort={requestSort}
          sortConfig={sortConfig}
        />
        <TableBody>
          {hasInlineCreate && createState && (
            <InlineCreate setCreateState={setCreateState} createHandler={createHandler} />
          )}
          {dataListAreFilled &&
            paginatedData.map((element: any) => (
              <TableContent
                key={element._id}
                element={element}
                render={render}
                handleSelectData={handleSelectData}
                selectedData={selectedData}
              />
            ))}
        </TableBody>
      </TableWrapper>
      {!dataListAreFilled && <EmptyState text={emptyStateTexts.EMPTY_LIST} />}
      {nothingFoundWithFilter && dataListAreFilled && <EmptyState text={emptyStateTexts.EMPTY_FILTER} />}
      <TableFooter>
        {!nothingFoundWithFilter && dataListAreFilled && <Advice>{advice}</Advice>}
        <Pagination
          currentPage={currentPage}
          totalCount={filteredData.length}
          pageSize={PAGE_SIZE}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </TableFooter>
    </Container>
  );
};

export default Table;
