import React from 'react';

import { PieChart } from 'react-minimal-pie-chart';
import { useTranslation } from 'react-i18next';

interface PieChartProps {
  data: any;
}

export const CustomPieChart = ({ data }: PieChartProps) => {
  const isEmpty = data?.pending === 0 && data?.inProgress === 0 && data?.finished === 0;
  const { t } = useTranslation();

  if (isEmpty) {
    const dataChart = [{ title: 'empty', value: 100, color: '#D8D8D8' }];

    return (
      <PieChart
        data={dataChart}
        paddingAngle={0}
        labelStyle={{
          display: 'none',
        }}
        lineWidth={45}
      />
    );
  }

  const dataChart = [
    { title: t('dashboard.notStartedPlural'), value: data?.pending || 0, color: '#F1615D' },
    { title: t('dashboard.inProgress'), value: data?.inProgress || 0, color: '#577EF4' },
    { title: t('dashboard.finishedPlural'), value: data?.finished || 0, color: '#99E596' },
  ];

  return (
    <PieChart
      data={dataChart}
      paddingAngle={0}
      labelStyle={{
        display: 'none',
      }}
      lineWidth={45}
    />
  );
};
