import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AnimatedTableRow, DisabledCheckbox, InlineFormButton, InlineFormInput, InlineFormWrapper } from './style';
import Alert from '../../../../components/Toast/toast';
import { ActionIcon, TableData, TableDataWrapper } from '../../../../components/Table/style';
import { EditIcon } from '../../../../assets/svgs/EditIcon';
import { DeleteIcon } from '../../../../assets/svgs/DeleteIcon';
import { TextWrapper } from '../../style';
import TextSelect from '../../../../components/TextSelect';
import FormError from '../../../../components/FormError';

interface InlineFormProps {
  value: string;
  onChange: any;
}

interface InlineEditProps {
  value: string;
  disabled: boolean;
  label: string;
  handler: (data: any, id: string) => void;
  open: boolean;
  id: string;
}

interface InlineCreateProps {
  createHandler: (email: string) => void;
  setCreateState: (state: boolean) => void;
}

export const SelectInlineEdit = ({ open, id, value, label, disabled, handler }: InlineEditProps) => {
  const [option, setOption] = useState();

  const submitRequest = async () => {
    try {
      await handler(option, id);
    } catch (e: any) {
      console.error(e);
      Alert.ERROR(e.message);
    }
  };
  const { t } = useTranslation();

  const USER_TYPE_OPTIONS = [
    {
      value: 'COUNTRY_ADVISOR',
      label: t('constants.COUNTRY_ADVISOR'),
    },
    {
      value: 'COUNTRY_ADMIN',
      label: t('constants.COUNTRY_ADMIN'),
    },
  ];

  return open ? (
    <div style={{ display: 'flex', gap: 20 }}>
      <div style={{ width: 200 }}>
        <TextSelect
          defaultValue={USER_TYPE_OPTIONS.find((selected) => selected.value === value)}
          options={USER_TYPE_OPTIONS}
          onChange={(selected: any) => setOption(selected.value)}
        />
      </div>
      <InlineFormButton onClick={submitRequest}>{t('common.save')}</InlineFormButton>
    </div>
  ) : (
    <TextWrapper disabled={disabled}>{label}</TextWrapper>
  );
};

const InlineForm = ({ value, onChange }: InlineFormProps) => {
  const inputEl = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputEl?.current?.focus();
  }, []);

  return (
    <InlineFormWrapper>
      <InlineFormInput value={value} onChange={onChange} ref={inputEl} />
    </InlineFormWrapper>
  );
};

export const SelectInlineCreate = ({ createHandler, setCreateState }: InlineCreateProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const closeCreateAction = () => {
    setCreateState(false);
  };

  const { t } = useTranslation();

  const USER_TYPE_OPTIONS = [
    {
      value: 'COUNTRY_ADVISOR',
      label: t('constants.COUNTRY_ADVISOR'),
    },
    {
      value: 'COUNTRY_ADMIN',
      label: t('constants.COUNTRY_ADMIN'),
    },
  ];

  const submitRequest = async (data: any) => {
    try {
      await createHandler(data);
      closeCreateAction();
    } catch (e: any) {
      console.error(e);
      Alert.SUCCESS(e.message);
    }
  };

  const REGEX_EMAIL = /\S+@\S+\.\S+/;
  const validateEmail = (email: string) => REGEX_EMAIL.test(email);

  return (
    <AnimatedTableRow>
      <TableData>
        <TableDataWrapper>
          <DisabledCheckbox />
        </TableDataWrapper>
      </TableData>
      <TableData>
        <TableDataWrapper gap={4} style={{ alignItems: 'start', flexDirection: 'column' }}>
          <Controller
            name="email"
            rules={{
              required: t<string>('common.required'),
              validate: (value) => validateEmail(value) || t<string>('userManagement.invalidEmail'),
            }}
            control={control}
            render={({ field }) => <InlineForm {...field} />}
          />
          <FormError errors={errors} name="email" />
        </TableDataWrapper>
      </TableData>
      <TableData>
        <TableDataWrapper gap={4} style={{ alignItems: 'start', flexDirection: 'column' }}>
          <div style={{ display: 'flex' }}>
            <Controller
              name="type"
              control={control}
              rules={{
                required: t<string>('common.required'),
              }}
              render={({ field }) => (
                <div style={{ width: 200 }}>
                  <TextSelect options={USER_TYPE_OPTIONS} {...field} />
                </div>
              )}
            />
            <InlineFormButton onClick={handleSubmit(submitRequest)}>{t('common.save')}</InlineFormButton>
          </div>
          <FormError errors={errors} name="type" />
        </TableDataWrapper>
      </TableData>
      <TableData>
        <TableDataWrapper justify="flex-end">
          <ActionIcon disabled>
            <EditIcon />
          </ActionIcon>
          <ActionIcon onClick={closeCreateAction}>
            <DeleteIcon />
          </ActionIcon>
        </TableDataWrapper>
      </TableData>
    </AnimatedTableRow>
  );
};
