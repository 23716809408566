import React from 'react';
import { ButtonProps, StyledButton } from './style';
import Loader from '../Loader';

const Button = ({ children, ...props }: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>) => (
  <StyledButton {...props}>
    <Loader scale={0.35} white={props.primary} solid={!props.primary} />
    <div className="children">{children}</div>
  </StyledButton>
);

export default Button;
