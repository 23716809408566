import styled, { css } from 'styled-components';
import { BREAKPOINTS } from '../../constants/screen';

const XS = css<IGrid>`
  @media (min-width: 0) {
    width: ${({ xs }) => Math.min((100 / 12) * (xs ?? 0), 100)}%;
  }
`;

const SM = css<IGrid>`
  @media (min-width: ${BREAKPOINTS.sm}px) {
    width: ${({ sm }) => Math.min((100 / 12) * (sm ?? 0), 100)}%;
  }
`;

const MD = css<IGrid>`
  @media (min-width: ${BREAKPOINTS.md}px) {
    width: ${({ md }) => Math.min((100 / 12) * (md ?? 0), 100)}%;
  }
`;

const LG = css<IGrid>`
  @media (min-width: ${BREAKPOINTS.lg}px) {
    width: ${({ lg }) => Math.min((100 / 12) * (lg ?? 0), 100)}%;
  }
`;

const XL = css<IGrid>`
  @media (min-width: ${BREAKPOINTS.xl}px) {
    width: ${({ xl }) => Math.min((100 / 12) * (xl ?? 0), 100)}%;
  }
`;

const EXTRA = css<IGrid>`
  @media (min-width: 0px) {
    ${({ xsPadding }) => xsPadding && `padding: ${xsPadding};`}
    ${({ xsMargin }) => xsMargin && `margin: ${xsMargin};`}
  }
  @media (min-width: ${BREAKPOINTS.sm}px) {
    ${({ smPadding }) => smPadding && `padding: ${smPadding};`}
    ${({ smMargin }) => smMargin && `margin: ${smMargin};`}
  }
  @media (min-width: ${BREAKPOINTS.md}px) {
    ${({ mdPadding }) => mdPadding && `padding: ${mdPadding};`}
    ${({ mdMargin }) => mdMargin && `margin: ${mdMargin};`}
  }
  @media (min-width: ${BREAKPOINTS.lg}px) {
    ${({ lgPadding }) => lgPadding && `padding: ${lgPadding};`}
    ${({ lgMargin }) => lgMargin && `margin: ${lgMargin};`}
  }
  @media (min-width: ${BREAKPOINTS.xl}px) {
    ${({ xlPadding }) => xlPadding && `padding: ${xlPadding};`}
    ${({ xlMargin }) => xlMargin && `margin: ${xlMargin};`}
  }
`;

const CONTAINER = css<IGrid>`
  display: flex;
  flex-wrap: ${({ wrap }) => wrap || 'wrap'};
  flex-direction: ${({ direction }) => direction || 'wrap'};
  justify-content: ${({ justify }) => justify || 'flex-start'};
  align-items: ${({ align }) => align || 'flex-start'};
  & > * {
    ${({ spacingX }) => spacingX && `padding-right: ${spacingX}; padding-left: ${spacingX};`}
    ${({ spacingY }) => spacingY && `padding-top: ${spacingY}; padding-bottom: ${spacingY};`}
  }
`;

const RELATIVE = css`
  position: relative;
`;

const ABSOLUTE = css`
  position: absolute;
`;

const Grid = styled.div<IGrid>`
  width: auto;
  ${({ xs, item }) => xs && item && XS}
  ${({ sm, item }) => sm && item && SM}
  ${({ md, item }) => md && item && MD}
  ${({ lg, item }) => lg && item && LG}
  ${({ xl, item }) => xl && item && XL}
  ${({ container }) => container && CONTAINER}
  ${({ relative }) => relative && RELATIVE}
  ${({ absolute }) => absolute && ABSOLUTE}
  ${({ flex }) => flex && `flex: ${flex}`}
  ${EXTRA}
`;

interface IGrid {
  item?: boolean;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  container?: boolean;
  relative?: boolean;
  absolute?: boolean;
  wrap?: string;
  flex?: string | number;
  direction?: string;
  justify?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';

  align?: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'unset';
  spacingX?: string;
  spacingY?: string;
  xsPadding?: string;
  xsMargin?: string;
  smPadding?: string;
  smMargin?: string;
  mdPadding?: string;
  mdMargin?: string;
  lgPadding?: string;
  lgMargin?: string;
  xlPadding?: string;
  xlMargin?: string;
}

export default Grid;
