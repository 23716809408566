import React from 'react';

interface LinkIconProps {
  width?: number;
  height?: number;
}

export const LinkIcon = ({ width = 17, height = 16 }: LinkIconProps) => (
  <svg width={width} height={height} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.5382 10.7994L12.4188 9.67835L13.5382 8.55894C13.9084 8.19196 14.2025 7.75547 14.4035 7.27453C14.6046 6.7936 14.7087 6.27768 14.7098 5.75641C14.711 5.23514 14.6091 4.71877 14.4102 4.23696C14.2112 3.75515 13.9191 3.31737 13.5505 2.94878C13.1819 2.58018 12.7441 2.28802 12.2623 2.08906C11.7805 1.89011 11.2641 1.78828 10.7428 1.78942C10.2216 1.79056 9.70565 1.89465 9.22471 2.09571C8.74378 2.29678 8.30729 2.59086 7.94031 2.96106L6.82089 4.08127L5.70069 2.96185L6.82169 1.84244C7.861 0.803121 9.27062 0.219238 10.7404 0.219238C12.2103 0.219238 13.6199 0.803121 14.6592 1.84244C15.6985 2.88176 16.2824 4.29137 16.2824 5.76119C16.2824 7.231 15.6985 8.64062 14.6592 9.67994L13.539 10.7994H13.5382ZM11.2994 13.0382L10.1791 14.1576C9.13983 15.1969 7.73021 15.7808 6.26039 15.7808C4.79058 15.7808 3.38096 15.1969 2.34164 14.1576C1.30233 13.1183 0.718445 11.7087 0.718445 10.2389C0.718445 8.76904 1.30233 7.35942 2.34164 6.3201L3.46185 5.20069L4.58127 6.32169L3.46185 7.4411C3.09165 7.80808 2.79757 8.24457 2.5965 8.72551C2.39544 9.20644 2.29135 9.72236 2.29021 10.2436C2.28907 10.7649 2.3909 11.2813 2.58985 11.7631C2.78881 12.2449 3.08097 12.6827 3.44957 13.0513C3.81816 13.4199 4.25594 13.712 4.73775 13.911C5.21956 14.1099 5.73593 14.2118 6.2572 14.2106C6.77847 14.2095 7.29439 14.1054 7.77532 13.9043C8.25626 13.7033 8.69275 13.4092 9.05973 13.039L10.1791 11.9196L11.2994 13.039V13.0382ZM10.7389 4.64098L11.8591 5.76119L6.26119 11.3583L5.14098 10.2389L10.7389 4.64177V4.64098Z"
      fill="#476BC3"
    />
  </svg>
);
