import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '../../Button';
import { Creators as modalActions } from '../../../redux/reducers/modal-reducer';
import { Container, Footer } from '../../DefaultModalContent/style';
import Input from '../../Input';
import {
  ActionIcon,
  InputContainer,
  StyledIconEye,
  StyledIconEyeSlash,
} from '../../../pages/Login/RegisterPassword/style';
import { validatePasswordStrength } from '../../../utils/passwordValidate';
import ErrorMessage from '../../ErrorMessage';
import { passwordChangeRequest } from '../../../requests/userManagement';
import Grid from '../../Grid';
// import Alert from '../../Toast/toast';

const PasswordChangeModal = () => {
  const dispatch = useDispatch();
  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordShown, setPasswordShown] = useState(true);
  const [error, setError] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const { closeModal } = modalActions;
  const { t } = useTranslation();
  const onClose = () => dispatch(closeModal());
  const onSave = async () => {
    if (submitting) return;
    const passwordValid = validatePasswordStrength(password);
    if (!passwordValid) {
      setError(t('login.validatePassword'));
      return;
    }
    if (password !== passwordConfirm) {
      setError(t('login.enterSamePassword'));
      return;
    }
    if ((currentPassword ?? '').length === 0 || (password ?? '').length === 0 || (passwordConfirm ?? '').length === 0) {
      setError(t('allFields'));
      return;
    }
    try {
      setSubmitting(true);
      await passwordChangeRequest({ currentPassword, newPassword: password });
      onClose();
    } catch (err: any) {
      // Alert.ERROR(err?.response?.data?.message);
      setError(t(err?.response?.data?.message));
    } finally {
      setSubmitting(false);
    }
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
    console.log(setError);
  };

  return (
    <Container>
      <Grid container direction="column" spacingY="10px" smPadding="0 40px" smMargin="-40px 0 0 0">
        <p style={{ textAlign: 'center' }}>{t('login.changePassword')}</p>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <InputContainer>
          <Input
            error={error}
            label={t('login.inputPassword')}
            id="currentPassword"
            type={passwordShown ? 'password' : 'text'}
            name="currentPassword"
            placeholder={t('login.labelPassword')}
            onChange={(event) => setCurrentPassword(event.target.value)}
            value={currentPassword}
          />
          <ActionIcon onClick={togglePassword}>{passwordShown ? <StyledIconEye /> : <StyledIconEyeSlash />}</ActionIcon>
        </InputContainer>
        <InputContainer>
          <Input
            error={error}
            label={t('login.registerSubTitle')}
            id="password"
            type={passwordShown ? 'password' : 'text'}
            name="password"
            placeholder={t('login.labelPassword')}
            onChange={(event) => setPassword(event.target.value)}
            value={password}
          />
          <ActionIcon onClick={togglePassword}>{passwordShown ? <StyledIconEye /> : <StyledIconEyeSlash />}</ActionIcon>
        </InputContainer>
        <InputContainer>
          <Input
            error={error}
            label={t('login.repeatPassword')}
            id="passwordConfirm"
            type={passwordShown ? 'password' : 'text'}
            name="passwordConfirm"
            placeholder={t('login.labelPassword')}
            onChange={(event) => setPasswordConfirm(event.target.value)}
            value={passwordConfirm}
          />
          <ActionIcon onClick={togglePassword}>{passwordShown ? <StyledIconEye /> : <StyledIconEyeSlash />}</ActionIcon>
        </InputContainer>
      </Grid>
      <Footer>
        <Button onClick={onClose}>{t('common.back')}</Button>
        <Button onClick={onSave} primary loading={submitting}>
          {t('common.save')}
        </Button>
      </Footer>
    </Container>
  );
};

export { PasswordChangeModal };
