import styled, { css } from 'styled-components';

export const PanelAccordion = styled.div`
  padding: 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #d8d8d8;
  border-radius: 16px;
  gap: 50px;
  width: 100%;
`;

export const PanelAccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  cursor: pointer;

  padding-bottom: 18px;
  border-bottom: 1px solid #e3dfda;
`;

interface PanelAccordionContentProps {
  visible: boolean;
}

export const PanelAccordionContent = styled.div<PanelAccordionContentProps>`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;

  ${({ visible }) =>
    visible &&
    css`
      display: none;
    `}
`;

interface DropdownProps {
  open: boolean;
}

export const DropdownWrapper = styled.div<DropdownProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 0.25s linear;

  ${({ open }) =>
    open &&
    css`
      transform: rotate(180deg);
    `}
`;

export const PanelTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
