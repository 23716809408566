import React, { useEffect, useMemo } from 'react';
import {
  BrowserRouter as Router,
  matchPath,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { addMinutes, isBefore } from 'date-fns';
import Login from './pages/Login';
import EmailPermissions from './pages/EmailPermissions';
import DataPurgeWordsConfig from './pages/DataPurgeWordsConfig';
import UserManagement from './pages/UserManagement';
import Navbar from './components/Navbar';
import SettingsForms from './pages/SettingsForms';
import FormEdit from './pages/Form/FormEdit';
import { CreateProductSettings } from './pages/SettingsForms/components/createSettings/createProduct';
import { CreateProjectTypeSettings } from './pages/SettingsForms/components/createSettings/createProjectType';
import { CreateServiceOfferSettings } from './pages/SettingsForms/components/createSettings/createServiceOffer';
import { EditBusinessUnitSettings } from './pages/SettingsForms/components/editSettings/editBusinessUnit';
import { CreateBusinessUnitSettings } from './pages/SettingsForms/components/createSettings/createBusinessUnit';
import { EditProductSettings } from './pages/SettingsForms/components/editSettings/editProduct';
import { EditProjectTypeSettings } from './pages/SettingsForms/components/editSettings/editProjectType';
import { EditServiceOfferSettings } from './pages/SettingsForms/components/editSettings/editServiceOffer';
import { Dashboard } from './pages/Dashboard';
import { DashboardAdmin } from './pages/DashboardAdmin';
import FormCreate from './pages/Form/FormCreate';
import FormList from './pages/Form/FormList';
import QuestionSettings from './pages/QuestionSettings/QuestionSettingsList';
import QuestionSettingsCreate from './pages/QuestionSettings/QuestionSettingsCreate';
import QuestionSettingsEdit from './pages/QuestionSettings/QuestionSettingsEdit';
import ReplyForm from './pages/ReplyForm';
import FormHistoric from './pages/Form/FormHistoric';
import VariablesList from './pages/Variables';
import { VariablesEdit } from './pages/Variables/editVariable';
import { MobileAlert } from './styles/styles';
import EmptyState from './components/EmptyState';
// import { ChatBot } from './ChatBot';
import { decodeJwt } from './utils/jwtUtils';
import { getFromLocalStorage, saveSessionStorage } from './utils/storageUtils';
import { fallbackRoutesByUserType, JwtContext, routesByUserType } from './JwtContext';
import ProjectSelection from './pages/ProjectSelection';
import { NotFound } from './pages/NotFound';
import { useWindowDimensions } from './utils/useWindowDimensions';
import FormView from './pages/Form/FormView';
import FormTest from './pages/Form/FormTest';
import DashboardPreview from './pages/DashboardPreview';
import { UserType } from './types/UserType';
import { refreshToken } from './http/autentication';
import FormAdvisorView from './pages/FormAdvisorView';
import { UserProject } from './pages/UserProject';
import { EditUserProject } from './pages/UserProject/editUserProject';
import { logout } from './services/user.service';

const EXPIRE_TIME = 60;

const Alert = () => {
  const { t } = useTranslation();

  return (
    <MobileAlert>
      <EmptyState text={t('mobileAlert.alert')} />
    </MobileAlert>
  );
};

const RoutesWithoutMobile = () => {
  const { width } = useWindowDimensions();
  const canBeAccessedFromMobile = width && width >= 1024;
  return canBeAccessedFromMobile ? <Outlet /> : <Alert />;
};

const RoutesWithNavbar = () => (
  <>
    <Navbar />
    <Outlet />
  </>
);
const RoutesWithChatbot = () => (
  <>
    <Outlet />
    {/* <ChatBot /> */}
  </>
);

const validateIfShouldRefreshTheToken = (expireDate: number) => {
  const difference = new Date(expireDate).getTime() - new Date().getTime();
  const resultInMinutes = Math.round(difference / 60000);
  if (resultInMinutes < 10) {
    refreshToken
      .post('', {
        refreshToken: getFromLocalStorage('refreshToken'),
      })
      .then(({ data }) => {
        saveSessionStorage('token', data.token);
        saveSessionStorage('refreshToken', data.refreshToken);
      });
  }
};

const SessionWrapper = () => {
  const jwt = getFromLocalStorage('token');
  const context: { decodedJwt: { [index: string]: any } } = useMemo(() => {
    try {
      return { decodedJwt: decodeJwt(jwt ?? '') };
    } catch (e: any) {
      return { decodedJwt: {} };
    }
  }, [jwt]);
  const location = useLocation();
  const expireDate = (context.decodedJwt?.exp ?? 0) * 1000;
  useEffect(() => {
    const interval = setInterval(() => {
      validateIfShouldRefreshTheToken(expireDate);
    }, 300000);
    return () => {
      clearInterval(interval);
    };
  }, [context]);

  const navigate = useNavigate();

  useEffect(() => {
    const checkForInactivite = () => {
      const expireTime = sessionStorage.getItem('expireTime');
      if (isBefore(new Date(expireTime as string), new Date())) {
        logout();
        navigate('/', { replace: true });
        sessionStorage.removeItem('expireTime');
      }
    };

    const intervalToCheckExpiration = setInterval(() => {
      checkForInactivite();
    }, 1000);
    return () => {
      clearInterval(intervalToCheckExpiration);
    };
  }, []);

  useEffect(() => {
    const updateExpireTime = () => {
      const expireTime = addMinutes(new Date(), EXPIRE_TIME);
      sessionStorage.setItem('expireTime', expireTime.toISOString());
    };
    updateExpireTime();
    window.addEventListener('click', updateExpireTime);
    window.addEventListener('keypress', updateExpireTime);
    window.addEventListener('scroll', updateExpireTime);
    window.addEventListener('mousemove', updateExpireTime);
    return () => {
      window.removeEventListener('click', updateExpireTime);
      window.removeEventListener('keypress', updateExpireTime);
      window.removeEventListener('scroll', updateExpireTime);
      window.removeEventListener('mousemove', updateExpireTime);
    };
  }, []);
  if (Object.keys(context.decodedJwt).length < 1 || Date.now() >= expireDate || context.decodedJwt.restriction) {
    return <Navigate to="/" />;
  }

  const userType = context.decodedJwt.type as UserType;
  const hasPermissionToSeeScreen = (routesByUserType[userType] as string[]).some((route) =>
    matchPath(route, location.pathname)
  );
  if (!hasPermissionToSeeScreen) return <Navigate to={fallbackRoutesByUserType[userType]} />;

  return (
    <JwtContext.Provider value={context}>
      <Outlet />
    </JwtContext.Provider>
  );
};

const AppRouter = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/" element={<SessionWrapper />}>
        <Route path="/" element={<RoutesWithNavbar />}>
          <Route path="/" element={<RoutesWithoutMobile />}>
            <Route path="settings/email-permissions" element={<EmailPermissions />} />
            <Route path="settings/data-purge-words" element={<DataPurgeWordsConfig />} />
            <Route path="settings/forms" element={<SettingsForms />} />
            <Route path="settings/variables" element={<VariablesList />} />
            <Route path="settings/variables/create" element={<VariablesEdit />} />
            <Route path="settings/variables/edit/:id" element={<VariablesEdit />} />
            <Route path="product" element={<CreateProductSettings />} />
            <Route path="project-type" element={<CreateProjectTypeSettings />} />
            <Route path="business-unit" element={<CreateBusinessUnitSettings />} />
            <Route path="service-offer" element={<CreateServiceOfferSettings />} />
            <Route path="product/edit" element={<EditProductSettings />} />
            <Route path="project-type/edit" element={<EditProjectTypeSettings />} />
            <Route path="business-unit/edit" element={<EditBusinessUnitSettings />} />
            <Route path="service-offer/edit" element={<EditServiceOfferSettings />} />
            <Route path="settings/question" element={<QuestionSettings />} />
            <Route path="settings/question/create" element={<QuestionSettingsCreate />} />
            <Route path="settings/question/edit" element={<QuestionSettingsEdit />} />
            <Route path="forms" element={<FormList />} />
            <Route path="forms/create" element={<FormCreate />} />
            <Route path="forms/view/:id" element={<FormView />} />
            <Route path="forms/test/:id" element={<FormTest />} />
            <Route path="forms/edit/:id" element={<FormEdit />} />
            <Route path="forms/historic/:id/:version" element={<FormHistoric />} />
            <Route path="admin" element={<DashboardAdmin />} />
            <Route path="user-management" element={<UserManagement />} />
            <Route path="dashboard/preview/:projectId" element={<DashboardPreview />} />
            <Route path="advisor-view/:id/:projectId" element={<FormAdvisorView />} />
          </Route>
          <Route path="/" element={<RoutesWithChatbot />}>
            <Route path="reply-form/:id" element={<ReplyForm />} />
            <Route path="user-project" element={<UserProject />} />
            <Route path="user-project/create" element={<EditUserProject />} />
            <Route path="user-project/edit/:id" element={<EditUserProject />} />
            <Route path="home" element={<Dashboard />} />
          </Route>
          <Route path="project-selection" element={<ProjectSelection />} />
        </Route>
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  </Router>
);

export { AppRouter };
