import api from '../http/api';

export const findReplyFormById = async (formAnswerId: string | undefined, projectId?: string) => {
  const queryString = projectId ? `/answer/${formAnswerId}?projectId=${projectId}` : `/answer/${formAnswerId}`;
  const { data } = await api.get(queryString);
  return data;
};

export const saveReplyForm = async (data: any, id: string | undefined) => {
  await api.post(`/answer/send/${id}`, data);
};

export const testDraft = async (data: any, id: string | undefined) => {
  await api.post(`/forms/draft/test/answer/${id}`, data);
};

export const saveDraftReplyForm = async (data: any, id: string | undefined) => {
  await api.post(`/answer/draft/${id}`, data);
};
