import React from 'react';
import { Container, Text, StyledLoader, BubbleWrapper, Bubble } from './style';

interface LoaderProps {
  text?: string;
  scale?: number;
  white?: boolean;
  solid?: boolean;
}

const Loader = ({ text, scale, white, solid }: LoaderProps) => (
  <Container className="loader-container">
    <StyledLoader>
      <BubbleWrapper scale={scale} white={white} solid={solid}>
        <Bubble scale={scale} white={white} solid={solid} />
      </BubbleWrapper>
      <BubbleWrapper scale={scale} white={white} solid={solid}>
        <Bubble scale={scale} white={white} solid={solid} />
      </BubbleWrapper>
      <BubbleWrapper scale={scale} white={white} solid={solid}>
        <Bubble scale={scale} white={white} solid={solid} />
      </BubbleWrapper>
      <BubbleWrapper scale={scale} white={white} solid={solid}>
        <Bubble scale={scale} white={white} solid={solid} />
      </BubbleWrapper>
    </StyledLoader>
    {text && <Text scale={scale}>{text}</Text>}
  </Container>
);

export default Loader;
