import { formTypeWithLabel, questionTypesWithLabel } from '../constants';
import { ACTIONS_LABELS, CONDITIONS_LABELS, QuestionConditions } from '../components/RulesModal/constants';
import { FieldType } from '../types/FieldType';
import { findByIdQuestionSettingsRequest } from '../../../requests/questionsSettings';

const getQuestionsTree = (questions: any) => {
  if (!questions) {
    return [];
  }

  const roots: any[] = [];
  const childrenByParentId = {};

  questions.forEach((item: any) => {
    if (!item.childOf) {
      roots.push(item);
      return;
    }
    // @ts-ignore
    if (!childrenByParentId[item.childOf]) {
      // @ts-ignore
      childrenByParentId[item.childOf] = [];
    }
    // @ts-ignore
    childrenByParentId[item.childOf].push(item);
  });

  function buildNodes(nodes: any) {
    if (!nodes) {
      return null;
    }
    return nodes.map((node: any) => ({
      ...node,
      // @ts-ignore
      questions: buildNodes(childrenByParentId[node.wId]),
    }));
  }

  return buildNodes(roots);
};

const isNotEmptyArray = (array: any) => Array.isArray(array) && array.length !== 0;

const mapQuestionsRulesToFrontendPattern = (rules: any) => {
  const conditions = rules.rulesList.reduce(
    (acc: any, cur: any) => [...acc, ...(Array.isArray(cur) ? cur : [cur])],
    []
  );
  const booleanConditions = rules.rulesList.reduce(
    (acc: any, cur: any, index: number, self: any) => [
      ...acc,
      // @ts-ignore
      ...[...new Array(cur.length - 1).keys()].map(() => 'OR'),
      ...(index !== self.length - 1 ? ['AND'] : []),
    ],
    []
  );
  return { conditions, booleanConditions };
};

const mapLabelForRulesConditions = (conditions: { condition: QuestionConditions }[]) =>
  conditions.map((conditionFields) => ({
    ...conditionFields,
    condition: CONDITIONS_LABELS()[conditionFields.condition],
  }));

const formatRules = (rules: { whenRequirementMet: 'show' | 'hide'; rulesList: any[] }) => {
  if (!isNotEmptyArray(rules?.rulesList)) return null;

  const { conditions, booleanConditions } = mapQuestionsRulesToFrontendPattern(rules);

  return {
    action: ACTIONS_LABELS()[rules.whenRequirementMet],
    conditions: mapLabelForRulesConditions(conditions),
    booleanConditions,
  };
};

const formatQuestionFields = async (questions: any) =>
  Promise.all(
    questions.map(async (question: any) => {
      let type;

      if (question.type === FieldType.CUSTOM) {
        const { customTypeId } = question;
        const props = await findByIdQuestionSettingsRequest(customTypeId);
        type = { value: FieldType.CUSTOM, label: props.name, props };
      } else {
        // @ts-ignore
        type = questionTypesWithLabel()[question.type];
      }

      return {
        ...question,
        files: question.files ? question.files.map((file: any) => ({ link: file })) : [],
        type,
        saved: true,
        rules: formatRules(question.rules),
      };
    })
  );

const formatReceivedFormData = async (data: any) => {
  const { questions, formType, ...remainingFields } = data;

  const formattedQuestions = await formatQuestionFields(questions);
  const flattedQuestions = getQuestionsTree(formattedQuestions);

  return {
    ...remainingFields,
    // @ts-ignore
    formType: formTypeWithLabel()[formType],
    questions: flattedQuestions,
  };
};

export default formatReceivedFormData;
