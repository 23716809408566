import React from 'react';

export interface ShapeCopyRightProps {
  width?: number;
  height?: number;
  className?: string;
}

export const ShapeCopyRight = ({ width = 11, height = 10, className }: ShapeCopyRightProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 11 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.70833 1.20833V8.79167H9.29167V5.54167C9.29167 5.24251 9.53418 5 9.83333 5C10.1325 5 10.375 5.24251 10.375 5.54167V8.79167C10.375 9.3875 9.8875 9.875 9.29167 9.875H1.70833C1.11002 9.875 0.625 9.38998 0.625 8.79167V1.20833C0.625 0.610025 1.11002 0.125 1.70833 0.125H4.95833C5.25749 0.125 5.5 0.367512 5.5 0.666667C5.5 0.965821 5.25749 1.20833 4.95833 1.20833H1.70833ZM6.58333 0.666667C6.58333 0.367512 6.82585 0.125 7.125 0.125H9.875C10.1511 0.125 10.375 0.348858 10.375 0.625V3.375C10.375 3.67415 10.1325 3.91667 9.83333 3.91667C9.53418 3.91667 9.29167 3.67415 9.29167 3.375V1.97208L4.34896 6.91479C4.13805 7.1257 3.79611 7.1257 3.58521 6.91479C3.3743 6.70389 3.3743 6.36195 3.58521 6.15104L8.52792 1.20833H7.125C6.82585 1.20833 6.58333 0.965821 6.58333 0.666667Z"
      fill="#A8C1FF"
    />
  </svg>
);
