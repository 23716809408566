import React, { useState } from 'react';
import { FieldTitle } from '../../style';
import PanelField from './components/PanelField';
import { PanelAccordion, PanelAccordionContent, PanelAccordionHeader } from './style';
import { DropdownIcon } from '../../../../../assets/svgs/DropdownIcon';

interface PanelProps {
  title: string;
  questions: any[];
}

const Panel = ({ title, questions }: PanelProps) => {
  const [open, setOpen] = useState(false);
  return (
    <PanelAccordion>
      <PanelAccordionHeader onClick={() => setOpen(!open)}>
        <FieldTitle>{title}</FieldTitle>
        <DropdownIcon />
      </PanelAccordionHeader>
      {open && (
        <PanelAccordionContent>
          {questions.map(({ type, ...props }) => (
            <PanelField fieldType={type?.value || type} customFieldTypeProps={type?.props} {...props} />
          ))}
        </PanelAccordionContent>
      )}
    </PanelAccordion>
  );
};

export default Panel;
