import api from '../http/api';
import { TableData } from '../types/TableData';

const BUSINESS_UNIT_PATH = '/business-units';

export const findAllBusinessUnitRequest = async () => {
  const { data } = await api.get(BUSINESS_UNIT_PATH);
  return data;
};

export const findBusinessUnitByIdRequest = async (id: number | string) => {
  const { data } = await api.get(`${BUSINESS_UNIT_PATH}/${id}`);
  return data;
};

export const addBusinessUnitRequest = async (data: TableData[]) => {
  await api.post(BUSINESS_UNIT_PATH, data);
};

export const updateBusinessUnitRequest = async (data: TableData, id: string) => {
  await api.put(`${BUSINESS_UNIT_PATH}/${id}`, data);
};

export const deleteBusinessUnitRequest = async (data: TableData[]) => {
  await api.patch(BUSINESS_UNIT_PATH, data);
};
