import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ActionsWrapper, Container, Title } from './style';
import { BreadcrumbWrapper } from '../../components/BreadCrumb/style';
import { Breadcrumbs } from '../../components/BreadCrumb';
import Table from '../../components/Table';
import { ActionIcon } from '../SettingsForms/style';

import { LoaderWrapper } from '../../components/Loader/style';
import Loader from '../../components/Loader';
import { deleteUserProject, findAllUserAnswererRequest } from '../../requests/userManagement';
import { useJwtContext } from '../../JwtContext';
import { DeleteIcon } from '../../assets/svgs/DeleteIcon';
import { Creators as modalActions } from '../../redux/reducers/modal-reducer';
import DefaultModalContent from '../../components/DefaultModalContent';

export const UserProject = () => {
  const { t } = useTranslation();
  const jwt = useJwtContext();
  const navigate = useNavigate();

  const [list, setList] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { openModal, closeModal } = modalActions;

  const getUserProject = async () => {
    setLoading(true);
    const data = await findAllUserAnswererRequest();
    if (data) {
      setList([{ name: jwt.name, email: jwt.email }, ...data]);
    }
    setLoading(false);
  };

  const deleteUserFromProjectModal = (email: string) => {
    const submitDelete = async () => {
      await deleteUserProject({ email });
      dispatch(closeModal());
      await getUserProject();
    };

    dispatch(
      openModal({
        content: (
          <DefaultModalContent
            onSubmit={submitDelete}
            submitButtonTitle={t('common.delete')}
            title={t('userManagement.alert')}
          />
        ),
      })
    );
  };

  const render = (linhaDaTabela: any) => [
    linhaDaTabela.name,
    linhaDaTabela.email,
    linhaDaTabela._id ? (
      <ActionsWrapper>
        {/* <ActionIcon onClick={() => navigate(`/user-project/edit/${linhaDaTabela._id}`)}>
          <EditIcon />
        </ActionIcon> */}
        <ActionIcon onClick={() => deleteUserFromProjectModal(linhaDaTabela.email)}>
          <DeleteIcon />
        </ActionIcon>
      </ActionsWrapper>
    ) : undefined,
  ];

  useEffect(() => {
    getUserProject();
  }, []);

  const createHandler = () => {
    navigate('/user-project/create');
  };

  if (loading) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  const WORDS_CONFIG_ADVICE = t('userManagement.listUserProject');
  const headers = [
    {
      head: t('utilsForm.USER_NAME'),
      key: 'name',
    },
    {
      head: t('utilsForm.USER_EMAIL'),
      key: 'email',
    },
  ];

  return (
    <Container>
      <BreadcrumbWrapper>
        <Breadcrumbs />
      </BreadcrumbWrapper>
      <Title>{t('breadcrumb.addProjetUser')}</Title>
      <Table
        searchKey="name"
        headers={headers}
        renderActions={() => []}
        advice={WORDS_CONFIG_ADVICE}
        emptyStateTexts={{
          EMPTY_FILTER: t('userManagement.emptyFilter'),
          EMPTY_LIST: t('userManagement.emptyList'),
        }}
        data={list}
        render={render}
        createHandler={createHandler}
      />
    </Container>
  );
};
