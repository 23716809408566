import { FieldType } from '../types/FieldType';
import { omitFromObject } from '../../../utils/omitFromObject';

interface FinalQuestionData {
  wId: string | number;
  title: string;
  rpaID: string;
  type: any;
  formLinkingSpecifier?: boolean;
  rules: {
    action: { value: string; label: string };
    conditions: any[];
    booleanConditions: any[];
  };
  expertMenu: string;
  expertField: string;
  choices?: any[];
  questions?: any[];
  supportedFileFormats?: string;
  files?: any[];
  saved: boolean;
}

interface FinalFormData {
  id: string;
  description: string;
  title: string;
  projectType: { value: string; label: string };
  businessUnit: { value: string; label: string };
  serviceOffer: { value: string; label: string };
  productType: { value: string; label: string };
  formType: { value: string; label: string };
  subType?: 'USER_REGISTRATION' | 'FORM_LINKING';
  questions: FinalQuestionData[];
}

const flatQuestions = (data: FinalQuestionData[]) => {
  const appendParentId = (questions: any[], parentId: number | string) =>
    questions.map((question) => ({ ...question, childOf: parentId }));

  return data.reduce(
    (acc, question) => [
      ...acc,
      ...(Array.isArray(question.questions)
        ? [question, ...appendParentId(question.questions, question.wId)]
        : [question]),
    ],
    [] as FinalQuestionData[]
  );
};

const formatRules = (conditions: any[], booleanConditions: any[]) => {
  if (conditions.length - 1 !== booleanConditions.length) return;
  const formattedConditions = conditions.map(({ formWId, ...conditionFields }) => ({
    ...conditionFields,
    id: conditionFields.id.value ?? conditionFields.id,
    condition: conditionFields.condition.value ?? conditionFields.condition,
  }));

  const [firstRule, ...remainingRules] = formattedConditions;
  return booleanConditions.reduce(
    (acc: any[], booleanCondition: 'OR' | 'AND', index: number) => {
      if (booleanCondition === 'OR') {
        const orGroup = acc.pop();
        return [...acc, [...orGroup, remainingRules[index]]];
      }
      return [...acc, [remainingRules[index]]];
    },
    [[firstRule]]
  );
};

const questionTypeWithChoices = [FieldType.SINGLE_CHOICE, FieldType.MULTIPLE_CHOICE];

const formatQuestions = (data: FinalQuestionData[]) =>
  data.map(({ questions, type, rules, supportedFileFormats, choices, files, ...keepAttrs }) => {
    let response;
    const typeValue = type?.value;
    if (typeValue === FieldType.CUSTOM) {
      response = {
        ...keepAttrs,
        rules: rules
          ? {
              whenRequirementMet: rules.action.value,
              rulesList: formatRules(rules.conditions, rules.booleanConditions),
            }
          : null,
        type: FieldType.CUSTOM,
        customTypeId: type?.props._id,
        choices: [],
        files: [],
        supportedFileFormats: '',
      };
    } else {
      response = {
        ...keepAttrs,
        rules: rules
          ? {
              whenRequirementMet: rules.action.value,
              rulesList: formatRules(rules.conditions, rules.booleanConditions),
            }
          : null,
        type: typeValue,
        choices: choices && questionTypeWithChoices.includes(type?.value) ? choices : [],
        files: files && type?.value === FieldType.DOWNLOAD ? files.map((file) => file.link) : [],
        supportedFileFormats: supportedFileFormats && type?.value === FieldType.UPLOAD ? supportedFileFormats : '',
      };
    }

    return omitFromObject(response, ['formLinkingSpecifier', 'saved']);
  });

const formatFormData = (data: FinalFormData) => {
  const { questions, projectType, businessUnit, serviceOffer, productType, formType, ...otherFields } = data;
  const cleanedFlattedQuestions = formatQuestions(flatQuestions(questions));

  return {
    ...otherFields,
    projectType: projectType?.value || projectType,
    businessUnit: businessUnit?.value || businessUnit,
    serviceOffer: serviceOffer?.value || serviceOffer,
    productType: productType?.value || productType,
    formType: formType.value,
    questions: cleanedFlattedQuestions,
  };
};

export default formatFormData;
