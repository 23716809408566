import api from '../http/api';

export const findAllUserFilterRestriction = async () => {
  const { data } = await api.get('/user-filter');
  return data;
};

export const findAllUserNoFilter = async () => {
  const { data } = await api.get('/user-no-filter');
  return data;
};

export const saveUserFilter = async (id: string, data: any) => {
  await api.post(`/user-filter/${id}`, data);
};
