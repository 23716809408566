import styled from 'styled-components';

export const FieldTitle = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 16px;
  color: #393533;
`;

export const DeleteIconWrapper = styled.div`
  cursor: pointer;
`;

export const LongInput = styled.textarea`
  padding: 8px;
  height: 124px;
  box-sizing: border-box;
  gap: 8px;
  width: 100%;
  height: 124px;
  background: #ffffff;
  border: 2px solid #938c85;
  border-radius: 8px;
  resize: none;
`;
