import styled from 'styled-components';

export const Title = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
`;

export const FileLinkContainer = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  align-items: center;
`;

export const DeleteIconWrapper = styled.div`
  width: 15px;
  height: 17px;
  cursor: pointer;
`;
