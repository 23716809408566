import { cloneElement, createElement } from 'react';
import MultipleChoice from '../../ChoicesFields/MultipleChoice';
import InputField from '../../InputField';
import Upload from '../../Upload';
import UniqueChoice from '../../ChoicesFields/UniqueChoice';
import LongText from '../../LongText';
import Download from '../../Download';
import { FieldType } from '../../../../../../Form/types/FieldType';
import AnswerSelect from '../../ChoicesFields/AnswerSelect';
import InputEmail from '../../InputEmail';
import InputNumber from '../../InputNumber';
import ValidationField from '../../ValidationField';
import InputDate from '../../InputDate';
import InputPhone from '../../InputPhone';

const elements = new Map();
elements.set(FieldType.TEXT, createElement(InputField, { type: 'text' }));
elements.set(FieldType.LONG_TEXT, createElement(LongText));
elements.set(FieldType.MULTIPLE_CHOICE, createElement(MultipleChoice));
elements.set(FieldType.SINGLE_CHOICE, createElement(UniqueChoice));
elements.set(FieldType.DOWNLOAD, createElement(Download));
elements.set(FieldType.UPLOAD, createElement(Upload));
elements.set(FieldType.EMAIL, createElement(InputEmail));
elements.set(FieldType.USER_NAME, createElement(InputField, { type: 'text' }));
elements.set(FieldType.USER_EMAIL, createElement(InputField, { type: 'text' }));
elements.set(FieldType.SELECT, createElement(AnswerSelect));
elements.set(FieldType.NUMBER, createElement(InputNumber));
elements.set(FieldType.VALIDATION, createElement(ValidationField));
elements.set(FieldType.DATE, createElement(InputDate));
elements.set(FieldType.PHONE, createElement(InputPhone));

interface PanelFieldProps {
  nestIndex: number;
  control: any;
  register: any;
  errors: any;
  accessFieldsName: string;
  fieldType: FieldType | null;
  title: string;
  wId: string;
}

const PanelField = ({ fieldType, ...props }: PanelFieldProps) => {
  const element = elements.get(fieldType);
  return cloneElement(element, props);
};

export default PanelField;
