import api from '../http/api';
import { TableData } from '../types/TableData';

const PRODUCT_PATH = '/products';

export const findAllProductRequest = async () => {
  const { data } = await api.get(PRODUCT_PATH);
  return data;
};

export const findProductTypeByIdRequest = async (id: number | string) => {
  const { data } = await api.get(`${PRODUCT_PATH}/${id}`);
  return data;
};

export const addProductRequest = async (data: TableData[]) => {
  await api.post(PRODUCT_PATH, data);
};

export const updateProductRequest = async (data: TableData, id: string) => {
  await api.put(`${PRODUCT_PATH}/${id}`, data);
};

export const deleteProductRequest = async (data: TableData[]) => {
  await api.patch(PRODUCT_PATH, data);
};
