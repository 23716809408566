import React, { useEffect, useState } from 'react';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

import JSZip from 'jszip';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../../config/i18n/config';
import {
  Alert,
  BarChartWrapper,
  ChartWrapper,
  Container,
  Content,
  ContentWrapper,
  Footer,
  Forms,
  Header,
  HeaderForm,
  IconWrapper,
  PdfDownload,
  PieChartWrapper,
  PreviewAlert,
  PreviewBackButton,
  ResponsiveActionMenu,
  ResponsiveFilterButton,
  ResponsiveFilterWrapper,
  ResponsiveMenuButton,
  ResponsiveSelectWrapper,
  ResponsiveSubmitButton,
  SelectWrapper,
  Text,
  TextAlert,
  TitleBarChart,
  TitleChart,
  TitleForms,
  TitleWrapper,
  User,
} from './style';
import { IconPieChart } from '../../assets/svgs/IconPieChart';
import { IconAlertDashboard } from '../../assets/svgs/IconAlertDashboard';
import { CustomPieChart } from '../../components/Charts/PieChart';
import { ListPanel, Lists, TabList } from './components/TabsList';
import { IconForms } from '../../assets/svgs/IconForms';
import { FORM_STATUS_LABEL, FormStatus } from './constants';
import { BarChart } from '../../components/Charts/BarChart';
import { SubTitleBarChart } from '../../components/Charts/BarChart/SubTitleBarChart';
import { SubTitlePieChart } from '../../components/Charts/PieChart/SubTitlePieChart';
import TextSelect from '../../components/TextSelect';
import MultiSelect from '../../components/MultiSelect';
import { DropdownIcon } from '../../assets/svgs/DropdownIcon';
import CustomerGroupList from './components/ListGroups';
import ListCompanies from './components/ListCompanies';
import { findAllDataClientDashboard } from '../../requests/dashboard';
import EmptyState from '../../components/EmptyState';
import { FormData, GeneratePdf, GeneratePdfMultipleForm } from '../../components/GeneratePdf';
import { findReplyFormById } from '../../requests/answers';
import { findAllProjectTypeRequest } from '../../requests/projectType';
import { findAllBusinessUnitRequest } from '../../requests/businessUnit';
import { findAllServiceOfferRequest } from '../../requests/serviceOffer';
import { findAllProductRequest } from '../../requests/product';
import DownloadIcon from '../../assets/svgs/DownloadIcon';
import ToastAlert from '../../components/Toast/toast';
import { LoaderWrapper } from '../../components/Loader/style';
import Loader from '../../components/Loader';
import { useJwtContext } from '../../JwtContext';
import { cleanFiles, getFileUrl64 } from '../../requests/files';

const generatePdfs = async (dashboard: any, projectId?: string) => {
  const concludedForms = dashboard?.formsObj?.filter((form: any) => form.status === 'FINISHED');

  const projectTypes = await findAllProjectTypeRequest();
  const businessUnits = await findAllBusinessUnitRequest();
  const serviceOffers = await findAllServiceOfferRequest();
  const products = await findAllProductRequest();

  const chunks: any[] = [];
  const fetchedForms: FormData[] = [];
  for (let i = 0; i < concludedForms.length; i += 15) {
    chunks.push(concludedForms.slice(i, i + 15));
  }
  for (let i = 0; i < chunks.length; i += 1) {
    const chunk = chunks[i];
    // eslint-disable-next-line no-await-in-loop
    await Promise.all(
      chunk.map(async (form: any) => {
        const fetchedForm = await findReplyFormById(form.formAnswerId, projectId);
        const projectType = projectTypes.find((element: any) => element._id === fetchedForm?.projectType);
        const businessUnit = businessUnits.find((element: any) => element._id === fetchedForm?.businessUnit);
        const serviceOffer = serviceOffers.find((element: any) => element._id === fetchedForm?.serviceOffer);
        const product = products.find((element: any) => element._id === fetchedForm?.productType);

        const formWithParameters = {
          ...fetchedForm,
          projectType: projectType?.name,
          businessUnit: businessUnit?.name,
          serviceOffer: serviceOffer?.name,
          productType: product?.name,
        };

        fetchedForms.push(formWithParameters);
      })
    );
  }
  const zip = new JSZip();

  const getFilesToDownload = (form: any) => {
    const getDownloadLinksFromQuestions = (questions: any[]) =>
      questions
        .filter((question: any) => question.type === 'UPLOAD')
        .reduce((acc, question: any) => [...acc, ...question.answer], [] as string[]);

    if (String(form.multipleForm) === '1') {
      return form.questions.reduce(
        (acc: string[], tab: any[]) => [...acc, ...getDownloadLinksFromQuestions(tab)],
        [] as string[]
      );
    }
    if (form.repeating) {
      // return form.entries.reduce(
      //   (acc: string[], entry: any) => [...acc, ...getDownloadLinksFromQuestions(entry.questions)],
      //   [] as string[]
      // );
      return [];
    }
    if (form.formType === 'BRANCH') {
      // return form.branches.reduce(
      //   (acc: string[], branch: any) => [...acc, ...getDownloadLinksFromQuestions(branch.questions)],
      //   [] as string[]
      // );
      return [];
    }
    return getDownloadLinksFromQuestions(form.questions);
  };

  for (let j = 0; j < fetchedForms.length; j += 1) {
    const form = fetchedForms[j];
    const filesToDownload = getFilesToDownload(form);

    // eslint-disable-next-line no-await-in-loop
    await Promise.all(
      filesToDownload.map(async (filename: any): Promise<any> => {
        try {
          const { data } = await getFileUrl64(filename);
          const blob = Buffer.from(data.file, 'base64');
          zip.file(`${form.companyName ? `${form.companyName}-` : ''}${filename.split('/').pop()}`, blob);
          return blob;
        } catch (error) {
          console.log(error);
        }
      })
    );

    if (!form.multipleForm) {
      // eslint-disable-next-line no-await-in-loop
      const blob = await pdf(<GeneratePdf key={form.tabId} data={form} />).toBlob();
      zip.file(`${form.companyName ? `${form.companyName}-` : ''}${form.title}.pdf`, blob);
    } else {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < form.questions.length; i++) {
        // eslint-disable-next-line no-await-in-loop
        const blob = await pdf(
          <GeneratePdfMultipleForm key={i} data={form} tabId={i} qtdTabs={form.questions.length} />
        ).toBlob();
        zip.file(
          `${form.companyName ? `${form.companyName}-` : ''}${form.title}-${i + 1}_${form.questions.length}.pdf`,
          blob
        );
      }
    }
  }

  const zipped = await zip.generateAsync({ type: 'blob' });
  saveAs(zipped, `finished-forms-${new Date().toISOString().replace(':', '-').replace('.', '-')}.zip`);
  cleanFiles();
};

export const selectOptions = [
  { value: null, label: i18n.t('dashboard.noOrdering') },
  { value: FormStatus.NOT_INITIATED, label: i18n.t('dashboard.notStarted') },
  { value: FormStatus.IN_PROGRESS, label: i18n.t('dashboard.inProgress') },
  { value: FormStatus.FINISHED, label: i18n.t('dashboard.finished') },
];

enum ResponsiveMenu {
  COMPANY = 1,
  ORDER = 2,
}

const ResponsiveCompanyFilter = ({ companies, onSubmit, defaultValue }: any) => {
  const [selectedFilter, setSelectedFilter] = useState<any[]>(defaultValue);
  const { t } = useTranslation();

  const addOrRemove = (arr: any[], item: any) => (arr.includes(item) ? arr.filter((i) => i !== item) : [...arr, item]);

  const handleChange = (choice: any) => {
    if (Array.isArray(selectedFilter)) {
      return setSelectedFilter(addOrRemove(selectedFilter, choice));
    }

    return setSelectedFilter([choice]);
  };

  return (
    <ResponsiveFilterWrapper>
      {companies.map((company: any) => (
        <ResponsiveMenuButton
          key={company.value}
          selected={selectedFilter.includes(company.value)}
          onClick={() => handleChange(company.value)}
        >
          {company.label}
        </ResponsiveMenuButton>
      ))}
      <ResponsiveSubmitButton onClick={() => onSubmit(selectedFilter)}>{t('dashboard.filter')}</ResponsiveSubmitButton>
    </ResponsiveFilterWrapper>
  );
};

const ResponsiveOrderFilter = ({ onSubmit, defaultValue }: any) => {
  const [selectedFilter, setSelectedFilter] = useState<FormStatus | null>(defaultValue);
  const { t } = useTranslation();

  return (
    <ResponsiveFilterWrapper>
      <ResponsiveMenuButton selected={selectedFilter === null} onClick={() => setSelectedFilter(null)}>
        {t('dashboard.noOrdering')}
      </ResponsiveMenuButton>
      <ResponsiveMenuButton
        selected={selectedFilter === FormStatus.NOT_INITIATED}
        onClick={() => setSelectedFilter(FormStatus.NOT_INITIATED)}
      >
        {t('dashboard.notStarted')}
      </ResponsiveMenuButton>
      <ResponsiveMenuButton
        selected={selectedFilter === FormStatus.IN_PROGRESS}
        onClick={() => setSelectedFilter(FormStatus.IN_PROGRESS)}
      >
        {t('dashboard.inProgress')}
      </ResponsiveMenuButton>
      <ResponsiveMenuButton
        selected={selectedFilter === FormStatus.FINISHED}
        onClick={() => setSelectedFilter(FormStatus.FINISHED)}
      >
        {t('dashboard.finished')}
      </ResponsiveMenuButton>
      <ResponsiveSubmitButton onClick={() => onSubmit(selectedFilter)}>{t('dashboard.filter')}</ResponsiveSubmitButton>
    </ResponsiveFilterWrapper>
  );
};

export const Dashboard = ({
  isPreview,
  projectId,
  clientName,
}: {
  isPreview?: boolean;
  projectId?: string;
  clientName?: string;
}) => {
  const { t } = useTranslation();
  const decodedJwt = useJwtContext();
  const [dashboard, setDashboard] = useState<any>([]);
  const [responsiveMenu, setResponsiveMenu] = useState<ResponsiveMenu | null>(null);
  const [selectedTabList, setSelectedTabList] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingPdfButton, setLoadingPdfButton] = useState(false);
  const [filterStatus, setFilterStatus] = useState<{ value: null | FormStatus; label: string }>({
    value: null,
    label: t('dashboard.noOrdering'),
  });
  const [filterCompany, setFilterCompany] = useState<any[]>([]);
  const navigate = useNavigate();

  const getForms = async () => {
    try {
      const data = await findAllDataClientDashboard(isPreview, projectId);
      if (data) setDashboard(data);
    } catch (err: any) {
      ToastAlert.ERROR(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getForms();
  }, []);

  const handlerFilterStatus = (option: any) => {
    setFilterStatus(option);
  };

  const handlerFilterCompany = (options: any[]) => {
    const mappedOptions = options.map((op) => op.value);
    setFilterCompany(mappedOptions);
  };

  const companyForms = dashboard?.formsObj?.filter((form: any) => form.formType !== 'GROUP') || [];
  const groupForms = dashboard?.formsObj?.filter((form: any) => form.formType === 'GROUP') || [];

  const companyFormsIsDisabled =
    groupForms?.filter((item: any) => item.status === FormStatus.NOT_INITIATED && FormStatus.IN_PROGRESS).length !== 0;

  const handleChangeTabsList = (event: MouseEvent, newSelectedTabList: number) => {
    setSelectedTabList(newSelectedTabList);
  };

  const isCompanyTab = selectedTabList === 1;

  const dataChart = isCompanyTab ? dashboard?.formCounts?.companies : dashboard?.formCounts?.group;

  const companiesSelect = dashboard?.companies?.map((company: any) => ({
    label: company.companyName,
    value: company._id,
  }));

  const onSubmitResponsiveOrderFilter = (filter: FormStatus | null) => {
    if (filter === null) {
      setFilterStatus({ value: null, label: t('dashboard.notStarted') });
    } else {
      setFilterStatus({ value: filter, label: FORM_STATUS_LABEL[filter] });
    }
    setResponsiveMenu(null);
  };

  const onSubmitResponsiveCompanyFilter = (filter: any) => {
    setFilterCompany(filter);
    setResponsiveMenu(null);
  };
  const greeting = `${t('common.hello')} ${decodedJwt?.name}`;

  const checkIfPdfButtonIsActive = () => {
    const customerTab = selectedTabList === 0;
    const companiesTab = selectedTabList === 1;

    if (customerTab && dashboard?.formCounts?.group?.finished > 0) return true;
    if (companiesTab && dashboard?.formCounts?.companies?.finished > 0) return true;
    return false;
  };
  const pdfButtonIsActive = checkIfPdfButtonIsActive();

  const onDownloadPdf = async () => {
    setLoadingPdfButton(true);
    try {
      await ToastAlert.PROMISE(generatePdfs(dashboard, projectId), {
        pending: t('generatePdf.pendingDownload'),
        success: t('generatePdf.finishedDownload'),
        error: t('generatePdf.errorDownload'),
      });
    } catch (e) {
      console.log(e);
    }
    setLoadingPdfButton(false);
  };

  return loading ? (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  ) : (
    <>
      {isPreview && (
        <PreviewAlert>
          <PreviewBackButton onClick={() => navigate('/admin')}>
            <DropdownIcon className="arrow_preview" />
            {t('common.back')}
          </PreviewBackButton>
          <p>
            {t('dashboard.preview')} {clientName}
          </p>
        </PreviewAlert>
      )}
      <ResponsiveActionMenu open={Boolean(responsiveMenu)}>
        <ResponsiveMenuButton onClick={() => setResponsiveMenu(null)}>
          <DropdownIcon className="arrow_responsive_button" />
          <p>{t('common.back')}</p>
        </ResponsiveMenuButton>
        {responsiveMenu === ResponsiveMenu.COMPANY && (
          <ResponsiveCompanyFilter
            companies={companiesSelect}
            defaultValue={filterCompany}
            onSubmit={onSubmitResponsiveCompanyFilter}
          />
        )}
        {responsiveMenu === ResponsiveMenu.ORDER && (
          <ResponsiveOrderFilter defaultValue={filterStatus.value} onSubmit={onSubmitResponsiveOrderFilter} />
        )}
      </ResponsiveActionMenu>
      <Container>
        <Header>
          <User>{greeting}</User>
          <Lists selectedTabList={selectedTabList} onChange={handleChangeTabsList}>
            <TabList label={t('dashboard.clientGroup')} />
            <TabList label={t('dashboard.companies')} />
          </Lists>
        </Header>
        {companyFormsIsDisabled && (
          <Alert>
            <IconAlertDashboard />
            <TextAlert>{t('dashboard.alert')}</TextAlert>
          </Alert>
        )}
        <BarChartWrapper>
          <TitleBarChart>
            <IconPieChart fill="#262321" />
            {t('dashboard.progress')}
          </TitleBarChart>
          <BarChart data={dataChart} />
          <SubTitleBarChart data={dataChart} />
          {pdfButtonIsActive ? (
            <PdfDownload primary onClick={onDownloadPdf} loading={loadingPdfButton}>
              <DownloadIcon />
              <p>{t('dashboard.download')}</p>
            </PdfDownload>
          ) : (
            <PdfDownload primary>
              <DownloadIcon />
              <p>{t('dashboard.notDownload')}</p>
            </PdfDownload>
          )}
        </BarChartWrapper>
        <ContentWrapper>
          <Forms>
            <HeaderForm>
              <TitleWrapper>
                <TitleWrapper>
                  <IconForms fill="#000" />
                </TitleWrapper>
                <TitleForms>{t('common.forms')}</TitleForms>
              </TitleWrapper>
              <SelectWrapper>
                <TextSelect defaultValue={filterStatus} options={selectOptions} onChange={handlerFilterStatus} />
                {isCompanyTab && (
                  <MultiSelect
                    placeholder={t('dashboard.selectCompanies')}
                    options={companiesSelect}
                    onChange={handlerFilterCompany}
                    defaultValue={filterCompany}
                  />
                )}
              </SelectWrapper>
              <ResponsiveSelectWrapper>
                <ResponsiveFilterButton onClick={() => setResponsiveMenu(ResponsiveMenu.ORDER)}>
                  <div>{filterStatus.label}</div>
                  <IconWrapper>
                    <DropdownIcon className="arrow_filter_button" />
                  </IconWrapper>
                </ResponsiveFilterButton>
                {isCompanyTab && (
                  <ResponsiveFilterButton onClick={() => setResponsiveMenu(ResponsiveMenu.COMPANY)}>
                    <div>{t('dashboard.selectCompanies')}</div>
                    <IconWrapper>
                      <DropdownIcon className="arrow_filter_button" />
                    </IconWrapper>
                  </ResponsiveFilterButton>
                )}
              </ResponsiveSelectWrapper>
            </HeaderForm>
            <Content>
              <ListPanel selectedTabList={selectedTabList} index={0}>
                {groupForms.length > 0 ? (
                  <CustomerGroupList
                    isPreview={isPreview}
                    filterStatus={filterStatus.value}
                    forms={groupForms}
                    projectId={projectId}
                  />
                ) : (
                  <EmptyState text={t('dashboard.noFormCustomer')} />
                )}
              </ListPanel>
              <ListPanel selectedTabList={selectedTabList} index={1}>
                {companyForms.length > 0 ? (
                  <ListCompanies
                    isPreview={isPreview}
                    disabled={companyFormsIsDisabled}
                    filterStatus={filterStatus.value}
                    filterCompany={filterCompany}
                    forms={companyForms}
                    projectId={projectId}
                  />
                ) : (
                  <EmptyState text={t('dashboard.noFormCompanies')} />
                )}
              </ListPanel>
            </Content>
          </Forms>
          <PieChartWrapper>
            <TitleChart>
              <IconPieChart />
              {t('dashboard.progress')}
            </TitleChart>
            <ChartWrapper>
              <CustomPieChart data={dataChart} />
            </ChartWrapper>
            <SubTitlePieChart data={dataChart} />
            {pdfButtonIsActive ? (
              <PdfDownload primary onClick={onDownloadPdf} loading={loadingPdfButton}>
                <DownloadIcon />
                <p>{t('dashboard.download')}</p>
              </PdfDownload>
            ) : (
              <PdfDownload primary>
                <DownloadIcon />
                <p>{t('dashboard.notDownload')}</p>
              </PdfDownload>
            )}
            <Text style={{ marginTop: 35 }}>{t('common.updateData')}</Text>
          </PieChartWrapper>
        </ContentWrapper>
        <Footer>{t('dashboard.infos')}</Footer>
      </Container>
    </>
  );
};
