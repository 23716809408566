import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Label, StyledIconArrow, Subtitle, Title } from './style';

import { doForgot } from '../../../services/user.service';
import Input from '../../../components/Input';
import Button from '../../../components/Button';

interface ForgotProps {
  label?: any;
  className?: string;
  setForm?: any;
}

const Forgot = ({ label, className, setForm }: ForgotProps) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    try {
      setLoading(true);
      setError('');
      const navigateRequestSent = await doForgot(email.toLowerCase());
      if (navigateRequestSent) {
        setForm('login.requestsent');
      }
    } catch (err: any) {
      setError(err.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form className={className}>
      <Title>
        {t('login.forgoTitle')}
        <Subtitle>{t('login.forgotSubTitle')}</Subtitle>
      </Title>
      <Label>{label}</Label>
      <Input
        error={error}
        label="Email"
        id="email"
        type="email"
        name="email"
        placeholder={t('login.inputEmail')}
        onChange={(event) => setEmail(event.target.value)}
        value={email}
      />
      <Button primary onClick={handleSubmit} full mt={20} loading={loading}>
        {t('login.forgotButton')}
        <StyledIconArrow />
      </Button>
    </Form>
  );
};

export default Forgot;
