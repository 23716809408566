import styled, { css } from 'styled-components';
import { MAX_WIDTHS } from '../../constants/screen';

const DISABLED_STYLE = css`
  pointer-events: none;
  user-select: none;
  cursor: none;
  color: #979797;

  path {
    fill: #979797;
  }
`;

interface TextButtonProps {
  mt: number;
}

export const StyledTextButton = styled.button.attrs({
  type: 'button',
})<TextButtonProps>`
  background: none;
  padding: 0;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #324fa5;
  margin-top: ${({ mt = 0 }) => mt}px;

  transition: filter 0.2s;
  &:hover {
    filter: brightness(0.7);
  }

  path {
    fill: #324fa5;
  }

  ${({ disabled }) => disabled && DISABLED_STYLE};

  @media (max-width: ${MAX_WIDTHS.md}px) {
    & > span {
      display: none;
    }
  } ;
`;
