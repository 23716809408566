import i18n from '../../../../config/i18n/config';

export enum QuestionConditions {
  NOT_ANSWERED = 'NOT_ANSWERED',
  ANSWERED = 'ANSWERED',
  ANSWER_IS = 'ANSWER_IS',
  ANSWER_IS_NOT = 'ANSWER_IS_NOT',
  ANSWER_CONTAINS = 'ANSWER_CONTAINS',
  ANSWER_NOT_CONTAINS = 'ANSWER_NOT_CONTAINS',
  ANSWER_STARTS_WITH = 'ANSWER_STARTS_WITH',
  ANSWER_NOT_STARTS_WITH = 'ANSWER_NOT_STARTS_WITH',
}

export enum BooleanCondition {
  OR = 'OR',
  AND = 'AND',
}

export const CONDITIONS_LABELS = () => ({
  [QuestionConditions.NOT_ANSWERED]: {
    label: i18n.t('conditions.NOT_ANSWERED'),
    value: QuestionConditions.NOT_ANSWERED,
  },
  [QuestionConditions.ANSWERED]: {
    label: i18n.t('conditions.ANSWERED'),
    value: QuestionConditions.ANSWERED,
  },
  [QuestionConditions.ANSWER_IS]: {
    label: i18n.t('conditions.ANSWER_IS'),
    value: QuestionConditions.ANSWER_IS,
  },
  [QuestionConditions.ANSWER_IS_NOT]: {
    label: i18n.t('conditions.ANSWER_IS_NOT'),
    value: QuestionConditions.ANSWER_IS_NOT,
  },
  [QuestionConditions.ANSWER_CONTAINS]: {
    label: i18n.t('conditions.ANSWER_CONTAINS'),
    value: QuestionConditions.ANSWER_CONTAINS,
  },
  [QuestionConditions.ANSWER_NOT_CONTAINS]: {
    label: i18n.t('conditions.ANSWER_NOT_CONTAINS'),
    value: QuestionConditions.ANSWER_NOT_CONTAINS,
  },
  [QuestionConditions.ANSWER_STARTS_WITH]: {
    label: i18n.t('conditions.ANSWER_STARTS_WITH'),
    value: QuestionConditions.ANSWER_STARTS_WITH,
  },
  [QuestionConditions.ANSWER_NOT_STARTS_WITH]: {
    label: i18n.t('conditions.ANSWER_NOT_STARTS_WITH'),
    value: QuestionConditions.ANSWER_NOT_STARTS_WITH,
  },
});

export const ACTIONS_LABELS = () => ({
  hide: {
    label: i18n.t('conditions.labelHide'),
    value: 'hide',
  },
  show: {
    label: i18n.t('conditions.labelShow'),
    value: 'show',
  },
});

export const SELECT_CONDITIONS = () => [
  {
    label: i18n.t('conditions.NOT_ANSWERED'),
    value: QuestionConditions.NOT_ANSWERED,
  },
  {
    label: i18n.t('conditions.ANSWERED'),
    value: QuestionConditions.ANSWERED,
  },
  {
    label: i18n.t('conditions.ANSWER_IS'),
    value: QuestionConditions.ANSWER_IS,
  },
  {
    label: i18n.t('conditions.ANSWER_IS_NOT'),
    value: QuestionConditions.ANSWER_IS_NOT,
  },
  {
    label: i18n.t('conditions.ANSWER_CONTAINS'),
    value: QuestionConditions.ANSWER_CONTAINS,
  },
  {
    label: i18n.t('conditions.ANSWER_NOT_CONTAINS'),
    value: QuestionConditions.ANSWER_NOT_CONTAINS,
  },
  {
    label: i18n.t('conditions.ANSWER_STARTS_WITH'),
    value: QuestionConditions.ANSWER_STARTS_WITH,
  },
  {
    label: i18n.t('conditions.ANSWER_NOT_STARTS_WITH'),
    value: QuestionConditions.ANSWER_NOT_STARTS_WITH,
  },
];

export const ACTIONS = () => [
  {
    label: i18n.t('conditions.labelHide'),
    value: 'hide',
  },
  {
    label: i18n.t('conditions.labelShow'),
    value: 'show',
  },
];
