import api from '../http/api';

const ALLOW_LIST_EMAIL_PATH = '/countries/email/allowList';
const BLOCK_LIST_EMAIL_PATH = '/countries/email/blockList';
const DATA_PURGE_WORDS_PATH = '/countries/data-purge-words';

export const findAllAllowListRequest = async () => {
  const { data } = await api.get(ALLOW_LIST_EMAIL_PATH);
  return data;
};

export const findAllBlockListRequest = async () => {
  const { data } = await api.get(BLOCK_LIST_EMAIL_PATH);
  return data;
};

export const findAllDataPurgeWordListRequest = async () => {
  const { data } = await api.get(DATA_PURGE_WORDS_PATH);
  return data;
};

export const addEmailToAllowListRequest = async (email: string) => {
  await api.post(ALLOW_LIST_EMAIL_PATH, { email });
};

export const addEmailToBlockListRequest = async (email: string) => {
  await api.post(BLOCK_LIST_EMAIL_PATH, { email });
};

export const addWordToDataPurgeWordListRequest = async (word: string) => {
  await api.post(DATA_PURGE_WORDS_PATH, { word });
};

export const updateEmailToAllowListRequest = async (email: string, currentEmail: string) => {
  await api.put(ALLOW_LIST_EMAIL_PATH, { email, currentEmail });
};

export const updateEmailToBlockListRequest = async (email: string, currentEmail: string) => {
  await api.put(BLOCK_LIST_EMAIL_PATH, { email, currentEmail });
};

export const updateWordToDataPurgeWordListRequest = async (word: string, currentWord: string) => {
  await api.put(DATA_PURGE_WORDS_PATH, { word, currentWord });
};

export const deleteEmailsToAllowListRequest = async (emails: string[]) => {
  await api.patch(ALLOW_LIST_EMAIL_PATH, { emails });
};

export const deleteEmailsToBlockListRequest = async (emails: string[]) => {
  await api.patch(BLOCK_LIST_EMAIL_PATH, { emails });
};

export const deleteWordsToDataPurgeWordListRequest = async (words: string[]) => {
  await api.patch(DATA_PURGE_WORDS_PATH, { words });
};
