import React from 'react';

interface LightIconProps {
  width?: number;
  height?: number;
}

export const LightIcon = ({ width = 22, height = 22 }: LightIconProps) => (
  <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 1V3C10 3.55 10.45 4 11 4C11.55 4 12 3.55 12 3V1C12 0.45 11.55 0 11 0C10.45 0 10 0.45 10 1ZM11 6C8.24 6 6 8.24 6 11C6 13.76 8.24 16 11 16C13.76 16 16 13.76 16 11C16 8.24 13.76 6 11 6ZM1 12H3C3.55 12 4 11.55 4 11C4 10.45 3.55 10 3 10H1C0.45 10 0 10.45 0 11C0 11.55 0.45 12 1 12ZM21 12H19C18.45 12 18 11.55 18 11C18 10.45 18.45 10 19 10H21C21.55 10 22 10.45 22 11C22 11.55 21.55 12 21 12ZM10 21V19C10 18.45 10.45 18 11 18C11.55 18 12 18.45 12 19V21C12 21.55 11.55 22 11 22C10.45 22 10 21.55 10 21ZM4.99 3.58C4.6 3.19 3.96 3.19 3.58 3.58C3.19 3.97 3.19 4.61 3.58 4.99L4.64 6.05C5.03 6.44 5.67 6.44 6.05 6.05C6.43 5.66 6.44 5.02 6.05 4.64L4.99 3.58ZM15.95 15.95C16.33 15.56 16.97 15.56 17.36 15.95L18.42 17.01C18.81 17.39 18.81 18.03 18.42 18.42C18.04 18.81 17.4 18.81 17.01 18.42L15.95 17.36C15.56 16.98 15.56 16.34 15.95 15.95ZM18.42 4.99C18.81 4.6 18.81 3.96 18.42 3.58C18.03 3.19 17.39 3.19 17.01 3.58L15.95 4.64C15.56 5.03 15.56 5.67 15.95 6.05C16.34 6.43 16.98 6.44 17.36 6.05L18.42 4.99ZM6.05 15.95C6.44 16.33 6.44 16.97 6.05 17.36L4.99 18.42C4.61 18.81 3.97 18.8 3.58 18.42C3.19 18.04 3.19 17.4 3.58 17.01L4.64 15.95C5.02 15.56 5.66 15.56 6.05 15.95Z"
      fill="black"
    />
  </svg>
);
