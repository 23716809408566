import styled, { css } from 'styled-components';
import { MAX_WIDTHS } from '../../constants/screen';

interface SidebarProps {
  active: boolean;
}

export const Overlay = styled.div<{ active: boolean }>`
  ${({ active }) =>
    !active &&
    css`
      display: none;
    `}
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 56px;
  width: 100%;
  background: #121c4e;
  padding: 0 20px;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  z-index: 999;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  position: relative;
`;

export const IconWrapper = styled.div`
  cursor: pointer;

  path {
    fill: white;
  }
`;

export const UserIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: #476bc3;
  cursor: pointer;

  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #fff;
  text-shadow: 0 1px 3px rgba(38, 35, 33, 0.14);
`;

export const Sidebar = styled.div<SidebarProps>`
  background: #ffffff;
  position: fixed;
  top: 56px;
  height: calc(100vh - 56px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 100;
  left: -100%;
  transition: 850ms;
  min-width: 255px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  & > div {
    width: 100%;
  }

  ${({ active }) =>
    active &&
    css`
      left: 0;
      transition: 350ms;
    `};

  @media (max-width: 540px) {
    width: 100%;
  } ;
`;

export const HelpButton = styled.a`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 24px 20px;
  gap: 12px;

  text-decoration: none;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #726c66;

  &.active {
    color: #324fa5;
    svg path {
      fill: #324fa5;
    }
  }

  &:hover {
    background-color: #f2f5ff;
    color: #324fa5;
    svg path {
      fill: #324fa5;
    }
  }
`;

export const NavigateButton = styled.a`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 24px 20px;
  gap: 12px;
  cursor: pointer;

  text-decoration: none;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #726c66;

  &.active {
    color: #324fa5;
    svg path {
      fill: #324fa5;
    }
  }

  &:hover {
    background-color: #f2f5ff;
    color: #324fa5;
    svg path {
      fill: #324fa5;
    }
  }
`;

export const SidebarItems = styled.div`
  list-style: none;
  width: 100% !important;
`;

export const SidebarItemsFooter = styled.div`
  align-self: flex-end;
`;

export const Accordion = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 20px;
  cursor: pointer;

  text-decoration: none;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #726c66;

  &:hover {
    background-color: #f2f5ff;
    color: #324fa5;
    svg path {
      fill: #324fa5;
    }
  }
`;

export const AccordionTitleWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

export const StyledIconArrow = styled.div`
  margin-left: 60px;
`;

export const StyledMenuAccordion = styled.div`
  &:hover {
    background-color: #f2f5ff;
    color: #324fa5;
  }
`;

export const HeaderInput = styled.div`
  display: flex;
  align-items: center;
  background: rgba(100, 100, 130, 0.4);
  mix-blend-mode: normal;
  border-radius: 8px;
  height: 32px;
  padding: 0 16px;
  gap: 16px;

  input::placeholder {
    color: #fff;
  }

  input[type='text'] {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;

    line-height: 24px;
    color: #fff;
    opacity: 1;

    background-color: transparent;
    border: none;
    outline: none;
  }

  @media (max-width: ${MAX_WIDTHS.sm}px) {
    display: none;
  } ;
`;

export const ItemsChildren = styled.div``;
