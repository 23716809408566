import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Input from '../../../../components/Input';
import FormError from '../../../../components/FormError';
import Select from '../../../../components/Select';
import { CustomQuestionType, customQuestionTypesWithLabel } from '../../constants';
import CreatableField from '../CreatableField';
import Button from '../../../../components/Button';
import { StyledForm } from '../../style';
import { Footer, InlineInputs, InputWrapper } from './style';

interface CustomTypeFormFields {
  name: string;
  type: { value: CustomQuestionType; label: string } | null;
  mask?: string;
  choices?: any[];
  validationCode?: string;
  validationMessage?: string;
}

interface FormProps {
  onSubmit: (data: any) => void;
  defaultValues?: {
    name: string;
    type: CustomQuestionType;
    mask?: string;
    choices?: any[];
    validationCode?: string;
    validationMessage?: string;
  };
}

const QuestionSettingsForm = ({ defaultValues, onSubmit }: FormProps) => {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<CustomTypeFormFields>({
    defaultValues: {
      name: defaultValues?.name || '',
      type: defaultValues?.type ? customQuestionTypesWithLabel()[defaultValues?.type] : null,
      mask: defaultValues?.mask || '',
      choices: defaultValues?.choices || [],
      validationCode: defaultValues?.validationCode || '',
      validationMessage: defaultValues?.validationMessage || '',
    },
    mode: 'onChange',
  });

  const { t } = useTranslation();

  const navigate = useNavigate();

  const watchSelectedCustomQuestionType = watch('type');

  const customQuestionTypeOptions = [
    {
      value: CustomQuestionType.SINGLE_CHOICE,
      label: t('questions.SINGLE_CHOICE'),
    },
    {
      value: CustomQuestionType.MULTIPLE_CHOICE,
      label: t('questions.MULTIPLE_CHOICE'),
    },
    {
      value: CustomQuestionType.VALIDATION,
      label: t('questions.VALIDATION'),
    },
  ];

  const backHandler = () => {
    navigate('/settings/question');
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <InlineInputs>
        <InputWrapper>
          <Controller
            name="name"
            control={control}
            rules={{
              required: t<string>('form.requiredQuestion'),
            }}
            render={({ field }) => <Input {...field} label={t('questions.name')} />}
          />
          <FormError errors={errors} name="name" />
        </InputWrapper>
        <InputWrapper>
          <Controller
            name="type"
            rules={{
              required: t<string>('questions.questionType'),
            }}
            control={control}
            render={({ field }) => (
              <Select {...field} options={customQuestionTypeOptions} label={t('questions.labelQuestionType')} />
            )}
          />
          <FormError errors={errors} name="type" />
        </InputWrapper>
      </InlineInputs>
      {watchSelectedCustomQuestionType && (
        <CreatableField
          fieldType={watchSelectedCustomQuestionType.value}
          {...{ control, errors, watch }}
          defaultCode={defaultValues?.validationCode}
        />
      )}
      <Footer>
        <Button onClick={backHandler}>{t('common.back')}</Button>
        <Button primary type="submit">
          {t('common.save')}
        </Button>
      </Footer>
    </StyledForm>
  );
};

export default QuestionSettingsForm;
