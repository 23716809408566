import { useNavigate } from 'react-router-dom';
import React, { useMemo } from 'react';
import { FORM_STATUS_LABEL, FormStatus } from '../../constants';
import {
  DashboardBadge,
  ListBody,
  ListContainer,
  ListData,
  ListDataWrapper,
  ListRow,
  ListWrapper,
  TextButton,
  Title,
} from '../../style';

interface IFormData {
  _id: string;
  title: string;
  formType: string;
  status: FormStatus;
  formAnswerId: string;
  companyId?: string;
  companyName?: string;
}

interface ListProps {
  forms: IFormData[];
  filterStatus: FormStatus | null;
  isPreview?: boolean;
  projectId?: any;
}

const CustomerGroupList = ({ isPreview, forms, filterStatus, projectId }: ListProps) => {
  const navigate = useNavigate();

  const filteredGroupList = useMemo(() => {
    if (!filterStatus) return forms;

    return forms.filter((item) => item.status === filterStatus);
  }, [filterStatus]);

  const formFilling = (item: any) => {
    if (isPreview) return navigate(`/advisor-view/${item.formAnswerId}/${projectId}`);
    navigate(`/reply-form/${item.formAnswerId}`);
  };

  return (
    <ListContainer>
      <ListWrapper>
        <ListBody>
          {filteredGroupList.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <ListRow key={`ListData${item._id}${index}`}>
              <ListData>
                <ListDataWrapper onClick={() => formFilling(item)}>
                  <Title>{item.title}</Title>
                  <DashboardBadge status={item.status}>
                    <TextButton>{FORM_STATUS_LABEL[item.status]}</TextButton>
                  </DashboardBadge>
                </ListDataWrapper>
              </ListData>
            </ListRow>
          ))}
        </ListBody>
      </ListWrapper>
    </ListContainer>
  );
};

export default CustomerGroupList;
