import api from '../http/api';
import { TableData } from '../types/TableData';

const USER_MANAGEMENT_PATH = '/user';

export const findAllUserManagementRequest = async () => {
  const { data } = await api.get(USER_MANAGEMENT_PATH);
  return data;
};

export const addAdminUserRequest = async (data: any) => {
  await api.post(USER_MANAGEMENT_PATH, data);
};

export const changeAdminUserType = async (type: string, id: string) => {
  await api.patch(`${USER_MANAGEMENT_PATH}/change-type/${id}`, { type });
};

export const blockUsersRequest = async (data: TableData[]) => {
  const ids = data.map((item) => ({ id: item._id }));
  await api.patch(`${USER_MANAGEMENT_PATH}/inactive`, { ids });
};

export const unblockUsersRequest = async (data: TableData[]) => {
  const ids = data.map((item) => ({ id: item._id }));
  await api.patch(`${USER_MANAGEMENT_PATH}/reactive`, { ids });
};

export const findAllUserAnswererRequest = async () => {
  const { data } = await api.get(`${USER_MANAGEMENT_PATH}/answerer`);
  return data;
};

export const findUserAnswererById = async (id: string) => {
  const { data } = await api.get(`${USER_MANAGEMENT_PATH}/answerer/${id}`);
  return data;
};

export const createUserProject = async (data: any) => {
  await api.post(`${USER_MANAGEMENT_PATH}/answerer`, data);
};

export const deleteUserProject = async (data: any) => {
  await api.patch(`${USER_MANAGEMENT_PATH}/answerer/delete`, data);
};

export const passwordChangeRequest = async (data: any) => {
  await api.post(`${USER_MANAGEMENT_PATH}/password-change`, data);
};
