import React from 'react';

interface DraftIconProps {
  width?: number;
  height?: number;
  selected?: boolean;
}

export const DraftIcon = ({ width = 16, height = 17, selected }: DraftIconProps) => {
  const fill = selected ? '#476BC3' : '#262321';

  return (
    <svg width={width} height={height} viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2C0 0.89543 0.89543 0 2 0H10L16 6V18C16 19.1046 15.1046 20 14 20H2C0.89543 20 0 19.1046 0 18V2ZM9 2H2V18H14V7H9V2ZM4 11C4 11.5523 4.44772 12 5 12H11C11.5523 12 12 11.5523 12 11C12 10.4477 11.5523 10 11 10H5C4.44772 10 4 10.4477 4 11ZM4 15C4 14.4477 4.44772 14 5 14H8C8.55229 14 9 14.4477 9 15C9 15.5523 8.55229 16 8 16H5C4.44772 16 4 15.5523 4 15Z"
        fill={fill}
      />
    </svg>
  );
};
