import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Creators as modalActions } from '../../../../redux/reducers/modal-reducer';
import Checkbox from '../../../../components/MarkableButton/Checkbox';
import { Container, Content, Footer, Label, Options, OptionWrapper, Title } from './style';
import Button from '../../../../components/Button';

export const ModalContent = ({
  products,
  businessUnits,
  serviceOffers,
  projectTypes,
  onSubmit,
  defaultValues,
}: any) => {
  const { reset, watch, register, handleSubmit } = useForm<{
    projectTypes: any[];
    businessUnits: any[];
    products: any[];
    serviceOffers: any[];
  }>({
    defaultValues,
  });
  const { t } = useTranslation();

  const filledProjectTypes = watch('projectTypes');
  const filledBusinessUnits = watch('businessUnits');
  const filledProducts = watch('products');
  const filledServiceOffers = watch('serviceOffers');

  const dispatch = useDispatch();
  const { closeModal } = modalActions;

  const closeHandler = () => dispatch(closeModal());

  const filterHandler = (data: any) => {
    const filteredData = {
      projectTypes: data.projectTypes.filter(Boolean),
      businessUnits: data.businessUnits.filter(Boolean),
      products: data.products.filter(Boolean),
      serviceOffers: data.serviceOffers.filter(Boolean),
    };
    onSubmit(filteredData);
    closeHandler();
  };

  const onClear = () => {
    reset({
      projectTypes: [],
      businessUnits: [],
      products: [],
      serviceOffers: [],
    });
  };

  return (
    <Container>
      <Content>
        <Title>{t('modal.product')}</Title>
        <Options>
          {products.length > 0 &&
            products.map((item: any) => (
              <OptionWrapper>
                <Checkbox
                  {...register(`products`)}
                  checked={filledProducts?.includes(item.id)}
                  id={`products-${item.id}`}
                  value={item.id}
                />
                <Label>{item.name}</Label>
              </OptionWrapper>
            ))}
        </Options>
        <Title>{t('modal.project')}</Title>
        <Options>
          {projectTypes.length > 0 &&
            projectTypes.map((item: any) => (
              <OptionWrapper>
                <Checkbox
                  {...register(`projectTypes`)}
                  checked={filledProjectTypes?.includes(item.id)}
                  id={`projectTypes-${item.id}`}
                  value={item.id}
                />
                <Label>{item.name}</Label>
              </OptionWrapper>
            ))}
        </Options>
        <Title>{t('modal.business')}</Title>
        <Options>
          {businessUnits.length > 0 &&
            businessUnits.map((item: any) => (
              <OptionWrapper>
                <Checkbox
                  {...register(`businessUnits`)}
                  checked={filledBusinessUnits?.includes(item.id)}
                  id={`businessUnits-${item.id}`}
                  value={item.id}
                />
                <Label>{item.name}</Label>
              </OptionWrapper>
            ))}
        </Options>
        <Title>{t('modal.service')}</Title>
        <Options>
          {serviceOffers.length > 0 &&
            serviceOffers.map((item: any) => (
              <OptionWrapper>
                <Checkbox
                  {...register(`serviceOffers`)}
                  checked={filledServiceOffers?.includes(item.id)}
                  id={`serviceOffers-${item.id}`}
                  value={item.id}
                />
                <Label>{item.name}</Label>
              </OptionWrapper>
            ))}
        </Options>
      </Content>
      <Footer>
        <Button onClick={onClear}>{t('modal.clear')}</Button>
        <Button onClick={handleSubmit(filterHandler)} primary>
          {t('modal.apply')}
        </Button>
      </Footer>
    </Container>
  );
};
