import styled from 'styled-components';

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin: 24px 0 0 0;
  align-items: center;
  justify-content: flex-end;
`;
