import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ChoiceContainer, Container, DeleteIconWrapper, Title } from './style';
import { NodeTreeIcon } from '../../assets/svgs/NodeTreeIcon';
import TextButton from '../TextButton';
import Input from '../Input';
import { DeleteIcon } from '../../assets/svgs/DeleteIcon';
import RadioButton from '../MarkableButton/RadioButton';
import FormError from '../FormError';
import Alert from '../Toast/toast';

interface ChoiceProps {
  deleteHandler: (index: number) => void;
  id: string;
  index: number;
  accessFieldsName?: string;
  control: any;
  selectedChoice: string | undefined;
  errors: any;
  setSelectedChoice: Dispatch<SetStateAction<string | undefined>>;
}

const Choice = ({
  deleteHandler,
  id,
  index,
  errors,
  control,
  accessFieldsName,
  selectedChoice,
  setSelectedChoice,
}: ChoiceProps) => {
  const name = accessFieldsName ? `${accessFieldsName}.choices[${index}].title` : `choices[${index}].title`;
  const { t } = useTranslation();

  return (
    <>
      <ChoiceContainer>
        <RadioButton id={id} checked={id === selectedChoice} onChange={() => setSelectedChoice(id)} />
        <Controller
          name={name}
          control={control}
          rules={{
            required: t<string>('singleChoice.completeOption'),
          }}
          render={({ field }) => <Input {...field} type="text" />}
        />
        <DeleteIconWrapper onClick={() => deleteHandler(index)}>
          <DeleteIcon />
        </DeleteIconWrapper>
      </ChoiceContainer>
      <FormError errors={errors} name={name} />
    </>
  );
};

const SingleChoice = ({ control, errors, accessFieldsName, hasLabel = true }: any) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: accessFieldsName ? `${accessFieldsName}.choices` : 'choices',
  });
  const [selectedChoice, setSelectedChoice] = useState<string>();
  const { t } = useTranslation();

  useEffect(() => {
    if (fields.length === 0) {
      append({ title: '' });
    }
  }, []);

  const deleteHandler = (index: number) => {
    if (fields.length > 1) return remove(index);

    Alert.WARNING(t('questions.choiceDeleteWarn'));
  };

  return (
    <Container>
      {hasLabel && <Title>{t('singleChoice.title')}</Title>}
      {fields.map((item, index) => (
        <Choice
          key={item.id}
          id={item.id}
          index={index}
          deleteHandler={deleteHandler}
          control={control}
          accessFieldsName={accessFieldsName}
          selectedChoice={selectedChoice}
          setSelectedChoice={setSelectedChoice}
          errors={errors}
        />
      ))}
      <TextButton style={{ justifyContent: 'left' }} onClick={() => append({ title: '' })}>
        <p>{t('singleChoice.addChoice')}</p>
        <NodeTreeIcon />
      </TextButton>
    </Container>
  );
};

export default SingleChoice;
