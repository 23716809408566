import React from 'react';
import { useParams } from 'react-router-dom';
import ReplyForm from '../ReplyForm';

const FormAdvisorView = () => {
  const { id, projectId } = useParams<{ projectId: string; id: string }>();

  return <ReplyForm isAdvisorView projectId={projectId} paramsId={id} key={Math.random()} />;
};

export default FormAdvisorView;
