import styled from 'styled-components';

export const StyledSubTitleDanger = styled.div`
  background-color: #f1615d;
  align-self: center;
  width: 2px;
  height: 2px;
  border-radius: 15px;
  padding: 2px;
`;

export const StyledSubTitleWarning = styled.div`
  background-color: #577ef4;
  align-self: center;
  width: 2px;
  height: 2px;
  border-radius: 15px;
  padding: 2px;
`;

export const StyledSubTitleSuccess = styled.div`
  background-color: #99e596;
  align-self: center;
  width: 2px;
  height: 2px;
  border-radius: 15px;
  padding: 2px;
`;

export const StyledSubTitle = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 16px;
  gap: 5px;
  justify-content: space-around;

  @media (max-width: 360px) {
    flex-direction: column;
  } ;
`;

export const DangerWrapper = styled.div`
  display: flex;
  gap: 5px;
`;

export const WarningWrapper = styled.div`
  display: flex;
  gap: 5px;
`;

export const SuccessWrapper = styled.div`
  display: flex;
  gap: 5px;
`;

export const Text = styled.div`
  font-size: 12px;
  white-space: nowrap;
`;

export const Value = styled.div`
  font-style: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -1px;
  color: #000;
`;

export const SubTitleWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 5px;
`;
