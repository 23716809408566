import React, { useContext, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  BoxActions,
  FormContainer,
  FormTitle,
  InlineInputs,
  InputWrapper,
  InputWrapperMultipleForm,
} from '../../style';
import { EditIcon } from '../../../../../assets/svgs/EditIcon';
import Select from '../../../../../components/Select';
import Input from '../../../../../components/Input';
import ButtonGroup from '../../../../../components/ButtonGroup';
import { ButtonGroupItem } from '../../../../../components/ButtonGroup/style';
import { DeleteIcon } from '../../../../../assets/svgs/DeleteIcon';
import FormContext from '../../context';
import FormError from '../../../../../components/FormError';
import { FORM_TYPE_VALUES, FORM_TYPE_YES_NO, INITIAL_VALUE_FORM_DETAILS } from '../../../constants';
import { FormViewContext } from '../../../FormView/context';
import { TitleWrapper } from './style';

interface DetailsProps {
  setSaved: (saved: boolean) => void;
  formHasSubType: boolean;
}

const DetailsForm = ({ setSaved, formHasSubType }: DetailsProps) => {
  const { control, deleteSubmit, errors, trigger, watch, products, businessUnits, serviceOffers, projectTypes } =
    useContext(FormContext);

  const { deleteDraftHandler } = useContext(FormContext);
  const { t } = useTranslation();

  const watchProjectType = watch?.('projectType');
  const watchBusinessUnit = watch?.('businessUnit');
  const watchServiceOffer = watch('serviceOffer');
  const watchProductType = watch('productType');
  const watchMultipleForm = watch?.('multipleForm');

  const fieldNames = Object.keys(INITIAL_VALUE_FORM_DETAILS());

  const onSubmit = async () => {
    const isValidEntries = await trigger(fieldNames);
    if (!isValidEntries) return;

    setSaved(true);
  };

  const selectedYesNoMultipleForm = FORM_TYPE_YES_NO().find((element: any) => {
    if (watchMultipleForm === undefined) {
      return element?.value;
    }

    if (watchMultipleForm?.value === undefined) {
      return element?.value === watchMultipleForm;
    }

    if (element?.value === FORM_TYPE_YES_NO().values) {
      return element?.value === watchMultipleForm.value;
    }
    return element?.value === watchMultipleForm.value;
  });

  return (
    <Box>
      <FormContainer>
        <InputWrapper>
          <TitleWrapper>
            <Controller
              name="title"
              control={control}
              rules={{
                required: t<string>('form.requiredForm'),
              }}
              render={({ field }) => <Input {...field} />}
            />
          </TitleWrapper>
          <FormError errors={errors} name="title" />
        </InputWrapper>
        <InlineInputs>
          <InputWrapper>
            <Controller
              name="productType"
              shouldUnregister={formHasSubType}
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  disabled={formHasSubType}
                  value={products.find((element: any) => element?.value === watchProductType)}
                  label={t('form.labelProduct')}
                  options={products}
                />
              )}
            />
          </InputWrapper>
          <InputWrapper>
            <Controller
              name="projectType"
              shouldUnregister={formHasSubType}
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  disabled={formHasSubType}
                  value={projectTypes.find((element: any) => element?.value === watchProjectType)}
                  label={t('form.labelProject')}
                  options={projectTypes}
                />
              )}
            />
          </InputWrapper>
          <InputWrapper>
            <Controller
              name="businessUnit"
              shouldUnregister={formHasSubType}
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  disabled={formHasSubType}
                  value={businessUnits.find((element: any) => element?.value === watchBusinessUnit)}
                  label={t('form.labelBusiness')}
                  options={businessUnits}
                />
              )}
            />
          </InputWrapper>
          <InputWrapper>
            <Controller
              name="serviceOffer"
              shouldUnregister={formHasSubType}
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  disabled={formHasSubType}
                  value={serviceOffers.find((element: any) => element?.value === watchServiceOffer)}
                  label={t('form.labelService')}
                  options={serviceOffers}
                />
              )}
            />
          </InputWrapper>
          <InputWrapper>
            <Controller
              name="formType"
              control={control}
              rules={{
                required: formHasSubType ? false : t<string>('form.requiredTypeForm'),
              }}
              render={({ field }) => (
                <Select
                  {...field}
                  disabled={formHasSubType}
                  options={FORM_TYPE_VALUES()}
                  label={t('form.selectType')}
                />
              )}
            />
            <FormError errors={errors} name="formType" />
          </InputWrapper>
        </InlineInputs>
        <InlineInputs>
          <InputWrapper>
            <Controller
              name="formId"
              control={control}
              rules={{
                required: t<string>('form.requiredIdForm'),
              }}
              render={({ field }) => <Input {...field} placeholder={t('common.type')} label={t('form.idForm')} />}
            />
            <FormError errors={errors} name="formId" />
          </InputWrapper>
          <InputWrapper grow={4}>
            <Controller
              name="description"
              control={control}
              render={({ field }) => <Input {...field} placeholder={t('common.type')} label={t('form.description')} />}
            />
          </InputWrapper>
        </InlineInputs>
        <InlineInputs>
          <InputWrapperMultipleForm>
            <Controller
              name="multipleForm"
              control={control}
              rules={{
                required: formHasSubType ? false : t<string>('common.required'),
              }}
              render={({ field }) => (
                <Select
                  {...field}
                  disabled={formHasSubType}
                  options={FORM_TYPE_YES_NO()}
                  value={selectedYesNoMultipleForm}
                  label={t('form.multipleForm')}
                />
              )}
            />
            <FormError errors={errors} name="multipleForm" />
          </InputWrapperMultipleForm>
          <BoxActions>
            <ButtonGroup>
              <ButtonGroupItem onClick={onSubmit}>
                <p>{t('common.save')}</p>
                <EditIcon />
              </ButtonGroupItem>
              {deleteDraftHandler ? (
                <ButtonGroupItem onClick={deleteDraftHandler}>
                  <p>{t('form.eraseDraft')}</p>
                  <DeleteIcon />
                </ButtonGroupItem>
              ) : (
                <ButtonGroupItem onClick={deleteSubmit}>
                  <p>{t('common.delete')}</p>
                  <DeleteIcon />
                </ButtonGroupItem>
              )}
            </ButtonGroup>
          </BoxActions>
        </InlineInputs>
      </FormContainer>
    </Box>
  );
};

const DetailsPreview = ({ setSaved, formHasSubType }: DetailsProps) => {
  const { getValues } = useContext(FormContext);
  const { title } = getValues();
  const { deleteSubmit, deleteDraftHandler } = useContext(FormContext);
  const { viewMode } = useContext(FormViewContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const handleEdit = () => {
    if (viewMode) {
      navigate(location.pathname.replace('/forms/view', '/forms/edit'));
      return;
    }
    setSaved(false);
  };
  return (
    <Box
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <FormTitle>{title}</FormTitle>
      <ButtonGroup>
        <ButtonGroupItem onClick={handleEdit}>
          <p>{t('common.edit')}</p>
          <EditIcon />
        </ButtonGroupItem>
        {!formHasSubType &&
          (deleteDraftHandler ? (
            <ButtonGroupItem onClick={deleteDraftHandler}>
              <p>{t('form.eraseDraft')}</p>
              <DeleteIcon />
            </ButtonGroupItem>
          ) : (
            <ButtonGroupItem onClick={deleteSubmit}>
              <p>{t('common.delete')}</p>
              <DeleteIcon />
            </ButtonGroupItem>
          ))}
      </ButtonGroup>
    </Box>
  );
};

interface MainDetailsProps {
  formHasSubType: boolean;
}

const Details = ({ formHasSubType }: MainDetailsProps) => {
  const [saved, setSaved] = useState<boolean>(false);
  const { viewMode } = useContext(FormViewContext);

  return viewMode || saved ? (
    <DetailsPreview setSaved={setSaved} formHasSubType={formHasSubType} />
  ) : (
    <DetailsForm {...{ setSaved, formHasSubType }} />
  );
};

export default Details;
