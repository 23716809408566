import React, { useMemo, useState } from 'react';
import PreviewField from '../../../../../components/Form/PreviewField';
import Divider from '../../../components/Divider';
import { Form, FormTitle, QuestionsContainer, StyledFormHeader } from './style';

interface FormHeaderProps {
  title: string;
}

const FormHeader = ({ title }: FormHeaderProps) => (
  <StyledFormHeader>
    <FormTitle>{title}</FormTitle>
  </StyledFormHeader>
);

const Question = ({ question }: any) => {
  const { type, ...otherProps } = question;
  const fieldType = type?.value;
  const customFieldTypeProps = type?.props;

  return <PreviewField fieldType={fieldType} customFieldTypeProps={customFieldTypeProps} {...otherProps} />;
};

const HistoricFormFields = ({ formData }: any) => {
  const [search, setSearch] = useState('');
  const questionAmount = formData?.questions?.length;

  const filteredQuestions = useMemo(() => {
    if (!formData?.questions) return [];

    const lowerCaseFilter = search.toLowerCase();
    return formData.questions.filter(({ title }: { title: string }) => title.toLowerCase().includes(lowerCaseFilter));
  }, [search, formData]);

  return (
    <Form>
      <FormHeader title={formData?.title} />
      <Divider search={search} setSearch={setSearch} questionAmount={questionAmount} />
      <QuestionsContainer>
        {filteredQuestions && filteredQuestions.map((question: any) => <Question question={question} />)}
      </QuestionsContainer>
    </Form>
  );
};

export default HistoricFormFields;
