const getQuestionsTree = (questions: any) => {
  if (!questions) {
    return [];
  }

  const roots: any[] = [];
  const childrenByParentId = {};

  questions.forEach((item: any) => {
    if (!item.childOf) {
      roots.push(item);
      return;
    }
    // @ts-ignore
    if (!childrenByParentId[item.childOf]) {
      // @ts-ignore
      childrenByParentId[item.childOf] = [];
    }
    // @ts-ignore
    childrenByParentId[item.childOf].push(item);
  });

  function buildNodes(nodes: any) {
    if (!nodes) {
      return null;
    }
    return nodes.map((node: any) => ({
      ...node,
      // @ts-ignore
      questions: buildNodes(childrenByParentId[node.wId]),
    }));
  }

  return buildNodes(roots);
};

const formatQuestionFields = (questions: any, variables: any) =>
  questions.map((question: any) => ({
    ...question,
    title: variables
      ? question.title.replace(/{(.*?)}/g, (variableWithBraces: string, variableWithoutBraces: string) => {
          const variableRes =
            variables[variableWithoutBraces]?.questionAnswer ??
            (question.branchIndex || question.branchIndex === 0
              ? variables[variableWithoutBraces]?.branchesAnswer?.[question.branchIndex]
              : '');
          if (!variableRes) return `[${variableWithoutBraces}]`;
          return variableRes;
        })
      : question.title,
    files: question.files ? question.files.map((file: any) => ({ link: file })) : [],
    ...(question.type === 'CUSTOM' && {
      type: question.customTypeSnapshot.type,
      choices: question.customTypeSnapshot.choices,
      mask: question.customTypeSnapshot.mask,
      validationCode: question.customTypeSnapshot.validationCode,
      customTypeName: question.customTypeSnapshot.name,
      validationMessage: question.customTypeSnapshot.validationMessage,
    }),
  }));

const formatQuestions = (questions: any, variables: any) =>
  getQuestionsTree(formatQuestionFields(questions, variables));

const formatReceivedFormData = (questions: any[], variables: any) => formatQuestions(questions, variables);

export default formatReceivedFormData;
