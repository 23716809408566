import styled from 'styled-components';
import { Ellipse16 } from '../../assets/svgs/Ellipse16';
import { Ellipse17 } from '../../assets/svgs/Ellipse17';
import { Ellipse18 } from '../../assets/svgs/Ellipse18';
import { Ellipse19 } from '../../assets/svgs/Ellipse19';
import { Ellipse20 } from '../../assets/svgs/Ellipse20';
import { Ellipse21 } from '../../assets/svgs/Ellipse21';
import { IconArrow } from '../../assets/svgs/IconArrow';
import { ImgPatternLeft } from '../../assets/svgs/ImgPatternLeft';
import { ImgPatternRight } from '../../assets/svgs/ImgPatternRight';
import { Shape } from '../../assets/svgs/Shape';
import { ShapeCopyRight } from '../../assets/svgs/ShapeCopyRight';
import Grid from '../../components/Grid';

import { BREAKPOINTS } from '../../constants/screen';

export const Body = styled(Grid)`
  background: #0f1a50;
  height: 100vh;
  width: 100vw;
  @media (max-width: ${BREAKPOINTS.md}px) {
    background: #fff;
  }
`;

export const Privacy = styled(Grid)`
  position: static;
  width: 4.44%;
  left: 1.18%;
  top: 0;
  font-family: 'Circular20';
  font-style: normal;
  font-weight: 450;
  font-size: 12px;
  line-height: 0.97%;
  text-align: center;
  text-decoration-line: underline;
  color: #a8c1ff;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 4px 4px;
  cursor: pointer;
  @media (max-width: ${BREAKPOINTS.md}px) {
    color: #979797;
  }
`;

export const Container = styled(Grid)`
  width: 69%;
  max-width: 995px;
  height: 511px;
  background: white;
  border-radius: 12px;
  display: flex;
  align-items: stretch;
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: stretch;
    flex-wrap: nowrap;
  }
`;

export const OrangSide = styled(Grid)`
  box-sizing: border-box;
  background: linear-gradient(322.28deg, #f1615d 3.42%, #ffb1a3 65.03%);
  overflow: hidden;
  align-items: stretch;
  border-radius: 12px 0 0 12px;
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 100%;
    border-radius: 0;
  }
`;

export const OrangeSideText = styled.div`
  padding: 14.77%;
  display: flex;
  flex-direction: column;
`;

export const Form = styled(Grid).attrs({ as: 'form' })`
  height: 100%;
  padding: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: ${BREAKPOINTS.md}px) {
    justify-content: flex-start;
  }
`;

export const Welcome = styled(Grid)`
  width: 68.67%;
  height: auto;
  left: 320px;
  top: 516px;
  font-family: 'Circular20';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  color: #ffffff;
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 13px;
  }
`;

export const Text = styled(Grid)`
  font-family: 'Circular20';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 4px 0;
`;

export const FooterInfos = styled(Grid)`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  bottom: 10px;
  @media (max-width: ${BREAKPOINTS.md}px) {
    flex-direction: column;
    position: relative;
  }
`;

export const StyledPrivacy = styled.div`
  display: flex;
  @media (max-width: ${BREAKPOINTS.md}px) {
    margin-bottom: 20px;
  }
`;

export const CopyRight = styled(Grid)`
  width: inherit;
  left: 0;
  top: 0;
  font-family: 'Circular20';
  font-style: normal;
  font-weight: 450;
  font-size: 12px;
  line-height: 1.11%;
  text-align: center;
  color: #476bc3;
  order: 0;
  flex-grow: 0;
  margin: 0 4px;
  @media (max-width: ${BREAKPOINTS.md}px) {
    color: #979797;
    margin: 15px;
  }
`;

export const StyledImgPatternLeft = styled(ImgPatternLeft)`
  position: absolute;
  width: auto;
  height: 11.46%;
  left: 0;
  top: 0;
  mix-blend-mode: luminosity;
  opacity: 1;
  @media (max-width: ${BREAKPOINTS.md}px) {
    opacity: 0;
  }
`;

export const StyledEllipse16 = styled(Ellipse16)`
  position: absolute;
  mix-blend-mode: soft-light;
  width: 36.29%;
  height: auto;
  bottom: 0;
  left: 0;
`;

export const StyledImgPatternRight = styled(ImgPatternRight)`
  position: absolute;
  bottom: 0;
  right: 0;
  width: auto;
  height: 5.9%;
  mix-blend-mode: luminosity;
  opacity: 1;
  @media (max-width: ${BREAKPOINTS.md}px) {
    opacity: 0;
    display: none;
  }
`;

export const StyledShapeCopyRight = styled(ShapeCopyRight)`
  left: 12.5%;
  right: 12.5%;
  top: 12.5%;
  bottom: 12.5%;
  @media (max-width: ${BREAKPOINTS.md}px) {
    color: #979797;
  }
`;

export const StyledShape = styled(Shape)`
  margin-bottom: 10px;
  width: 88px;
  height: 40px;
  left: 452px;
  top: 320px;
`;

export const StyledIconArrow = styled(IconArrow)`
  margin: 0 0 -3px 10px;
  left: 16.67%;
  right: 18.41%;
  top: 16.67%;
  bottom: 20.06%;
`;

export const StyledEllipse19 = styled(Ellipse19)`
  position: absolute;
  mix-blend-mode: soft-light;
  width: 48.52%;
  height: auto;
  bottom: 0;
  left: 0;
`;

export const StyledEllipse21 = styled(Ellipse21)`
  position: absolute;
  mix-blend-mode: soft-light;
  width: 19.83%;
  right: 1.27%;
  top: 50%;
  transform: translateY(-50%);
`;

export const StyledEllipse18 = styled(Ellipse18)`
  position: absolute;
  mix-blend-mode: soft-light;
  width: 21.52%;
  right: 3.16%;
  top: 50%;
  transform: translateY(-50%);
`;

export const StyledEllipse20 = styled(Ellipse20)`
  position: absolute;
  mix-blend-mode: soft-light;
  width: 30.17%;
  height: auto;
  top: 0;
  left: 17.09%;
`;

export const StyledEllipse17 = styled(Ellipse17)`
  position: absolute;
  mix-blend-mode: soft-light;
  width: 32.49%;
  height: auto;
  top: 0;
  left: 12.24%;
`;

export const StyledOpenModal = styled.div``;
