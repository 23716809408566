import React from 'react';

interface ArchiveIconProps {
  width?: number;
  height?: number;
}

const ArchiveIcon = ({ width = 16, height = 18 }: ArchiveIconProps) => (
  <svg width={width} height={height} viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.50002 0.669157V0.666656H14.665C15.125 0.666656 15.5 1.04582 15.5 1.49332V16.5067C15.4998 16.726 15.4125 16.9362 15.2574 17.0912C15.1022 17.2463 14.8918 17.3333 14.6725 17.3333H1.32752C1.21808 17.3326 1.10987 17.3102 1.00906 17.2677C0.908246 17.2251 0.816808 17.163 0.739967 17.0851C0.663126 17.0072 0.602387 16.9149 0.561216 16.8135C0.520046 16.7121 0.499252 16.6036 0.500021 16.4942V5.66666L5.50002 0.669157ZM2.85835 5.66666H5.50002V3.02499L2.85835 5.66666ZM7.16669 2.33332V6.49999C7.16669 6.721 7.07889 6.93296 6.92261 7.08925C6.76633 7.24553 6.55437 7.33332 6.33335 7.33332H2.16669V15.6667H13.8334V2.33332H7.16669Z"
      fill="#262321"
    />
  </svg>
);

export default ArchiveIcon;
