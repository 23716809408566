import { cloneElement, createElement } from 'react';
import MultipleChoice from '../../../../components/MultipleChoice';
import SingleChoice from '../../../../components/SingleChoice';
import { CustomQuestionType } from '../../constants';
import Validation from './components/Validation';

interface CreatableFieldProps {
  fieldType: CustomQuestionType | null;
  defaultCode?: string;
}

const elements = new Map();
elements.set(CustomQuestionType.MULTIPLE_CHOICE, createElement(MultipleChoice, { hasLabel: false }));
elements.set(CustomQuestionType.SINGLE_CHOICE, createElement(SingleChoice, { hasLabel: false }));
elements.set(CustomQuestionType.VALIDATION, createElement(Validation));

const CreatableField = ({ fieldType, ...props }: CreatableFieldProps) => {
  const element = elements.get(fieldType);
  return cloneElement(element, props);
};

export default CreatableField;
