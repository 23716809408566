import React from 'react';

interface DeleteIconProps {
  width?: number;
  height?: number;
  fill?: string;
}

export const DeleteIcon = ({ width = 15, height = 17, fill = '#262321' }: DeleteIconProps) => {
  const viewBox = `0 0 ${width} ${height}`;

  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.72454 1.59267C4.98022 1.59267 5.1875 1.3854 5.1875 1.12971C5.1875 0.874024 5.39478 0.666748 5.65046 0.666748H10.3495C10.6052 0.666748 10.8125 0.874024 10.8125 1.12971C10.8125 1.3854 11.0198 1.59267 11.2755 1.59267H14.5C15.0523 1.59267 15.5 2.04039 15.5 2.59267V3.44453H14.5625V15.4816C14.5625 16.5043 13.723 17.3334 12.6875 17.3334H3.3125C2.27697 17.3334 1.4375 16.5043 1.4375 15.4816V3.44453H0.5V2.59267C0.5 2.04039 0.947715 1.59267 1.5 1.59267H4.72454ZM12.6875 14.9816V3.44453H3.3125V14.9816C3.3125 15.2577 3.53636 15.4816 3.8125 15.4816H12.1875C12.4636 15.4816 12.6875 15.2577 12.6875 14.9816ZM5.1875 12.6922C5.1875 13.21 5.60723 13.6297 6.125 13.6297C6.64277 13.6297 7.0625 13.21 7.0625 12.6922V6.23388C7.0625 5.71611 6.64277 5.29638 6.125 5.29638C5.60723 5.29638 5.1875 5.71611 5.1875 6.23388V12.6922ZM8.9375 6.23388C8.9375 5.71611 9.35723 5.29638 9.875 5.29638C10.3928 5.29638 10.8125 5.71611 10.8125 6.23388V12.6922C10.8125 13.21 10.3928 13.6297 9.875 13.6297C9.35723 13.6297 8.9375 13.21 8.9375 12.6922V6.23388Z"
        fill={fill}
      />
    </svg>
  );
};
