import React from 'react';
import { toast, ToastPromiseParams } from 'react-toastify';
import ToastErrorIcon from '../../assets/svgs/ToastErrorIcon';
import ToastWarningIcon from '../../assets/svgs/ToastWarningIcon';
import ToastInfoIcon from '../../assets/svgs/ToastInfoIcon';
import ToastSuccessIcon from '../../assets/svgs/ToastSuccessIcon';
import { ToasterContentWrapper } from './style';

interface MessageComponentProps {
  message: string;
}

export const ErrorToast = ({ message }: MessageComponentProps) => (
  <ToasterContentWrapper>
    <ToastErrorIcon />
    {message}
  </ToasterContentWrapper>
);

export const WarningToast = ({ message }: MessageComponentProps) => (
  <ToasterContentWrapper>
    <ToastWarningIcon />
    {message}
  </ToasterContentWrapper>
);

export const InfoToast = ({ message }: MessageComponentProps) => (
  <ToasterContentWrapper>
    <ToastInfoIcon />
    {message}
  </ToasterContentWrapper>
);

export const SuccessToast = ({ message }: MessageComponentProps) => (
  <ToasterContentWrapper>
    <ToastSuccessIcon />
    {message}
  </ToasterContentWrapper>
);

interface AlertFunctions {
  SUCCESS: (message: string) => void;
  ERROR: (message: string) => void;
  INFO: (message: string) => void;
  WARNING: (message: string) => void;
  PROMISE: (promise: Promise<any>, options: ToastPromiseParams) => void;
}

const Alert: AlertFunctions = {
  SUCCESS: (message) => toast(<SuccessToast message={message} />),
  ERROR: (message) => toast(<ErrorToast message={message} />),
  INFO: (message) => toast(<InfoToast message={message} />),
  WARNING: (message) => toast(<WarningToast message={message} />),
  PROMISE: (promise, options) => toast.promise(promise, options),
};

export default Alert;
