import styled from 'styled-components';
import Grid from '../../../components/Grid';
import { BREAKPOINTS } from '../../../constants/screen';

export const Footer = styled.div`
  background: #fff;
  margin-top: 16px;
  width: 100%;

  display: flex;
  justify-content: end;

  @media (max-width: ${BREAKPOINTS.md}px) {
    height: 100px;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
  }
`;

export const Container = styled(Grid)`
  width: 100%;
  max-width: 1200px;
  height: 530px;
  background: #fff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-style: normal;
    font-weight: 450;
    font-size: 32px;
    line-height: 38px;
    flex: none;
    flex-grow: 0;
    margin: 24px 0;
    flex-direction: column;
    align-items: stretch;
    flex-wrap: nowrap;

    .default_modal_header {
      background-color: red !important;
    }
  }
`;

export const Content = styled(Grid)`
  height: 100%;
  font-style: italic;
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  color: #262321;
  overflow-y: auto;
  padding: 8px;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 30px;
  }

  ::-webkit-scrollbar-thumb {
    background: #e3dfda;
    border-radius: 30px;
  }

  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 13px;
    line-height: 16px;
  }
`;

export const ButtonWrapper = styled.div`
  height: 40px;
  border-radius: 8px;

  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 100%;
    padding: 0 24px;

    button {
      width: 100%;
    }
  }
`;

export const Text = styled(Grid)`
  position: absolute;
  height: 16px;
  left: 28px;
  right: 28px;
  top: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #fff;
  @media (max-width: ${BREAKPOINTS.md}px) {
    position: static;
    left: 106px;
    right: 106px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0 10px;
  }
`;
