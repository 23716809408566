import React, { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from '../../assets/svgs/CloseIcon';
import { SearchIcon } from '../../assets/svgs/SearchIcon';
import { IconWrapper, SearchButton, SearchInput, SearchWrapper } from './style';

interface SearchProps {
  value: string;
  setValue: (value: string) => void;
}

const SearchBar = ({ value, setValue }: SearchProps) => {
  const [selected, setSelected] = useState(false);
  const inputEl = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const onChange: ChangeEventHandler<HTMLInputElement> = (event) => setValue(event.target.value);

  const openSearchBox = () => {
    setSelected(true);
  };

  useEffect(() => {
    if (selected) {
      inputEl?.current?.focus();
    }
  }, [selected]);

  const closeSearchBox = () => {
    setSelected(false);
    setValue('');
  };

  return (
    <SearchWrapper>
      {selected ? (
        <>
          <SearchInput
            placeholder={t('userShortProfile.search')}
            value={value}
            onChange={onChange}
            selected={selected}
            ref={inputEl}
          />
          <IconWrapper selected={selected} onClick={closeSearchBox}>
            <CloseIcon />
          </IconWrapper>
        </>
      ) : (
        <SearchButton onClick={openSearchBox}>
          <span>{t('searchBar.search')}</span>
          <SearchIcon className="search_icon" />
        </SearchButton>
      )}
    </SearchWrapper>
  );
};

export default SearchBar;
