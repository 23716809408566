import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Creators as modalActions } from '../../../../redux/reducers/modal-reducer';
import {
  Column,
  Container,
  IconWrapper,
  InputWrapper,
  ModalFooter,
  Row,
  Rules,
  RulesContainer,
  Title,
  Wrapper,
} from './style';
import Select from '../../../../components/Select';
import { DeleteIcon } from '../../../../assets/svgs/DeleteIcon';
import Button from '../../../../components/Button';
import ConditionSwitch from './components/ConditionSwitch';
import { ACTIONS, QuestionConditions, SELECT_CONDITIONS } from './constants';
import TextButton from '../../../../components/TextButton';
import { CreateIcon } from '../../../../assets/svgs/CreateIcon';
import Input from '../../../../components/Input';
import { INITIAL_CONDITION_VALUE } from '../../constants';
import { RootState } from '../../../../redux/store';
import { FieldType } from '../../types/FieldType';
import i18n from '../../../../config/i18n/config';

const conditionsWithoutAssociatedValue = [QuestionConditions.ANSWERED, QuestionConditions.NOT_ANSWERED];

const Condition = ({
  forms,
  index,
  control,
  deleteHandler,
  fieldsSize,
  watch,
  selectableConditions,
  item,
  setValue,
}: any) => {
  const selectedFormHolder = watch(`conditions[${index}].formWId`);
  const selectedForm = selectedFormHolder?.value;
  const { t } = useTranslation();

  const { questionsIndexedByForm } = useSelector(
    (state: RootState) => state?.data?.questionsFromOtherFormsQuestionsToUseOnRules
  );

  const [conditions, setConditions] = useState<any>(
    selectedForm === 'CURRENT_FORM' ? selectableConditions : questionsIndexedByForm[item.formWId?.value]
  );

  const hasConditionSwitch = index + 1 === fieldsSize;
  const watchCondition = watch(`conditions[${index}].condition`);
  const selectedCondition = watchCondition?.value || null;
  const showValueInput = selectedCondition && !conditionsWithoutAssociatedValue.includes(selectedCondition);

  const fetchFormQuestion = () => {
    if (!selectedForm) return;
    if (selectedForm === 'CURRENT_FORM') {
      return setConditions(selectableConditions);
    }

    if (!questionsIndexedByForm?.[selectedForm]) return;
    setConditions(questionsIndexedByForm[selectedForm]);
  };
  const oldSelectedForm = useRef(item.formWId?.value);
  useEffect(() => {
    if (oldSelectedForm.current === selectedForm) {
      return;
    }
    oldSelectedForm.current = selectedForm;
    fetchFormQuestion();
    setValue(`conditions[${index}].id`, null);
    setValue(`conditions[${index}].condition`, null);
    setValue(`conditions[${index}].value`, '');
  }, [selectedForm]);

  useEffect(() => {
    if (conditionsWithoutAssociatedValue.includes(selectedCondition)) setValue(`conditions[${index}].value`, '');
  }, [selectedCondition]);

  return (
    <>
      <Title>{t('form.conditions')}</Title>
      <Wrapper>
        <Row>
          <InputWrapper>
            <Controller
              name={`conditions[${index}].formWId`}
              rules={{
                required: true,
              }}
              control={control}
              render={({ field }) => (
                <Select {...field} options={forms} placeholder={t('common.selectForm')} menuPosition="fixed" />
              )}
            />
          </InputWrapper>
          <InputWrapper>
            <Controller
              name={`conditions[${index}].id`}
              rules={{
                required: true,
              }}
              control={control}
              render={({ field }) => (
                <Select {...field} isDisabled={!selectedForm} options={conditions} menuPosition="fixed" />
              )}
            />
          </InputWrapper>
          <InputWrapper>
            <Controller
              name={`conditions[${index}].condition`}
              rules={{
                required: true,
              }}
              control={control}
              render={({ field }) => (
                <Select {...field} isDisabled={!selectedForm} options={SELECT_CONDITIONS()} menuPosition="fixed" />
              )}
            />
          </InputWrapper>
          <InputWrapper>
            <Controller
              rules={{
                required: !conditionsWithoutAssociatedValue.includes(selectedCondition),
              }}
              name={`conditions[${index}].value`}
              control={control}
              render={({ field }) => <Input {...field} disabled={!showValueInput} placeholder={t('common.type')} />}
            />
          </InputWrapper>
        </Row>
        <IconWrapper onClick={() => deleteHandler(index)}>
          <DeleteIcon />
        </IconWrapper>
      </Wrapper>
      {!hasConditionSwitch && <ConditionSwitch name={`booleanConditions[${index}]`} control={control} watch={watch} />}
    </>
  );
};
const mapDefaultValues = ({
  enableMapToDefaultValues,
  defaultValues,
  reverseIndex,
  questionsIndexedByForm,
  selectableConditions,
}: {
  defaultValues: any;
  reverseIndex: { [index: string]: { value: string; label: string } };
  questionsIndexedByForm: any;
  selectableConditions: any;
  enableMapToDefaultValues: boolean;
}) => {
  if (enableMapToDefaultValues) {
    if (
      defaultValues.conditions?.length === 1 &&
      defaultValues.conditions[0].id === '' &&
      defaultValues.conditions[0].condition === '' &&
      defaultValues.conditions[0].value === ''
    )
      return defaultValues;
    return {
      ...defaultValues,
      conditions: defaultValues.conditions.map((dv: any) => {
        const formByReverseIndex: any = reverseIndex[dv.id?.value ?? dv.id];

        const questions = !formByReverseIndex ? selectableConditions : questionsIndexedByForm[formByReverseIndex.value];

        const question = questions.find((quest: any) => (quest.wId ?? quest.value) === (dv.id?.value ?? dv.id));

        const id = {
          label: question.label ?? question.title,
          value: question.value ?? question.wId,
        };

        const condition = SELECT_CONDITIONS().find((cond) => cond?.value === (dv?.condition?.value ?? dv.condition));

        return {
          ...dv,
          formWId: formByReverseIndex ?? { label: i18n.t('forms.currentForm'), value: 'CURRENT_FORM' },
          id,
          condition,
        };
      }),
    };
  }

  return defaultValues;
};

const blockedQuestionsForRules = [FieldType.DOWNLOAD, FieldType.PANEL];

const RulesModal = ({ enableMapToDefaultValues, handler, defaultValues, questionId, flattedQuestions }: any) => {
  const selectableConditions = useMemo(
    () =>
      flattedQuestions
        .filter((item: any) => item.title)
        .filter((item: any) => item.wId !== questionId)
        .filter((item: any) => !blockedQuestionsForRules.includes(item.type?.value))
        .map((item: any) => ({ value: item.wId, label: item.title })),
    [flattedQuestions]
  );
  const dispatch = useDispatch();
  const { closeModal } = modalActions;
  const { reverseIndex, questionsIndexedByForm } = useSelector(
    (state: RootState) => state?.data?.questionsFromOtherFormsQuestionsToUseOnRules
  );
  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: mapDefaultValues({
      enableMapToDefaultValues,
      defaultValues,
      reverseIndex,
      questionsIndexedByForm,
      selectableConditions,
    }),
  });
  const forms = useSelector((state: RootState) => state?.data?.otherFormsQuestionsToUseOnRules);
  const {
    fields: conditionFields,
    append: appendCondition,
    remove: removeCondition,
  } = useFieldArray({
    control,
    name: 'conditions',
    keyName: 'conditionId',
  });

  const onSubmit = (data: any) => {
    if (data.conditions.length < 1) return;
    handler(data);
    dispatch(closeModal());
  };

  const clearRules = () => {
    handler(null);
    dispatch(closeModal());
  };

  const deleteHandler = (index: number) => {
    removeCondition(index);
    if (index === 0) {
      setValue(`conditions[${index}].formWId`, '');
      setValue(`conditions[${index}].id`, '');
      setValue(`conditions[${index}].condition`, '');
    }
  };

  const { t } = useTranslation();

  return (
    <Container>
      <RulesContainer>
        <Column>
          <Title>{t('table.actions')}</Title>
          <InputWrapper>
            <Controller
              name="action"
              control={control}
              render={({ field }) => <Select {...field} options={ACTIONS()} menuPosition="fixed" />}
            />
          </InputWrapper>
        </Column>
        <Rules>
          {conditionFields.map((item, index) => (
            <div key={`Condition${item.conditionId}`}>
              <Condition
                watch={watch}
                fieldsSize={conditionFields.length}
                deleteHandler={deleteHandler}
                item={item}
                index={index}
                control={control}
                setValue={setValue}
                selectableConditions={selectableConditions}
                forms={forms}
              />
            </div>
          ))}
          <TextButton onClick={() => appendCondition(INITIAL_CONDITION_VALUE)} style={{ alignSelf: 'start' }}>
            <p>{t('form.create')}</p>
            <CreateIcon />
          </TextButton>
        </Rules>
      </RulesContainer>
      <ModalFooter>
        <Button onClick={clearRules}>{t('form.clearRules')}</Button>
        <Button type="submit" onClick={handleSubmit(onSubmit)} primary>
          {t('form.save')}
        </Button>
      </ModalFooter>
    </Container>
  );
};

export default RulesModal;
