import React from 'react';

const FormContext = React.createContext<{
  products?: any;
  businessUnits?: any;
  serviceOffers?: any;
  projectTypes?: any;
  customFieldTypes?: any;
  fields?: any;
  append?: any;
  remove?: any;
  move?: any;
  control?: any;
  getValues?: any;
  getFieldState?: any;
  errors?: any;
  trigger?: any;
  update?: any;
  isValid?: any;
  isDirty?: any;
  watch?: any;
  register?: any;
  setValue?: any;
  deleteSubmit?: any;
  deleteDraftHandler?: any;
  enableMapToDefaultValues?: boolean;
  flattedQuestions?: any;
  setError?: any;
}>({});

export default FormContext;
