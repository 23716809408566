import { cloneElement, createElement } from 'react';
import { FieldType } from '../../../../pages/Form/types/FieldType';
import Input from './components/Input';
import CheckBox from './components/CheckBox';
import Radio from './components/Radio';
import Download from './components/Download';
import Upload from './components/Upload';
import Panel from './components/Panel';

const elements = new Map();
elements.set(FieldType.TEXT, createElement(Input));
elements.set(FieldType.EMAIL, createElement(Input));
elements.set(FieldType.USER_NAME, createElement(Input));
elements.set(FieldType.USER_EMAIL, createElement(Input));
elements.set(FieldType.MULTIPLE_CHOICE, createElement(CheckBox));
elements.set(FieldType.SINGLE_CHOICE, createElement(Radio));
elements.set(FieldType.PANEL, createElement(Panel));
elements.set(FieldType.LONG_TEXT, createElement(Input));
elements.set(FieldType.DATE, createElement(Input));
elements.set(FieldType.NUMBER, createElement(Input));
elements.set(FieldType.PHONE, createElement(Input));
elements.set(FieldType.VALIDATION, createElement(Input));
elements.set(FieldType.UPLOAD, createElement(Upload));
elements.set(FieldType.DOWNLOAD, createElement(Download));
elements.set(FieldType.SELECT, createElement(Radio));

interface QuestionPdfProps {
  fieldType: FieldType | null;
  title: string;
  customTypeSnapshot: any;
}

const QuestionPdf = ({ fieldType, customTypeSnapshot, ...props }: QuestionPdfProps) => {
  if (fieldType === FieldType.CUSTOM) {
    const { type: customType } = customTypeSnapshot;
    const element = elements.get(customType);
    return cloneElement(element, props);
  }

  const element = elements.get(fieldType);
  return cloneElement(element, props);
};

export default QuestionPdf;
