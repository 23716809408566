import React from 'react';
import { Provider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { store } from './redux/store';
import { AppRouter } from './routes';
import Toast from './components/Toast';
import Modal from './components/Modal';

// import styled from 'styled-components';

// const ChatWrapper = styled.div<{ stage: number }>`
//   display: ${({ stage }) => (stage > 2 ? 'auto' : 'none')};
//   pointer-events: ${({ stage }) => (stage > 2 ? 'all' : 'none')};
// `;
// esmDefine(['https://contactcenter.eu.adp.com/web-component/chat-widget/1.17.0/src/components/chat-component.js']);

// global.Buffer = global.Buffer || require('buffer').Buffer;

const App = () => (
  <div className="App">
    <Provider store={store}>
      <Modal />
      <Toast />
      <AppRouter />
    </Provider>
  </div>
);

export default App;
