import styled, { css } from 'styled-components';

export const TitleWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

interface IconWrapperProps {
  open: boolean;
}

export const IconWrapper = styled.div<IconWrapperProps>`
  ${({ open }) =>
    open &&
    css`
      display: none;
    `}
`;
