import styled from 'styled-components';
import { scrollStyle } from '../../../../styles/styles';
import { MAX_WIDTHS } from '../../../../constants/screen';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: calc(70vh - 56px);
  width: 70vw;
  margin-top: 40px;

  form {
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const Row = styled.div`
  flex-wrap: wrap;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  & > div {
    width: 100%;
  }
`;

export const InputWrapper = styled.div`
  flex: calc(25% - 24px);

  @media (max-width: ${MAX_WIDTHS.xl}px) {
    flex: calc(50% - 24px);
  } ;
`;

export const RulesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${scrollStyle};
  height: 500px;
  padding-right: 20px;
  overflow-y: scroll;
  gap: 40px;
`;

export const Rules = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const ModalFooter = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 20px;
`;

export const Title = styled.h1`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
`;

export const IconWrapper = styled.div`
  cursor: pointer;
`;
