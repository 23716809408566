import React from 'react';

export interface IconEyeProps {
  width?: number;
  height?: number;
  className?: string;
}

export const IconEye = ({ width = 14, height = 10, className }: IconEyeProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 14 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.333252 5.00004C1.38174 2.26849 3.96962 0.333374 6.99992 0.333374C10.0302 0.333374 12.6181 2.26849 13.6666 5.00004C12.6181 7.7316 10.0302 9.66671 6.99992 9.66671C3.96962 9.66671 1.38174 7.7316 0.333252 5.00004ZM6.99992 8.11115C5.32633 8.11115 3.96962 6.71826 3.96962 5.00004C3.96962 3.28182 5.32633 1.88893 6.99992 1.88893C8.67351 1.88893 10.0302 3.28182 10.0302 5.00004C10.0302 6.71826 8.67351 8.11115 6.99992 8.11115ZM6.99992 3.13337C5.99576 3.13337 5.18174 3.96911 5.18174 5.00004C5.18174 6.03097 5.99576 6.86671 6.99992 6.86671C8.00407 6.86671 8.8181 6.03097 8.8181 5.00004C8.8181 3.96911 8.00407 3.13337 6.99992 3.13337Z"
      fill="#262321"
    />
  </svg>
);
