import React from 'react';
import { components, DropdownIndicatorProps } from 'react-select';
import { DropdownIcon } from '../../assets/svgs/DropdownIcon';
import { DropdownWrapper, StyledSelect, Wrapper } from './style';

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  // eslint-disable-next-line react/destructuring-assignment
  const { menuIsOpen } = props.selectProps;

  const dropdownClassname = menuIsOpen ? 'dropdown-icon open' : 'dropdown-icon';

  return (
    <components.DropdownIndicator {...props}>
      <DropdownWrapper menuIsOpen={menuIsOpen}>
        <DropdownIcon className={dropdownClassname} />
      </DropdownWrapper>
    </components.DropdownIndicator>
  );
};

const TextSelect = React.forwardRef(({ options, ...props }: any, ref) => (
  <Wrapper>
    <StyledSelect
      ref={ref}
      options={options}
      classNamePrefix="Select"
      isSearchable={false}
      components={{ DropdownIndicator, IndicatorSeparator: () => null }}
      {...props}
    />
  </Wrapper>
));

export default TextSelect;
