import { combineReducers } from 'redux';
import modal from './reducers/modal-reducer';
import data from './reducers/dataReducer';
import blockPage from './reducers/blockPageReducer';

export default combineReducers({
  modal,
  data,
  blockPage,
});
