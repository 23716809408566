import React, { useContext } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Input from '../../../../components/Input';
import FormError from '../../../../components/FormError';
import Select from '../../../../components/Select';
import Field from '../CreatableField';
import { FormContainer, InlineInputs, InputWrapper, Line } from './style';
import FormContext from '../../GenericFormFields/context';
import { FieldType } from '../../types/FieldType';

const QuestionForm = ({
  accessFieldsName,
  selectedFieldIsMoldable,
  selectedField,
  nestIndex,
  childPanelRef,
  id,
  creatableFields,
  fieldTypes,
  blockTypeChange,
}: any) => {
  const { control, errors, register, customFieldTypes } = useContext(FormContext);
  const { t } = useTranslation();

  const selectFieldTypes = [...fieldTypes, ...customFieldTypes];
  return (
    <FormContainer>
      <input type="hidden" name={`${accessFieldsName}.wId`} {...register(`${accessFieldsName}.wId`)} value={id} />
      <InputWrapper>
        <Controller
          name={`${accessFieldsName}.title`}
          control={control}
          rules={{
            required: t<string>('form.requiredQuestion'),
          }}
          render={({ field }) => <Input {...field} label={t('form.labelQuestion')} />}
        />
        <FormError errors={errors} name={`${accessFieldsName}.title`} />
      </InputWrapper>
      <InlineInputs>
        <InputWrapper grow={6}>
          <Controller
            name={`${accessFieldsName}.type`}
            control={control}
            rules={{
              required: t<string>('form.requiredType'),
            }}
            render={({ field }) => (
              <Select
                {...field}
                disabled={blockTypeChange}
                label={t('form.labelType')}
                options={selectFieldTypes}
                isOptionSelected={(option: any, selectValue: any[]) => {
                  if (selectValue[0]?.value === 'CUSTOM') {
                    return selectValue[0]?.props._id === option.props?._id;
                  }
                  return selectValue[0]?.value === option.value;
                }}
              />
            )}
          />
          <FormError errors={errors} name={`${accessFieldsName}.type`} />
        </InputWrapper>
        <InputWrapper>
          <Controller
            name={`${accessFieldsName}.rpaID`}
            control={control}
            rules={{
              required: t<string>('form.requiredID'),
            }}
            render={({ field }) => <Input {...field} placeholder={t('common.type')} label="ID RPA" />}
          />
          <FormError errors={errors} name={`${accessFieldsName}.rpaID`} />
        </InputWrapper>
      </InlineInputs>
      {selectedField?.value === FieldType.UPLOAD && (
        <InlineInputs>
          <InputWrapper>
            <Controller
              name={`${accessFieldsName}.supportedFileFormats`}
              control={control}
              rules={{
                required: t<string>('form.supportedFileFormats'),
              }}
              render={({ field }) => (
                <Input {...field} placeholder={t('common.type')} label={t('form.labelTypeFormats')} />
              )}
            />
            <FormError errors={errors} name={`${accessFieldsName}.supportedFileFormats`} />
          </InputWrapper>
        </InlineInputs>
      )}
      <InlineInputs>
        <InputWrapper>
          <Controller
            name={`${accessFieldsName}.expertMenu`}
            control={control}
            render={({ field }) => <Input {...field} placeholder={t('common.type')} label={t('form.labelMenu')} />}
          />
        </InputWrapper>
        <InputWrapper>
          <Controller
            name={`${accessFieldsName}.expertField`}
            control={control}
            render={({ field }) => <Input {...field} placeholder={t('common.type')} label={t('form.labelField')} />}
          />
        </InputWrapper>
      </InlineInputs>
      {selectedFieldIsMoldable && (
        <>
          <Line />
          <Field
            elements={creatableFields}
            fieldType={selectedField.value}
            nestIndex={nestIndex}
            {...{ control, errors, register, accessFieldsName, childPanelRef }}
          />
        </>
      )}
    </FormContainer>
  );
};

export default QuestionForm;
