import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Creators as modalActions } from '../../../../redux/reducers/modal-reducer';
import { Column, Container, IconWrapper, ModalFooter, Row, Rules, RulesContainer, Title } from '../RulesModal/style';
import Select from '../../../../components/Select';
import { DeleteIcon } from '../../../../assets/svgs/DeleteIcon';
import Button from '../../../../components/Button';
import TextButton from '../../../../components/TextButton';
import { CreateIcon } from '../../../../assets/svgs/CreateIcon';
import Input from '../../../../components/Input';
import { FieldType } from '../../types/FieldType';
import FormError from '../../../../components/FormError';

export interface SelectableInternalVariable {
  code: string;
  wId: { label: string; value: string };
}
export interface ModalInternalVariableForm {
  internalVariables: (SelectableInternalVariable | undefined)[];
}

const blockedQuestionsForRules = [FieldType.DOWNLOAD, FieldType.PANEL, FieldType.UPLOAD, FieldType.THEMES];

const InternalVariableModal = ({
  handler,
  flattedQuestions,
  defaultValues,
}: {
  handler: any;
  flattedQuestions: any;
  defaultValues: ModalInternalVariableForm;
}) => {
  const selectableConditions = useMemo(
    () =>
      flattedQuestions
        .filter((item: any) => item.title && (item.type?.value || item.type))
        .filter((item: any) => !blockedQuestionsForRules.includes(item.type?.value))
        .map((item: any) => ({ value: item.wId, label: item.title })),
    [flattedQuestions]
  );
  const dispatch = useDispatch();
  const { closeModal } = modalActions;
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ModalInternalVariableForm>({ defaultValues });
  const {
    fields: conditionFields,
    append: appendCondition,
    remove: removeCondition,
  } = useFieldArray({
    control,
    name: 'internalVariables',
    keyName: 'internalVariableId',
  });

  const onSubmit = (data: any) => {
    handler(data);
    dispatch(closeModal());
  };

  const deleteHandler = (index: number) => {
    removeCondition(index);
  };

  const { t } = useTranslation();

  return (
    <Container>
      <RulesContainer>
        <Rules>
          {conditionFields.map((item, index) => (
            <div key={`internalVariables${item.internalVariableId}`}>
              <Column>
                <Title>{t('form.internalVariables')}</Title>
                <Row>
                  <div style={{ flex: '1' }}>
                    <Controller
                      rules={{
                        required: t<string>('common.required'),
                        validate: {
                          onlyNumbers: (value) =>
                            /^[a-zA-Z0-9][a-zA-Z0-9_]*[a-zA-Z0-9]$/.test(value) ||
                            t<string>('editVariables.validateCode'),
                        },
                      }}
                      name={`internalVariables.${index}.code`}
                      control={control}
                      render={({ field }) => <Input {...field} placeholder={t('variables.code')} />}
                    />
                  </div>
                  <div style={{ flex: '1' }}>
                    <Controller
                      name={`internalVariables.${index}.wId`}
                      rules={{
                        required: true,
                      }}
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={selectableConditions}
                          isOptionSelected={(option: any, selectValue: any[]) =>
                            selectValue[0]?.value.id === option.value
                          }
                          menuPosition="fixed"
                        />
                      )}
                    />
                  </div>
                  <IconWrapper onClick={() => deleteHandler(index)}>
                    <DeleteIcon />
                  </IconWrapper>
                </Row>
                <FormError errors={errors} name={`internalVariables.${index}.code`} />
              </Column>
            </div>
          ))}
          <TextButton onClick={() => appendCondition({ code: '', wId: undefined })} style={{ alignSelf: 'start' }}>
            <p>{t('form.create')}</p>
            <CreateIcon />
          </TextButton>
        </Rules>
      </RulesContainer>
      <ModalFooter>
        <Button type="submit" onClick={handleSubmit(onSubmit)} primary>
          {t('form.save')}
        </Button>
      </ModalFooter>
    </Container>
  );
};

export default InternalVariableModal;
