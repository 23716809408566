const formatDate = (dateValue: Date, format: string) => {
  const day = dateValue.getDate();
  const month = dateValue.getMonth() + 1;
  const year = dateValue.getFullYear();
  let result = format;
  result = result.replace('MM', month.toString().padStart(2, '0'));

  if (result.indexOf('yyyy') > -1) {
    result = result.replace('yyyy', year.toString());
  } else if (result.indexOf('yy') > -1) {
    result = result.replace('yy', year.toString().substr(2, 2));
  }

  result = result.replace('dd', day.toString().padStart(2, '0'));

  return result;
};

export default formatDate;
