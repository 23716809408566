/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useContext } from 'react';
import ReplyFormContext from '../../context';
import { ReplyFormQuestionsContainer } from '../style';
import { FieldType } from '../../../Form/types/FieldType';
import AnswerField from '../AnswerField';
import formatReceivedFormData from '../../utils/formatReceivedData';
import { useFormCustomRerender, useFormCustomRerenderMultipleForm } from './useFormCustomRerender';
import { useInternalVariableReplacer } from './useInternalVariableReplacer';
import { useJwtContext } from '../../../../JwtContext';

interface QuestionProps {
  index?: any;
  tabId?: number;
  item: {
    answer: string | string[];
    wId: string;
    rpaID: string;
    formID: string;
    type: FieldType;
    customTypeId: string;
    choices?: any[];
    rules: any;
    title: string;
    childOf?: string;
    expertMenu?: string;
    expertField?: string;
    files?: any[];
    supportedFileFormats?: string;
    questions?: any[];
    externalDependencies: any;
    mask?: string;
    validationCode?: string;
    validationMessage?: string;
    customTypeName?: string;
  };
}

const QuestionSingleForm = ({ index, item }: QuestionProps) => {
  const { wId, type, questions, choices, files, supportedFileFormats } = item;
  const { mask, validationCode, validationMessage, customTypeName } = item;
  const { control, register, errors } = useContext(ReplyFormContext);
  const accessFieldsName = `answers[${index}]`;
  const decodedJwt = useJwtContext();
  const dateFormat = decodedJwt?.dateFormat;
  const shouldRender = useFormCustomRerender(item, accessFieldsName, dateFormat);
  const replacedTitle = useInternalVariableReplacer(item, accessFieldsName);
  if (!shouldRender) return <></>;

  return (
    <AnswerField
      nestIndex={index}
      control={control}
      register={register}
      wId={wId}
      errors={errors}
      accessFieldsName={accessFieldsName}
      fieldType={type}
      {...{
        title: replacedTitle,
        questions,
        choices,
        files,
        supportedFileFormats,
        mask,
        validationCode,
        validationMessage,
        customTypeName,
      }}
    />
  );
};

const QuestionMultiForm = ({ index, item, tabId }: QuestionProps) => {
  const { wId, type, questions, choices, files, supportedFileFormats } = item;
  const { mask, validationCode, validationMessage, customTypeName } = item;
  const { control, register, errors } = useContext(ReplyFormContext);
  const accessFieldsName = `answers[${tabId}][${index}]`;
  const decodedJwt = useJwtContext();
  const dateFormat = decodedJwt?.dateFormat;
  const shouldRender = useFormCustomRerenderMultipleForm(item, accessFieldsName, tabId, dateFormat);
  const replacedTitle = useInternalVariableReplacer(item, accessFieldsName);
  if (!shouldRender) return <></>;

  return (
    <AnswerField
      nestIndex={index}
      control={control}
      register={register}
      wId={wId}
      errors={errors}
      accessFieldsName={accessFieldsName}
      fieldType={type}
      {...{
        title: replacedTitle,
        questions,
        choices,
        files,
        supportedFileFormats,
        mask,
        validationCode,
        validationMessage,
        customTypeName,
        tabId,
      }}
    />
  );
};

const DefaultReplyForm = ({ data, dataQuestions, tabId }: any) => {
  const questions =
    (data.multipleForm == 1 && Array.isArray(dataQuestions) ? dataQuestions : data.questions) ||
    data.questions[0].map((question: any) => ({
      ...question,
      answer: '',
    }));

  const formattedQuestions = Array.isArray(questions[0])
    ? formatReceivedFormData(questions[0], data?.titleVariableAnswers)
    : formatReceivedFormData(questions, data?.titleVariableAnswers);

  return (
    <ReplyFormQuestionsContainer>
      {data.multipleForm == 1
        ? formattedQuestions?.map((item: any, index: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <QuestionMultiForm tabId={tabId} index={index} key={`${tabId}-${item.wId}${index}`} item={item} />
          ))
        : formattedQuestions?.map((item: any, index: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <QuestionSingleForm index={index} key={`${item.wId}${index}`} item={item} />
          ))}
    </ReplyFormQuestionsContainer>
  );
};

export default DefaultReplyForm;
