import React from 'react';

interface DownIconProps {
  width?: number;
  height?: number;
  fill?: string;
}

export const DownIcon = ({ width = 15, height = 17, fill = '#262321' }: DownIconProps) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.293,14.707a1,1,0,0,1,1.414-1.414L11,19.586V2a1,1,0,0,1,2,0V19.586l6.293-6.293a1,1,0,0,1,1.414,1.414l-8,8a1,1,0,0,1-.325.216.986.986,0,0,1-.764,0,1,1,0,0,1-.325-.216Z"
      fill={fill}
    />
  </svg>
);
