import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Container, Footer, HistoricInfo, StyledHistoricData } from './style';
import Button from '../../../../../components/Button';
import { Creators as modalActions } from '../../../../../redux/reducers/modal-reducer';
import RadioButton from '../../../../../components/MarkableButton/RadioButton';
import { findFormHistoricByIdRequest } from '../../../../../requests/forms';

interface HistoricModalProps {
  onSubmit: (version: number, date: string) => void;
  id: string | undefined;
}

interface HistoricDataProps {
  id: string;
  date: string;
  owner: string;
  versionNumber: number;
  selectedVersion: number | string | null;
  setSelectedVersion: (selectedVersion: number | null) => void;
}

const HistoricData = ({ id, date, owner, selectedVersion, versionNumber, setSelectedVersion }: HistoricDataProps) => {
  const historicDate = date.split('T')[0].split('-').reverse().join('/');

  return (
    <StyledHistoricData key={id}>
      <RadioButton
        id={`HistoricListData-Checkbox${id}`}
        checked={versionNumber === selectedVersion}
        onChange={() => setSelectedVersion(versionNumber)}
      />
      <HistoricInfo>
        <p>{historicDate}</p>
        <p>{owner}</p>
      </HistoricInfo>
    </StyledHistoricData>
  );
};

interface HistoricDataFields {
  _id: string;
  date: string;
  owner: string;
  versionNumber: number;
  modifiedBy: string;
}

const HistoricModal = ({ id, onSubmit }: HistoricModalProps) => {
  const [selectedVersion, setSelectedVersion] = useState<number | null>(null);
  const [historicData, setHistoricData] = useState<HistoricDataFields[]>([]);

  const { t } = useTranslation();

  const fetchHistoricData = async () => {
    const data = await findFormHistoricByIdRequest(id);
    if (data) {
      setHistoricData(data.history);
    }
  };

  useEffect(() => {
    fetchHistoricData();
  }, []);

  const dispatch = useDispatch();
  const { closeModal } = modalActions;
  const close = () => dispatch(closeModal());

  const submitHistoric = () => {
    if (selectedVersion === null) return;
    // @ts-ignore
    const { date } = historicData.find((element) => element.versionNumber === selectedVersion);
    onSubmit(selectedVersion, date);
    close();
  };

  return (
    <Container>
      {historicData.map(({ _id, date, modifiedBy, versionNumber }) => (
        <HistoricData
          id={_id}
          date={date}
          owner={modifiedBy}
          selectedVersion={selectedVersion}
          versionNumber={versionNumber}
          setSelectedVersion={setSelectedVersion}
        />
      ))}
      <Footer>
        <Button onClick={submitHistoric} primary>
          {t('form.viewHistoric')}
        </Button>
      </Footer>
    </Container>
  );
};

export default HistoricModal;
