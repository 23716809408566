import React from 'react';

export interface IconBreadCrumbProps {
  width?: number;
  height?: number;
}

export const IconBreadCrumb = ({ width = 6, height = 10 }: IconBreadCrumbProps) => (
  <svg width={width} height={height} viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.78145 4.99999L0.481445 1.69999L1.42411 0.757324L5.66678 4.99999L1.42411 9.24266L0.481445 8.29999L3.78145 4.99999Z"
      fill="#324FA5"
    />
  </svg>
);
