export enum ActionsKind {
  OPEN = 'OPEN',
  CLOSE = 'CLOSE',
}

interface Action {
  type: ActionsKind;
  payload?: string;
}

interface State {
  open: boolean;
  data?: string;
}

const initialState = { open: false };

export const toggleReducer = (state: State, action: Action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionsKind.OPEN:
      return { open: true, data: payload };
    case ActionsKind.CLOSE:
      return initialState;
    default:
      return state;
  }
};
