import styled, { css } from 'styled-components';
import CalendarArrow from '../../../../../../assets/svgs/calendar_arrow.svg';

export const DISABLED_STYLE = css`
  background: #f2f0ed;
  border: 2px solid #cdc7c2;
  color: #938c85;
  &::placeholder {
    color: #938c85;
  }
`;

export const DatePickerWrapper = styled.div<{ disabled?: boolean }>`
  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__header {
    padding: 16px;
    background: #fff;
    box-sizing: border-box;
  }

  .react-datepicker__navigation {
    border: none;
  }

  .react-datepicker__navigation-icon {
    display: none;
  }

  .react-datepicker__navigation--next {
    background-image: url(${CalendarArrow});
    width: 15px;
    height: 15px;

    top: 17px;
    right: 24px;
  }

  .react-datepicker__navigation--previous {
    background-image: url(${CalendarArrow});
    width: 15px;
    height: 15px;

    transform: rotate(180deg);
    top: 17px;
    left: 24px;
  }

  .react-datepicker__current-month {
    font-family: 'Circular20', serif;
    text-transform: capitalize;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: #262321;
  }

  .react-datepicker__input-container > input {
    width: 260px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #938c85;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0 0 0 10px;
    ${({ disabled }) => disabled && DISABLED_STYLE}
  }
`;
