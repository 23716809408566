import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs } from '../../components/BreadCrumb';
import { Container } from '../EmailPermissions/style';
import { ActionsWrapper, Title } from '../SettingsForms/style';
import { TableData } from '../../types/TableData';
import { ActionIcon } from '../Login/LoginForm/style';
import { EditIcon } from '../../assets/svgs/EditIcon';
import { LoaderWrapper } from '../../components/Loader/style';
import Loader from '../../components/Loader';
import Table from '../../components/Table';
import { findAllVariablesRequest } from '../../requests/variables';
import { BreadcrumbWrapper } from '../../components/BreadCrumb/style';

const VariablesContent = () => {
  const [variables, setVariables] = useState<TableData[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const getVariables = async () => {
    setLoading(true);
    const data = await findAllVariablesRequest();
    if (data) {
      setVariables(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getVariables();
    return () => {
      setVariables([]);
    };
  }, []);

  const createHandler = () => {
    navigate('/settings/variables/create');
  };

  const editHandler = (data: any) => {
    navigate(`/settings/variables/edit/${data._id}`, { state: { name: data.name } });
  };

  const VARIABLES_ADVICE = t('variables.advice');
  const VARIABLES_EMPTY_STATE_TEXTS = {
    EMPTY_FILTER: t('variables.emptyFilter'),
    EMPTY_LIST: t('variables.emptyList'),
  };

  const render = (element: any) => [
    element.name,
    element.code,
    <ActionsWrapper>
      <ActionIcon
        onClick={() => {
          editHandler(element);
        }}
      >
        <EditIcon />
      </ActionIcon>
    </ActionsWrapper>,
  ];

  const headers = [
    {
      head: t('variables.registered'),
      key: 'name',
    },
    {
      head: t('variables.code'),
      key: 'code',
    },
  ];

  return loading ? (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  ) : (
    <Table
      searchKey="name"
      renderActions={() => []}
      render={render}
      headers={headers}
      emptyStateTexts={VARIABLES_EMPTY_STATE_TEXTS}
      data={variables}
      advice={VARIABLES_ADVICE}
      createHandler={createHandler}
    />
  );
};

const VariablesList = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <BreadcrumbWrapper>
        <Breadcrumbs />
      </BreadcrumbWrapper>
      <Title>{t('variables.title')}</Title>
      <VariablesContent />
    </Container>
  );
};
export default VariablesList;
