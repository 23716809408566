import React from 'react';

export interface IconFormsProps {
  width?: number;
  height?: number;
}

export const IconFormsChecked = ({ width = 32, height = 32 }: IconFormsProps) => (
  <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.6667 5.33335V2.66669H9.33333V5.33335H5.324C4.59333 5.33335 4 5.92669 4 6.65735V28.0093C4 28.74 4.59333 29.3333 5.324 29.3333H18.4895C17.744 28.5664 17.1371 27.6641 16.7092 26.6667H6.66667V8.00002H9.33333V10.6667H22.6667V8.00002H25.3333V14.4159C26.2691 14.4718 27.1655 14.674 28 14.9999V6.65735C28 5.92669 27.4067 5.33335 26.676 5.33335H22.6667ZM12 24V21.3334H9.33333V24H12ZM12 20V17.3334H9.33333V20H12ZM12 16V13.3334H9.33333V16H12ZM12 5.33335H20V8.00002H12V5.33335Z"
      fill="#000"
    />
    <path
      d="M28.737 18.5544L22.6913 24.6008L18.9081 20.8176L18.7313 20.6408L18.5545 20.8176L17.4233 21.9488L17.2465 22.1256L17.4233 22.3023L22.5145 27.3935L22.6913 27.5703L22.8681 27.3935L30.2226 20.0392L30.3994 19.8623L30.2225 19.6855L29.0905 18.5543L28.9137 18.3777L28.737 18.5544Z"
      fill="#000"
      stroke="#000"
      strokeWidth="0.5"
    />
  </svg>
);
