import styled from 'styled-components';
import Grid from '../../../components/Grid';

export const Container = styled(Grid).attrs({
  container: true,
})`
  padding: 0 40px;
  margin-top: 70px;
  margin-bottom: 140px;
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  gap: 24px;
`;

export const HistoricInfo = styled.div`
  background: none;
  border: none;
  display: flex;
  align-items: center;
  gap: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #324fa5;
  path {
    fill: #324fa5;
  }
`;
