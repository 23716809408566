import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Title, Subtitle, StyledIconArrow } from './style';
import Button from '../../../components/Button';

interface SuccessProps {
  setForm?: any;
}
const Success = ({ setForm }: SuccessProps) => {
  const { t } = useTranslation();

  return (
    <Form>
      <Title>
        {t('login.successTitle')}
        <Subtitle>{t('login.successSubtitle')}</Subtitle>
      </Title>
      <Button primary full mt={20} onClick={() => setForm('login')}>
        {t('login.requestButton')}
        <StyledIconArrow />
      </Button>
    </Form>
  );
};

export default Success;
