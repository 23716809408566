import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ImageMap } from '@qiuz/react-image-map';
import ImageError from '../../assets/images/ImageError.png';
import { Image, Container } from './style';
import { useWindowDimensions } from '../../utils/useWindowDimensions';

/**
 * Area map generated at http://blog.qiuz.site/q/react-image-map/?imgSrc=http://5b0988e595225.cdn.sohucs.com/images/20170920/2a178d11bc8b4178a387398b5658e105.jpeg
 */
export const NotFound = () => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  const handleSubmit = () => {
    navigate('/home');
  };
  const mapArea = [
    (width ?? 1440) > 590
      ? {
          width: '38.0743%',
          height: '9.95296%',
          left: '61.0400%',
          top: '57.7253%',
        }
      : {
          width: '36.0744%',
          height: '11.95296%',
          left: '54.0401%',
          top: '56.7253%',
        },
  ];
  return (
    <Container>
      <Image>
        <ImageMap className="usage-map" src={ImageError} map={mapArea} onMapClick={handleSubmit} />
      </Image>
    </Container>
  );
};
