import React from 'react';
import { IconAlert } from '../../assets/svgs/IconAlert';
import { FormErrorMessage, StyledFormError } from './style';

const ErrorWithIcon = ({ message }: { message: string }) => (
  <StyledFormError>
    <IconAlert />
    <FormErrorMessage>{message}</FormErrorMessage>
  </StyledFormError>
);

export default ErrorWithIcon;
