import axios from 'axios';
import { refreshToken } from './autentication';
import { getFromLocalStorage, saveSessionStorage } from '../utils/storageUtils';
import Alert from '../components/Toast/toast';
import i18n from '../config/i18n/config';

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axios.interceptors.response.use(
  (response) => response,
  (err) => {
    const originalRequest = err.config;

    if (err.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return axios(originalRequest);
          })
          .catch((err1) => Promise.reject(err1));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise((resolve, reject) => {
        refreshToken
          .post('', {
            refreshToken: getFromLocalStorage('refreshToken'),
          })
          .then(({ data }) => {
            saveSessionStorage('token', data.token);
            saveSessionStorage('refreshToken', data.refreshToken);
            axios.defaults.headers.common.Authorization = `Bearer ${data.token}`;
            originalRequest.headers.Authorization = `Bearer ${data.token}`;
            processQueue(null, data.token);
            resolve(axios(originalRequest));
          })
          .catch((err2: any) => {
            processQueue(err2, null);
            Alert.ERROR(i18n.t('login.error'));
            reject(err2);
          })
          .then(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(err);
  }
);
