import { createReducer, createActions } from 'reduxsauce';
import { ReactElement } from 'react';

export const { Types, Creators } = createActions({
  openModal: ['modalProps'],
  closeModal: [],
});

interface ModalProps {
  title: string | ReactElement;
  content: string | ReactElement;
}

interface ModalState {
  modalProps: ModalProps | null;
}

const INITIAL_STATE = {
  modalProps: null,
};

// eslint-disable-next-line @typescript-eslint/default-param-last,@typescript-eslint/no-unused-vars
const openModal = (state: ModalState = INITIAL_STATE, action: any) => ({
  modalProps: action.modalProps,
});

const closeModal = () => INITIAL_STATE;

const HANDLERS = {
  [Types.OPEN_MODAL]: openModal,
  [Types.CLOSE_MODAL]: closeModal,
};

export default createReducer(INITIAL_STATE, HANDLERS);
