import api from '../http/api';
import { TableData } from '../types/TableData';

const PROJECT_TYPE_PATH = '/project-types';

export const findAllProjectTypeRequest = async () => {
  const { data } = await api.get(PROJECT_TYPE_PATH);
  return data;
};

export const findProjectTypeByIdRequest = async (id: number | string) => {
  const { data } = await api.get(`${PROJECT_TYPE_PATH}/${id}`);
  return data;
};

export const addProjectTypeRequest = async (data: TableData[]) => {
  await api.post(PROJECT_TYPE_PATH, data);
};

export const updateProjectTypeRequest = async (data: TableData, id: string) => {
  await api.put(`${PROJECT_TYPE_PATH}/${id}`, data);
};

export const deleteProjectTypeRequest = async (data: TableData[]) => {
  await api.patch(PROJECT_TYPE_PATH, data);
};
