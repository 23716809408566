import styled from 'styled-components';
import { MarkableLabel } from '../style';
import { disabledOrDefault } from '../../../utils/a';

export const CheckboxInput = styled.input.attrs({
  type: 'checkbox',
  className: 'checkbox-input',
})<{ ref: any }>`
  display: none;
  pointer-events: none;
`;

export const CheckboxLabel = styled(MarkableLabel)<{ disabled?: boolean }>`
  &:before {
    border-radius: 6px;
  }

  .checkbox-input:checked + &:before {
    background: ${disabledOrDefault('#476bc3')};
    border-color: ${disabledOrDefault('#476bc3')};
  }

  .checkbox-input:checked + & {
    .check {
      stroke-dashoffset: 0;
    }
  }
`;
