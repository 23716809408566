import styled from 'styled-components';
import Select from 'react-select';
import CheckIcon from '../../assets/svgs/check.svg';

interface DropdownProps {
  menuIsOpen: boolean;
}

export const DropdownWrapper = styled.div<DropdownProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  .dropdown-icon {
    transition: all 0.25s linear;
  }

  .dropdown-icon.open {
    transform: rotate(180deg);
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const Label = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #393533;
`;

export const StyledSelect = styled(Select)`
  .Select__control {
    height: 40px;
    width: 100%;
    cursor: pointer;

    background: #fff;
    border: 1px solid #938c85;
    box-sizing: border-box;
    border-radius: 8px;
    outline: none;

    &:hover {
      border-color: #605b5b;
    }

    &--is-focused {
      border: 1px solid #476bc3;
      box-shadow: none;
      outline: none;
    }

    &--is-disabled {
      user-select: none;
      pointer-events: none;
      cursor: default;
      background: #f2f0ed;
    }
  }

  .Select__indicator-separator {
    display: none;
  }

  .Select__clear-indicator ~ .Select__indicator-separator {
    display: block;
  }

  .Select__clear-indicator {
    &:hover path {
      fill: #262321;
    }
  }

  .Select__clear-indicator {
    &:hover path {
      fill: #262321;
    }
  }

  .Select__menu {
    color: #3c3d3e;
    padding: 8px;
    z-index: 999;
  }

  .Select__option {
    &:not(:last-child) {
      border-bottom: solid 1px rgba(0, 0, 0, 0.2);
    }
    display: flex;
    align-items: center;
    min-height: 48px;
    padding: 4px 8px;
    cursor: pointer;

    &--is-selected {
      background: #e8effa !important;
      color: #20337d;

      &:before {
        display: block;
        position: absolute;
        content: ' ';
        right: 12px;
        background-image: url(${CheckIcon});
        background-size: 14px 18px;
        height: 18px;
        width: 14px;
      }
    }

    &--is-disabled {
      user-select: none;
      pointer-events: none;
      cursor: default;
      background: #f2f0ed;
      border: 1px solid #cdc7c2;
    }
  }
`;
