import i18n from '../../config/i18n/config';

interface BreadCrumbItem {
  path?: string;
  breadcrumb?: string | null;
}

export const routes: Array<BreadCrumbItem> = [
  {
    path: '/settings',
    breadcrumb: i18n.t('breadcrumb.settings'),
  },
  {
    path: '/settings/data-purge-words',
    breadcrumb: i18n.t('breadcrumb.dataPurge'),
  },
  {
    path: '/settings/email-permissions',
    breadcrumb: i18n.t('breadcrumb.emailPermissions'),
  },
  {
    path: '/settings/variables',
    breadcrumb: i18n.t('breadcrumb.variables'),
  },
  {
    path: '/settings/variables/edit',
    breadcrumb: i18n.t('breadcrumb.editVariables'),
  },
  {
    path: '/settings/variables/edit/:id',
    breadcrumb: null,
  },
  {
    path: '/settings/variables/create',
    breadcrumb: i18n.t('breadcrumb.createVariables'),
  },
  {
    path: '/settings/forms',
    breadcrumb: i18n.t('breadcrumb.settingsForms'),
  },
  {
    path: '/settings/question',
    breadcrumb: i18n.t('breadcrumb.settingsQuestions'),
  },
  {
    path: '/settings/question/create',
    breadcrumb: i18n.t('breadcrumb.settingsQuestionsCreate'),
  },
  {
    path: '/settings/question/edit',
    breadcrumb: i18n.t('breadcrumb.settingsQuestionsEdit'),
  },
  {
    path: '/product',
    breadcrumb: i18n.t('breadcrumb.product'),
  },
  {
    path: '/product/edit',
    breadcrumb: i18n.t('breadcrumb.productEdit'),
  },
  {
    path: '/project-type',
    breadcrumb: i18n.t('breadcrumb.projectType'),
  },
  {
    path: '/project-type/edit',
    breadcrumb: i18n.t('breadcrumb.projectTypeEdit'),
  },
  {
    path: '/business-unit',
    breadcrumb: i18n.t('breadcrumb.businessUnit'),
  },
  {
    path: '/business-unit/edit',
    breadcrumb: i18n.t('breadcrumb.businessUnitEdit'),
  },
  {
    path: '/service-offer',
    breadcrumb: i18n.t('breadcrumb.serviceOffer'),
  },
  {
    path: '/service-offer/edit',
    breadcrumb: i18n.t('breadcrumb.serviceOfferEdit'),
  },
  {
    path: '/forms',
    breadcrumb: i18n.t('breadcrumb.forms'),
  },
  {
    path: '/forms/create',
    breadcrumb: i18n.t('breadcrumb.formsCreate'),
  },
  {
    path: '/forms/edit',
    breadcrumb: i18n.t('breadcrumb.formsEdit'),
  },
  {
    path: '/forms/historic',
    breadcrumb: i18n.t('breadcrumb.formsHistoric'),
  },
  {
    path: '/forms/historic/:id',
    breadcrumb: null,
  },
  {
    path: '/forms/historic/:id/:version',
    breadcrumb: null,
  },
  {
    path: '/forms/edit/:id',
    breadcrumb: null,
  },
  {
    path: '/forms/test',
    breadcrumb: i18n.t('breadcrumb.test'),
  },
  {
    path: '/forms/test/:id',
    breadcrumb: null,
  },
  {
    path: '/user-management',
    breadcrumb: i18n.t('breadcrumb.userManagement'),
  },
  {
    path: '/reply-form',
    breadcrumb: i18n.t('breadcrumb.replyForm'),
  },
  {
    path: '/reply-form/:id',
    breadcrumb: null,
  },
  {
    path: '/advisor-view',
    breadcrumb: i18n.t('breadcrumb.view'),
  },
  {
    path: '/advisor-view/:id',
    breadcrumb: null,
  },
  {
    path: '/advisor-view/:id/:projectId',
    breadcrumb: null,
  },
  {
    path: '/user-project',
    breadcrumb: i18n.t('breadcrumb.addProjetUser'),
  },
  {
    path: '/user-project/create',
    breadcrumb: i18n.t('breadcrumb.createProjetUser'),
  },
];
