import React from 'react';
import { useTranslation } from 'react-i18next';
import { DividerWrapper, QuestionAmount, StyledDivider } from './style';
import SearchBar from '../../../../components/SearchBar';

interface DividerProps {
  search: string;
  setSearch: (search: string) => void;
  questionAmount: number;
}

const Divider = ({ search, setSearch, questionAmount = 0 }: DividerProps) => {
  const { t } = useTranslation();

  return (
    <StyledDivider>
      <QuestionAmount>
        {questionAmount} {t('form.questions')}
      </QuestionAmount>
      <DividerWrapper>
        <SearchBar value={search} setValue={setSearch} />
      </DividerWrapper>
    </StyledDivider>
  );
};

export default Divider;
