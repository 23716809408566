import React, { ReactElement } from 'react';
import i18n from '../../config/i18n/config';
import { IconDashboard } from '../../assets/svgs/IconDashboard';
import { IconForms } from '../../assets/svgs/IconForms';
import { IconAddUser } from '../../assets/svgs/IconAddUser';

export interface SideBarItem {
  key: number | string;
  title: string;
  path?: string;
  icon?: ReactElement;
  children?: any;
  filterFunction?: any;
}

export const SidebarData: Array<SideBarItem> = [
  {
    key: 'SidebarData1',
    title: 'Dashboard',
    path: '/home',
    icon: <IconDashboard />,
  },
  {
    key: 'SidebarData4',
    title: i18n.t('breadcrumb.addProjetUser'),
    path: '/user-project',
    icon: <IconAddUser />,
    filterFunction: ({ decodedJwt, selectedProject }: any) =>
      decodedJwt.sponsorOf?.some((projectId: string) => projectId === selectedProject),
  },
  {
    key: 'SidebarData2',
    title: 'Dashboard',
    path: '/admin',
    icon: <IconDashboard />,
  },
  {
    key: 'SidebarData3',
    title: i18n.t('navBar.titleForms'),
    path: '/forms',
    icon: <IconForms />,
  },
];
