import styled from 'styled-components';

export const ButtonGroupItem = styled.div`
  cursor: pointer !important;
  display: flex;
  align-items: center;
  gap: 6px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #fff;
  path {
    max-width: 11px;
    fill: #fff;
  }
`;

export const StyledButtonGroup = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  padding: 0 20px;
  gap: 25px;
  height: 40px;
  background: #476bc3;
  border-radius: 12px;
`;
