export enum FieldType {
  TEXT = 'TEXT',
  LONG_TEXT = 'LONG_TEXT',
  DATE = 'DATE',
  PANEL = 'PANEL',
  NUMBER = 'NUMBER',
  PHONE = 'PHONE',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  SINGLE_CHOICE = 'SINGLE_CHOICE',
  UPLOAD = 'UPLOAD',
  DOWNLOAD = 'DOWNLOAD',
  VALIDATION = 'VALIDATION',
  SELECT = 'SELECT',
  CUSTOM = 'CUSTOM',
  EMAIL = 'EMAIL',
  USER_NAME = 'USER_NAME',
  USER_EMAIL = 'USER_EMAIL',
  THEMES = 'THEMES',
}
