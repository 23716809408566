import styled from 'styled-components';

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  gap: 8px;

  width: 161px;

  background: #ffffff;
  box-shadow: 0 3px 8px rgba(38, 35, 33, 0.16);
  border-radius: 8px;

  position: absolute;
  top: calc(100% + 4px);
  right: 0;

  & > p {
    font-family: 'Circular20';
    color: #262321;
    font-style: normal;
  }

  & > p.name {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -1px;
    color: #262321;
  }
  & > p.email {
    font-weight: 450;
    font-size: 12px;
    line-height: 14px;
  }
`;

export const ProjectHolder = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: 8px;
  & > p:first-child {
    word-break: break-all;
    font-family: 'Circular20';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;

    color: #262321;
  }
  & > svg {
    min-width: 15px;
    min-height: 15px;
  }
`;
