import styled from 'styled-components';

export const StyledConditionSwitch = styled.div`
  width: 50px;
  input[type='checkbox'] {
    display: none;
  }
`;

export const SwitchView = styled.div`
  display: flex;
  gap: 10px;

  margin-top: 24px;

  cursor: pointer;
  user-select: none;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #324fa5;

  svg > path {
    fill: #324fa5;
  }
`;
