import api from '../http/api';
import { TableData } from '../types/TableData';

const FORMS_PATH = '/forms';

export const findAllFormsRequest = async (props?: {
  projectType: string | null;
  businessUnit: string | null;
  serviceOffer: string | null;
  productType: string | null;
  formType: string | null;
}) => {
  if (props) {
    const { data } = await api.get(
      `${FORMS_PATH}?formType=${props.formType}&projectType=${props.projectType}&businessUnit=${props.businessUnit}&serviceOffer=${props.serviceOffer}&productType=${props.productType}&`
    );
    return data;
  }

  const { data } = await api.get(FORMS_PATH);
  return data;
};

export const findFormByIdRequest = async (id: string | undefined) => {
  const { data } = await api.get(`${FORMS_PATH}/${id}`);
  return data;
};
export const findFormDraftWithFallbackByIdRequest = async (id: string | undefined) => {
  const { data } = await api.get(`${FORMS_PATH}/draft/with-fallback/${id}`);
  return data;
};
export const findFormToTestRequest = async (id: string | undefined) => {
  const { data } = await api.get(`${FORMS_PATH}/draft/test/${id}`);
  return data;
};

export const findFormByIdAndVersionRequest = async (id: string | undefined, version: string | undefined) => {
  const { data } = await api.get(`${FORMS_PATH}/${id}/${version}`);
  return data;
};

export const blockFormRequest = async (data: TableData[]) => {
  const ids = data.map((item) => ({ wId: item.wId }));

  await api.patch(`${FORMS_PATH}/inactive`, { ids });
};

export const unblockFormRequest = async (data: TableData[]) => {
  const ids = data.map((item) => ({ wId: item.wId }));

  await api.patch(`${FORMS_PATH}/reactive`, { ids });
};
export const deleteDraftRequest = async (data: TableData[]) => {
  const ids = data.map((item) => ({ wId: item.wId }));

  await api.patch(`${FORMS_PATH}/draft/delete`, { ids });
};

export const findFormHistoricByIdRequest = async (id: string | undefined) => {
  const { data } = await api.get(`/form-history/${id}`);
  return data;
};

export const createFormRequest = async (data: any) => api.post(FORMS_PATH, data);
export const publishForm = async (id: any) => api.post(`${FORMS_PATH}/draft/publish/${id}`);

export const updateFormRequest = async (data: any) => {
  await api.put(`${FORMS_PATH}/update/${data.wId}`, data);
};
export const restoreVersionRequest = async (wId: string, version: string) => {
  await api.post(`${FORMS_PATH}/restore/${wId}/${version}`);
};

export const findQuestionsInIndex = async (wIds: string[]) => {
  const { data } = await api.post(`/question-index/latest`, wIds);
  return data;
};
