import { View, Text } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../../style';

const Input = ({ title, answer }: any) => (
  <View wrap={false}>
    <Text style={styles.title}>{title}</Text>
    <Text style={styles.input}>{answer}</Text>
  </View>
);

export default Input;
