import { cloneElement, createElement } from 'react';
import { FieldType } from '../../../../../../pages/Form/types/FieldType';
import MultipleChoice from '../../ChoicesFields/MultipleChoice';
import InputField from '../../InputField';
import Upload from '../../Upload';
import UniqueChoice from '../../ChoicesFields/UniqueChoice';
import LongText from '../../LongText';
import Download from '../../Download';

const elements = new Map();
elements.set(FieldType.MULTIPLE_CHOICE, createElement(MultipleChoice));
elements.set(FieldType.SINGLE_CHOICE, createElement(UniqueChoice));
elements.set(FieldType.TEXT, createElement(InputField, { type: 'text' }));
elements.set(FieldType.LONG_TEXT, createElement(LongText));
elements.set(FieldType.DATE, createElement(InputField));
elements.set(FieldType.NUMBER, createElement(InputField, { type: 'number' }));
elements.set(FieldType.PHONE, createElement(InputField, { type: 'text' }));
elements.set(FieldType.UPLOAD, createElement(Upload));
elements.set(FieldType.DOWNLOAD, createElement(Download));
elements.set(FieldType.VALIDATION, createElement(InputField));
elements.set(FieldType.EMAIL, createElement(InputField, { type: 'text' }));
elements.set(FieldType.USER_EMAIL, createElement(InputField, { type: 'text' }));
elements.set(FieldType.USER_NAME, createElement(InputField, { type: 'text' }));

interface PanelFieldProps {
  fieldType: FieldType | null;
  customFieldTypeProps?: any;
  title: string;
}

const PanelField = ({ fieldType, customFieldTypeProps, title, ...props }: PanelFieldProps) => {
  if (fieldType === FieldType.CUSTOM) {
    const { type, ...customProps } = customFieldTypeProps;

    const element = elements.get(type);
    return cloneElement(element, { ...customProps, title });
  }

  const element = elements.get(fieldType);
  return cloneElement(element, { ...props, title });
};

export default PanelField;
