import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 54px;

  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -1px;
  color: #262321;
`;

export const Footer = styled.div`
  justify-content: center;
  display: flex;
  gap: 12px;
`;
