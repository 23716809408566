import React, { ChangeEvent, useContext } from 'react';
import { Controller } from 'react-hook-form';
// @ts-ignore
import safeEval from 'safe-eval';
import VMasker from 'vanilla-masker';
import { useTranslation } from 'react-i18next';
import ReplyFormContext from '../../../../context';
import { AnswerContainer, FieldTitle } from '../../style';
import { InputWrapper } from '../../../../../Form/components/QuestionForm/style';
import FormError from '../../../../../../components/FormError';
import Input from '../../../../../../components/Input';
import { FormTestContext } from '../../../../../Form/FormTest/context';
import Alert from '../../../../../../components/Toast/toast';

const CMaskedInput = ({ disabled, onChange, value, mask, label, ...otherProps }: any) => {
  const formatMask = (valueToMask: string) => VMasker.toPattern(valueToMask, mask);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: currentValue } = event.target;

    onChange(formatMask(currentValue));
  };

  return <Input {...otherProps} disabled={disabled} label={label} value={value} onChange={handleChange} />;
};

const ValidationField = ({
  control,
  errors,
  accessFieldsName,
  register,
  wId,
  title,
  mask,
  validationCode,
  customTypeName,
  validationMessage,
}: any) => {
  const { formDisabled } = useContext(ReplyFormContext);
  const { testForm } = useContext(FormTestContext);
  const { t } = useTranslation();
  return (
    <AnswerContainer>
      <FieldTitle>{title}</FieldTitle>
      <input type="hidden" name={`${accessFieldsName}.wId`} {...register(`${accessFieldsName}.wId`)} value={wId} />
      <InputWrapper>
        <Controller
          name={`${accessFieldsName}.answer`}
          control={control}
          rules={{
            required: t<string>('common.required'),
            validate: (maskedValue) => {
              const validateFunction = safeEval(validationCode);
              const unmaskedText = maskedValue.replace(/[^a-zA-Z0-9]/g, '');
              try {
                return validateFunction(maskedValue, unmaskedText) || validationMessage;
              } catch (err: any) {
                if (testForm)
                  Alert.ERROR(
                    `${t<string>('questions.typeCodeError')} ${customTypeName}!! ${t<string>(
                      'questions.question'
                    )} ${title}`
                  );
                Alert.ERROR(`${t<string>('questions.fieldError')} ${title}. ${t<string>('questions.contactAdmin')}`);
                return t<string>('questions.errorValidating');
              }
            },
          }}
          render={({ field }) => <CMaskedInput {...field} mask={mask} disabled={formDisabled} />}
        />
        <FormError errors={errors} name={`${accessFieldsName}.answer`} />
      </InputWrapper>
    </AnswerContainer>
  );
};

export default ValidationField;
