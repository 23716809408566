import React, { ChangeEvent, useContext } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReplyFormContext from '../../../../context';
import { AnswerContainer, FieldTitle } from '../../style';
import { InputWrapper } from '../../../../../Form/components/QuestionForm/style';
import FormError from '../../../../../../components/FormError';
import Input from '../../../../../../components/Input';

interface MaskedInputProps {
  onChange: (value: string) => void;
  value: string;
  label?: string;
  disabled: boolean;
}

const CInputNumber = ({ disabled, onChange, value, label, ...otherProps }: MaskedInputProps) => {
  const maskOnlyNumbers = (numberValue: any) => numberValue.replace(/[^\d.,]/gi, '');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: currentValue } = event.target;

    onChange(maskOnlyNumbers(currentValue));
  };

  return <Input {...otherProps} disabled={disabled} label={label} value={value} onChange={handleChange} />;
};

const InputNumber = ({ control, errors, accessFieldsName, register, wId, title }: any) => {
  const { formDisabled } = useContext(ReplyFormContext);
  const { t } = useTranslation();

  return (
    <AnswerContainer>
      <FieldTitle>{title}</FieldTitle>
      <input type="hidden" name={`${accessFieldsName}.wId`} {...register(`${accessFieldsName}.wId`)} value={wId} />
      <InputWrapper>
        <Controller
          name={`${accessFieldsName}.answer`}
          control={control}
          rules={{
            required: t<string>('common.required'),
          }}
          render={({ field }) => <CInputNumber {...field} disabled={formDisabled} />}
        />
        <FormError errors={errors} name={`${accessFieldsName}.answer`} />
      </InputWrapper>
    </AnswerContainer>
  );
};

export default InputNumber;
