import api from '../http/api';

export const findAllDataClientDashboard = async (isPreview?: boolean, projectId?: string) => {
  const queryString = isPreview ? `/client-dashboard?projectId=${projectId}` : '/client-dashboard';

  const { data } = await api.get(queryString);
  return data;
};

export const findAllDataAdminDashboard = async () => {
  const { data } = await api.get('/admin-dashboard');
  return data;
};
