import React, { useContext, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FieldTitle } from '../../../style';
import { ChoicesContainer, StyledChoice } from '../style';
import Checkbox from '../../../../../../../components/MarkableButton/Checkbox';
import FormError from '../../../../../../../components/FormError';
import ReplyFormContext from '../../../../../context';

const Choice = ({ handleChange, title, index, prependToKey, defaultChecked = false }: any) => {
  const [checked, setChecked] = useState(defaultChecked);
  const { formDisabled } = useContext(ReplyFormContext);
  const onChange = (e: any) => {
    if (formDisabled) return;
    const { value } = e.target;
    handleChange(value);
    setChecked(!checked);
  };
  useEffect(() => {
    setChecked(defaultChecked);
  }, [defaultChecked]);

  return (
    <StyledChoice disabled={formDisabled}>
      <Checkbox
        value={title}
        checked={checked}
        onChange={onChange}
        id={`${prependToKey}-Checkbox${title}${index}`}
        disabled={formDisabled}
      />
      <span>{title}</span>
    </StyledChoice>
  );
};

interface ChoiceListProps {
  control: any;
  watchKey: string;
  onChange: any;
  value: any;
  choices: { title: string }[];
  prependToKey: string;
}

const ChoiceList = ({ value, choices, control, onChange, prependToKey, watchKey }: ChoiceListProps) => {
  const addOrRemove = (arr: any[], item: any) => (arr.includes(item) ? arr.filter((i) => i !== item) : [...arr, item]);

  const handleChange = (choice: any) => {
    if (Array.isArray(value)) {
      return onChange(addOrRemove(value, choice));
    }

    return onChange([choice]);
  };
  const { watch } = useContext(ReplyFormContext);
  const values = watch(watchKey);
  return (
    <>
      {choices.map(({ title: choiceTitle }, index: number) => (
        <Choice
          // eslint-disable-next-line react/no-array-index-key
          key={`${prependToKey}-Choice-${choiceTitle}${index}`}
          handleChange={handleChange}
          title={choiceTitle}
          defaultChecked={values.includes(choiceTitle)}
          control={control}
          index={index}
          prependToKey={prependToKey}
        />
      ))}
    </>
  );
};

export interface MultipleChoiceProps {
  control: any;
  errors: any;
  accessFieldsName: string;
  register: any;
  wId: string;
  title: string;
  choices: { title: string }[];
}

const MultipleChoice = ({ errors, control, register, wId, accessFieldsName, title, choices }: MultipleChoiceProps) => {
  const { t } = useTranslation();

  return (
    <ChoicesContainer>
      <input type="hidden" name={`${accessFieldsName}.wId`} {...register(`${accessFieldsName}.wId`)} value={wId} />
      <FieldTitle>{title}</FieldTitle>
      <Controller
        control={control}
        rules={{
          required: t<string>('common.required'),
        }}
        defaultValue={[]}
        name={`${accessFieldsName}.answer`}
        render={({ field: { onChange, value } }) => (
          <ChoiceList
            watchKey={`${accessFieldsName}.answer`}
            value={value}
            choices={choices}
            onChange={onChange}
            control={control}
            prependToKey={accessFieldsName}
          />
        )}
      />
      <FormError errors={errors} name={`${accessFieldsName}.answer`} />
    </ChoicesContainer>
  );
};

export default MultipleChoice;
