import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CloseIcon } from '../../assets/svgs/CloseIcon';
import { CloseIconWrapper, ModalContainer, ModalContent, ModalHeader, ModalOverlay, ModalTitle } from './style';
// import ClickAwayListener from '../ClickAwayListener';
import { Creators as modalActions } from '../../redux/reducers/modal-reducer';
import { RootState } from '../../redux/store';

const Modal = () => {
  const modalProps = useSelector((state: RootState) => state?.modal?.modalProps);
  const dispatch = useDispatch();
  const { closeModal } = modalActions;
  const closeHandler = () => dispatch(closeModal());

  if (!modalProps) {
    return null;
  }

  return (
    <ModalOverlay>
      <ModalContainer>
        <ModalHeader className="default_modal_header">
          <ModalTitle>{modalProps?.title}</ModalTitle>
          <CloseIconWrapper onClick={closeHandler}>
            <CloseIcon width={10} height={10} />
          </CloseIconWrapper>
        </ModalHeader>
        <ModalContent>{modalProps?.content}</ModalContent>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default Modal;
