import styled from 'styled-components';

export const Content = styled.div`
  padding: 24px 24px 40px;
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
  height: 80px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 13px;
`;
