import { useNavigate } from 'react-router-dom';
import React, { useMemo } from 'react';
import {
  BadgeWrapper,
  CompanyBadge,
  DashboardBadge,
  ListContainer,
  ListDataWrapper,
  ListRow,
  ListWrapper,
  Title,
} from '../../style';
import { FORM_STATUS_LABEL, FormStatus } from '../../constants';

interface IFormData {
  _id: string;
  title: string;
  formType: string;
  status: FormStatus;
  formAnswerId: string;
  company?: string;
  companyName?: string;
}

interface ListProps {
  forms: IFormData[];
  filterStatus: FormStatus | null;
  isPreview?: boolean;
  disabled: boolean;
  filterCompany: any[];
  projectId?: any;
}

const ListCompanies = ({ filterCompany, forms, filterStatus, disabled, isPreview, projectId }: ListProps) => {
  const navigate = useNavigate();

  const orderedCompanyList = useMemo(() => {
    if (!filterStatus) return forms;
    return forms.filter((item) => item.status === filterStatus);
  }, [filterStatus]);

  const filteredCompanyList = useMemo(() => {
    if (!filterCompany.length) return orderedCompanyList;
    return orderedCompanyList.filter((item) => filterCompany.includes(item.company));
  }, [filterCompany, orderedCompanyList]);

  const formFilling = (item: any) => {
    if (disabled) return;
    if (isPreview) return navigate(`/advisor-view/${item.formAnswerId}/${projectId}`);
    navigate(`/reply-form/${item.formAnswerId}`);
  };

  return (
    <ListContainer>
      <ListWrapper>
        {filteredCompanyList.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <ListRow key={`ListData${item._id}${index}`}>
            <ListDataWrapper disabled={disabled} onClick={() => formFilling(item)}>
              <Title disabled={disabled}>{item.title}</Title>
              <BadgeWrapper>
                <DashboardBadge status={item.status}>{FORM_STATUS_LABEL[item.status]}</DashboardBadge>
                <CompanyBadge>{item.companyName}</CompanyBadge>
              </BadgeWrapper>
            </ListDataWrapper>
          </ListRow>
        ))}
      </ListWrapper>
    </ListContainer>
  );
};

export default ListCompanies;
