import React from 'react';

interface DownloadIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const DownloadIcon = ({ width = 12, height = 12, className }: DownloadIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M6.58366 4.83333H9.50033L6.00033 8.33333L2.50033 4.83333H5.41699V0.75H6.58366V4.83333ZM1.33366 10.0833H10.667V6H11.8337V10.6667C11.8337 10.8214 11.7722 10.9697 11.6628 11.0791C11.5534 11.1885 11.405 11.25 11.2503 11.25H0.750326C0.595616 11.25 0.447243 11.1885 0.337847 11.0791C0.22845 10.9697 0.166992 10.8214 0.166992 10.6667V6H1.33366V10.0833Z"
      fill="white"
    />
  </svg>
);

export default DownloadIcon;
