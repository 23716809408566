import styled from 'styled-components';

export const StyledFooter = styled.div`
  position: fixed;
  width: 100%;
  height: 88px;
  left: 0;
  bottom: 0;
  background: #fff;
  padding: 0 32px;
  box-sizing: border-box;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);
`;
