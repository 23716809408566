import styled from 'styled-components';
import { ChangeEvent } from 'react';
import Grid from '../../../../components/Grid';

interface StyledModalProps {
  onClose?: (event: ChangeEvent<HTMLButtonElement>) => any;
  onClick?: (event: ChangeEvent<HTMLButtonElement>) => any;
}

export const Container = styled(Grid)`
  display: flex;
  flex-direction: column;
  height: 60vh;
  width: 70vw;
  background: #fff;
  border-radius: 16px;
`;

export const Content = styled(Grid)`
  font-weight: 450;
  font-size: 14px;
  color: #262321;
  padding: 8px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 30px;
  }
  ::-webkit-scrollbar-thumb {
    background: #e3dfda;
    border-radius: 30px;
  }
`;

export const Footer = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
  align-items: flex-end;
  height: 88px;
  gap: 24px;
  border-radius: 0 0 16px 16px;
`;

export const ButtonApply = styled.button<StyledModalProps>`
  white-space: nowrap;
  display: flex;
  align-items: flex-start;
  padding: 12px 16px;
  gap: 10px;
  width: 132px;
  height: 40px;
  background: #476bc3;
  color: #fff;
  border-radius: 8px;
  border: none;
  cursor: pointer;
`;

export const Title = styled.div`
  width: 100%;
  height: 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #262321;
`;

export const OptionWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 12px;
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 16px;
`;

export const Label = styled.div`
  width: 100%;
  height: 16px;
  font-size: 14px;
  line-height: 16px;
  color: #393533;
`;

export const Options = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  margin-top: 26px;
  margin-bottom: 56px;
  margin-left: 20px;
`;
