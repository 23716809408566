import React from 'react';
import { FieldTitle, LongInput } from '../../style';

const LongText = ({ type, title, ...props }: any) => (
  <>
    <FieldTitle>{title}</FieldTitle>
    <LongInput type={type} {...props} />
  </>
);

export default LongText;
