import React from 'react';

interface UploadIconProps {
  width?: number;
  height?: number;
  fill: string;
}

const UploadIcon = ({ width = 14, height = 12, fill }: UploadIconProps) => (
  <svg width={width} height={height} viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.66671 10.6667H12.3334V6H13.6667V11.3333C13.6667 11.5101 13.5965 11.6797 13.4714 11.8047C13.3464 11.9298 13.1769 12 13 12H1.00004C0.82323 12 0.65366 11.9298 0.528636 11.8047C0.403612 11.6797 0.333374 11.5101 0.333374 11.3333V6H1.66671V10.6667ZM7.66671 4V8.66667H6.33337V4H3.00004L7.00004 0L11 4H7.66671Z"
      fill={fill}
    />
  </svg>
);

export default UploadIcon;
