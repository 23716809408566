import React from 'react';

export interface IconArrowUpProps {
  width?: number;
  height?: number;
}

export const IconArrowUp = ({ width = 12, height = 8 }: IconArrowUpProps) => (
  <svg width={width} height={height} viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.00011 3.02331L10.1251 7.14832L11.3034 5.96998L6.00011 0.666648L0.696777 5.96998L1.87511 7.14832L6.00011 3.02331Z"
      fill="#324FA5"
    />
  </svg>
);
