import React from 'react';

interface BlockFormIconProps {
  width?: number;
  height?: number;
  fill?: string;
}

const BlockFormIcon = ({ width = 16, height = 16, fill }: BlockFormIconProps) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3333 2.66668V1.33334H4.66667V2.66668H2.662C2.29667 2.66668 2 2.96334 2 3.32868V14.0047C2 14.37 2.29667 14.6667 2.662 14.6667H9.30278C8.92324 14.2835 8.61209 13.8324 8.38917 13.3333H3.33333V4.00001H4.66667V5.33334H11.3333V4.00001H12.6667V7.00304C13.1327 7.02 13.5806 7.10783 14 7.25607V3.32868C14 2.96334 13.7033 2.66668 13.338 2.66668H11.3333ZM6 12V10.6667H4.66667V12H6ZM6 10V8.66668H4.66667V10H6ZM6 8.00001V6.66668H4.66667V8.00001H6ZM6 2.66668H10V4.00001H6V2.66668Z"
      fill="#979797"
    />
    <path
      d="M12.3333 14.6667C11.4493 14.6667 10.6014 14.3155 9.97631 13.6904C9.35119 13.0652 9 12.2174 9 11.3333C9 10.4493 9.35119 9.60143 9.97631 8.97631C10.6014 8.35119 11.4493 8 12.3333 8C13.2174 8 14.0652 8.35119 14.6904 8.97631C15.3155 9.60143 15.6667 10.4493 15.6667 11.3333C15.6667 12.2174 15.3155 13.0652 14.6904 13.6904C14.0652 14.3155 13.2174 14.6667 12.3333 14.6667ZM11.4713 13.1387C11.8446 13.3173 12.2642 13.3757 12.672 13.3059C13.0799 13.236 13.4561 13.0414 13.7488 12.7488C14.0414 12.4561 14.236 12.0799 14.3059 11.672C14.3757 11.2641 14.3173 10.8446 14.1387 10.4713L11.4713 13.1387ZM10.528 12.1953L13.1953 9.52867C12.822 9.35006 12.4025 9.29164 11.9946 9.36148C11.5867 9.43131 11.2105 9.62596 10.9179 9.91858C10.6253 10.2112 10.4306 10.5874 10.3608 10.9953C10.291 11.4032 10.3494 11.822 10.528 12.1953Z"
      fill="#979797"
    />
  </svg>
);

export default BlockFormIcon;
