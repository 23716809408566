import styled from 'styled-components';
import Grid from '../../../components/Grid';

export const Container = styled(Grid).attrs({
  container: true,
})`
  padding: 0 40px;
  margin-top: 70px;
  margin-bottom: 140px;
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  gap: 24px;
`;
