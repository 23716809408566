import styled, { css } from 'styled-components';

export const StyledList = styled.div`
  display: flex;
  justify-content: end;
  gap: 40px;
  width: 100%;

  &:after {
    content: '';
    bottom: 1.5px;
    position: absolute;
    display: block;
    width: 100%;
    border-radius: 0.5px;
    z-index: -1;
  }

  @media (max-width: 600px) {
    margin-top: 15px;
    justify-content: flex-start;
  }
`;

interface ListProps {
  isActive?: boolean;
  disabled?: boolean;
}

export const StyledTabList = styled.div<ListProps>`
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.7;
    `}

  ${({ isActive }) =>
    isActive &&
    css`
      &:after {
        content: '';
        margin-top: 8px;
        display: block;
        width: 100%;
        border-top: 2px solid #476bc3;
        border-bottom: 2px solid #476bc3;
        border-radius: 2px;
      }
    `}
`;
