import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Title, Subtitle, StyledIconArrow, Label } from './style';

import { doValidate } from '../../../services/user.service';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import ErrorMessage from '../../../components/ErrorMessage';

interface ValidateProps {
  label?: any;
  className?: string;
  setForm?: any;
  providedPassword: string | undefined;
}

const Validate = ({ label, className, setForm, providedPassword }: ValidateProps) => {
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    try {
      setLoading(true);
      setError('');
      if (!providedPassword) return;
      const navigateSuccess = await doValidate(code, providedPassword);
      if (navigateSuccess) {
        setForm('success');
      }
    } catch (err: any) {
      setError(t('login.errorValidate'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form className={className}>
      <Title>
        {t('login.validateTitle')}
        <Subtitle>{t('login.validateSubtitle')}</Subtitle>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Title>
      <Label>{label}</Label>
      <Input
        error={error}
        label={t('login.labelValidate')}
        id="code"
        name="code"
        placeholder=""
        onChange={(event) => setCode(event.target.value)}
        value={code}
      />
      <Button primary full mt={20} onClick={handleSubmit} loading={loading}>
        {t('common.validateButton')}
        <StyledIconArrow />
      </Button>
    </Form>
  );
};

export default Validate;
