import styled from 'styled-components';
import React from 'react';

const TooltipWrapper = styled.div`
  position: relative;

  & > .tooltip-bubble {
    display: none;
  }
  &:hover > .tooltip-bubble {
    display: block;
  }
`;
const TooltipBubble = styled.div`
  position: absolute;
  padding: 6px;
  background: #c0d8f6;
  bottom: calc(100% + 13px);
  border-radius: 5px;
  width: fit-content;
  white-space: nowrap;
  right: 40%;
  font-size: 14px;

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;

    border-width: 0 14px 13px 0;
    border-color: transparent #c0d8f6 transparent transparent;
    bottom: -13px;
    left: 90%;
    margin-left: -10.5px;
  }
`;

export const Tooltip = ({ className, children, content, disabled = false }: any) => (
  <TooltipWrapper className={className}>
    {children}
    {!disabled && <TooltipBubble className="tooltip-bubble">{content}</TooltipBubble>}
  </TooltipWrapper>
);
