import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  BadgeProgress,
  BadgeSuccess,
  BadgeText,
  BadgeTextSuccess,
  BadgeWarning,
  Bar,
  BarProgress,
  BarSuccess,
  BarWarning,
  Box,
  Forms,
  FormsWrapper,
  InfosWrapper,
  LegendWrapper,
  ProgressBarLineContainer,
  Text,
  Title,
} from './style';

const TOTAL_PERCENT = 100;

interface FormsAmount {
  pending: number;
  inProgress: number;
  finished: number;
  total: number;
}

export const BoxLegend = ({ data }: { data: FormsAmount }) => {
  const pendingPercentage = Math.round((data.pending * TOTAL_PERCENT) / data.total);
  const inProgressPercentage = Math.round((data.inProgress * TOTAL_PERCENT) / data.total);
  const finishedPercentage = Math.round((data.finished * TOTAL_PERCENT) / data.total);

  const { t } = useTranslation();

  return (
    <Box>
      <LegendWrapper>
        <InfosWrapper>
          <Title>{t('dashboard.notStartedPlural')}</Title>
          <FormsWrapper>
            <Forms>{data.pending}</Forms>
            <Text>{t('common.forms')}</Text>
          </FormsWrapper>
        </InfosWrapper>
        <Bar>
          {!(data.pending === 0) && (
            <BadgeWarning percentage={pendingPercentage}>
              <BadgeText>{pendingPercentage}%</BadgeText>
            </BadgeWarning>
          )}
          <ProgressBarLineContainer>
            <BarWarning percentage={pendingPercentage} />
          </ProgressBarLineContainer>
        </Bar>
      </LegendWrapper>
      <LegendWrapper>
        <InfosWrapper>
          <Title>{t('dashboard.inProgress')}</Title>
          <FormsWrapper>
            <Forms>{data.inProgress}</Forms>
            <Text>{t('common.forms')}</Text>
          </FormsWrapper>
        </InfosWrapper>
        <Bar>
          {!(data.inProgress === 0) && (
            <BadgeProgress percentage={inProgressPercentage}>
              <BadgeText>{inProgressPercentage}%</BadgeText>
            </BadgeProgress>
          )}
          <ProgressBarLineContainer>
            <BarProgress percentage={inProgressPercentage} />
          </ProgressBarLineContainer>
        </Bar>
      </LegendWrapper>
      <LegendWrapper>
        <InfosWrapper>
          <Title>{t('dashboard.finishedPlural')}</Title>
          <FormsWrapper>
            <Forms>{data.finished}</Forms>
            <Text>{t('common.forms')}</Text>
          </FormsWrapper>
        </InfosWrapper>
        <Bar>
          {!(data.finished === 0) && (
            <BadgeSuccess percentage={finishedPercentage}>
              <BadgeTextSuccess>{finishedPercentage}%</BadgeTextSuccess>
            </BadgeSuccess>
          )}
          <ProgressBarLineContainer>
            <BarSuccess percentage={finishedPercentage} />
          </ProgressBarLineContainer>
        </Bar>
      </LegendWrapper>
    </Box>
  );
};
