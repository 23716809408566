import React from 'react';

interface DarkIconProps {
  width?: number;
  height?: number;
}

export const DarkIcon = ({ width = 24, height = 24 }: DarkIconProps) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.93028 2.00003C8.88028 2.00003 8.84028 2.00003 8.79028 2.00003C7.96028 2.02003 7.70028 3.12003 8.40028 3.56003C11.1803 5.33003 13.0303 8.45003 13.0303 12C13.0303 15.55 11.1903 18.66 8.41028 20.43C7.70028 20.89 7.98028 21.98 8.82028 22C8.89028 22 8.96028 22 9.03028 22C15.0803 22 19.8903 16.61 18.9003 10.37C18.1403 5.53003 13.8303 1.95003 8.93028 2.00003Z"
      fill="white"
    />
  </svg>
);
