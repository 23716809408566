import { FieldType } from '../../../pages/Form/types/FieldType';

const getQuestionsTree = (questions: any) => {
  if (!questions) {
    return [];
  }

  const roots: any[] = [];
  const childrenByParentId = {};

  questions.forEach((item: any) => {
    if (!item.childOf) {
      roots.push(item);
      return;
    }
    // @ts-ignore
    if (!childrenByParentId[item.childOf]) {
      // @ts-ignore
      childrenByParentId[item.childOf] = [];
    }
    // @ts-ignore
    childrenByParentId[item.childOf].push(item);
  });

  function buildNodes(nodes: any) {
    if (!nodes) {
      return null;
    }
    return nodes.map((node: any) => ({
      ...node,
      // @ts-ignore
      questions: buildNodes(childrenByParentId[node.wId]),
    }));
  }

  return buildNodes(roots);
};
const filterAnsweredQuestions = (question: any) => Boolean(question?.answered) || question.type === FieldType.PANEL;

const formatPdfQuestions = (questions: any) => getQuestionsTree(questions.filter(filterAnsweredQuestions));

export default formatPdfQuestions;
