import React, { ChangeEvent, useEffect, useState } from 'react';
import VMasker from 'vanilla-masker';
import Input from '../../../../../../components/Input';

interface MaskedInputProps {
  onChange: (value: string) => void;
  value: string;
  mask: string;
  label: string;
  disabled: boolean;
}

const MaskedInput = ({ disabled, onChange, value, mask, label, ...otherProps }: MaskedInputProps) => {
  const [localValue, setLocalValue] = useState(value);

  const formatMask = (valueToMask: string, maskFormat: string) => VMasker.toPattern(valueToMask, maskFormat);

  useEffect(() => {
    if (value === '') setLocalValue('');
  }, [value]);

  useEffect(() => {
    onChange(localValue);
  }, [localValue]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: currentValue } = event.target;

    setLocalValue(formatMask(currentValue, mask));
  };

  return <Input {...otherProps} disabled={disabled} label={label} value={localValue} onChange={handleChange} />;
};

export default MaskedInput;
