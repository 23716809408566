import styled, { css } from 'styled-components';

export const PaginationContainer = styled.ul`
  display: flex;
  list-style-type: none;
`;

interface PaginationItemProps {
  selected?: boolean;
  disabled?: boolean;
  arrowItem?: boolean;
}

export const PaginationItem = styled.li<PaginationItemProps>`
  height: 24px;
  text-align: center;
  color: #726c66;
  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  min-width: 24px;

  font-family: 'Circular20', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    cursor: pointer;
  }

  ${({ arrowItem }) =>
    arrowItem &&
    css`
      background: #e3dfda;
    `}

  ${({ arrowItem, disabled }) =>
    arrowItem &&
    disabled &&
    css`
      background: none;
    `}
  
  ${({ selected }) =>
    selected &&
    css`
      background: #d8e5f9;
      color: #20337d;
    `}
  
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;

      & > div::before {
        border-right: 0.12em solid rgba(0, 0, 0, 0.43);
        border-top: 0.12em solid rgba(0, 0, 0, 0.43);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    `}
`;

export const Dots = styled.li`
  &:hover {
    background-color: transparent;
    cursor: default;
  }
`;

interface ArrowProps {
  side: 'left' | 'right';
}

export const Arrow = styled.div<ArrowProps>`
  display: flex;
  justify-content: center;

  &::before {
    position: relative;
    content: '';
    display: inline-block;
    width: 0.4em;
    height: 0.4em;
    border-right: 0.12em solid rgba(0, 0, 0, 0.87);
    border-top: 0.12em solid rgba(0, 0, 0, 0.87);
    top: 1px;
    right: 1px;
  }

  ${({ side }) =>
    side === 'left' &&
    css`
      transform: rotate(-135deg);
    `}

  ${({ side }) =>
    side === 'right' &&
    css`
      transform: rotate(45deg);
    `}
`;
