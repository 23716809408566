import styled from 'styled-components';
import { BREAKPOINTS } from '../../constants/screen';

export const ModalOverlay = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContainer = styled.div`
  position: relative;
  background: #ffffff;
  box-shadow: 0 2px 18px rgba(38, 35, 33, 0.16), 0 24px 32px rgba(38, 35, 33, 0.32);
  border-radius: 16px;
  padding: 24px;
  max-height: 800px;
  max-width: 1400px;

  @media (max-width: ${BREAKPOINTS.md}px) {
    max-height: none;
    height: 100vh;
  }

  @media (max-width: ${BREAKPOINTS.sm}px) {
    height: 100vh;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ModalTitle = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  margin: 8px;
  line-height: 32px;
  letter-spacing: -1px;
  color: #262321;
`;

export const CloseIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #e3dfda;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media (max-width: ${BREAKPOINTS.md}px) {
    display: none;
  }
`;

export const ModalContent = styled.div``;
