import React from 'react';

export interface IconArrowProps {
  width?: number;
  height?: number;
  className?: string;
}

export const IconArrow = ({ width = 16, height = 16, className }: IconArrowProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 8.5925H12.17L7.29 13.4725C6.9 13.8625 6.9 14.5025 7.29 14.8925C7.68 15.2825 8.31 15.2825 8.7 14.8925L15.29 8.3025C15.68 7.9125 15.68 7.2825 15.29 6.8925L8.71 0.2925C8.32 -0.0975 7.69 -0.0975 7.3 0.2925C6.91 0.6825 6.91 1.3125 7.3 1.7025L12.17 6.5925H1C0.45 6.5925 0 7.0425 0 7.5925C0 8.1425 0.45 8.5925 1 8.5925Z"
      fill="white"
    />
  </svg>
);
