import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  page: {
    paddingTop: 100,
    paddingBottom: 60,
    fontStyle: 'normal',
  },
  image: {
    width: 80,
    height: 44,
    marginLeft: 20,
  },
  pageNumber: {
    position: 'absolute',
    width: 45,
    left: 545,
    top: 38,
    fontWeight: 450,
    fontSize: 7,
    color: '#393533',
  },
  lines: {
    position: 'absolute',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    position: 'absolute',
    left: -12,
    top: 20,
    marginBottom: 20,
  },
  title: {
    font: 'bold',
    marginLeft: 10,
    fontWeight: 700,
    fontSize: 12,
    color: '#393533',
  },
  subtitle: {
    font: 'bold',
    marginTop: 10,
    fontWeight: 700,
    fontSize: 14,
    marginLeft: 20,
    color: '#262321',
  },
  companyData: {
    display: 'flex',
    flexDirection: 'row',
    padding: 15,
    marginLeft: 5,
  },
  badgeCompany: {
    font: 'bold',
    fontWeight: 700,
    paddingHorizontal: 8,
    paddingVertical: 4,
    fontSize: 10,
    borderRadius: 4,
    backgroundColor: '#725DF1',
    color: '#fff',
    marginRight: 10,
  },
  badge: {
    font: 'bold',
    fontWeight: 700,
    paddingHorizontal: 8,
    paddingVertical: 4,
    fontSize: 10,
    borderRadius: 4,
    backgroundColor: '#F56A00',
    color: '#fff',
    marginRight: 10,
  },
  body: {
    margin: 20,
  },
  copyright: {
    fontWeight: 450,
    fontSize: 7,
    marginLeft: 20,
    color: '#262321',
  },
  user: {
    fontWeight: 450,
    fontSize: 7,
    color: '#577ef4',
  },
  date: {
    fontWeight: 450,
    marginLeft: 10,
    fontSize: 7,
  },
  svgY: {
    position: 'absolute',
    top: 60,
    right: 5,
  },
  svgX: {
    position: 'absolute',
    top: 40,
    right: 15,
  },
  item_wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    marginBottom: 16,
    justifyContent: 'space-between',
  },
  infos: {
    right: 30,
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'space-between',
  },
  footer: {
    position: 'absolute',
    paddingTop: 10,
    left: 0,
    bottom: 0,
  },
  questionsWrapper: {
    marginBottom: 10,
    padding: 24,
    borderRadius: 16,
    border: '1px solid #D8D8D8',
  },
});
