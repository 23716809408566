import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Container, Footer } from './style';
import Button from '../Button';
import { Creators as modalActions } from '../../redux/reducers/modal-reducer';

interface DeleteModalProps {
  onSubmit: () => Promise<void> | void;
  title: string;
  submitButtonTitle: string;
  otherButtons?: { primary?: boolean; title: string; onClick: () => Promise<void> | void }[];
}

const DefaultModalContent = ({ onSubmit, title, submitButtonTitle, otherButtons }: DeleteModalProps) => {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const { closeModal } = modalActions;
  const { t } = useTranslation();
  const onClose = () => dispatch(closeModal());
  const onDelete = async () => {
    if (submitting) return;
    try {
      setSubmitting(true);
      await onSubmit();
      onClose();
    } catch (err: any) {
      console.log(err);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Container>
      <p style={{ textAlign: 'center' }}>{title}</p>
      <Footer>
        {otherButtons ? (
          otherButtons.map((button) => (
            <Button
              primary={button.primary}
              onClick={() => {
                button.onClick();
                onClose();
              }}
            >
              {button.title}
            </Button>
          ))
        ) : (
          <Button onClick={onClose}>{t('common.back')}</Button>
        )}
        <Button onClick={onDelete} primary loading={submitting}>
          {submitButtonTitle}
        </Button>
      </Footer>
    </Container>
  );
};

export default DefaultModalContent;
