import React from 'react';
import Input from '../../../../Input';
import { FieldTitle } from '../../style';

const InputField = ({ type, title, ...props }: any) => (
  <>
    <FieldTitle>{title}</FieldTitle>
    <Input type={type} {...props} />
  </>
);

export default InputField;
