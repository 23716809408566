import styled from 'styled-components';
import { disabledOrDefault } from '../../../../../../utils/a';

export const ChoicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

export const StyledChoice = styled.div<{ disabled?: boolean }>`
  display: flex;
  gap: 8px;
  color: ${disabledOrDefault('unset')};
`;
