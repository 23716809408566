import i18n from '../../config/i18n/config';

export enum CustomQuestionType {
  SINGLE_CHOICE = 'SINGLE_CHOICE',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  VALIDATION = 'VALIDATION',
}

export const customQuestionTypesWithLabel = () => ({
  [CustomQuestionType.SINGLE_CHOICE]: {
    value: CustomQuestionType.SINGLE_CHOICE,
    label: i18n.t('utilsForm.SINGLE_CHOICE'),
  },
  [CustomQuestionType.MULTIPLE_CHOICE]: {
    value: CustomQuestionType.MULTIPLE_CHOICE,
    label: i18n.t('utilsForm.MULTIPLE_CHOICE'),
  },
  [CustomQuestionType.VALIDATION]: {
    value: CustomQuestionType.VALIDATION,
    label: i18n.t('questions.VALIDATION'),
  },
});
