import styled from 'styled-components';

export const PanelAccordion = styled.div`
  padding: 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #d8d8d8;
  border-radius: 16px;
  gap: 50px;
`;

export const PanelAccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  cursor: pointer;

  padding-bottom: 18px;
  border-bottom: 1px solid #e3dfda;
`;

export const PanelAccordionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
`;
