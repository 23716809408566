import React from 'react';

export interface EmailPermissionsContextProps {
  editState?: any;
  dispatchEdit?: any;
  createState?: any;
  dispatchCreate?: any;
}

const EmailPermissionsContext = React.createContext<EmailPermissionsContextProps>({});

export default EmailPermissionsContext;
