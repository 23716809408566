/* eslint-disable eqeqeq */
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import Footer from '../../components/Form/Footer';
import Button from '../../components/Button';
import {
  ActionsWrapper,
  ButtonDelete,
  CompanyBadge,
  Container,
  ContainerQuestions,
  Deadline,
  FooterWrapper,
  Header,
  HeaderInfos,
  HeaderTitle,
  ModalWrapper,
  NavTabs,
  PlusButton,
  ResponsiveActionButton,
  ResponsiveActionMenu,
  ResponsiveActions,
  ResponsiveButtonWrapper,
  ResponsiveMenuButton,
} from './style';
import ProgressBar from './components/ProgressBar';
import DefaultReplyForm from './components/DefaultForm';
import ReplyFormContext from './context';
import { DropdownIcon } from '../../assets/svgs/DropdownIcon';
import BranchForm from './components/BranchForm';
import Alert from '../../components/Toast/toast';
import DefaultModalContent from '../../components/DefaultModalContent';
import { Creators as modalActions } from '../../redux/reducers/modal-reducer';
import { findReplyFormById, saveDraftReplyForm, saveReplyForm, testDraft } from '../../requests/answers';
import parseTabRules, { parseQuestions, parseReceivedData } from './parseReceivedData';
import { LoaderWrapper } from '../../components/Loader/style';
import Loader from '../../components/Loader';
import { parseSubmitData } from './parseSubmitData';
import RepeatingForm from './components/RepeatingForm';
import SaveDraftIcon from '../../assets/svgs/SaveDraftFormIcon';
import SendFormIcon from '../../assets/svgs/SendForm';
import { FormStatus } from '../Dashboard/constants';
import { IconExit } from '../../assets/svgs/IconExit';
import { FormTestContext } from '../Form/FormTest/context';
import { publishForm } from '../../requests/forms';
import { findAllAllowListRequest, findAllBlockListRequest } from '../../requests/country';
import { Breadcrumbs } from '../../components/BreadCrumb';
import { BreadcrumbWrapper } from '../../components/BreadCrumb/style';
import { Creators as blockPageActions } from '../../redux/reducers/blockPageReducer';
import { Tab, TabEmpty, TabPanel, Tabs } from '../../components/Tabs';
import PlusIcon from '../../assets/svgs/plus.svg';
import TrashIcon from '../../assets/svgs/trash.svg';

export interface AnswersData {
  answers: any;

  [index: string]: any;
}

type QueryParams = {
  id: string;
};

const DesktopActions = ({ returnHandler, saveDraftHandler, sendFormHandler, handleSubmit, validateFormDraft }: any) => {
  const { formDisabled } = useContext(ReplyFormContext);
  const { testForm } = useContext(FormTestContext);

  const { t } = useTranslation();

  if (testForm) {
    return (
      <>
        <Button onClick={returnHandler}>{t('common.back')}</Button>
        <Button primary disabled={formDisabled} onClick={handleSubmit(validateFormDraft)}>
          {t('common.validateButton')}
        </Button>
      </>
    );
  }
  return (
    <>
      <Button onClick={returnHandler}>{t('common.cancel')}</Button>
      <Button disabled={formDisabled} onClick={saveDraftHandler}>
        {t('questions.saveDraft')}
      </Button>
      <Button primary disabled={formDisabled} onClick={handleSubmit(sendFormHandler)}>
        {t('questions.sentForm')}
      </Button>
    </>
  );
};

const ReplyForm = ({
  isAdvisorView = false,
  paramsId,
  projectId,
}: {
  isAdvisorView?: boolean;
  paramsId?: string;
  projectId?: string;
}) => {
  const { testForm, setTesting } = useContext(FormTestContext);
  const [loading, setLoading] = useState<any>(null);
  const [data, setData] = useState<any>(null);
  const [blockListEmail, setBlockListEmail] = useState<any[]>([]);
  const [allowListEmail, setAllowListEmail] = useState<any[]>([]);
  const [responsiveMenu, setResponsiveMenu] = useState(false);
  const [entries, setEntries] = useState<any[]>();
  const { id } = useParams<QueryParams>();
  const hiddenFieldsRefs = useRef({});
  const hiddenFieldsRefsMulti = useRef([{}]);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { blockPage, unblockPage } = blockPageActions;
  const blockPageHandler = () => dispatch(blockPage());
  const unblockPageHandler = () => dispatch(unblockPage());

  const {
    formState: { errors },
    handleSubmit,
    control,
    register,
    setValue,
    watch,
    getValues,
    setError,
    reset,
  } = useForm<AnswersData>({
    defaultValues: {
      answers: [],
    },
    mode: 'onChange',
  });
  const formTypeIsBranch = data?.formType === 'BRANCH';
  const formTypeRepeating = data?.repeating === true;
  const formDisabled = data?.status === FormStatus.FINISHED || isAdvisorView;

  const removeEntry = useCallback(
    (entry: number) => {
      const values = getValues();
      if (!values?.entries) return;
      const persistedEntries = values.entries.filter((toNotRemove: any) => toNotRemove.entry !== entry);
      setValue('entries', persistedEntries);
      setEntries((entriesOld) => entriesOld?.filter((toNotRemove) => toNotRemove.entry !== entry));
    },
    [getValues]
  );
  const addEntry = (formData: any, scroll = true) => {
    if (formDisabled) return;
    const toClone = JSON.stringify(formData.questions);
    const cloned = JSON.parse(toClone);
    const uuid = uuidv4();
    const { entries: oldEntries, ...oldValues } = getValues();
    const parsed = parseQuestions(cloned);
    reset({
      ...oldValues,
      entries: [...(oldEntries ?? []), { entry: uuid, answers: parsed }],
    });
    setEntries((oldValue) => [...(oldValue ?? []), { entry: uuid, questions: cloned }]);
    if (scroll) {
      setTimeout(() => {
        const doc = document?.getElementById(`entry-div-${uuid}`);
        if (doc) doc?.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }
  };
  const fetchFormDataById = async () => {
    setLoading(true);

    const [blockListEmailData, allowListEmailData] = await Promise.all<any[]>([
      findAllBlockListRequest(),
      findAllAllowListRequest(),
    ]);

    if (blockListEmailData) {
      setBlockListEmail(blockListEmailData);
    }

    if (allowListEmailData) {
      setAllowListEmail(allowListEmailData);
    }

    if (testForm) {
      setData(testForm);
      if (testForm.multipleForm === '1') {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        initDraftTabs(testForm.questions.length);
      }
      return;
    }

    const receivedData = await findReplyFormById(paramsId ?? id, projectId);

    if (receivedData) {
      setData(receivedData);
      if (receivedData.multipleForm) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        initDraftTabs(receivedData.questions.length);
      }
    }
  };

  useEffect(() => {
    fetchFormDataById();
  }, []);

  useEffect(() => {
    if (data) {
      reset(parseReceivedData(data));

      if (data.repeating === true)
        if (!data.entries || data.entries.length < 1) {
          addEntry(data);
        } else setEntries(data.entries ?? []);
      setLoading(false);
    }
  }, [data]);

  const { openModal } = modalActions;

  const alertUserFromLeaving = () => {
    function confirmExit() {
      return t('form.confirmExit');
    }
    window.onbeforeunload = confirmExit;

    // eslint-disable-next-line no-restricted-globals
    history.pushState(null, 'null', null);

    function confirmBack(event: PopStateEvent) {
      event.preventDefault();
      // eslint-disable-next-line no-restricted-globals,no-alert
      const r = confirm(t('form.confirmExit'));
      if (r) {
        // Call Back button programmatically as per user confirmation.
        // eslint-disable-next-line no-restricted-globals
        history.back();
        // Uncomment below line to redirect to the previous page instead.
        // window.location = document.referrer // Note: IE11 is not supporting this.
      } else {
        // Stay on the current page.
        // eslint-disable-next-line no-restricted-globals
        history.pushState(null, 'null', window.location.pathname);
      }
    }

    window.onpopstate = confirmBack;

    blockPageHandler();
  };

  useEffect(() => {
    alertUserFromLeaving();
  }, []);

  useEffect(
    () => () => {
      window.onbeforeunload = null;
      window.onpopstate = null;
      unblockPageHandler();
    },
    []
  );

  const context = useMemo(
    () => ({
      data,
      control,
      register,
      setValue,
      errors,
      setError,
      watch,
      hiddenFieldsRefs,
      hiddenFieldsRefsMulti,
      removeEntry,
      formDisabled,
      allowListEmail,
      blockListEmail,
      getValues,
    }),
    [
      data,
      control,
      register,
      setValue,
      errors,
      setError,
      watch,
      hiddenFieldsRefs,
      hiddenFieldsRefsMulti,
      removeEntry,
      formDisabled,
      allowListEmail,
      blockListEmail,
    ]
  );

  const navigate = useNavigate();
  const location = useLocation();
  const publish = async () => {
    try {
      await publishForm(id);
      Alert.SUCCESS(t('form.formPublish'));
      navigate('/forms');
    } catch (e: any) {
      Alert.ERROR(e?.response?.data?.message);
    }
  };
  const validateFormDraft = async (submitData: any) => {
    try {
      await testDraft(
        {
          ...parseSubmitData(submitData, data),
          rulesDependenciesAnswers: testForm.rulesDependenciesAnswers,
        },
        id
      );
      dispatch(
        openModal({
          content: (
            <DefaultModalContent
              onSubmit={publish}
              title={t('form.successfullyValidate')}
              otherButtons={[
                {
                  title: t('form.backToEdit'),
                  onClick: () => {
                    setTesting(false);
                    return navigate(location.pathname.replace('/forms/test', '/forms/edit'));
                  },
                },
                {
                  title: t('form.continue'),
                  onClick: () => {},
                },
              ]}
              submitButtonTitle={t('form.publishForm')}
            />
          ),
        })
      );
    } catch (err: any) {
      Alert.ERROR(err.response.data.message);
    }
  };

  const sendFormHandler = async (submitData: any) => {
    if (context.formDisabled) return;
    const sendFormSubmit = async () => {
      try {
        await saveReplyForm(parseSubmitData(submitData, data), id);
        Alert.SUCCESS(t('questions.sent'));
        navigate('/home');
        fetchFormDataById();
      } catch (e: any) {
        Alert.ERROR(e?.response?.data?.message);
      }
    };

    dispatch(
      openModal({
        content: (
          <ModalWrapper>
            <DefaultModalContent
              onSubmit={sendFormSubmit}
              title={t('questions.confirmSent')}
              submitButtonTitle={t('questions.submit')}
            />
          </ModalWrapper>
        ),
      })
    );
  };

  const returnHandler = () => {
    if (testForm) {
      setTesting(false);
      return;
    }

    // eslint-disable-next-line no-restricted-globals, no-alert
    const response = confirm(t('form.confirmExit'));
    if (!response) return;

    navigate('/home');
  };

  const saveDraftHandler = async () => {
    if (context.formDisabled) return;
    const submitData = getValues();

    const saveDraftSubmit = async () => {
      if (context.formDisabled) return;
      try {
        await saveDraftReplyForm(parseSubmitData(submitData, data), id);
        Alert.SUCCESS(t('questions.draft'));
        navigate('/home');
      } catch (e: any) {
        Alert.ERROR(e?.response?.data?.message);
      }
    };

    dispatch(
      openModal({
        content: (
          <ModalWrapper>
            <DefaultModalContent
              onSubmit={saveDraftSubmit}
              title={t('questions.confirmDraft')}
              submitButtonTitle={t('common.save')}
            />
          </ModalWrapper>
        ),
      })
    );
  };

  const toggleResponsiveMenu = () => {
    setResponsiveMenu(!responsiveMenu);
  };

  const formatDeadline = (deadlineString: string) => {
    const MILLISECONDS_IN_ONE_DAY = 86400000;

    const deadline = new Date(deadlineString);
    const today = new Date();

    if (deadline < today) return 0;

    // @ts-ignore
    return Math.round(Math.abs((today - deadline) / MILLISECONDS_IN_ONE_DAY));
  };

  const [searchParams] = useSearchParams();

  const [selectedTab, setSelectedTab] = useState(Number(searchParams.get('selectedTab')) || 0);

  const handleChangeTabs = (event: MouseEvent, newSelectedTab: number) => {
    hiddenFieldsRefsMulti.current.push({});
    setSelectedTab(newSelectedTab);
  };

  const [qtdTabs, setQtdTabs] = useState<any[]>([
    {
      idTab: 0,
      status: true,
    },
  ]);

  const initDraftTabs = (tabAmount: number) => {
    const tabs: any[] = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < tabAmount; i++) {
      tabs.push({
        idTab: i,
        status: true,
      });
    }
    setQtdTabs(tabs);
  };

  const handleAddTab = () => {
    qtdTabs.push({
      idTab: qtdTabs.length,
      status: true,
    });
    setValue('answers', [...getValues().answers, getValues().answers[0]]);
    reset({
      ...getValues(),
      ...parseTabRules(getValues().answers, data, qtdTabs.length - 1),
    });
    hiddenFieldsRefsMulti.current.push({});
    setSelectedTab(selectedTab + 1);
  };

  const qtdTabsTrue = Object.values(qtdTabs).filter((tab) => tab.status === true).length;

  const handleDeleteTab = async (idTab: number) => {
    const handleDeleteTabSubmit = async () => {
      if (data.questions[idTab]) {
        if (data.questions.length > 1) {
          setData({
            ...data,
            questions: data.questions.filter((tab: any) => tab !== data.questions[idTab]),
          });
        } else {
          const newTab = getValues().answers[idTab + 1].map((question: any, index: any) => ({
            ...data.questions[idTab][index],
            answer: question?.answer || '',
            answered: question?.answered || false,
          }));
          setData({
            ...data,
            questions: [...data.questions.filter((tab: any) => tab !== data.questions[idTab]), newTab],
          });
        }
      }

      setValue(
        'answers',
        getValues().answers.filter((el: any) => el !== getValues().answers[idTab])
      );

      setSelectedTab(qtdTabsTrue - 2);
      setQtdTabs(qtdTabs.filter((tab: any) => tab !== qtdTabs[idTab]));

      hiddenFieldsRefsMulti.current.splice(idTab, 1);
    };

    const message = t('form.deleteSelectedTab');

    dispatch(
      openModal({
        content: (
          <DefaultModalContent
            onSubmit={handleDeleteTabSubmit}
            title={message}
            submitButtonTitle={t('form.deleteTab')}
          />
        ),
      })
    );
  };

  return loading ? (
    <LoaderWrapper>
      <Loader text={t('form.search')} />
    </LoaderWrapper>
  ) : (
    <ReplyFormContext.Provider value={context}>
      <ResponsiveActionMenu open={responsiveMenu}>
        <ResponsiveButtonWrapper onClick={() => setResponsiveMenu(false)}>
          <DropdownIcon className="arrow" />
          <ResponsiveMenuButton>{t('common.back')}</ResponsiveMenuButton>
        </ResponsiveButtonWrapper>
        <ResponsiveButtonWrapper onClick={returnHandler}>
          <IconExit />
          <ResponsiveMenuButton>{t('common.cancel')}</ResponsiveMenuButton>
        </ResponsiveButtonWrapper>
        <ResponsiveButtonWrapper
          disabled={context.formDisabled}
          onClick={(e) => {
            setResponsiveMenu(false);
            handleSubmit(saveDraftHandler)(e);
          }}
        >
          <SaveDraftIcon />
          <ResponsiveMenuButton>{t('questions.saveDraft')}</ResponsiveMenuButton>
        </ResponsiveButtonWrapper>
        <ResponsiveButtonWrapper
          disabled={context.formDisabled}
          onClick={(e) => {
            setResponsiveMenu(false);
            handleSubmit(sendFormHandler)(e);
          }}
        >
          <SendFormIcon />
          <ResponsiveMenuButton>{t('questions.sentForm')}</ResponsiveMenuButton>
        </ResponsiveButtonWrapper>
      </ResponsiveActionMenu>
      <Container>
        <Header>
          <BreadcrumbWrapper>
            <Breadcrumbs />
          </BreadcrumbWrapper>
          <HeaderInfos>
            <HeaderTitle>{data?.title}</HeaderTitle>
            {data?.companyName && <CompanyBadge>{data.companyName}</CompanyBadge>}
          </HeaderInfos>
          <Deadline>
            {formatDeadline(data?.deadline)} {t('questions.days')}
          </Deadline>

          <NavTabs>
            <Tabs selectedTab={selectedTab} onChange={handleChangeTabs}>
              {qtdTabs.map((tab, index) =>
                data?.multipleForm == 1 && tab.status == true ? (
                  <Tab key={tab.idTab} label={`${data?.title} (${index + 1}) `} />
                ) : (
                  <TabEmpty />
                )
              )}
            </Tabs>
            {data?.multipleForm == 1 && data?.status !== 'FINISHED' && (
              <PlusButton onClick={handleAddTab}>
                <img src={PlusIcon} alt={t('form.addTabs')} title={t('form.addTabs')} />
              </PlusButton>
            )}
          </NavTabs>
          {data?.multipleForm == 1 && data?.status !== 'FINISHED' && qtdTabsTrue > 1 && selectedTab != 0 && (
            <ButtonDelete onClick={() => handleDeleteTab(selectedTab)}>
              <img src={TrashIcon} alt={t('form.deleteTabs')} title={t('form.deleteTabs')} />
            </ButtonDelete>
          )}
        </Header>
        <ContainerQuestions>
          {qtdTabs.map(
            (tab, index) =>
              tab.status && (
                // eslint-disable-next-line react/no-array-index-key
                <TabPanel key={index} selectedTab={selectedTab} index={index}>
                  {data &&
                    // eslint-disable-next-line no-nested-ternary
                    (formTypeIsBranch ? (
                      <BranchForm data={data} />
                    ) : formTypeRepeating ? (
                      <RepeatingForm entries={entries ?? []} />
                    ) : (
                      <DefaultReplyForm data={data} dataQuestions={data.questions[index]} tabId={index} />
                    ))}
                  {formTypeRepeating && (
                    <Button mt={15} onClick={() => addEntry(data, true)} disabled={context.formDisabled}>
                      {t('questions.addEntry')}
                    </Button>
                  )}
                </TabPanel>
              )
          )}
        </ContainerQuestions>

        {!isAdvisorView ? (
          <Footer>
            <FooterWrapper>
              <ProgressBar />
              <ActionsWrapper>
                <DesktopActions
                  {...{
                    returnHandler,
                    saveDraftHandler,
                    sendFormHandler,
                    handleSubmit,
                    validateFormDraft,
                  }}
                />
              </ActionsWrapper>
              <ResponsiveActions>
                <ResponsiveActionButton onClick={toggleResponsiveMenu}>
                  {t('table.actions')}
                  <DropdownIcon className="arrow" />
                </ResponsiveActionButton>
              </ResponsiveActions>
            </FooterWrapper>
          </Footer>
        ) : (
          <Footer>
            <FooterWrapper>{t('form.previewMode')}</FooterWrapper>
          </Footer>
        )}
      </Container>
    </ReplyFormContext.Provider>
  );
};

export default ReplyForm;
