import React from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CreateIcon } from '../../../../assets/svgs/CreateIcon';
import { DeleteIcon } from '../../../../assets/svgs/DeleteIcon';
import { Breadcrumbs } from '../../../../components/BreadCrumb';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import TextButton from '../../../../components/TextButton';
import Alert from '../../../../components/Toast/toast';
import { addServiceOfferRequest } from '../../../../requests/serviceOffer';
import { ButtonWrapper, Display, Footer, FormWrapper, InputWrapper, Title } from '../style';
import { BreadcrumbWrapper } from '../../../../components/BreadCrumb/style';

export function CreateServiceOfferSettings() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const returnHandler = () => {
    navigate('/settings/forms?selectedTab=3');
  };

  const createHandler = async (data: any) => {
    try {
      await addServiceOfferRequest(data.serviceOffers);
      Alert.SUCCESS(t('createService.service'));
      returnHandler();
    } catch (error: any) {
      const { message } = error.response.data;
      Alert.ERROR(message);
    }
  };

  const { control, handleSubmit } = useForm({
    defaultValues: {
      serviceOffers: [{ name: '' }],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'serviceOffers',
  });

  const onSubmit = (data: any) => {
    createHandler(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormWrapper>
        <BreadcrumbWrapper>
          <Breadcrumbs />
        </BreadcrumbWrapper>
        <Title>{t('createService.title')}</Title>
        <Display>
          {fields.map((item, index) => (
            <InputWrapper key={item.id}>
              <Controller
                name={`serviceOffers.${index}.name`}
                control={control}
                rules={{
                  required: t<string>('createService.required'),
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    name={`serviceOffers[${index}].name]`}
                    label={t('createService.label')}
                    placeholder={t('common.type')}
                  />
                )}
              />
              <ButtonWrapper onClick={() => remove(index)}>
                <DeleteIcon fill="#324fa5" />
              </ButtonWrapper>
            </InputWrapper>
          ))}
          <Footer>
            <TextButton
              onClick={() => {
                append({ name: '' });
              }}
            >
              <p>{t('createService.newService')}</p>
              <CreateIcon />
            </TextButton>
            <Button onClick={() => returnHandler()}>{t('common.back')}</Button>
            <Button type="submit" primary>
              {t('common.save')}
            </Button>
          </Footer>
        </Display>
      </FormWrapper>
    </form>
  );
}
