import styled, { css } from 'styled-components';
import Grid from '../../components/Grid';
import { MAX_WIDTHS } from '../../constants/screen';

export const Container = styled(Grid).attrs({
  container: true,
})`
  flex-direction: column;
  padding: 0 40px;
  margin: 80px 0;

  @media (max-width: ${MAX_WIDTHS.sm}px) {
    padding: 7%;
  } ;
`;

export const Title = styled.h1`
  font-family: 'Circular20', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0;
  text-align: left;
  margin-bottom: 36px;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: right;
  width: 100%;
  gap: 16px;
`;

export const StyledForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  box-sizing: border-box;
  padding: 26px;
  background: #ffffff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
`;

interface ValidateMessageProps {
  success?: boolean;
  error?: boolean;
}

export const ValidateMessage = styled.div<ValidateMessageProps>`
  margin-top: 6px;
  display: flex;
  gap: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  ${({ success }) =>
    success &&
    css`
      color: #4fba4b;
    `}

  ${({ error }) =>
    error &&
    css`
      color: #9c2514;
    `}
`;
