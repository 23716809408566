import styled, { css } from 'styled-components';
import Grid from '../../components/Grid';
import Button from '../../components/Button';
import { disabledOrDefault } from '../../utils/a';
import { BREAKPOINTS } from '../../constants/screen';

export const Container = styled(Grid).attrs({
  container: true,
})`
  padding: 0 40px;
  margin-top: 56px;
  margin-bottom: 140px;
`;

export const ContainerQuestions = styled.div`
  margin-top: 220px;
  width: 100%;
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  gap: 24px;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  gap: 24px;

  @media (max-width: 950px) {
    display: none;
  } ;
`;

export const ResponsiveActions = styled.div`
  display: none;

  @media (max-width: 950px) {
    display: block;
  } ;
`;

export const ResponsiveButtonWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
  color: ${disabledOrDefault('#000')};
  svg path {
    fill: ${disabledOrDefault('#000')};
  }

  &:hover {
    background-color: #f2f5ff;
    color: ${disabledOrDefault('#324fa5')};

    svg path {
      fill: ${disabledOrDefault('#324fa5')};
    }
  }

  .arrow {
    transform: rotate(90deg);
  }
`;

export const ResponsiveActionButton = styled(Button).attrs({
  primary: true,
})`
  display: flex;
  align-items: center;
  gap: 15px;

  .arrow {
    transform: rotate(180deg);
    margin-left: 10px;
    & > path {
      fill: #fff;
    }
  }
`;

interface ResponsiveMenuProps {
  open: boolean;
}

export const ResponsiveActionMenu = styled.div<ResponsiveMenuProps>`
  position: fixed;
  overflow: hidden;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: none;
  background-color: #fff;

  ${({ open }) =>
    open &&
    css`
      display: flex;
      flex-direction: column;
    `}
`;

export const ResponsiveMenuButton = styled.button.attrs({
  type: 'button',
})`
  background: none;
  outline: none;
  border: none;

  display: flex;
  align-items: center;
  text-decoration: none;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: inherit;
`;

export const Deadline = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #476bc3;
  margin-bottom: 20px;
`;

export const Header = styled.div`
  position: fixed;
  z-index: 10;
  width: 100%;
  box-sizing: border-box;
  height: 120px;
  left: 0;
  top: 56px;
  background: #fff;
  padding: 16px 40px 40px 32px;
`;

export const HeaderInfos = styled.div`
  display: flex;
  gap: 16px;
`;

export const HeaderTitle = styled.h1`
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  color: #262321;
`;

export const ModalWrapper = styled.div`
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const CompanyBadge = styled.div`
  padding: 4px 8px;
  border-radius: 4px;
  text-align: center;
  background: #725df1;
  display: flex;
  align-items: center;
  gap: 10px;

  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #fff;
`;

export const PlusButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #f2f5ff;
  cursor: pointer;
  position: relative;
  bottom: 25px;
  right: 5px;
`;

export const NavTabs = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  background: #fff;
  border-bottom: 1px solid #e5e5e5;
`;

export const ButtonDelete = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  width: 100%;
  margin-top: 0px;
  background: #fff;
  padding-top: 20px;
  text-align: right;
  padding-bottom: 10px;

  img {
    width: 20px;
    height: 20px;
  }
`;
