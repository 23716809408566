import api from '../http/api';
import { TableData } from '../types/TableData';

const SERVICE_OFFER_PATH = '/service-offers';

export const findAllServiceOfferRequest = async () => {
  const { data } = await api.get(SERVICE_OFFER_PATH);
  return data;
};

export const findServiceOfferByIdRequest = async (id: number | string) => {
  const { data } = await api.get(`${SERVICE_OFFER_PATH}/${id}`);
  return data;
};

export const addServiceOfferRequest = async (data: TableData) => {
  await api.post(SERVICE_OFFER_PATH, data);
};

export const updateServiceOfferRequest = async (data: TableData, id: string) => {
  await api.put(`${SERVICE_OFFER_PATH}/${id}`, data);
};

export const deleteServiceOfferRequest = async (data: TableData[]) => {
  await api.patch(SERVICE_OFFER_PATH, data);
};
