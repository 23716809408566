import React, { Children, cloneElement, ReactElement } from 'react';
import { StyledTabList, StyledList } from './style';

interface ListProps {
  selectedTabList: number;
  children: ReactElement | ReactElement[];
  onChange: any;
}

interface TabListProps {
  label: string;
  onClick?: any;
  isActive?: boolean;
  disabled?: boolean;
}

interface ListPanelProps {
  selectedTabList: number;
  index: number;
  children: ReactElement | ReactElement[];
}

export const TabList = ({ disabled, label, onClick, isActive }: TabListProps) => (
  <StyledTabList disabled={disabled} isActive={isActive} onClick={onClick}>
    {label}
  </StyledTabList>
);

export const Lists = ({ selectedTabList, onChange, children }: ListProps) => {
  const handleTabListClick = (event: MouseEvent, index: number) => {
    onChange(event, index);
  };

  return (
    <StyledList>
      {Children.map(children, (child, index) =>
        cloneElement(child, {
          onClick: (e: MouseEvent) => handleTabListClick(e, index),
          listIndex: index,
          isActive: index === selectedTabList,
        })
      )}
    </StyledList>
  );
};

export const ListPanel = ({ children, selectedTabList, index }: ListPanelProps) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>{selectedTabList === index && children}</>
);
