import styled from 'styled-components';

export const StyledFormHeader = styled.div`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
  background: #fbfcff;
  border: 1px solid #dae5ff;
  border-radius: 16px;
`;

export const Form = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const FormTitle = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 16px;
  color: #324fa5;
`;

export const QuestionsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 40px;
`;
