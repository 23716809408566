import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 400px 1fr;
  margin-top: 56px;
  height: calc(100vh - 56px);
  box-sizing: border-box;
`;

export const BoxColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 400px;
  min-height: calc(100vh - 56px);
  overflow: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
  background: #fff;
  border: 1px solid #e3dfda;
  padding: 24px;
  box-sizing: border-box;
  gap: 14px;
`;

export const PieChartWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 24px;
  background: #fff;
  border: 1px solid #e3dfda;
  border-radius: 8px;
  gap: 30px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const OpenForms = styled.div`
  font-size: 32px;
  line-height: 38px;
  display: flex;
  align-items: center;
  color: #000;
`;

export const TextOpenForms = styled.div`
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
  color: #000;
`;

export const Clients = styled.div`
  box-sizing: border-box;
  align-items: flex-start;
  padding: 16px 24px;
  gap: 20px;
  border: 1px solid #e3dfda;
  border-radius: 8px;
  min-height: 175px !important;

  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #f2f0ed;
    border-radius: 30px;
  }

  ::-webkit-scrollbar-thumb {
    background: #e3dfda;
    border-radius: 30px;
  }
`;

export const TitleClients = styled.div`
  width: 79px;
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
  color: #000;
`;

export const ClientsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Client = styled.div`
  margin-top: 20px;
  align-items: center;
  font-size: 14px;
  line-height: 17px;
  color: #000;
`;

export const Quantity = styled.div`
  margin-top: 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;

  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: #7c756f;
`;

export const User = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #000;
`;

export const ClientsColumn = styled.div`
  position: relative;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 56px);
  overflow: hidden;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 70px;
    width: 100%;
    pointer-events: none;
    user-select: none;
    cursor: none;
    background: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 16px 16px 0;
`;

export const HeaderClient = styled.div`
  height: 32px;
  font-weight: 500;
  font-size: 32px;
  line-height: 32px;
  color: #000;
`;

export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  height: 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #324fa5;
`;

export const StyleSearch = styled.div`
  .search_icon > path {
    fill: #324fa5;
  }
`;

export const OpenModal = styled.div`
  display: flex;
  cursor: pointer;
  gap: 10px;
`;

export const ContainerList = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #f2f0ed;
    border-radius: 30px;
  }

  ::-webkit-scrollbar-thumb {
    background: #e3dfda;
    border-radius: 30px;
  }
`;

export const Card = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px;
  gap: 64px;
  background: #fff;
  border: 1px solid #e3dfda;
  border-radius: 8px;
  margin-right: 16px;
`;

export const IdProject = styled.div`
  font-weight: 450;
  font-size: 12px;
  color: #262321;
`;
export const Company = styled.div`
  font-weight: 700;
  font-size: 20px;
  color: #262321;
  cursor: pointer;

  &:hover {
    color: #577ef4;
  }
`;

export const BadgeWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

export const Badge = styled.div`
  display: flex;
  padding: 4px 8px;
  gap: 4px;
  width: 100px;
  background: #577ef4;
  border-radius: 4px;
  text-align: center;
`;

export const Text = styled.div`
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  padding: 6px;
  font-weight: 700;
  font-size: 12px;
  color: #fff;
`;

export const Infos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: #262321;
`;

export const ActionsAndBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 350px;
`;

export const TitleBarChart = styled.div`
  height: 19px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #262321;
`;

export const ChartWrapper = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
`;
