import React, { useContext } from 'react';

export const JwtContext = React.createContext<{ decodedJwt: { [index: string]: any } }>({ decodedJwt: {} });
export const useJwtContext = () => {
  const { decodedJwt } = useContext(JwtContext);
  return decodedJwt;
};
export const routesByUserType: { [index: string]: string[] } = {
  COUNTRY_ADVISOR: ['/admin', '/dashboard/preview/:projectId', '/advisor-view/:id/:projectId'],
  COUNTRY_ADMIN: [
    '/admin',
    '/settings/email-permissions',
    '/settings/data-purge-words',
    '/settings/forms',
    '/settings/variables',
    '/settings/variables/create',
    '/settings/variables/edit/:id',
    '/product',
    '/project-type',
    '/business-unit',
    '/service-offer',
    '/product/edit',
    '/project-type/edit',
    '/business-unit/edit',
    '/service-offer/edit',
    '/settings/question',
    '/settings/question/create',
    '/settings/question/edit',
    '/forms',
    '/forms/create',
    '/forms/view/:id',
    '/forms/test/:id',
    '/forms/edit/:id',
    '/forms/historic/:id/:version',
    '/dashboard/preview/:projectId',
    '/user-management',
    '/advisor-view/:id/:projectId',
  ],
  SPONSOR: [
    '/reply-form/:id',
    '/home',
    '/project-selection',
    '/user-project',
    '/user-project/create',
    '/user-project/edit/:id',
  ],
  ANSWERER: ['/reply-form/:id', '/home', '/project-selection'],
};

export const fallbackRoutesByUserType: { [index: string]: string } = {
  COUNTRY_ADVISOR: '/admin',
  COUNTRY_ADMIN: '/admin',
  SPONSOR: '/home',
  ANSWERER: '/home',
};
