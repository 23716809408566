import { cloneElement, createElement } from 'react';
import { FieldType } from '../../../../../../../pages/Form/types/FieldType';
import CheckBox from '../../CheckBox';
import Download from '../../Download';
import Input from '../../Input';
import Radio from '../../Radio';
import Upload from '../../Upload';

const elements = new Map();
elements.set(FieldType.TEXT, createElement(Input));
elements.set(FieldType.EMAIL, createElement(Input));
elements.set(FieldType.USER_NAME, createElement(Input));
elements.set(FieldType.USER_EMAIL, createElement(Input));
elements.set(FieldType.MULTIPLE_CHOICE, createElement(CheckBox));
elements.set(FieldType.SINGLE_CHOICE, createElement(Radio));
elements.set(FieldType.LONG_TEXT, createElement(Input));
elements.set(FieldType.DATE, createElement(Input));
elements.set(FieldType.NUMBER, createElement(Input));
elements.set(FieldType.PHONE, createElement(Input));
elements.set(FieldType.VALIDATION, createElement(Input));
elements.set(FieldType.UPLOAD, createElement(Upload));
elements.set(FieldType.DOWNLOAD, createElement(Download));
elements.set(FieldType.SELECT, createElement(Radio));

interface QuestionPdfProps {
  fieldType: FieldType | null;
  title: string;
  customTypeSnapshot?: any;
}

const QuestionPanelPdf = ({ fieldType, customTypeSnapshot, ...props }: QuestionPdfProps) => {
  if (fieldType === FieldType.CUSTOM) {
    const { type: customType } = customTypeSnapshot;

    const element = elements.get(customType);
    return cloneElement(element, props);
  }

  const element = elements.get(fieldType);
  return cloneElement(element, { ...props, ...customTypeSnapshot });
};

export default QuestionPanelPdf;
