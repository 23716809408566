import React from 'react';

interface BlockUserIconProps {
  width?: number;
  height?: number;
  fill?: string;
}

const BlockUserIcon = ({ width = 16, height = 18, fill }: BlockUserIconProps) => (
  <svg width={width} height={height} viewBox="0 0 14 16" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.33329 9.50132V10.8947C6.72991 10.6813 6.08414 10.6159 5.45023 10.7038C4.81631 10.7918 4.21276 11.0306 3.69025 11.4001C3.16773 11.7697 2.74152 12.2592 2.4474 12.8276C2.15328 13.396 1.99984 14.0267 1.99996 14.6667L0.666626 14.666C0.666419 13.8519 0.852573 13.0486 1.21083 12.3176C1.56908 11.5866 2.08992 10.9473 2.73344 10.4487C3.37696 9.95008 4.12608 9.60541 4.92339 9.44109C5.72071 9.27676 6.54507 9.29714 7.33329 9.50066V9.50132ZM5.99996 8.66666C3.78996 8.66666 1.99996 6.87666 1.99996 4.66666C1.99996 2.45666 3.78996 0.666656 5.99996 0.666656C8.20996 0.666656 9.99996 2.45666 9.99996 4.66666C9.99996 6.87666 8.20996 8.66666 5.99996 8.66666ZM5.99996 7.33332C7.47329 7.33332 8.66663 6.13999 8.66663 4.66666C8.66663 3.19332 7.47329 1.99999 5.99996 1.99999C4.52663 1.99999 3.33329 3.19332 3.33329 4.66666C3.33329 6.13999 4.52663 7.33332 5.99996 7.33332ZM10.6666 11.724L12.0806 10.3093L13.024 11.2527L11.6093 12.6667L13.024 14.0807L12.0806 15.024L10.6666 13.6093L9.25263 15.024L8.30929 14.0807L9.72396 12.6667L8.30929 11.2527L9.25263 10.3093L10.6666 11.724Z"
      fill="#979797"
    />
  </svg>
);

export default BlockUserIcon;
