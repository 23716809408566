import styled from 'styled-components';

export const InlineInputs = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
`;

export const InputWrapper = styled.div`
  flex-grow: 1;
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 24px;
`;
