import React from 'react';

export interface IconEyeSlashProps {
  width?: number;
  height?: number;
  className?: string;
}

export const IconEyeSlash = ({ width = 14, height = 12, className }: IconEyeSlashProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.31649 1.80519C1.1052 1.59514 1.1052 1.25326 1.31649 1.0432C1.52603 0.834883 1.86447 0.834883 2.07402 1.0432L11.9497 10.8611C12.1612 11.0714 12.1612 11.4135 11.9497 11.6238C11.7402 11.832 11.402 11.8323 11.1922 11.6244L9.81325 10.2582L9.56125 10.0077C8.75125 10.3298 7.86325 10.5088 6.93325 10.5088C3.93325 10.5088 1.37125 8.65367 0.333252 6.03507C0.799342 4.86087 1.57439 3.83283 2.57725 3.05858L2.30125 2.7842L1.31649 1.80519ZM9.9345 5.82311C9.9345 4.17087 8.53886 2.82992 6.81923 2.82992C6.41425 2.82992 6.03419 2.90774 5.67905 3.04543L4.33325 1.75237C5.10584 1.48298 5.94073 1.33332 6.813 1.33332C9.92827 1.33332 12.5887 3.19509 13.6666 5.82311C13.2118 6.93658 12.4703 7.91237 11.5295 8.66665L9.7102 6.91862C9.8535 6.5774 9.9345 6.21223 9.9345 5.82311ZM4.0101 4.66665L5.01473 5.52776C4.98233 5.64443 4.96288 5.76665 4.96288 5.88887C4.96288 6.8111 5.8314 7.55554 6.90733 7.55554C7.04992 7.55554 7.19251 7.53887 7.32862 7.5111L8.33325 8.37221C7.89899 8.55554 7.41936 8.66665 6.90733 8.66665C5.11844 8.66665 3.66658 7.42221 3.66658 5.88887C3.66658 5.44999 3.79621 5.03887 4.0101 4.66665ZM8.9873 5.99999L6.99992 4.00631L7.10717 3.99999C8.15449 3.99999 8.99992 4.84809 8.99992 5.89872L8.9873 5.99999Z"
      fill="#262321"
    />
  </svg>
);
