import axios from 'axios';
import { client, secret } from './constants';
import getEnvironment from './getEnvironment';

const baseURL = getEnvironment();

const header = () => ({
  'Content-Type': 'application/json',
  Authorization: `Basic ${btoa(`${client}:${secret}`)}`,
  ...((localStorage.getItem('i18nextLng') ?? '') !== ''
    ? { 'Accept-Language': localStorage.getItem('i18nextLng') as string }
    : {}),
});

const login = axios.create({
  baseURL: `${baseURL}/oauth/login`,
  headers: header(),
});

const refreshToken = axios.create({
  baseURL: `${baseURL}/oauth/refreshToken`,
  headers: header(),
});

const forgot = axios.create({
  baseURL: `${baseURL}/auth/password-change-request`,
  headers: header(),
});

const register = axios.create({
  baseURL: `${baseURL}/auth/change-user-password`,
  headers: header(),
});

export { login, forgot, register, refreshToken };
