import { v4 as uuidv4 } from 'uuid';
import i18n from '../../config/i18n/config';
import { FieldType } from './types/FieldType';

export const formTypeWithLabel = () => ({
  GROUP: {
    value: 'GROUP',
    label: i18n.t('utilsForm.group'),
  },
  COMPANY: {
    value: 'COMPANY',
    label: i18n.t('utilsForm.company'),
  },
  BRANCH: {
    value: 'BRANCH',
    label: i18n.t('utilsForm.branch'),
  },
});

export const FORM_TYPE_VALUES = () => {
  const translatedLables = formTypeWithLabel();
  return [translatedLables.GROUP, translatedLables.COMPANY, translatedLables.BRANCH];
};

export const FORM_TYPE_YES_NO = () => [
  { value: '0', label: i18n.t('utilsForm.NO') },
  { value: '1', label: i18n.t('utilsForm.YES') },
];

export const FORM_TYPE_VARIABLE_VALUES = () => [
  {
    value: 'GROUP',
    label: i18n.t('utilsForm.all'),
  },
  {
    value: 'COMPANY',
    label: i18n.t('utilsForm.companyBranch'),
  },
  {
    value: 'BRANCH',
    label: i18n.t('utilsForm.onlyBranch'),
  },
];

export const INITIAL_CONDITION_VALUE = {
  id: '',
  condition: '',
  value: '',
};

export const INITIAL_VALUE_QUESTION = () => ({
  title: '',
  rpaID: '',
  type: null,
  expertMenu: '',
  expertField: '',
  wId: uuidv4(),
});

export const INITIAL_VALUE_FORM_DETAILS = () => ({
  formId: '',
  formType: null,
  multipleForm: null,
  description: '',
  title: i18n.t('utilsForm.title'),
  projectType: null,
  businessUnit: null,
  serviceOffer: null,
  productType: null,
});

export const FORM_DEFAULT_VALUES = () => ({
  ...INITIAL_VALUE_FORM_DETAILS(),
  questions: [INITIAL_VALUE_QUESTION()],
});

export const questionTypesWithLabel = () => ({
  [FieldType.TEXT]: {
    value: FieldType.TEXT,
    label: i18n.t('utilsForm.TEXT'),
  },
  [FieldType.EMAIL]: {
    value: FieldType.EMAIL,
    label: 'Email',
  },
  [FieldType.LONG_TEXT]: {
    value: FieldType.LONG_TEXT,
    label: i18n.t('utilsForm.LONG_TEXT'),
  },
  [FieldType.DATE]: {
    value: FieldType.DATE,
    label: i18n.t('utilsForm.DATE'),
  },
  [FieldType.NUMBER]: {
    value: FieldType.NUMBER,
    label: i18n.t('utilsForm.NUMBER'),
  },
  [FieldType.PHONE]: {
    value: FieldType.PHONE,
    label: i18n.t('utilsForm.PHONE'),
  },
  [FieldType.MULTIPLE_CHOICE]: {
    value: FieldType.MULTIPLE_CHOICE,
    label: i18n.t('utilsForm.MULTIPLE_CHOICE'),
  },
  [FieldType.SINGLE_CHOICE]: {
    value: FieldType.SINGLE_CHOICE,
    label: i18n.t('utilsForm.SINGLE_CHOICE'),
  },
  [FieldType.UPLOAD]: {
    value: FieldType.UPLOAD,
    label: 'Upload',
  },
  [FieldType.PANEL]: {
    value: FieldType.PANEL,
    label: i18n.t('utilsForm.PANEL'),
  },
  [FieldType.DOWNLOAD]: {
    value: FieldType.DOWNLOAD,
    label: 'Download',
  },
  [FieldType.USER_NAME]: {
    value: FieldType.USER_NAME,
    label: i18n.t('utilsForm.USER_NAME'),
  },
  [FieldType.USER_EMAIL]: {
    value: FieldType.USER_EMAIL,
    label: i18n.t('utilsForm.USER_EMAIL'),
  },
  [FieldType.THEMES]: {
    value: FieldType.THEMES,
    label: i18n.t('utilsForm.THEMES'),
  },
});

export const defaultQuestionTypes = () => [
  {
    value: FieldType.TEXT,
    label: i18n.t('utilsForm.TEXT'),
  },
  {
    value: FieldType.LONG_TEXT,
    label: i18n.t('utilsForm.LONG_TEXT'),
  },
  {
    value: FieldType.DATE,
    label: i18n.t('utilsForm.DATE'),
  },
  {
    value: FieldType.NUMBER,
    label: i18n.t('utilsForm.NUMBER'),
  },
  {
    value: FieldType.PHONE,
    label: i18n.t('utilsForm.PHONE'),
  },
  {
    value: FieldType.MULTIPLE_CHOICE,
    label: i18n.t('utilsForm.MULTIPLE_CHOICE'),
  },
  {
    value: FieldType.SINGLE_CHOICE,
    label: i18n.t('utilsForm.SINGLE_CHOICE'),
  },
  {
    value: FieldType.UPLOAD,
    label: 'Upload',
  },
  {
    value: FieldType.DOWNLOAD,
    label: 'Download',
  },
  {
    value: FieldType.EMAIL,
    label: 'Email',
  },
];

export const questionTypesWithPanel = () => [
  ...defaultQuestionTypes(),
  {
    value: FieldType.PANEL,
    label: i18n.t('utilsForm.PANEL'),
  },
];
