import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Creators as modalActions } from '../../../redux/reducers/modal-reducer';
import { TableData } from '../../../types/TableData';
import { ActionsWrapper, Container, Title } from '../style';
import { ActionIcon } from '../../../components/Table/style';
import { EditIcon } from '../../../assets/svgs/EditIcon';
import Table from '../../../components/Table';
import { CustomQuestionType } from '../constants';
import {
  blockCustomQuestionRequest,
  findAllQuestionSettingsRequest,
  unblockCustomQuestionRequest,
} from '../../../requests/questionsSettings';
import { Breadcrumbs } from '../../../components/BreadCrumb';
import TextButton from '../../../components/TextButton';
import BlockFormIcon from '../../../assets/svgs/BlockFormIcon';
import UnblockFormIcon from '../../../assets/svgs/UnblockFormIcon';
import { TextWrapper } from '../../Form/FormList/style';
import Alert from '../../../components/Toast/toast';
import DeleteModal from '../../../components/DefaultModalContent';
import { LoaderWrapper } from '../../../components/Loader/style';
import Loader from '../../../components/Loader';
import { BreadcrumbWrapper } from '../../../components/BreadCrumb/style';

interface QuestionSettingsFields {
  _id: number | string;
  name: string;
  type: CustomQuestionType;
  status: 'ACTIVE' | 'INACTIVE';
}

const QuestionSettings = () => {
  const [questionSettings, setQuestionSettings] = useState<TableData[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const CUSTOM_QUESTION_TYPE_LABEL = {
    [CustomQuestionType.SINGLE_CHOICE]: t('questions.SINGLE_CHOICE'),
    [CustomQuestionType.MULTIPLE_CHOICE]: t('questions.MULTIPLE_CHOICE'),
    [CustomQuestionType.VALIDATION]: t('questions.VALIDATION'),
  };

  const dispatch = useDispatch();
  const { openModal, closeModal } = modalActions;
  const closeHandler = () => dispatch(closeModal());

  const getQuestionSettings = async () => {
    setLoading(true);
    const questionData = await findAllQuestionSettingsRequest();
    if (questionData) {
      setQuestionSettings(questionData);
    }
    setLoading(false);
  };

  useEffect(() => {
    getQuestionSettings();
  }, []);

  const blockHandler = async (...data: any[]) => {
    const blockSubmit = () => {
      const filteredData = data.filter((item) => item.status === 'ACTIVE');

      try {
        blockCustomQuestionRequest(filteredData);
        const ids = filteredData.map((item) => item._id);
        setQuestionSettings((prevState) =>
          prevState.map((customQuestion) =>
            ids.includes(customQuestion._id) ? { ...customQuestion, status: 'INACTIVE' } : customQuestion
          )
        );
        const alert = filteredData.length > 1 ? t('form.blockFormPlural') : t('form.blockForm');
        Alert.SUCCESS(alert);
        closeHandler();
      } catch (e: any) {
        Alert.ERROR(e?.response?.data?.message);
      }
    };

    const message = data.length > 1 ? t('form.messageBlockPlural') : t('form.messageBlock');

    dispatch(
      openModal({
        content: <DeleteModal onSubmit={blockSubmit} title={message} submitButtonTitle={t('common.block')} />,
      })
    );
  };

  const unblockHandler = async (...data: any[]) => {
    const unblockSubmit = () => {
      const filteredData = data.filter((item) => item.status === 'INACTIVE');

      try {
        unblockCustomQuestionRequest(filteredData);
        const ids = filteredData.map((item) => item._id);
        setQuestionSettings((prevState) =>
          prevState.map((customQuestion) =>
            ids.includes(customQuestion._id) ? { ...customQuestion, status: 'ACTIVE' } : customQuestion
          )
        );
        const alert = filteredData.length > 1 ? t('form.unblockFormPlural') : t('form.unblockForm');
        Alert.SUCCESS(alert);
        closeHandler();
      } catch (e: any) {
        Alert.ERROR(e?.response?.data?.message);
      }
    };

    const message = data.length > 1 ? t('form.messageunBlockPlural') : t('form.messageunBlock');

    dispatch(
      openModal({
        content: <DeleteModal onSubmit={unblockSubmit} title={message} submitButtonTitle={t('common.unlock')} />,
      })
    );
  };

  const editHandler = (questionData: any) => {
    navigate(`/settings/question/edit`, { state: { questionData } });
  };

  const createHandler = async () => {
    navigate('/settings/question/create');
  };

  const render = (element: QuestionSettingsFields) => [
    <TextWrapper disabled={element.status === 'INACTIVE'}>{element.name}</TextWrapper>,
    <TextWrapper disabled={element.status === 'INACTIVE'}>{CUSTOM_QUESTION_TYPE_LABEL[element.type]}</TextWrapper>,
    <ActionsWrapper>
      <ActionIcon disabled={element.status === 'INACTIVE'} onClick={() => editHandler(element)}>
        <EditIcon />
      </ActionIcon>
      {element.status === 'INACTIVE' ? (
        <ActionIcon onClick={() => unblockHandler(element)}>
          <BlockFormIcon />
        </ActionIcon>
      ) : (
        <ActionIcon onClick={() => blockHandler(element)}>
          <UnblockFormIcon />
        </ActionIcon>
      )}
    </ActionsWrapper>,
  ];

  const headers = [
    {
      key: 'name',
      head: t('questions.custom'),
    },
    {
      key: 'type',
      head: t('questions.type'),
    },
  ];

  const TABLE_ADVICE = t('form.advice');
  const EMPTY_STATE_TEXTS = {
    EMPTY_FILTER: t('form.emptyFilter'),
    EMPTY_LIST: t('form.emptyList'),
  };

  const renderActions = (selectedData: any[]) => [
    <TextButton
      key="BlockForm"
      onClick={() => blockHandler(...selectedData)}
      disabled={
        selectedData.length === 0 ||
        !selectedData.some((selectedElement) => !selectedElement.status || selectedElement.status === 'ACTIVE')
      }
    >
      <span>{t('common.block')}</span>
      <UnblockFormIcon fill={selectedData.length > 0 ? '#4C65B0' : '#979797'} />
    </TextButton>,
    <TextButton
      key="DesblockForm"
      onClick={() => unblockHandler(...selectedData)}
      disabled={
        selectedData.length === 0 || !selectedData.some((selectedElement) => selectedElement.status === 'INACTIVE')
      }
    >
      <span>{t('common.unlock')}</span>
      <BlockFormIcon fill={selectedData.length > 0 ? '#4C65B0' : '#979797'} />
    </TextButton>,
  ];

  return (
    <Container>
      <BreadcrumbWrapper>
        <Breadcrumbs />
      </BreadcrumbWrapper>
      <Title>{t('questions.settingsQuestions')}</Title>
      {loading ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        <Table
          data={questionSettings}
          render={render}
          headers={headers}
          emptyStateTexts={EMPTY_STATE_TEXTS}
          advice={TABLE_ADVICE}
          renderActions={renderActions}
          createHandler={createHandler}
          searchKey="name"
        />
      )}
    </Container>
  );
};

export default QuestionSettings;
