import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionTitleWrapper,
  HeaderContainer,
  HeaderWrapper,
  HelpButton,
  IconWrapper,
  ItemsChildren,
  NavigateButton,
  Overlay,
  Sidebar,
  SidebarItems,
  SidebarItemsFooter,
  StyledIconArrow,
  StyledMenuAccordion,
  UserIcon,
} from './style';
import { CloseIcon } from '../../assets/svgs/CloseIcon';
import { HamburguerIcon } from '../../assets/svgs/HamburguerIcon';
import { ADPLogo } from '../../assets/svgs/ADPLogo';
import ClickAwayListener from '../ClickAwayListener';
import { SidebarData, SideBarItem } from './data';
import { IconArrowUp } from '../../assets/svgs/IconArrowUp';
import { IconArrowDown } from '../../assets/svgs/IconArrowDown';
import { SidebarSettings } from './dataSettings';
import { logout } from '../../services/user.service';
import { UserInfos } from '../../types/UserInfos';
import { routesByUserType, useJwtContext } from '../../JwtContext';
import { UserType } from '../../types/UserType';
import { UserShortProfile } from './UserShortProfile';
import { IconExit } from '../../assets/svgs/IconExit';
import i18n from '../../config/i18n/config';
import { IconHelp } from '../../assets/svgs/IconHelp';
import { RootState } from '../../redux/store';
import { getFromLocalStorage } from '../../utils/storageUtils';

interface SidebarItemProps {
  item?: any;
  closeHandler: () => void;
}
interface MenuItemProps {
  item?: any;
}

const MenuNavButton = ({ path, icon, title, onClick }: any) => {
  const navigate = useNavigate();
  const blocked = useSelector((state: RootState) => state?.blockPage?.active);
  const { t } = useTranslation();

  const handler = () => {
    if (blocked) {
      // eslint-disable-next-line no-restricted-globals,no-alert
      const response = confirm(t('form.confirmExit'));

      if (!response) return;
    }

    return navigate(path);
  };

  return (
    <NavigateButton onClick={onClick ?? handler}>
      {icon}
      <span>{title}</span>
    </NavigateButton>
  );
};

const MenuItem = ({ item }: MenuItemProps) => <MenuNavButton path={item.path} icon={item.icon} title={item.title} />;

const MenuAccordion = ({ item, closeHandler }: SidebarItemProps) => {
  const [open, setOpen] = useState(false);

  return (
    <StyledMenuAccordion key={item.key}>
      <Accordion
        onClick={(event) => {
          event.stopPropagation();
          setOpen(!open);
        }}
      >
        <AccordionTitleWrapper>
          {item.icon}
          <span>{item.title}</span>
        </AccordionTitleWrapper>
        <StyledIconArrow>{open ? <IconArrowDown /> : <IconArrowUp />}</StyledIconArrow>
      </Accordion>
      <ItemsChildren onClick={closeHandler}>
        {open && item.children.map((itemChildren: any) => <MenuItem key={itemChildren.key} item={itemChildren} />)}
      </ItemsChildren>
    </StyledMenuAccordion>
  );
};

const SidebarTopItems = ({ closeHandler }: { closeHandler: () => void }) => {
  const decodedJwt = useJwtContext();

  const userType = decodedJwt.type as UserType;
  const userRoutes = routesByUserType[userType] as string[];
  const selectedProject = getFromLocalStorage('selectedProject');
  const sidebarData = useMemo(
    () =>
      SidebarData.filter((item) => userRoutes.includes(item.path ?? '')).filter(
        (item) => item.filterFunction?.({ decodedJwt, selectedProject }) ?? true
      ),
    [userType, selectedProject]
  );
  const sidebarSettings = useMemo(
    () => ({
      ...SidebarSettings,
      children: SidebarSettings.children.filter((item: SideBarItem) => userRoutes.includes(item.path ?? '')),
    }),
    [userType]
  );
  return (
    <div>
      <SidebarItems onClick={closeHandler}>
        {sidebarData
          .filter((item) => Object.keys(item).length !== 0)
          .map((item) => (
            <MenuItem key={item.key} item={item} />
          ))}
      </SidebarItems>
      {sidebarSettings.children.length > 0 && (
        <SidebarItems>
          <MenuAccordion closeHandler={closeHandler} item={sidebarSettings} />
        </SidebarItems>
      )}
    </div>
  );
};

const Navbar = () => {
  const [sideBarIsOpen, setSideBarIsOpen] = useState<boolean>(false);
  const [openProfile, setOpenProfile] = useState(false);
  const toggleSidebar = () => setSideBarIsOpen(!sideBarIsOpen);
  const decodedJwt = useJwtContext();

  const navigate = useNavigate();

  const closeHandler = () => {
    setSideBarIsOpen(false);
  };

  const logoutHandler = async () => {
    await logout();
    navigate('/', { replace: true });
  };

  const getUserNameAcronym = () => {
    if (Object.keys(decodedJwt).length === 0) return '';

    const { name, email } = decodedJwt as UserInfos;

    if (name === email) {
      const nameFromEmail = email.substring(0, email.lastIndexOf('@'));

      const matches = nameFromEmail.match(/\b(\w)/g);
      if (!matches) return '';

      return matches.join('').toUpperCase();
    }

    const matches = name.match(/\b(\w)/g);
    if (!matches) return '';

    return matches.join('').toUpperCase();
  };
  const openUserShortProfile = () => setOpenProfile((old) => !old);
  return (
    <ClickAwayListener onClickAway={closeHandler}>
      <HeaderContainer>
        <HeaderWrapper>
          <IconWrapper onClick={toggleSidebar}>
            {sideBarIsOpen ? <CloseIcon width={18} height={12} /> : <HamburguerIcon width={18} height={12} />}
          </IconWrapper>
          <ADPLogo />
        </HeaderWrapper>
        <HeaderWrapper>
          <UserIcon onClick={openUserShortProfile}>{getUserNameAcronym()}</UserIcon>
          {openProfile && <UserShortProfile setOpenProfile={setOpenProfile} />}
        </HeaderWrapper>
      </HeaderContainer>
      <Overlay active={sideBarIsOpen} onClick={closeHandler}>
        <Sidebar active={sideBarIsOpen}>
          <SidebarTopItems closeHandler={closeHandler} />
          <SidebarItemsFooter>
            <MenuNavButton path="#" icon={<IconExit />} title={i18n.t('navBar.exit')} onClick={logoutHandler} />
            <HelpButton href="https://br.adp.com/atendimento-a-clientes.aspx" target="_blank" rel="noopener noreferrer">
              <IconHelp />
              <span>{i18n.t('navBar.help') as string}</span>
            </HelpButton>
          </SidebarItemsFooter>
        </Sidebar>
      </Overlay>
    </ClickAwayListener>
  );
};

export default Navbar;
