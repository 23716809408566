import styled from 'styled-components';
import Grid from '../../components/Grid';
import { MAX_WIDTHS } from '../../constants/screen';

export const Container = styled(Grid).attrs({
  container: true,
})`
  flex-direction: column;
  padding: 0 40px;
  margin: 80px 0;

  @media (max-width: ${MAX_WIDTHS.sm}px) {
    padding: 7%;
  } ;
`;

export const Title = styled.h1`
  font-family: 'Circular20', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0;
  text-align: left;
  margin-bottom: 36px;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: right;
  width: 100%;
  gap: 16px;
`;
