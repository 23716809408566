import styled from 'styled-components';
import Grid from '../../../components/Grid';
import { MAX_WIDTHS } from '../../../constants/screen';

export const FormWrapper = styled(Grid).attrs({
  container: true,
})`
  flex-direction: column;
  padding: 0 40px;
  margin: 80px 0;

  @media (max-width: ${MAX_WIDTHS.sm}px) {
    padding: 7%;
  } ;
`;

export const Title = styled.h1`
  font-family: 'Circular20', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0;
  text-align: left;
  margin-bottom: 36px;
`;

export const Display = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 26px;
  width: 97%;
  height: 20%;
  left: 40px;
  top: 165px;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 10px;
`;

export const InputCode = styled.div`
  width: 40%;
`;

export const ButtonWrapper = styled.div`
  border: none;
  align-self: end;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 32px 0 26px 0;
  gap: 24px;
`;
