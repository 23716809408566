import styled from 'styled-components';

interface BarChartProps {
  danger?: number;
  warning?: number;
  success?: number;
}

export const Bars = styled.div`
  margin-top: 10px;
  display: flex;
  width: 90%;
`;

export const ProgressBarLineContainer = styled.div`
  display: flex;
  height: 10px;
`;

export const StyledBarDanger = styled.div<BarChartProps>`
  background-color: #f1615d;
  height: 10px;
  width: ${({ danger }) => danger && danger * 100}%;
`;

export const StyledBarWarning = styled.div<BarChartProps>`
  background-color: #577ef4;
  height: 10px;
  width: ${({ warning }) => warning && warning * 100}%;
`;

export const StyledBarSuccess = styled.div<BarChartProps>`
  background-color: #99e596;
  height: 10px;
  width: ${({ success }) => success && success * 100}%;
`;
