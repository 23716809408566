import React, { ChangeEvent } from 'react';
import { CheckIcon } from '../../../assets/svgs/CheckIcon';
import { RadioButtonInput, RadioButtonLabel } from './style';

interface RadioButtonProps {
  id: any;
  value?: any;
  checked?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const RadioButton = ({ id, checked, onChange, value, disabled, ...props }: RadioButtonProps) => (
  <>
    <RadioButtonInput id={id} checked={checked} onChange={onChange} value={value} {...props} />
    <RadioButtonLabel htmlFor={id} disabled={disabled}>
      <CheckIcon />
    </RadioButtonLabel>
  </>
);

export default RadioButton;
