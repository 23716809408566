import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ReplyForm from '../../ReplyForm';
import { Container } from '../FormEdit/style';
import { LoaderWrapper } from '../../../components/Loader/style';
import Loader from '../../../components/Loader';
import { findFormToTestRequest } from '../../../requests/forms';
import { FormTestContext } from './context';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import { ActionsWrapper, FooterWrapper, Header, HeaderTitle } from '../../ReplyForm/style';
import { ReplyFormQuestionsContainer } from '../../ReplyForm/components/style';
import { FieldTitle } from '../../ReplyForm/components/AnswerField/style';
import Footer from '../../../components/Form/Footer';
import { PanelAccordion, PanelAccordionHeader } from '../../../components/Form/PreviewField/components/Panel/style';

interface ExternalDependency {
  formTitle: string;
  questionTitle: string;
  formType: string;
  questionWId: string;
}
const cleance = (str: string) => str.replace(/\./g, '_');

const getAnswer = (data: any, cur: string, holder: any) => {
  const finalData = data[cleance(cur)] ?? data[cleance(holder?.questionWId)];
  return Array.isArray(finalData) ? { branchesAnswer: finalData } : { questionAnswer: finalData };
};

const setAnswer = (answerHolder: any, data: any) =>
  Object.keys(answerHolder).reduce(
    (acc, cur) => ({
      ...acc,
      [cur]: {
        ...answerHolder[cur],
        ...getAnswer(data, cur, answerHolder[cur]),
      },
    }),
    {}
  );
const Instructions = styled.div`
  & > h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    color: #262321;
  }

  padding: 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #cad9f6;
  border-radius: 16px;
  gap: 10px;
  background: rgba(210, 220, 253, 0.5);
`;
const FormInfoGatherer = ({ handleSubmit, onSubmit, form, externalQuestions, control }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Header style={{ height: 70 }}>
        <HeaderTitle>
          {t('form.test')} {form?.title}
        </HeaderTitle>
      </Header>
      <Container>
        <ReplyFormQuestionsContainer style={{ marginTop: 77 }}>
          <Instructions>
            <h2>{t('form.instructions')}</h2>
            <li>{t('form.instructionsKey1')}</li>
            <li>{t('form.instructionsKey2')}</li>
            <li>{t('form.instructionsKey3')}</li>
            <li>{t('form.instructionsKey4')}</li>
            <li>{t('form.instructionsKey5')}</li>
          </Instructions>
          {externalQuestions
            .filter((question: ExternalDependency) => question.formType !== 'BRANCH')
            .map((question: ExternalDependency) => (
              <Fragment key={`FakeQuestion-root-${question.questionWId}`}>
                <FieldTitle>{`${question.formTitle} - ${question.questionTitle}`}</FieldTitle>
                <Controller
                  name={cleance(question.questionWId)}
                  control={control}
                  render={({ field }) => <Input {...field} placeholder={t('common.type')} />}
                />
              </Fragment>
            ))}
          {form?.branches?.map((a: any, index: number) => (
            <PanelAccordion style={{ gap: 10 }} key={`FakeQuestionPanel-${a.branchIndex}`}>
              <PanelAccordionHeader>
                <FieldTitle>{`${t('form.branch')} ${index + 1}`}</FieldTitle>
              </PanelAccordionHeader>
              {externalQuestions
                .filter((question: ExternalDependency) => question.formType === 'BRANCH')
                .map((question: ExternalDependency) => (
                  <Fragment key={`FakeQuestion-${a.branchIndex}-${question.questionWId}`}>
                    <FieldTitle>{`${question.formTitle} - ${question.questionTitle}`}</FieldTitle>
                    <Controller
                      name={`${cleance(question.questionWId)}.${index}`}
                      control={control}
                      render={({ field }) => <Input {...field} placeholder={t('common.type')} />}
                    />
                    <div style={{ height: 15 }} />
                  </Fragment>
                ))}
            </PanelAccordion>
          ))}
        </ReplyFormQuestionsContainer>
        <Footer>
          <FooterWrapper>
            <ActionsWrapper />
            <ActionsWrapper>
              <Button onClick={() => navigate(location.pathname.replace('/forms/test', '/forms/edit'))}>
                {t('form.backToEdit')}
              </Button>
              <Button primary type="submit">
                {t('common.test')}
              </Button>
            </ActionsWrapper>
          </FooterWrapper>
        </Footer>
      </Container>
    </form>
  );
};

const FormTest = () => {
  const [loading, setLoading] = useState(true);
  const [testing, setTesting] = useState(false);
  const [externalQuestions, setExternalQuestions] = useState<ExternalDependency[]>([]);
  const [form, setForm] = useState<any>();
  const { id } = useParams<{ id?: string }>();
  const { t } = useTranslation();

  const { handleSubmit, control } = useForm();
  const onSubmit = (data: any) => {
    const { titleVariableAnswers, rulesDependenciesAnswers, ...oldForm } = form;
    setForm({
      ...oldForm,
      titleVariableAnswers: setAnswer(titleVariableAnswers, data),
      rulesDependenciesAnswers: setAnswer(rulesDependenciesAnswers, data),
      randomKey: Math.random(),
    });
    setTesting(true);
  };
  const fetchFormDataById = async () => {
    const data = await findFormToTestRequest(id);

    if (data) {
      const dataQuestionsArray = { ...data, questions: [data.questions] };

      let x;
      let mapped;

      if (data.multipleForm === '1') {
        setForm(dataQuestionsArray);

        x = [
          ...Object.keys(dataQuestionsArray.rulesDependenciesAnswers),
          ...Object.keys(dataQuestionsArray.titleVariableAnswers),
        ].filter((value: any, index: number, self: any[]) => self.indexOf(value) === index);

        mapped = x.map(
          (wId) => dataQuestionsArray.rulesDependenciesAnswers[wId] ?? dataQuestionsArray.titleVariableAnswers[wId]
        );
      } else {
        setForm(data);

        x = [...Object.keys(data.rulesDependenciesAnswers), ...Object.keys(data.titleVariableAnswers)].filter(
          (value: any, index: number, self: any[]) => self.indexOf(value) === index
        );

        mapped = x.map((wId) => data.rulesDependenciesAnswers[wId] ?? data.titleVariableAnswers[wId]);
      }

      setExternalQuestions(mapped as ExternalDependency[]);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchFormDataById();
  }, []);
  const context = useMemo(
    () => ({
      testForm: form,
      setTesting,
      customFooterButtons: [
        {
          children: t('form.refill'),
          props: {
            primary: true,
            onClick: () => {
              setTesting(false);
              // alert('//TODO validation');
            },
          },
        },
      ],
    }),
    [form]
  );
  return loading ? (
    <LoaderWrapper>
      <Loader text={t('form.search')} />
    </LoaderWrapper>
  ) : (
    <>
      {testing ? (
        <FormTestContext.Provider value={context}>
          <ReplyForm key={form?.randomKey} />
        </FormTestContext.Provider>
      ) : (
        <FormInfoGatherer
          {...{
            handleSubmit,
            onSubmit,
            form,
            externalQuestions,
            control,
          }}
        />
      )}
      <div />
    </>
  );
};

export default FormTest;
