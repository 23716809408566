import i18n from '../../config/i18n/config';

export const CHART_OPTIONS = {
  series: {
    0: {
      color: 'f1615d',
    },
  },
  legend: 'none',
  pieSliceText: 'none',
  backgroundColor: '324fa5',
  pieHole: 0.5,
  is3D: false,
  slices: {
    0: { color: 'f1615d' },
    1: { color: '577ef4' },
    2: { color: '99e596' },
  },
  tooltip: { trigger: 'none' },
};

export enum FormStatus {
  NOT_INITIATED = 'NOT_INITIATED',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
}

export const FORM_STATUS_LABEL = {
  [FormStatus.NOT_INITIATED]: i18n.t('dashboard.notStarted'),
  [FormStatus.IN_PROGRESS]: i18n.t('dashboard.progress'),
  [FormStatus.FINISHED]: i18n.t('dashboard.finished'),
};
