import React, { cloneElement, createElement } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldType } from '../../../pages/Form/types/FieldType';
import MultipleChoice from './components/ChoicesFields/MultipleChoice';
import UniqueChoice from './components/ChoicesFields/UniqueChoice';
import InputField from './components/InputField';
import Panel from './components/Panel';
import Upload from './components/Upload';
import Download from './components/Download';
import LongText from './components/LongText';
import { FieldTitle } from './style';

const Themes = ({ title }: { title: string }) => {
  const { t } = useTranslation();

  return (
    <div>
      <FieldTitle>{title}</FieldTitle>
      <p style={{ marginTop: 15 }}>{t('forms.themesQuestion')}</p>
    </div>
  );
};

const elements = new Map();
elements.set(FieldType.MULTIPLE_CHOICE, createElement(MultipleChoice));
elements.set(FieldType.SINGLE_CHOICE, createElement(UniqueChoice));
elements.set(FieldType.PANEL, createElement(Panel));
elements.set(FieldType.TEXT, createElement(InputField, { type: 'text' }));
elements.set(FieldType.LONG_TEXT, createElement(LongText));
elements.set(FieldType.DATE, createElement(InputField));
elements.set(FieldType.NUMBER, createElement(InputField, { type: 'number' }));
elements.set(FieldType.PHONE, createElement(InputField, { type: 'text' }));
elements.set(FieldType.UPLOAD, createElement(Upload));
elements.set(FieldType.DOWNLOAD, createElement(Download));
elements.set(FieldType.VALIDATION, createElement(InputField));

elements.set(FieldType.EMAIL, createElement(InputField, { type: 'text' }));
elements.set(FieldType.USER_EMAIL, createElement(InputField, { type: 'text' }));
elements.set(FieldType.USER_NAME, createElement(InputField, { type: 'text' }));
elements.set(FieldType.THEMES, createElement(Themes));

interface PreviewFieldProps {
  fieldType: FieldType | null;
  customFieldTypeProps?: any;
  title: string;
}

const PreviewField = ({ fieldType, customFieldTypeProps, title, ...props }: PreviewFieldProps) => {
  if (fieldType === FieldType.CUSTOM) {
    const { type, ...customProps } = customFieldTypeProps;

    const element = elements.get(type);
    return cloneElement(element, { ...customProps, title });
  }

  const element = elements.get(fieldType);
  return cloneElement(element, { ...props, title });
};

export default PreviewField;
