import { useForm, Controller } from 'react-hook-form';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import { decodeJwt } from '../../utils/jwtUtils';
import { getFromLocalStorage, saveSessionStorage } from '../../utils/storageUtils';
import RadioButton from '../../components/MarkableButton/RadioButton';
import { Container, Title } from '../QuestionSettings/style';
import Footer from '../../components/Form/Footer';
import { FooterWrapper } from '../Form/FormCreate/style';
import { ProjectOption, Card } from './styles';

export default () => {
  const formMethods = useForm();
  const { handleSubmit, control } = formMethods;
  const navigate = useNavigate();
  const onSubmit = (data: { [x: string]: any }) => {
    if (!data.selectedProject) return;
    saveSessionStorage('selectedProject', data.selectedProject);
    navigate('/home');
  };
  const jwt = getFromLocalStorage('token');
  const { t } = useTranslation();

  const decodedJwt = useMemo(() => {
    try {
      return decodeJwt(jwt ?? '');
    } catch (e: any) {
      return {};
    }
  }, [jwt]);
  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Title>{t('projectSelection.title')}</Title>

        <Card>
          <Title style={{ fontSize: '20px', marginBottom: 10 }}>{t('projectSelection.select')}</Title>
          {decodedJwt.projects.map((project: string, index: number, self: string[]) => (
            <ProjectOption last={index === self.length - 1}>
              <Controller
                name="selectedProject"
                control={control}
                render={({ field }) => <RadioButton id={`id-${project}`} {...field} value={project} />}
              />
              <label htmlFor={`id-${project}`}>
                {t('projectSelection.project')} {project}
              </label>
              <br />
            </ProjectOption>
          ))}
        </Card>
        <Footer>
          <FooterWrapper>
            <Button type="submit" primary>
              {t('projectSelection.selectView')}
            </Button>
          </FooterWrapper>
        </Footer>
      </form>
    </Container>
  );
};
