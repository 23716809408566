import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../../config/i18n/config';
import Checkbox from '../../../../../components/MarkableButton/Checkbox';
import {
  Actions,
  ActionsButtonsWrapper,
  Advice,
  Container,
  OrderIconWrapper,
  SearchBarWrapper,
  TableBody,
  TableData,
  TableDataWrapper,
  TableFooter,
  TableHead,
  TableHeadData,
  TableRow,
  TableWrapper,
} from '../../../../../components/Table/style';
import { OrderIcon } from '../../../../../assets/svgs/OrderIcon';
import { TableData as Data } from '../../../../../types/TableData';
import Pagination from '../../../../../components/Pagination';
import TextButton from '../../../../../components/TextButton';
import { CreateIcon } from '../../../../../assets/svgs/CreateIcon';
import SearchBar from '../../../../../components/SearchBar';
import { FiltersWrapper } from './style';
import TextSelect from '../../../../../components/TextSelect';
import EmptyState from '../../../../../components/EmptyState';
import FilterIconDashboard from '../../../../../assets/svgs/FilterIconDashboard';

const enum StatusSortingOptions {
  WITHOUT_SORTING,
  BLOCKED,
  UNBLOCKED,
}

const enum SortingOptions {
  ASCENDING,
  DESCENDING,
}

const PAGE_SIZE = 10;

const STATUS_SORTING_OPTIONS = [
  {
    label: i18n.t('status.WITHOUT_SORTING'),
    value: StatusSortingOptions.WITHOUT_SORTING,
  },
  {
    label: i18n.t('status.BLOCKED'),
    value: StatusSortingOptions.BLOCKED,
  },
  {
    label: i18n.t('status.UNBLOCKED'),
    value: StatusSortingOptions.UNBLOCKED,
  },
];

interface SortConfig {
  order: SortingOptions;
  key: string;
}

interface HeaderItem {
  head: string;
  key: string;
}

interface TableProps {
  searchKey: string;
  headers: HeaderItem[];
  renderActions: (selectedData: any) => any[];
  advice: string;
  emptyStateTexts: {
    EMPTY_FILTER: string;
    EMPTY_LIST: string;
  };
  data: Data[];
  render: (element: any) => any[];
  createHandler: (data?: any) => void;
  onFilterClick: (data?: any) => void;
}

interface TableContentProps {
  element: Data;
  render: (element: any) => any[];
  selectedData: any[];
  handleSelectData: (event: ChangeEvent<HTMLInputElement>) => void;
}

interface TableHeadProps {
  headers: HeaderItem[];
  allDataAreSelected: boolean;
  handleSelectAll: () => void;
  requestSort: (key: string) => void;
  sortConfig: SortConfig | null;
}

const TableContent = ({ element, render, selectedData, handleSelectData }: TableContentProps) => {
  const rendered = render(element);
  const isChecked = selectedData.some((e) => e._id === element._id);
  return (
    <TableRow key={`TableRow${element._id}`}>
      <TableData>
        <TableDataWrapper>
          <Checkbox id={element._id} value={JSON.stringify(element)} checked={isChecked} onChange={handleSelectData} />
        </TableDataWrapper>
      </TableData>
      {rendered.map((field: any, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <TableData key={`TableData${element._id}${index}`}>
          <TableDataWrapper>{field}</TableDataWrapper>
        </TableData>
      ))}
    </TableRow>
  );
};

const TableHeader = ({ headers, allDataAreSelected, handleSelectAll, requestSort, sortConfig }: TableHeadProps) => {
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        <TableHeadData>
          <Checkbox id="select-all" checked={allDataAreSelected} onChange={handleSelectAll} />
        </TableHeadData>
        {headers.map(({ head, key }) => (
          <TableHeadData key={key}>
            <TableDataWrapper>
              <span>{head}</span>
              <OrderIconWrapper active={sortConfig?.key === key} onClick={() => requestSort(key)}>
                <OrderIcon />
              </OrderIconWrapper>
            </TableDataWrapper>
          </TableHeadData>
        ))}
        <TableHeadData>{t('table.actions')}</TableHeadData>
      </TableRow>
    </TableHead>
  );
};

const Table = ({
  data,
  render,
  headers,
  advice,
  emptyStateTexts,
  createHandler,
  renderActions,
  searchKey,
  onFilterClick,
}: TableProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedData, setSelectedData] = useState<any[]>([]);
  const [sortConfig, setSortConfig] = useState<SortConfig | null>(null);
  const [filter, setFilter] = useState<string>('');
  const [filterStatus, setFilterStatus] = useState(StatusSortingOptions.WITHOUT_SORTING);
  const { t } = useTranslation();

  useEffect(() => {
    if (currentPage > 1 && data.length <= PAGE_SIZE) {
      setCurrentPage(1);
    }
    setSelectedData((prevState) => prevState.filter((prevSelectedData) => data.includes(prevSelectedData)));
  }, [data]);

  const filteredData = useMemo(() => {
    const lowerCaseFilter = filter.toLowerCase();
    return data.filter((text) => text[searchKey].toLowerCase().includes(lowerCaseFilter));
  }, [filter, data]);

  const sortedData = useMemo(() => {
    let result = [...filteredData];

    if (sortConfig) {
      const isAscendingOrder = sortConfig.order === SortingOptions.ASCENDING;

      result = result.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return isAscendingOrder ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return isAscendingOrder ? 1 : -1;
        }
        return 0;
      });
    }

    if (filterStatus === StatusSortingOptions.WITHOUT_SORTING) return result;

    const status = filterStatus === StatusSortingOptions.UNBLOCKED ? 'INACTIVE' : 'ACTIVE';

    result = result.sort((a, b) => {
      if (a.status === b.status) return 0;
      if (a.status === status) return 1;
      return -1;
    });

    return result;
  }, [sortConfig, filterStatus, filteredData]);

  const paginatedData = useMemo(() => {
    if (currentPage > 1 && sortedData.length <= PAGE_SIZE) {
      setCurrentPage(1);
    }
    const firstPageIndex = (currentPage - 1) * PAGE_SIZE;
    const lastPageIndex = firstPageIndex + PAGE_SIZE;
    return sortedData.slice(firstPageIndex, lastPageIndex);
  }, [sortedData, currentPage]);

  const dataListAreFilled = data.length > 0;
  const allDataAreSelected = dataListAreFilled && selectedData.length === data.length;
  const nothingFoundWithFilter = filteredData.length === 0;

  const handleSelectAll = () => {
    if (nothingFoundWithFilter) return;

    if (allDataAreSelected) {
      setSelectedData([]);
      return;
    }

    setSelectedData(data);
  };

  const handleSelectData = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const parsed = JSON.parse(value);

    if (!selectedData.some((e) => e._id === parsed._id)) {
      return setSelectedData((prevState) => [...prevState, parsed]);
    }

    return setSelectedData(selectedData.filter((selectedDataValue) => selectedDataValue._id !== parsed._id));
  };

  const requestSort = (key: string) => {
    if (!sortConfig) {
      return setSortConfig({ order: SortingOptions.ASCENDING, key });
    }

    if (sortConfig.key !== key) {
      return setSortConfig({ order: SortingOptions.ASCENDING, key });
    }

    if (sortConfig.order === SortingOptions.ASCENDING) {
      return setSortConfig({ order: SortingOptions.DESCENDING, key });
    }

    return setSortConfig(null);
  };

  const onClickCreate = () => {
    createHandler();
  };

  const handlerFilterStatus = (option: any) => {
    setFilterStatus(option?.value);
  };

  const renderedActions = renderActions(selectedData);

  return (
    <Container>
      <Actions>
        <ActionsButtonsWrapper>
          {renderedActions}
          <TextButton onClick={onClickCreate}>
            <span>{t('table.add')}</span>
            <CreateIcon />
          </TextButton>
        </ActionsButtonsWrapper>
        <FiltersWrapper>
          <SearchBarWrapper>
            <SearchBar value={filter} setValue={setFilter} />
          </SearchBarWrapper>
          <TextSelect
            defaultValue={STATUS_SORTING_OPTIONS[0]}
            options={STATUS_SORTING_OPTIONS}
            onChange={handlerFilterStatus}
          />
          <TextButton onClick={onFilterClick}>
            <span>{t('dashboardAdmin.filter')}</span>
            <FilterIconDashboard />
          </TextButton>
        </FiltersWrapper>
      </Actions>
      <TableWrapper>
        <TableHeader
          headers={headers}
          allDataAreSelected={allDataAreSelected}
          handleSelectAll={handleSelectAll}
          requestSort={requestSort}
          sortConfig={sortConfig}
        />
        <TableBody>
          {dataListAreFilled &&
            paginatedData.map((element: any) => (
              <TableContent
                key={element._id}
                element={element}
                render={render}
                handleSelectData={handleSelectData}
                selectedData={selectedData}
              />
            ))}
        </TableBody>
      </TableWrapper>
      {!dataListAreFilled && <EmptyState text={emptyStateTexts.EMPTY_LIST} />}
      {nothingFoundWithFilter && dataListAreFilled && <EmptyState text={emptyStateTexts.EMPTY_FILTER} />}
      <TableFooter>
        {!nothingFoundWithFilter && dataListAreFilled && <Advice>{advice}</Advice>}
        <Pagination
          currentPage={currentPage}
          totalCount={filteredData.length}
          pageSize={PAGE_SIZE}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </TableFooter>
    </Container>
  );
};

export default Table;
