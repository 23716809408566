import React from 'react';

export interface ADPLogoProps {
  width?: number;
  height?: number;
}

export const ADPLogo = ({ width = 31, height = 14 }: ADPLogoProps) => (
  <svg width={width} height={height} viewBox="0 0 31 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.3026 10.9993C28.3635 10.9993 30.8133 8.5378 30.8133 5.49715C30.8133 2.46148 28.3635 0 25.3423 0H20.5571V2.34665C19.295 0.923681 17.4166 0 15.3495 0H8.15427L0 14H5.5455L7.30456 10.9993H10.8276V14H15.3843C17.4514 14 19.29 13.0763 20.5919 11.6534V14H25.3026V10.9993ZM19.3695 7C19.3695 9.23181 17.5707 10.9993 15.3893 10.9993H13.8588V9.26676H15.3893C16.6166 9.26676 17.6502 8.26819 17.6502 6.99501C17.6502 5.76177 16.6564 4.72325 15.3893 4.72325H13.8588V2.99073H15.3893C17.5707 2.99572 19.3695 4.80813 19.3695 7ZM10.8326 3.03566V6.26605H8.03998L7.00641 8.03352H12.5569V3.03566H10.8326ZM15.3495 12.2675C17.4564 12.2675 19.2552 11.0342 20.095 9.26676H22.3162V12.2675H23.5783V9.26676H25.3374C27.4045 9.26676 29.089 7.57418 29.089 5.49715C29.089 3.42011 27.4095 1.72753 25.3423 1.72753H22.3559V6.22611H20.5969C20.1745 3.68973 17.9931 1.72753 15.3545 1.72753H9.22262L3.10071 12.2675H4.55665L6.31571 9.26676H12.5917V12.2675H15.3495ZM23.5833 2.99572H25.3423C26.7188 2.99572 27.8318 4.11412 27.8318 5.56705C27.8318 6.95506 26.7188 8.06847 25.3423 8.06847H23.5833V6.301H25.3423C25.7647 6.301 26.1076 5.95649 26.1076 5.5321C26.1076 5.1077 25.7647 4.7632 25.3423 4.7632H23.5833V2.99572Z"
      fill="white"
    />
  </svg>
);
