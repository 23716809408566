import React from 'react';
import { Bars, StyledBarDanger, StyledBarSuccess, StyledBarWarning } from './style';

interface BarChartProps {
  data?: any;
}

export const BarChart = ({ data }: BarChartProps) => (
  <Bars>
    <StyledBarDanger danger={data?.pending} />
    <StyledBarWarning warning={data?.inProgress} />
    <StyledBarSuccess success={data?.finished} />
  </Bars>
);
