import React from 'react';
import { Controller } from 'react-hook-form';
import { BooleanCondition } from '../../constants';
import { StyledConditionSwitch, SwitchView } from './style';
import RotateIcon from '../../../../../../assets/svgs/RotateIcon';

interface ConditionSwitchProps {
  name: string;
  control: any;
  watch: any;
}

const ConditionSwitch = ({ name, control, watch }: ConditionSwitchProps) => {
  const selectedBooleanCondition = watch(name);

  return (
    <StyledConditionSwitch>
      <Controller
        name={name}
        control={control}
        defaultValue={BooleanCondition.AND}
        render={({ field: { onChange, onBlur, value, name: Tname, ref } }) => (
          <label htmlFor={name}>
            <input
              id={name}
              type="checkbox"
              onChange={(e) => {
                if (e.currentTarget.checked) return onChange(BooleanCondition.OR);
                return onChange(BooleanCondition.AND);
              }}
              onBlur={onBlur}
              value={value}
              name={Tname}
              ref={ref}
            />
            <SwitchView>
              {selectedBooleanCondition === BooleanCondition.OR ? 'OU' : 'E'}
              <RotateIcon />
            </SwitchView>
          </label>
        )}
      />
    </StyledConditionSwitch>
  );
};

export default ConditionSwitch;
