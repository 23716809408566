import styled from 'styled-components';

export const QuestionsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 40px;
`;

export const StyledEmptyState = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  gap: 8px;
  margin-top: 40px;
  color: #979797;
`;
