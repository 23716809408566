import styled from 'styled-components';
import { MAX_WIDTHS } from '../../constants/screen';

export const SearchWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 32px;
`;

interface ActiveProps {
  selected?: boolean;
}

export const SearchInput = styled.input.attrs({
  type: 'text',
})<ActiveProps>`
  background: #ffffff;
  height: 32px;
  box-sizing: border-box;
  border-radius: 8px;
  color: #262321;
  width: 270px;
  padding: 4px 12px;
  border: 2px solid #476bc3;
`;

export const SearchButton = styled.div<ActiveProps>`
  display: flex;
  position: absolute;
  right: 0;
  align-items: center;
  color: #324fa5;
  gap: 12px;
  cursor: pointer;

  @media (max-width: ${MAX_WIDTHS.sm}px) {
    & > span {
      display: none;
    }
  } ;
`;

export const IconWrapper = styled.div<ActiveProps>`
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
`;
