import styled from 'styled-components';

export const MarkableLabel = styled.label`
  width: 20px;
  cursor: pointer;
  display: grid;

  svg {
    width: 20px;
    stroke: #fff;
    stroke-width: 10;
    fill: white;
    stroke-linejoin: round;
    stroke-linecap: round;
    z-index: 1;

    .check {
      stroke-dasharray: 70;
      stroke-dashoffset: 70;
      fill: none;
      transition: stroke-dashoffset 0.2s linear;
    }
  }

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 20px;
    height: 20px;
    background: #ffffff;
    border: 2px solid #938c85;
    box-sizing: border-box;
  }

  &:active {
    animation: zoom-in-out 0.2s ease;
  }

  @keyframes zoom-in-out {
    50% {
      transform: scale(0.8);
    }
  }
`;
