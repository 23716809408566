import React from 'react';

interface RuleIconProps {
  width?: number;
  height?: number;
}

const RuleIcon = ({ width = 13, height = 12 }: RuleIconProps) => (
  <svg width={width} height={height} viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.25 0.152344C11.2856 0.152344 12.125 0.991719 12.125 2.02734C12.125 3.06297 11.2856 3.90234 10.25 3.90234H8.375C7.55875 3.90234 6.86437 3.38109 6.60687 2.65234H5.875C5.1875 2.65234 4.625 3.21484 4.625 3.90234V4.00922C5.35375 4.26672 5.875 4.96109 5.875 5.77734C5.875 6.59359 5.35375 7.28797 4.625 7.54547V7.65234C4.625 8.33984 5.1875 8.90234 5.875 8.90234H6.60625C6.86375 8.17422 7.55875 7.65234 8.375 7.65234H10.25C11.2856 7.65234 12.125 8.49172 12.125 9.52734C12.125 10.563 11.2856 11.4023 10.25 11.4023H8.375C7.55875 11.4023 6.86437 10.8811 6.60687 10.1523H5.875C4.49375 10.1523 3.375 9.03359 3.375 7.65234H2.125C1.08938 7.65234 0.25 6.81297 0.25 5.77734C0.25 4.74172 1.08938 3.90234 2.125 3.90234H3.375C3.375 2.52109 4.49375 1.40234 5.875 1.40234H6.60625C6.86375 0.674219 7.55875 0.152344 8.375 0.152344H10.25ZM10.25 8.90234H8.375C8.03 8.90234 7.75 9.18234 7.75 9.52734C7.75 9.87234 8.03 10.1523 8.375 10.1523H10.25C10.595 10.1523 10.875 9.87234 10.875 9.52734C10.875 9.18234 10.595 8.90234 10.25 8.90234ZM4 5.15234H2.125C1.78 5.15234 1.5 5.43234 1.5 5.77734C1.5 6.12234 1.78 6.40234 2.125 6.40234H4C4.345 6.40234 4.625 6.12234 4.625 5.77734C4.625 5.43234 4.345 5.15234 4 5.15234ZM10.25 1.40234H8.375C8.03 1.40234 7.75 1.68234 7.75 2.02734C7.75 2.37234 8.03 2.65234 8.375 2.65234H10.25C10.595 2.65234 10.875 2.37234 10.875 2.02734C10.875 1.68234 10.595 1.40234 10.25 1.40234Z"
      fill="white"
    />
  </svg>
);

export default RuleIcon;
