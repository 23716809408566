import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useJwtContext } from '../../../JwtContext';
import { UserInfos } from '../../../types/UserInfos';
import { UserIcon } from '../style';
import { getFromLocalStorage } from '../../../utils/storageUtils';
import { ProjectHolder, Box } from './styles';
import { Creators as modalActions } from '../../../redux/reducers/modal-reducer';
import { PasswordChangeModal } from '../PasswordChange';
import { ChangePasswordIcon } from '../../../assets/svgs/ChangePasswordIcon';

export const UserShortProfile = ({ setOpenProfile }: { setOpenProfile: (p: boolean) => void }) => {
  const decodedJwt = useJwtContext();
  const selectedProject = getFromLocalStorage('selectedProject');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { name, email, type } = decodedJwt as UserInfos;
  const dispatch = useDispatch();
  const { openModal } = modalActions;

  const getUserNameAcronym = () => {
    if (Object.keys(decodedJwt).length === 0) return '';

    if (name === email) {
      const nameFromEmail = email.substring(0, email.lastIndexOf('@'));

      const matches = nameFromEmail.match(/\b(\w)/g);
      if (!matches) return '';

      return matches.join('').toUpperCase();
    }

    const matches = name.match(/\b(\w)/g);
    if (!matches) return '';

    return matches.join('').toUpperCase();
  };
  const handleNavigate = () => {
    navigate('/project-selection');
    setOpenProfile(false);
  };
  const handlePasswordChange = () => {
    dispatch(
      openModal({
        content: <PasswordChangeModal />,
      })
    );
    setOpenProfile(false);
  };

  return (
    <Box>
      <UserIcon style={{ width: 40, height: 40, fontSize: 16 }}>{getUserNameAcronym()}</UserIcon>

      <p className="name">{decodedJwt.name}</p>
      <p className="email">{decodedJwt.email}</p>
      {['SPONSOR', 'ANSWERER'].includes(type) && (
        <ProjectHolder onClick={handleNavigate}>
          <p>
            {t('userShortProfile.project')}: {selectedProject}
          </p>
          <svg width="15" height="15" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.1125 6.02914L11 8.91664L8.1125 11.8041L7.28767 10.9793L8.767 9.49939L1.08333 9.49997V8.3333H8.767L7.28767 6.85397L8.1125 6.02914ZM3.3875 0.195801L4.21233 1.02063L2.733 2.49997H10.4167V3.66663H2.733L4.21233 5.14597L3.3875 5.9708L0.5 3.0833L3.3875 0.195801Z"
              fill="#5F86D8"
            />
          </svg>
        </ProjectHolder>
      )}
      <ProjectHolder onClick={handlePasswordChange}>
        <p>{t('login.changePassword')}:</p>
        <ChangePasswordIcon fill="#5F86D8" />
      </ProjectHolder>
    </Box>
  );
};
