import styled from 'styled-components';

export const BranchQuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  padding: 24px;
  box-sizing: border-box;
  align-items: flex-start;
  border: 1px solid #d8d8d8;
  border-radius: 16px;
`;
