/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldTitle } from '../../style';
import PanelField from './components/PanelField';
import { DropdownWrapper, PanelAccordion, PanelAccordionContent, PanelAccordionHeader, PanelTitle } from './style';
import { DropdownIcon } from '../../../../../../assets/svgs/DropdownIcon';
import ErrorWithIcon from '../../../../../../components/ErrorWithIcon';
import { useFormCustomRerender, useFormCustomRerenderMultipleForm } from '../../../DefaultForm/useFormCustomRerender';
import { useJwtContext } from '../../../../../../JwtContext';
import { useInternalVariableReplacer } from '../../../DefaultForm/useInternalVariableReplacer';

const useFormCustomFork = (tabId: any, item: any, nestedAccessFieldName: any, dateFormat: any, props: any) => {
  if (tabId !== undefined) {
    return useFormCustomRerenderMultipleForm(item, nestedAccessFieldName, tabId, dateFormat, props);
  }
  return useFormCustomRerender(item, nestedAccessFieldName, dateFormat, props);
};

const PanelItem = ({ accessFieldsName, control, register, errors, wId, type, index, nestIndex, ...props }: any) => {
  const nestedAccessFieldName = `${accessFieldsName}.answers[${index}]`;
  const item = { wId, rules: props.rules, answer: props.answer, branchId: props.branchId, title: props.title };
  const decodedJwt = useJwtContext();
  const dateFormat = decodedJwt?.dateFormat;
  const shouldRender = useFormCustomFork(props.tabId, item, nestedAccessFieldName, dateFormat, {
    branchIndex: props?.branchIndex,
  });
  const replacedTitle = useInternalVariableReplacer(item, nestedAccessFieldName, {
    branchIndex: props?.branchIndex,
  });
  if (!shouldRender) return <></>;
  return (
    <PanelField
      errors={errors}
      control={control}
      register={register}
      wId={wId}
      accessFieldsName={nestedAccessFieldName}
      fieldType={type}
      {...props}
      title={replacedTitle}
    />
  );
};

const Panel = ({
  branchId = '',
  accessFieldsName,
  nestIndex,
  errors,
  control,
  register,
  wId,
  title,
  questions,
  tabId,
}: any) => {
  const [open, setOpen] = useState(true);

  const hasNestedQuestionsWithErrors =
    tabId !== undefined ? errors?.answers?.[tabId]?.[nestIndex] : errors?.answers?.[nestIndex];
  const { t } = useTranslation();

  return (
    <PanelAccordion>
      <PanelAccordionHeader onClick={() => setOpen(!open)}>
        <PanelTitle>
          <FieldTitle>{title}</FieldTitle>
          {hasNestedQuestionsWithErrors && <ErrorWithIcon message={t('questions.alert')} />}
        </PanelTitle>
        <DropdownWrapper open={open}>
          <DropdownIcon />
        </DropdownWrapper>
      </PanelAccordionHeader>
      <PanelAccordionContent visible={open}>
        {questions.map((props: any, index: number) => (
          <PanelItem
            {...props}
            branchId={branchId}
            control={control}
            register={register}
            nestIndex={nestIndex}
            index={index}
            errors={errors}
            accessFieldsName={accessFieldsName}
            key={`panel-${wId}-item${props.wId}-${branchId}`}
            tabId={tabId}
          />
        ))}
      </PanelAccordionContent>
    </PanelAccordion>
  );
};

export default Panel;
