import React, { useRef, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Container, FooterWrapper } from './style';
import Footer from '../../../components/Form/Footer';
import { CreateIcon } from '../../../assets/svgs/CreateIcon';
import TextButton from '../../../components/TextButton';
import Button from '../../../components/Button';
import { FORM_DEFAULT_VALUES, INITIAL_VALUE_QUESTION } from '../constants';
import GenericFormFields from '../GenericFormFields';
import DeleteModal from '../../../components/DefaultModalContent';
import { Creators as modalActions } from '../../../redux/reducers/modal-reducer';
import formatFormData from '../utils/formatFormData';
import { createFormRequest } from '../../../requests/forms';
import Alert from '../../../components/Toast/toast';
import { IFormData } from '../types/FormData';
import { Breadcrumbs } from '../../../components/BreadCrumb';
import { BreadcrumbWrapper } from '../../../components/BreadCrumb/style';
import { Tooltip } from '../../../components/Tooltip';

const FormCreate = () => {
  const [loadingButton, setLoadingButton] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { openModal } = modalActions;
  const { t } = useTranslation();

  const { handleSubmit, control, getValues, getFieldState, formState, trigger, watch, register, setValue, setError } =
    useForm<IFormData>({
      defaultValues: FORM_DEFAULT_VALUES(),
      mode: 'onChange',
    });

  const { fields, append, remove, move, update } = useFieldArray({
    control,
    name: 'questions',
  });

  const onSubmit = (navigateToTest: boolean) => async (data: any) => {
    setLoadingButton(true);
    const formattedData = formatFormData(data);
    try {
      const res = await createFormRequest(formattedData);
      Alert.SUCCESS(t('form.createForm'));
      navigate(navigateToTest ? `/forms/test/${res.data.wId}` : '/forms');
    } catch (e: any) {
      Alert.ERROR(e.response.data.message);
    } finally {
      setLoadingButton(false);
    }
  };

  const deleteSubmit = () => {
    const handler = () => {
      navigate('/forms');
    };

    dispatch(
      openModal({
        content: <DeleteModal onSubmit={handler} title={t('form.alert')} submitButtonTitle={t('common.delete')} />,
      })
    );
  };

  const openVariableCreatorModal = useRef<any>();
  const setOpenVariableCreatorModal = (val: any) => {
    openVariableCreatorModal.current = val;
  };

  return (
    <Container>
      <BreadcrumbWrapper>
        <Breadcrumbs />
      </BreadcrumbWrapper>
      <GenericFormFields
        {...{
          control,
          getValues,
          getFieldState,
          formState,
          trigger,
          watch,
          register,
          fields,
          append,
          remove,
          move,
          update,
          setValue,
          deleteSubmit,
          setError,
          enableMapToDefaultValues: false,
          setOpenVariableCreatorModal,
        }}
      />
      <Footer>
        <FooterWrapper>
          <TextButton onClick={() => openVariableCreatorModal.current()}>
            <p>Variáveis internas</p>
            <CreateIcon />
          </TextButton>
          <TextButton onClick={() => append(INITIAL_VALUE_QUESTION())}>
            <p>{t('form.newQuestion')}</p>
            <CreateIcon />
          </TextButton>
          <Button loading={loadingButton} onClick={handleSubmit(onSubmit(true))}>
            {t('form.saveDraftTest')}
          </Button>
          <Button loading={loadingButton} onClick={handleSubmit(onSubmit(false))}>
            {t('form.saveDraft')}
          </Button>
          <Tooltip
            content={
              <>
                {t('form.toolTipOrder')} <br /> {t('form.toolTipTest')}
              </>
            }
          >
            <Button disabled primary>
              {t('form.publish')}
            </Button>
          </Tooltip>
        </FooterWrapper>
      </Footer>
    </Container>
  );
};

export default FormCreate;
