import styled, { css } from 'styled-components';
import { ButtonGroupItem } from '../../components/ButtonGroup/style';

interface RuleButtonProp {
  hasRules: boolean;
}

export const RuleButton = styled(ButtonGroupItem)<RuleButtonProp>`
  position: relative;

  ${({ hasRules }) =>
    hasRules &&
    css`
      &:before {
        content: '';
        box-sizing: border-box;
        position: absolute;
        width: 12px;
        height: 12px;
        top: 0;
        right: -9px;
        background: #ff6e00;
        border: 2px solid #476bc3;
        border-radius: 50%;
      }
    `}
`;
