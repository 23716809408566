import React, { useEffect } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Container, DeleteIconWrapper, FileLinkContainer, Title } from './style';
import { DeleteIcon } from '../../../../../../assets/svgs/DeleteIcon';
import Input from '../../../../../../components/Input';
import TextButton from '../../../../../../components/TextButton';
import Alert from '../../../../../../components/Toast/toast';
import UploadInput from '../../../../../../components/UploadInput';
import { uploadFile } from '../../../../../../requests/files';

interface FileLinkProps {
  deleteHandler: (index: number) => void;
  index: number;
  accessFieldsName: string;
  control: any;
}

const FileLinkInput = ({ deleteHandler, index, control, accessFieldsName }: FileLinkProps) => {
  const { t } = useTranslation();
  return (
    <FileLinkContainer>
      <Controller
        name={accessFieldsName ? `${accessFieldsName}.files[${index}].link` : `files[${index}].link`}
        control={control}
        render={({ field }) => <Input {...field} type="text" placeholder={t('form.example')} />}
      />
      <DeleteIconWrapper onClick={() => deleteHandler(index)}>
        <DeleteIcon />
      </DeleteIconWrapper>
    </FileLinkContainer>
  );
};

const Download = ({ control, accessFieldsName }: any) => {
  const { id } = useParams<{ id: string }>();
  const { fields, remove, append } = useFieldArray({
    control,
    name: accessFieldsName ? `${accessFieldsName}.files` : 'files',
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (fields.length === 0) {
      append({ link: '' });
    }
  }, []);

  const deleteHandler = (index: number) => {
    if (fields.length > 1) return remove(index);

    Alert.WARNING(t('questions.downloadDeleteWarn'));
  };

  const uploadHandler = async (event: any) => {
    const { files } = event.target;

    if (!files) return;

    [...files].forEach((file: File) => {
      const fileData = new FormData();
      fileData.append('file', file);
      fileData.append('formId', id ?? 'nonSavedForm');

      const options = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent: ProgressEvent) => {
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / total);
          console.log(`${loaded}kb of ${total}kb | ${percent}%`);
        },
      };

      try {
        uploadFile(fileData, options).then(({ data }: { data: { key: string } }) => {
          const { key } = data;

          append({ link: key });
        });
      } catch (e) {
        console.error(e);
      }
    });
  };

  return (
    <Container>
      <Title>{t('form.downloadTitle')}</Title>
      {fields.map((item, index) => (
        <FileLinkInput
          deleteHandler={deleteHandler}
          control={control}
          key={item.id}
          index={index}
          accessFieldsName={accessFieldsName}
        />
      ))}
      <TextButton style={{ justifyContent: 'left' }} onClick={() => append({ link: '' })}>
        <p>{t('form.addURL')}</p>
      </TextButton>
      <UploadInput id={`${accessFieldsName}uploadButton`} onChange={uploadHandler} />
    </Container>
  );
};

export default Download;
