import React from 'react';

interface IconAlertProps {
  width?: number;
  height?: number;
  className?: string;
}

export const IconAlert = ({ width = 12, height = 12, className }: IconAlertProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.00008 0.166504C2.78008 0.166504 0.166748 2.77984 0.166748 5.99984C0.166748 9.21984 2.78008 11.8332 6.00008 11.8332C9.22008 11.8332 11.8334 9.21984 11.8334 5.99984C11.8334 2.77984 9.22008 0.166504 6.00008 0.166504ZM6.58341 8.33317C6.58341 8.65534 6.32225 8.9165 6.00008 8.9165C5.67792 8.9165 5.41675 8.65534 5.41675 8.33317C5.41675 8.011 5.67792 7.74984 6.00008 7.74984C6.32225 7.74984 6.58341 8.011 6.58341 8.33317ZM6.00008 6.58317C6.32225 6.58317 6.58341 6.322 6.58341 5.99984V3.6665C6.58341 3.34434 6.32225 3.08317 6.00008 3.08317C5.67792 3.08317 5.41675 3.34434 5.41675 3.6665V5.99984C5.41675 6.322 5.67792 6.58317 6.00008 6.58317Z"
      fill="#262321"
    />
  </svg>
);
