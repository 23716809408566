import React from 'react';

export interface DataPurgeWordsConfigContextProps {
  editState?: any;
  dispatchEdit?: any;
  createState?: any;
  dispatchCreate?: any;
}

const DataPurgeWordsConfigContext = React.createContext<DataPurgeWordsConfigContextProps>({});

export default DataPurgeWordsConfigContext;
