import React from 'react';

export interface Ellipse20Props {
  width?: number;
  height?: number;
  className?: string;
}

export const Ellipse20 = ({ width = 150, height = 113, className }: Ellipse20Props) => (
  <svg
    id="el20"
    className={className}
    width={width}
    height={height}
    viewBox="0 0 150 113"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g style={{ mixBlendMode: 'soft-light' }}>
      <ellipse cx="75" cy="41.5" rx="71.5" ry="75" transform="rotate(90 75 41.5)" fill="#FFB1A3" fillOpacity="0.63" />
    </g>
  </svg>
);
