import styled, { css } from 'styled-components';

export const scrollStyle = css`
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    display: none;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #c6c6c6;
    border-radius: 10px;
  }
`;

export const MobileAlert = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;
