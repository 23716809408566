import React, { useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { ActionsWrapper, Container, Title } from './style';
import EmailPermissionsContext from './context';
import { ActionsKind, toggleReducer } from './reducers';
import {
  addEmailToAllowListRequest,
  addEmailToBlockListRequest,
  deleteEmailsToAllowListRequest,
  deleteEmailsToBlockListRequest,
  findAllAllowListRequest,
  findAllBlockListRequest,
  updateEmailToAllowListRequest,
  updateEmailToBlockListRequest,
} from '../../requests/country';
import Alert from '../../components/Toast/toast';
import Table from '../../components/Table';
import { InlineCreate, InlineEdit } from './components/InlineForm';
import { Tab, TabPanel, Tabs } from '../../components/Tabs';
import { EditIcon } from '../../assets/svgs/EditIcon';
import { DeleteIcon } from '../../assets/svgs/DeleteIcon';
import { ActionIcon } from '../../components/Table/style';
import { TableData } from '../../types/TableData';
import TextButton from '../../components/TextButton';
import { LoaderWrapper } from '../../components/Loader/style';
import Loader from '../../components/Loader';
import { Breadcrumbs } from '../../components/BreadCrumb';
import { BreadcrumbWrapper } from '../../components/BreadCrumb/style';

export const AllowListContent = () => {
  const [emails, setEmails] = useState<TableData[]>([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const { editState, dispatchEdit } = useContext(EmailPermissionsContext);

  const getEmails = async () => {
    const data = await findAllAllowListRequest();
    if (data) {
      const dataWithId = data.map((email: string) => ({ _id: uuidv4(), email }));
      setEmails(dataWithId);
    }
  };

  useEffect(() => {
    setLoading(true);
    getEmails().then(() => setLoading(false));
    return () => {
      setEmails([]);
    };
  }, []);

  const ALLOW_LIST_ADVICE = t('allowList.advice');
  const EMPTY_STATE_TEXTS = {
    EMPTY_FILTER: t('allowList.emptyFilter'),
    EMPTY_LIST: t('allowList.emptyList'),
  };

  const inlineEditHandler = async (email: string, currentEmail: string) => {
    try {
      await updateEmailToAllowListRequest(email, currentEmail);
      await getEmails();
      Alert.SUCCESS(t('allowList.edit'));
    } catch (e: any) {
      Alert.ERROR(e?.response?.data?.message);
    }
  };

  const createHandler = async (email: string) => {
    try {
      await addEmailToAllowListRequest(email);
      await getEmails();
      Alert.SUCCESS(t('allowList.create'));
    } catch (e: any) {
      Alert.ERROR(e?.response?.data?.message);
    }
  };

  const editHandler = async (data: TableData) => {
    if (editState.open) {
      return dispatchEdit({ type: ActionsKind.CLOSE });
    }
    return dispatchEdit({ type: ActionsKind.OPEN, payload: data._id });
  };

  const deleteHandler = async (...data: TableData[]) => {
    const emailsToDelete = data.map((item) => item.email);
    try {
      await deleteEmailsToAllowListRequest(emailsToDelete);
      await getEmails();
      Alert.SUCCESS(t('allowList.delete'));
    } catch (e: any) {
      Alert.ERROR(e?.response?.data?.message);
    }
  };

  const render = (element: any) => [
    <InlineEdit
      open={editState.open && element._id === editState.data}
      defaultValue={element.email}
      handler={inlineEditHandler}
    />,
    <ActionsWrapper>
      <ActionIcon onClick={() => editHandler(element)}>
        <EditIcon />
      </ActionIcon>
      <ActionIcon onClick={() => deleteHandler(element)}>
        <DeleteIcon />
      </ActionIcon>
    </ActionsWrapper>,
  ];

  const headers = [
    {
      head: t('allowList.emails'),
      key: 'email',
    },
  ];

  const renderActions = (selectedData: any[]) => [
    <TextButton onClick={() => deleteHandler(...selectedData)} disabled={selectedData.length === 0}>
      <span>{t('common.buttonDelete')}</span>
      <DeleteIcon fill={selectedData.length > 0 ? '#4C65B0' : '#979797'} />
    </TextButton>,
  ];

  return loading ? (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  ) : (
    <Table
      InlineCreate={InlineCreate}
      render={render}
      emptyStateTexts={EMPTY_STATE_TEXTS}
      data={emails}
      headers={headers}
      advice={ALLOW_LIST_ADVICE}
      createHandler={createHandler}
      renderActions={renderActions}
      hasInlineCreate
      searchKey="email"
    />
  );
};

export const BlockListContent = () => {
  const [emails, setEmails] = useState<TableData[]>([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const { editState, dispatchEdit } = useContext(EmailPermissionsContext);

  const getEmails = async () => {
    setLoading(true);
    const data = await findAllBlockListRequest();
    if (data) {
      const dataWithId = data.map((email: string) => ({ _id: uuidv4(), email }));
      setEmails(dataWithId);
    }
    setLoading(false);
  };

  useEffect(() => {
    getEmails();
    return () => {
      setEmails([]);
    };
  }, []);

  const BLOCK_LIST_ADVICE = t('blockList.advice');
  const EMPTY_STATE_TEXTS = {
    EMPTY_FILTER: t('blockList.emptyFilter'),
    EMPTY_LIST: t('blockList.emptyList'),
  };

  const inlineEditHandler = async (email: string, currentEmail: string) => {
    try {
      await updateEmailToBlockListRequest(email, currentEmail);
      await getEmails();
      Alert.SUCCESS(t('blockList.edit'));
    } catch (e: any) {
      Alert.ERROR(e?.response?.data?.message);
    }
  };

  const createHandler = async (email: string) => {
    try {
      await addEmailToBlockListRequest(email);
      await getEmails();
      Alert.SUCCESS(t('blockList.create'));
    } catch (e: any) {
      Alert.ERROR(e?.response?.data?.message);
    }
  };

  const editHandler = async (data: TableData) => {
    if (editState.open) {
      return dispatchEdit({ type: ActionsKind.CLOSE });
    }
    return dispatchEdit({ type: ActionsKind.OPEN, payload: data._id });
  };

  const deleteHandler = async (...data: TableData[]) => {
    const emailsToDelete = data.map((item) => item.email);

    try {
      await deleteEmailsToBlockListRequest(emailsToDelete);
      await getEmails();
      Alert.SUCCESS(t('blockList.delete'));
    } catch (e: any) {
      Alert.ERROR(e?.response?.data?.message);
    }
  };

  const render = (element: any) => [
    <InlineEdit
      open={editState.open && element._id === editState.data}
      defaultValue={element.email}
      handler={inlineEditHandler}
    />,
    <ActionsWrapper>
      <ActionIcon onClick={() => editHandler(element)}>
        <EditIcon />
      </ActionIcon>
      <ActionIcon onClick={() => deleteHandler(element)}>
        <DeleteIcon />
      </ActionIcon>
    </ActionsWrapper>,
  ];

  const headers = [
    {
      head: t('blockList.emails'),
      key: 'email',
    },
  ];

  const renderActions = (selectedData: any[]) => [
    <TextButton onClick={() => deleteHandler(...selectedData)} disabled={selectedData.length === 0}>
      <span>{t('common.buttonDelete')}</span>
      <DeleteIcon fill={selectedData.length > 0 ? '#4C65B0' : '#979797'} />
    </TextButton>,
  ];

  return loading ? (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  ) : (
    <Table
      InlineCreate={InlineCreate}
      emptyStateTexts={EMPTY_STATE_TEXTS}
      render={render}
      data={emails}
      headers={headers}
      advice={BLOCK_LIST_ADVICE}
      createHandler={createHandler}
      renderActions={renderActions}
      hasInlineCreate
      searchKey="email"
    />
  );
};

const EmailPermissions = () => {
  const [editState, dispatchEdit] = useReducer(toggleReducer, { open: false });
  const [createState, dispatchCreate] = useReducer(toggleReducer, { open: false });
  const [selectedTab, setSelectedTab] = useState(0);

  const { t } = useTranslation();

  const context = useMemo(
    () => ({
      editState,
      dispatchEdit,
      createState,
      dispatchCreate,
    }),
    [editState, dispatchEdit, createState, dispatchCreate]
  );

  useEffect(() => {
    dispatchCreate({ type: ActionsKind.CLOSE });
    dispatchEdit({ type: ActionsKind.CLOSE });
  }, [selectedTab]);

  const handleChangeTabs = (event: MouseEvent, newSelectedTab: number) => {
    setSelectedTab(newSelectedTab);
  };

  return (
    <EmailPermissionsContext.Provider value={context}>
      <Container>
        <BreadcrumbWrapper>
          <Breadcrumbs />
        </BreadcrumbWrapper>
        <Title>{t('emailPermissions.title')}</Title>
        <Tabs selectedTab={selectedTab} onChange={handleChangeTabs}>
          <Tab label="Blocklist" />
          <Tab label="Allowlist" />
        </Tabs>
        <TabPanel selectedTab={selectedTab} index={0}>
          <BlockListContent />
        </TabPanel>
        <TabPanel selectedTab={selectedTab} index={1}>
          <AllowListContent />
        </TabPanel>
      </Container>
    </EmailPermissionsContext.Provider>
  );
};

export default EmailPermissions;
