import React from 'react';
import { components, DropdownIndicatorProps } from 'react-select';
import { useTranslation } from 'react-i18next';
import { DropdownIcon } from '../../assets/svgs/DropdownIcon';
import { DropdownWrapper, StyledSelect, Label, Wrapper } from './style';

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  // eslint-disable-next-line react/destructuring-assignment
  const { menuIsOpen } = props.selectProps;

  const dropdownClassname = menuIsOpen ? 'dropdown-icon open' : 'dropdown-icon';

  return (
    <components.DropdownIndicator {...props}>
      <DropdownWrapper menuIsOpen={menuIsOpen}>
        <DropdownIcon className={dropdownClassname} />
      </DropdownWrapper>
    </components.DropdownIndicator>
  );
};

const Select = React.forwardRef(({ label, innerRef, placeholder, disabled, ...props }: any, ref) => {
  const { t } = useTranslation();

  return (
    <Wrapper onClick={(e) => e.stopPropagation()}>
      <Label>{label}</Label>
      <StyledSelect
        ref={ref}
        isClearable
        isSearchable={false}
        isDisabled={disabled}
        components={{ DropdownIndicator }}
        classNamePrefix="Select"
        placeholder={placeholder || t('select.placeholder')}
        noOptionsMessage={() => t('select.emptyState')}
        {...props}
      />
    </Wrapper>
  );
});

export default Select;
