import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PreviewField from '../../../../../../../components/Form/PreviewField';
import { ButtonGroupItem } from '../../../../../../../components/ButtonGroup/style';
import { EditIcon } from '../../../../../../../assets/svgs/EditIcon';
import { DeleteIcon } from '../../../../../../../assets/svgs/DeleteIcon';
import ButtonGroup from '../../../../../../../components/ButtonGroup';
// import MoveIcon from '../../../../../../../assets/svgs/MoveIcon';
import { StyledQuestionPreview } from './style';
import FormContext from '../../../../context';
import { FormViewContext } from '../../../../../FormView/context';
import { DownIcon } from '../../../../../../../assets/svgs/DownIcon';
import { UpIcon } from '../../../../../../../assets/svgs/UpIcon';

const QuestionPreview = ({
  fieldType,
  dragHandleProps,
  index,
  deleteHandler,
  customFieldTypeProps,
  title,
  blockDeletion,
  ...props
}: any) => {
  const { viewMode } = useContext(FormViewContext);
  const [isHover, setIsHover] = useState(false);
  const { getValues, update } = useContext(FormContext);

  const editHandler = async () => {
    const values = getValues(`questions[${index}]`);
    values.saved = false;
    await update(index, values);
  };
  const { t } = useTranslation();

  const getTitleWithIndex = () => `ID${index} - ${title}`;

  return (
    <StyledQuestionPreview hover={isHover} onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
      <PreviewField
        fieldType={fieldType}
        customFieldTypeProps={customFieldTypeProps}
        title={getTitleWithIndex()}
        {...props}
      />
      {!viewMode && isHover && (
        <div>
          <ButtonGroup>
            <ButtonGroupItem onClick={editHandler}>
              <p>{t('common.edit')}</p>
              <EditIcon />
            </ButtonGroupItem>
            {!blockDeletion && (
              <ButtonGroupItem onClick={() => deleteHandler(index)}>
                <p>{t('common.delete')}</p>
                <DeleteIcon />
              </ButtonGroupItem>
            )}
            {dragHandleProps.up.show && (
              <ButtonGroupItem {...dragHandleProps.up}>
                <p>{t('common.up')}</p>
                <UpIcon />
              </ButtonGroupItem>
            )}
            {dragHandleProps.down.show && (
              <ButtonGroupItem {...dragHandleProps.down}>
                <p>{t('common.down')}</p>
                <DownIcon />
              </ButtonGroupItem>
            )}
          </ButtonGroup>
        </div>
      )}
    </StyledQuestionPreview>
  );
};

export default QuestionPreview;
