import { View, Text, Link, Path, Svg } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../../style';

const Download = ({ title, links }: any) => (
  <View style={{ marginBottom: 10 }} wrap={false}>
    <Text style={styles.title}>{title}</Text>
    {links.map((source: string) => (
      <View style={styles.wrapper}>
        <Svg width="10" height="10" viewBox="0 0 10 10">
          <Path
            d="M7.65165 6.47333L7.06248 5.88333L7.65165 5.29416C7.8465 5.10101 8.00127 4.87128 8.1071 4.61816C8.21292 4.36504 8.2677 4.0935 8.26831 3.81915C8.26891 3.54479 8.21531 3.27302 8.1106 3.01944C8.00588 2.76585 7.85212 2.53544 7.65812 2.34145C7.46412 2.14745 7.23371 1.99368 6.98013 1.88896C6.72654 1.78425 6.45477 1.73066 6.18042 1.73126C5.90606 1.73186 5.63453 1.78664 5.3814 1.89247C5.12828 1.99829 4.89855 2.15307 4.7054 2.34791L4.11623 2.93749L3.52665 2.34833L4.11665 1.75916C4.66366 1.21215 5.40556 0.904846 6.17915 0.904846C6.95274 0.904846 7.69464 1.21215 8.24165 1.75916C8.78866 2.30617 9.09597 3.04807 9.09597 3.82166C9.09597 4.59525 8.78866 5.33715 8.24165 5.88416L7.65207 6.47333H7.65165ZM6.47332 7.65166L5.88373 8.24083C5.33673 8.78784 4.59482 9.09514 3.82123 9.09514C3.04765 9.09514 2.30574 8.78784 1.75873 8.24083C1.21173 7.69382 0.904419 6.95192 0.904419 6.17833C0.904419 5.40474 1.21173 4.66284 1.75873 4.11583L2.34832 3.52666L2.93748 4.11666L2.34832 4.70583C2.15347 4.89897 1.99869 5.12871 1.89287 5.38183C1.78705 5.63495 1.73226 5.90649 1.73166 6.18084C1.73106 6.4552 1.78466 6.72697 1.88937 6.98055C1.99408 7.23414 2.14785 7.46455 2.34185 7.65854C2.53585 7.85254 2.76626 8.00631 3.01984 8.11103C3.27343 8.21574 3.5452 8.26933 3.81955 8.26873C4.09391 8.26813 4.36544 8.21335 4.61857 8.10753C4.87169 8.0017 5.10142 7.84692 5.29457 7.65208L5.88373 7.06291L6.47332 7.65208V7.65166ZM6.17832 3.23208L6.7679 3.82166L3.82165 6.7675L3.23207 6.17833L6.17832 3.23249V3.23208Z"
            fill="#476BC3"
          />
        </Svg>
        <View style={styles.link}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link src={source}>{source}</Link>
        </View>
      </View>
    ))}
  </View>
);

export default Download;
