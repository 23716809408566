import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Creators as modalActions } from '../../redux/reducers/modal-reducer';
import Forgot from './Forgot';
import RequestSent from './RequestSent';
import LoginForm from './LoginForm';
import RegisterPassword from './RegisterPassword';
import Success from './Success';
import Validate from './Validate';
import ModalContent from './ModalContent';

import {
  Body,
  Container,
  OrangSide,
  OrangeSideText,
  Welcome,
  Text,
  Form,
  FooterInfos,
  CopyRight,
  Privacy,
  StyledImgPatternRight,
  StyledImgPatternLeft,
  StyledEllipse16,
  StyledEllipse17,
  StyledEllipse18,
  StyledEllipse19,
  StyledEllipse20,
  StyledEllipse21,
  StyledShape,
  StyledShapeCopyRight,
  StyledOpenModal,
  StyledPrivacy,
} from './style';

type Current = 'login' | 'register' | 'forgot' | 'requestsent' | 'firstaccess' | 'success' | 'validate';

const LoginComponent = () => {
  const [params] = useSearchParams();
  const passwordChangeToken = params.get('passwordChangeToken');
  const [form, setForm] = useState<Current>(passwordChangeToken ? 'register' : 'login');
  const [providedPassword, setProvidedPassword] = useState<string | undefined>();
  const { t } = useTranslation();
  const getCurrentForm = () => {
    switch (form) {
      case 'login':
        return <LoginForm setForm={setForm} />;
      case 'firstaccess':
        return <RegisterPassword setForm={setForm} firstAccess setProvidedPassword={setProvidedPassword} />;
      case 'register':
        return <RegisterPassword setForm={setForm} />;
      case 'forgot':
        return <Forgot setForm={setForm} />;
      case 'requestsent':
        return <RequestSent setForm={setForm} />;
      case 'validate':
        return <Validate setForm={setForm} providedPassword={providedPassword} />;
      case 'success':
        return <Success setForm={setForm} />;
      default:
        return <LoginForm setForm={setForm} />;
    }
  };

  const dispatch = useDispatch();
  const { openModal } = modalActions;

  const copyRightYear = () => {
    const year = new Date().getFullYear();
    return year;
  };

  return (
    <Body container align="center" justify="center">
      <StyledImgPatternLeft />
      <Container container id="container" relative>
        <OrangSide item xs={12} md={6} container direction="column" justify="center" relative>
          <StyledEllipse20 />
          <StyledEllipse17 />
          <StyledEllipse18 />
          <StyledEllipse21 />
          <StyledEllipse19 />
          <StyledEllipse16 />
          <OrangeSideText>
            <StyledShape />
            <Welcome>{t('login.welcome')}</Welcome>
            <Text>{t('login.welcomeText')}</Text>
          </OrangeSideText>
        </OrangSide>
        <Form item xs={12} md={6} relative>
          {getCurrentForm()}
        </Form>
      </Container>
      <FooterInfos>
        <CopyRight>
          CopyRight ©{copyRightYear()}, ADP, LLC. {t('login.copyRight')}
        </CopyRight>
        <StyledOpenModal
          onClick={() =>
            dispatch(
              openModal({
                title: t('login.modalTitle'),
                content: <ModalContent />,
              })
            )
          }
        >
          <StyledPrivacy>
            <StyledShapeCopyRight />
            <Privacy>{t('login.privacy')}</Privacy>
          </StyledPrivacy>
        </StyledOpenModal>
      </FooterInfos>
      <StyledImgPatternRight />
    </Body>
  );
};

export default LoginComponent;
