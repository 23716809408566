import api from '../http/api';
import { TableData } from '../types/TableData';

const CUSTOM_TYPE = '/custom-type';

export const findAllQuestionSettingsRequest = async () => {
  const { data } = await api.get(CUSTOM_TYPE);
  return data;
};

export const findByIdQuestionSettingsRequest = async (id: string) => {
  const { data } = await api.get(`${CUSTOM_TYPE}/${id}`);
  return data;
};

export const addQuestionSettingsRequest = async (data: TableData) => {
  await api.post(CUSTOM_TYPE, data);
};

export const updateQuestionSettingsRequest = async (data: TableData, id: string) => {
  await api.put(`${CUSTOM_TYPE}/${id}`, data);
};

export const blockCustomQuestionRequest = async (data: TableData[]) => {
  const ids = data.map((item) => item._id);

  await api.patch(`${CUSTOM_TYPE}/inactive`, { ids });
};

export const unblockCustomQuestionRequest = async (data: TableData[]) => {
  const ids = data.map((item) => item._id);

  await api.patch(`${CUSTOM_TYPE}/reactive`, { ids });
};
