import React from 'react';

interface UpIconProps {
  width?: number;
  height?: number;
  fill?: string;
}

export const UpIcon = ({ width = 15, height = 17, fill = '#262321' }: UpIconProps) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.293,1.293a1,1,0,0,1,.325-.216.986.986,0,0,1,.764,0,1,1,0,0,1,.325.216l8,8a1,1,0,0,1-1.414,1.414L13,4.414V22a1,1,0,0,1-2,0V4.414L4.707,10.707A1,1,0,0,1,3.293,9.293Z"
      fill={fill}
    />
  </svg>
);
