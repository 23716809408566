import { createReducer, createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  blockPage: [],
  unblockPage: [],
});

interface State {
  active: boolean;
}

const INITIAL_STATE = {
  active: false,
};

// eslint-disable-next-line @typescript-eslint/default-param-last,@typescript-eslint/no-unused-vars
const blockPage = (state: State = INITIAL_STATE, action: any) => ({
  active: true,
});

const unblockPage = () => INITIAL_STATE;

const HANDLERS = {
  [Types.BLOCK_PAGE]: blockPage,
  [Types.UNBLOCK_PAGE]: unblockPage,
};

export default createReducer(INITIAL_STATE, HANDLERS);
