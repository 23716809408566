import { Document, Image, Line, Page, Rect, StyleSheet, Svg, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { styles } from '../../../../components/GeneratePdf/style';
import { useJwtContext } from '../../../../JwtContext';

const date = new Date();
const day = String(date.getDate()).padStart(2, '0');
const month = String(date.getMonth() + 1).padStart(2, '0');
const year = date.getFullYear();
const currentDate = `${day}/${month}/${year}`;

export const reportStyles = StyleSheet.create({
  text: {
    font: 'normal',
    marginTop: 10,
    fontWeight: 450,
    fontSize: 11,
    marginLeft: 20,
    color: '#262321',
  },
});

const ReportPdf = ({ data }: any) => {
  const { t } = useTranslation();
  const decodedJwt = useJwtContext();
  const greeting = decodedJwt?.name;

  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <View style={styles.header} fixed>
          <Image style={styles.image} src="images/logo.jpg" />
          <Text style={styles.title}>{t('anonymization.dataReport')}</Text>
        </View>
        <View style={styles.svgY} fixed>
          <Svg height="10" width="150">
            <Line x1="0" y1="0" x2="500" y2="0" strokeWidth={4} stroke="#ffd5cb" />
          </Svg>
        </View>
        <View style={styles.svgX} fixed>
          <Svg height="200" width="20">
            <Line x1="20" y1="1" x2="20" y2="150" strokeWidth={4} stroke="#ffd5cb" />
          </Svg>
        </View>
        <View style={styles.pageNumber} fixed>
          <Text render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} fixed />
        </View>
        <View>
          <Text style={styles.subtitle}>{t('anonymization.data')}</Text>
          <Text style={reportStyles.text}>{t('anonymization.group')}</Text>
          <Text style={reportStyles.text}>{t('anonymization.companies')}</Text>
          <Text style={reportStyles.text}>{t('anonymization.cnpj')}</Text>
          <Text style={reportStyles.text}>{t('anonymization.email')}</Text>
        </View>
        <View>
          <Text style={styles.subtitle}>{t('anonymization.answers')}</Text>
          {data.map(({ formTitle, title }: any) => (
            <Text style={reportStyles.text}>{`${formTitle} - ${title}`}</Text>
          ))}
        </View>
        <View style={styles.footer} fixed>
          <View style={styles.item_wrapper}>
            <Text style={styles.copyright} fixed>
              Copyright {year} ADP. Inc ADP Confidential
            </Text>
            <View style={styles.infos}>
              <Text style={styles.user} fixed>
                {greeting}
              </Text>
              <Text style={styles.date} fixed>
                {currentDate}
              </Text>
            </View>
          </View>
          <Svg viewBox="0 0 220 10">
            <Rect width="70" height="10" fill="#f06255" x="0" y="0" />
            <Rect width="40" height="10" fill="#fecbbe" x="110" y="0" />
            <Rect width="100" height="10" fill="#4950a2" x="140" y="0" />
          </Svg>
        </View>
      </Page>
    </Document>
  );
};

export default ReportPdf;
