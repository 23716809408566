import React, { ChangeEvent } from 'react';
import { CheckIcon } from '../../../assets/svgs/CheckIcon';
import { CheckboxInput, CheckboxLabel } from './style';

interface CheckboxProps {
  id?: any;
  value?: any;
  checked?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const Checkbox = React.forwardRef(({ id, checked, onChange, disabled, value, ...props }: CheckboxProps, ref) => (
  <>
    <CheckboxInput ref={ref} id={id} checked={checked} onChange={onChange} value={value} {...props} />
    <CheckboxLabel htmlFor={id} disabled={disabled}>
      <CheckIcon />
    </CheckboxLabel>
  </>
));
export default Checkbox;
