import styled from 'styled-components';

export const ProgressBarContainer = styled.div`
  display: flex;
  width: 40%;
  flex-direction: column;
  gap: 8px;
`;

export const ProgressBarLineContainer = styled.div`
  height: 8px;
  width: 100%;
  background: #d8e5f9;
  border-radius: 9999px;
`;

interface ProgressBarLineProps {
  percentage: number;
}

export const ProgressBarLine = styled.div<ProgressBarLineProps>`
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  background: #476bc3;
  color: #fff;
  border-radius: 9999px;
  width: ${({ percentage }) => percentage}%;
`;

export const AmountBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 4px;
  width: 59px;
  height: 22px;
  background: #fff;
  border: 1px solid #476bc3;
  border-radius: 8px;

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 14px;
  color: #476bc3;
`;

export const ProgressBarInfo = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-end;
`;
