import React from 'react';

interface EditIconProps {
  width?: number;
  height?: number;
  selected?: boolean;
}

export const EditIcon = ({ width = 16, height = 16, selected }: EditIconProps) => {
  const viewBox = `0 0 ${width} ${height}`;
  const fill = selected ? '#476BC3' : '#262321';

  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2583 2.69375C15.5833 3.00208 15.5833 3.54375 15.2583 3.86875L13.725 5.39375L10.6 2.26875L12.1333 0.74375C12.4583 0.41875 13 0.41875 13.3083 0.74375L15.2583 2.69375ZM4.54514 14.5811L0.5 15.5021L1.45372 11.4225L9.71667 3.15208L12.8417 6.27708L4.54514 14.5811Z"
        fill={fill}
      />
    </svg>
  );
};
