import { useContext, useMemo } from 'react';
import ReplyFormContext from '../../context';
import { getBranchStatement } from '../../misc';

export const useInternalVariableReplacer = (
  item: { wId: string; rules: any; answer: any; branchId?: any; title: string },
  accessFieldsName: string,
  { branchIndex, entryIndex }: { branchIndex?: number; entryIndex?: number } = {}
) => {
  const { watch, data } = useContext(ReplyFormContext);
  const titleVariables: string[] | undefined = item.title
    .match(/\[(.*?)\]/g)
    ?.map((variable) => `${variable}`.replace('[', '').replace(']', ''))
    .filter((value: any, index: number, self: any[]) => self.indexOf(value) === index)
    .filter((i) => i);

  const toWatch = useMemo(
    () =>
      titleVariables?.map((variable: string) => {
        const rule = watch(`internal_variable_${variable}`);
        return `${getBranchStatement(branchIndex, entryIndex)}${rule.ref}`;
      }) ?? [],
    [titleVariables]
  );
  if (Array.isArray(toWatch) && toWatch.length > 0) {
    const variables = watch(toWatch);

    const matchedAnswersAndCodes = titleVariables?.reduce(
      (acc, variable) => ({
        ...acc,
        [variable]: variables.find(
          ({ wId }: any) => wId === data.internalVariables.find(({ code }: any) => code === variable).wId
        ).answer,
      }),
      {} as { [index: string]: string | undefined }
    );
    return item.title.replace(
      /\[(.*?)\]/g,
      (variableWithBraces: string, variableWithoutBraces: string) =>
        matchedAnswersAndCodes?.[variableWithoutBraces] ?? ''
    );
  }

  return item.title;
};
