import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const FileListContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 554px;
  gap: 8px;
`;

const TEXT_DEFAULT_STYLE = css`
  font-style: normal;
  font-weight: 450;
`;

export const FileName = styled.p`
  ${TEXT_DEFAULT_STYLE};
  font-size: 16px;
  line-height: 19px;
`;

export const FileSize = styled.p`
  ${TEXT_DEFAULT_STYLE};
  font-size: 12px;
  line-height: 14px;
`;

export const StyledFileItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  gap: 14px;
  background: #ffffff;
  border: 1px solid #d8d8d8;
`;

export const FileItemInfos = styled.div`
  display: flex;
  gap: 10px;
`;
