import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Dashboard } from '../Dashboard';

type QueryParams = {
  projectId: string;
};

const DashboardPreview = () => {
  const { projectId } = useParams<QueryParams>();

  const location = useLocation();
  const { clientName } = location.state as { clientName: string };
  return <Dashboard isPreview projectId={projectId} clientName={clientName} />;
};

export default DashboardPreview;
