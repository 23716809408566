import React from 'react';
import { useNavigate } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IconBreadCrumb } from '../../assets/svgs/IconBreadCrumb';
import { routes } from './data';
import { Breadcrumb, BreadLink } from './style';
import { RootState } from '../../redux/store';

const redirects: { [index: string]: string } = {
  '/': '/home',
  '/settings': '',
  '/settings/variables/edit': '/settings/variables',
};

const BreadButton = ({ path, breadcrumb, blocked }: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handler = () => {
    if (blocked) {
      // eslint-disable-next-line no-restricted-globals,no-alert
      const response = confirm(t('form.confirmExit'));

      if (!response) return;
    }

    return navigate(redirects[path] ?? path);
  };

  return <BreadLink onClick={handler}>{breadcrumb}&emsp;</BreadLink>;
};

export const Breadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs(routes);

  const blockPageIsActive = useSelector((state: RootState) => state?.blockPage?.active);

  return (
    <Breadcrumb>
      {breadcrumbs.map(({ match, breadcrumb }, index) =>
        index + 1 === breadcrumbs.length ? (
          <span key={match.pathname}>{breadcrumb}</span>
        ) : (
          breadcrumb && (
            <span key={match.pathname}>
              <BreadButton breadcrumb={breadcrumb} path={match.pathname} blocked={blockPageIsActive} />
              <IconBreadCrumb />
            </span>
          )
        )
      )}
    </Breadcrumb>
  );
};
