import { View, Text, Path, Svg } from '@react-pdf/renderer';
import React from 'react';
import formatBytes from '../../../../../../utils/formatBytes';
import { styles } from '../../style';

const Upload = ({ title, files }: any) => (
  <View style={{ marginBottom: 10 }} wrap={false}>
    <Text style={styles.title}>{title}</Text>
    {files.map((file: { name: string; size: number }) => (
      <View style={styles.upload}>
        <Svg width="12" height="14" viewBox="0 0 12 14">
          <Path
            d="M4.00002 0.335344V0.333344H11.332C11.7 0.333344 12 0.636677 12 0.994677V13.0053C11.9998 13.1808 11.93 13.349 11.8059 13.473C11.6818 13.597 11.5135 13.6667 11.338 13.6667H0.662017C0.574468 13.6661 0.487897 13.6482 0.407247 13.6141C0.326597 13.5801 0.253447 13.5305 0.191974 13.4681C0.130501 13.4058 0.0819094 13.3319 0.0489732 13.2508C0.016037 13.1697 -0.000598536 13.0829 1.64487e-05 12.9953V4.33334L4.00002 0.335344ZM1.88668 4.33334H4.00002V2.22001L1.88668 4.33334ZM5.33335 1.66668V5.00001C5.33335 5.17682 5.26311 5.34639 5.13809 5.47141C5.01306 5.59644 4.84349 5.66668 4.66668 5.66668H1.33335V12.3333H10.6667V1.66668H5.33335Z"
            fill="#262321"
          />
        </Svg>
        <View style={styles.file}>
          <Text style={{ marginLeft: 10 }}>{file.name}</Text>
          <Text style={{ marginLeft: 10, marginTop: 5 }}>{formatBytes(file.size)}</Text>
        </View>
      </View>
    ))}
  </View>
);

export default Upload;
