import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Questions from './components/Questions';
import FormContext from './context';
import Details from './components/Details';
import { Form } from './style';
import { QuestionFields } from '../types/Question';
import { findAllQuestionSettingsRequest } from '../../../requests/questionsSettings';
import { FieldType } from '../types/FieldType';
import Divider from '../components/Divider';
import Loader from '../../../components/Loader';
import { LoaderWrapper } from '../../../components/Loader/style';
import { fetchOtherFormsQuestionsToUseOnRules } from '../../../redux/fetchActions/dataReducer';
import { Creators as blockPageActions } from '../../../redux/reducers/blockPageReducer';
import InternalVariableModal, { ModalInternalVariableForm } from '../components/InternalVariableModal';
import { InternalVariable, InternalVariables } from '../types/FormData';
import { Creators as modalActions } from '../../../redux/reducers/modal-reducer';
import { findAllUserFilterRestriction } from '../../../requests/userFilterRestriction';

interface SelectOptions {
  value: any;
  label: string;
}

const GenericFormFields = ({
  control,
  getValues,
  setValue,
  setError,
  getFieldState,
  formState: { errors, isValid, isDirty },
  trigger,
  watch,
  register,
  fields,
  append,
  remove,
  move,
  update,
  deleteSubmit,
  formHasSubType,
  deleteDraftHandler,
  enableMapToDefaultValues,
  setOpenVariableCreatorModal = () => {},
}: any) => {
  const questionAmount = fields.length;
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { blockPage, unblockPage } = blockPageActions;
  const blockPageHandler = () => dispatch(blockPage());
  const unblockPageHandler = () => dispatch(unblockPage());
  const { openModal } = modalActions;

  const [search, setSearch] = useState('');
  const [loadingInfos, setLoadingInfos] = useState(0);
  const [customFieldTypes, setCustomFieldTypes] = useState<any[]>([]);
  const [products, setProducts] = useState<SelectOptions[]>([]);
  const [businessUnits, setBusinessUnits] = useState<SelectOptions[]>([]);
  const [serviceOffers, setServiceOffers] = useState<SelectOptions[]>([]);
  const [projectTypes, setProjectTypes] = useState<SelectOptions[]>([]);

  const formatForSelect = (data: { name: string; _id: number | string }) => ({ label: data.name, value: data._id });

  const fetchUserFilterRestriction = async () => {
    setLoadingInfos((stage) => stage + 1);
    const userFilterRestriction = await findAllUserFilterRestriction();

    const formattedServiceOffersToSelect = userFilterRestriction.serviceOffers.map(formatForSelect);
    await setServiceOffers(formattedServiceOffersToSelect);

    const formattedProductsToSelect = userFilterRestriction.products.map(formatForSelect);
    await setProducts(formattedProductsToSelect);

    const formattedProjectTypesToSelect = userFilterRestriction.projectTypes.map(formatForSelect);
    await setProjectTypes(formattedProjectTypesToSelect);

    const formattedBusinessUnitsToSelect = userFilterRestriction.businessUnits.map(formatForSelect);
    await setBusinessUnits(formattedBusinessUnitsToSelect);

    setLoadingInfos((stage) => stage - 1);
  };

  useEffect(() => {
    fetchUserFilterRestriction();
  }, []);

  const flatQuestions = (data: QuestionFields[]) =>
    data.flatMap((question) => (Array.isArray(question.questions) ? [question, ...question.questions] : question));

  const flattedQuestions = useMemo(() => flatQuestions(fields), [fields]);

  const fetchQuestionCustomTypes = async () => {
    try {
      const data = await findAllQuestionSettingsRequest();
      const filteredData = data.filter((item: any) => item.status !== 'INACTIVE');
      const formattedCustomTypes = filteredData.map((item: any) => ({
        value: FieldType.CUSTOM,
        label: item.name,
        props: item,
      }));

      setCustomFieldTypes(formattedCustomTypes);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    setLoadingInfos((stage) => stage + 1);
    fetchQuestionCustomTypes().then(() => setLoadingInfos((stage) => stage - 1));
  }, []);

  const watchProjectType = watch('projectType');
  const watchBusinessUnit = watch('businessUnit');
  const watchServiceOffer = watch('serviceOffer');
  const watchProductType = watch('productType');
  const watchFormType = watch('formType');
  const wId = watch('wId');
  useEffect(() => {
    fetchOtherFormsQuestionsToUseOnRules(dispatch, {
      projectType: watchProjectType,
      businessUnit: watchBusinessUnit,
      serviceOffer: watchServiceOffer,
      productType: watchProductType,
      wId,
      formType: watchFormType,
    });
  }, [watchProjectType, watchBusinessUnit, watchServiceOffer, watchProductType, watchFormType]);

  const questions = useMemo(() => {
    const lowerCaseFilter = search.toLowerCase();
    return fields.filter(({ title }: { title: string }) => title.toLowerCase().includes(lowerCaseFilter));
  }, [search, fields]);

  const alertUserFromLeaving = () => {
    function confirmExit() {
      return t('form.confirmExit');
    }
    window.onbeforeunload = confirmExit;

    // eslint-disable-next-line no-restricted-globals
    history.pushState(null, 'null', null);

    function confirmBack(event: PopStateEvent) {
      event.preventDefault();
      // eslint-disable-next-line no-restricted-globals,no-alert
      const r = confirm(t('form.confirmExit'));
      if (r) {
        // Call Back button programmatically as per user confirmation.
        // eslint-disable-next-line no-restricted-globals
        history.back();
        // Uncomment below line to redirect to the previous page instead.
        // window.location = document.referrer // Note: IE11 is not supporting this.
      } else {
        // Stay on the current page.
        // eslint-disable-next-line no-restricted-globals
        history.pushState(null, 'null', window.location.pathname);
      }
    }

    window.onpopstate = confirmBack;

    blockPageHandler();
  };

  useEffect(() => {
    alertUserFromLeaving();
  }, [isDirty]);

  useEffect(
    () => () => {
      window.onbeforeunload = null;
      window.onpopstate = null;
      unblockPageHandler();
    },
    []
  );

  const openVariableCreatorModal = () => {
    const variablesFlatQuestions = (data: QuestionFields[]) =>
      data.flatMap((question) => (Array.isArray(question.questions) ? [question, ...question.questions] : question));
    const variablesFlattedQuestions = variablesFlatQuestions(getValues('questions'));
    const defaultValues: ModalInternalVariableForm = {
      internalVariables: getValues('internalVariables')?.map((iv: InternalVariable) => {
        const foundQuestion = variablesFlattedQuestions.find((q) => q.wId === iv.wId);
        if (!foundQuestion) return undefined;
        return {
          code: iv.code,
          wId: {
            label: foundQuestion.title,
            value: iv.wId,
          },
        };
      }),
    };
    dispatch(
      openModal({
        title: t('form.internalVariablesModal'),
        content: (
          <InternalVariableModal
            handler={(val: ModalInternalVariableForm) => {
              setValue(
                'internalVariables',
                val.internalVariables
                  .map((iv) => (iv ? { code: iv.code, wId: iv.wId.value } : undefined))
                  .filter((iv) => iv) as InternalVariables
              );
            }}
            defaultValues={defaultValues}
            flattedQuestions={variablesFlattedQuestions}
          />
        ),
      })
    );
  };
  setOpenVariableCreatorModal(openVariableCreatorModal);
  const context = useMemo(
    () => ({
      products,
      businessUnits,
      serviceOffers,
      projectTypes,
      customFieldTypes,
      flattedQuestions,
      fields,
      append,
      remove,
      move,
      control,
      getValues,
      getFieldState,
      errors,
      trigger,
      update,
      isValid,
      isDirty,
      watch,
      register,
      setValue,
      deleteSubmit,
      deleteDraftHandler,
      enableMapToDefaultValues,
      setError,
    }),
    [
      products,
      businessUnits,
      serviceOffers,
      projectTypes,
      customFieldTypes,
      flattedQuestions,
      fields,
      control,
      getValues,
      getFieldState,
      errors,
      trigger,
      update,
      isValid,
      isDirty,
      watch,
      register,
      deleteSubmit,
      deleteDraftHandler,
      enableMapToDefaultValues,
    ]
  );

  return (
    <FormContext.Provider value={context}>
      {loadingInfos > 0 ? (
        <LoaderWrapper>
          <Loader text={t('form.searchParameters')} />
        </LoaderWrapper>
      ) : (
        <Form>
          <Details formHasSubType={formHasSubType} />
          <Divider search={search} setSearch={setSearch} questionAmount={questionAmount} />
          <Questions questions={questions} />
        </Form>
      )}
    </FormContext.Provider>
  );
};

export default GenericFormFields;
