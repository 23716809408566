import styled from 'styled-components';

export const TwoColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
`;

export const CodeLabel = styled.p`
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 16px;
  color: #262321;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledCodeEditor = styled.div.attrs<{ theme: string }>(
  ({ theme }) =>
    ({
      'data-color-mode': theme,
    } as any)
)`
  display: flex;
  flex-direction: column;
  gap: 6px;
  min-height: 400px;
  position: relative;
`;

export const StyledToogle = styled.div`
  position: absolute;
  cursor: pointer;
  z-index: 1;
  top: 35px;
  right: 12px;
`;

export const ToogleBtn = styled.div``;
