import React, { ChangeEvent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import VMasker from 'vanilla-masker';
import { AnswerContainer, FieldTitle } from '../../style';
import { InputWrapper } from '../../../../../Form/components/QuestionForm/style';
import FormError from '../../../../../../components/FormError';
import Input from '../../../../../../components/Input';
import ReplyFormContext from '../../../../context';
import { useJwtContext } from '../../../../../../JwtContext';

interface MaskedInputProps {
  onChange: (value: string) => void;
  value: string;
  label?: string;
  disabled: boolean;
  phoneFormat?: string;
}

const CInputPhone = ({
  disabled,
  onChange,
  value,
  phoneFormat = '(99) 99999-9999',
  label,
  ...otherProps
}: MaskedInputProps) => {
  const formatMask = (valueToMask: string) => VMasker.toPattern(valueToMask, phoneFormat);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: currentValue } = event.target;

    onChange(formatMask(currentValue));
  };

  return <Input {...otherProps} disabled={disabled} label={label} value={value} onChange={handleChange} />;
};

const InputPhone = ({ control, errors, accessFieldsName, register, wId, title }: any) => {
  const { formDisabled } = useContext(ReplyFormContext);
  const { t } = useTranslation();

  const decodedJwt = useJwtContext();
  const phoneFormat = decodedJwt?.phoneFormat;

  return (
    <AnswerContainer>
      <FieldTitle>{title}</FieldTitle>
      <input type="hidden" name={`${accessFieldsName}.wId`} {...register(`${accessFieldsName}.wId`)} value={wId} />
      <InputWrapper>
        <Controller
          name={`${accessFieldsName}.answer`}
          control={control}
          rules={{
            required: t<string>('common.required'),
          }}
          render={({ field }) => <CInputPhone {...field} phoneFormat={phoneFormat} disabled={formDisabled} />}
        />
        <FormError errors={errors} name={`${accessFieldsName}.answer`} />
      </InputWrapper>
    </AnswerContainer>
  );
};

export default InputPhone;
