import React from 'react';

interface ToastErrorIconProps {
  width?: number;
  height?: number;
}

const ToastErrorIcon = ({ width = 20, height = 20 }: ToastErrorIconProps) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 14C11 14.5523 10.5523 15 10 15C9.44771 15 9 14.5523 9 14C9 13.4477 9.44771 13 10 13C10.5523 13 11 13.4477 11 14ZM10 11C10.5523 11 11 10.5523 11 10V6C11 5.44772 10.5523 5 10 5C9.44771 5 9 5.44772 9 6V10C9 10.5523 9.44771 11 10 11Z"
      fill="#F1615D"
    />
  </svg>
);

export default ToastErrorIcon;
