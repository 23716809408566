import i18n from 'i18next';
import { removeFromLocalStorage, saveSessionStorage } from '../utils/storageUtils';
import { UserInfos } from '../types/UserInfos';
import { decodeJwt } from '../utils/jwtUtils';
import { forgot, login, register } from '../http/autentication';
import api from '../http/api';
import { Creators as modalActions } from '../redux/reducers/modal-reducer';
import { store } from '../redux/store';

export const doLogin = async (email: string, password: string) => {
  const res = await login.post('', { email, password });
  const { data } = res;
  saveSessionStorage('token', data.token);
  saveSessionStorage('refreshToken', data.refreshToken);
  const userData = decodeJwt(data.token) as UserInfos;
  await i18n.changeLanguage(userData.language);
  return [userData.restriction, userData.type];
};

export const doRegister = async (password: string, token: string) => {
  const res = await register.post('', { password, token });
  return res;
};

export const doForgot = async (email: string) => {
  const res = await forgot.post('', { email });
  return res;
};

export const doValidate = async (code: string, password: string) => {
  const res = await api.post('/auth/first-access-change-password', { code, password });
  return res;
};

export const doRequestFirstAccessToken = async () => {
  await api.post('/auth/first-access-token-request', {});
  return true;
};

export const logout = () => {
  removeFromLocalStorage('token');
  const { closeModal } = modalActions;
  store.dispatch(closeModal());
  removeFromLocalStorage('refreshToken');
};
