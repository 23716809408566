import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, FooterWrapper, Title } from './style';
import { BreadcrumbWrapper } from '../../components/BreadCrumb/style';
import { Breadcrumbs } from '../../components/BreadCrumb';
import { Display } from '../SettingsForms/components/style';
import Grid from '../../components/Grid';
import { InputWrapper } from '../Form/GenericFormFields/style';
import Input from '../../components/Input';
import FormError from '../../components/FormError';
import Button from '../../components/Button';
import Alert from '../../components/Toast/toast';
import Loader from '../../components/Loader';
import { LoaderWrapper } from '../../components/Loader/style';
import { createUserProject, findUserAnswererById } from '../../requests/userManagement';

export const EditUserProject = () => {
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const formMethods = useForm<any>({
    mode: 'onChange',
  });
  const {
    // watch,
    formState: { errors },
    reset,
    control,
    handleSubmit,
    // setValue,
  } = formMethods;

  const getUserProject = async () => {
    if (!id) return;

    setLoading(true);
    try {
      const data = await findUserAnswererById(id);
      if (data) {
        reset(data);
      }
    } catch (error) {
      Alert.ERROR(t('userManagement.alertList'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserProject();
  }, []);

  const returnHandler = () => {
    navigate('/user-project');
  };

  const onSubmit = async (formData: any) => {
    if (id) {
      // todo for edit list
    } else {
      try {
        setSaving(true);
        await createUserProject(formData);
        Alert.SUCCESS(t('userManagement.create'));
        returnHandler();
      } catch (error: any) {
        Alert.ERROR(error?.response?.data?.message);
        // Alert.ERROR(t('userManagement.errAlert'));
      } finally {
        setSaving(false);
      }
    }
  };

  if (loading) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container>
        <BreadcrumbWrapper>
          <Breadcrumbs />
        </BreadcrumbWrapper>
        <Title>{t('breadcrumb.createProjetUser')}</Title>
        <Display>
          <Grid item container xs={12} direction="column" align="unset">
            <InputWrapper>
              <Controller
                name="name"
                control={control}
                rules={{ required: t<string>('common.required') }}
                render={({ field }) => <Input {...field} label={t('utilsForm.USER_NAME')} />}
              />
              {errors.name?.message && <FormError errors={errors} name="name" />}
            </InputWrapper>
            <Grid xsMargin="24px 0 0 0" />
            <InputWrapper>
              <Controller
                name="email"
                control={control}
                rules={{ required: t<string>('common.required') }}
                render={({ field }) => <Input {...field} label={t('utilsForm.USER_EMAIL')} />}
              />
              {errors.email?.message && <FormError errors={errors} name="email" />}
            </InputWrapper>
            <Grid xsMargin="24px 0 0 0" />
            <FooterWrapper>
              <Button onClick={returnHandler}>{t('common.back')}</Button>
              <Button primary type="submit" loading={saving}>
                {t('common.save')}
              </Button>
            </FooterWrapper>
          </Grid>
        </Display>
      </Container>
    </form>
  );
};
