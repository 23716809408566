import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

export const ToasterContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const StyledToast = styled(ToastContainer).attrs({
  className: 'toast-container',
  toastClassName: 'toast',
  bodyClassName: 'body',
  progressClassName: 'progress',
})`
  .toast {
    padding: 24px;
    background: #fff;
    box-shadow: 0px -0.4px 8px rgba(38, 35, 33, 0.04), 0px 16px 32px rgba(38, 35, 33, 0.2);
    border-radius: 12px;

    font-family: 'Circular20', sans-serif;
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 24px;
    color: #262321;
  }

  .body {
  }

  .progress {
  }
`;
