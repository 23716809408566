import React from 'react';

export interface SettingsFormsContextProps {
  editState?: any;
  dispatchEdit?: any;
  createState?: any;
  dispatchCreate?: any;
}

const SettingsFormsContext = React.createContext<SettingsFormsContextProps>({});

export default SettingsFormsContext;
