import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  title: {
    marginBottom: 5,
    fontStyle: 'normal',
    fontWeight: 450,
    fontSize: 11,
    color: '#393533',
  },
  titlePanel: {
    borderBottom: '1px solid #E3DFDA',
    lineHeight: 2,
    marginBottom: 10,
    fontStyle: 'normal',
    fontWeight: 450,
    fontSize: 11,
    color: '#393533',
  },
  input: {
    marginBottom: 10,
    fontSize: 12,
    textAlign: 'justify',
    padding: 6,
    paddingLeft: 10,
    border: '1px solid #D8D8D8',
    borderRadius: 8,
    color: '#262321',
    minHeight: 25,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  choiceTitle: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
  },
  check: {
    position: 'absolute',
    top: 3,
    left: 2,
  },
  radio: {
    position: 'absolute',
    top: 5.5,
    left: 2,
  },
  link: {
    fontSize: 11,
    fontStyle: 'normal',
    fontWeight: 500,
    textDecorationLine: 'underline',
    color: '#476BC3',
    marginLeft: 5,
    marginTop: 5,
    marginBottom: 10,
  },
  upload: {
    marginBottom: 10,
    fontStyle: 'normal',
    fontWeight: 450,
    fontSize: 11,
    textAlign: 'justify',
    padding: 13,
    paddingLeft: 15,
    border: '1px solid #D8D8D8',
    color: '#262321',
    display: 'flex',
    flexDirection: 'row',
  },
  file: {
    display: 'flex',
    flexDirection: 'column',
  },
  panel: {
    marginBottom: 10,
    padding: 24,
    borderRadius: 16,
    border: '1px solid #D8D8D8',
  },
});
