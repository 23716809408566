import React, { useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { ActionsWrapper, Container, Title } from '../EmailPermissions/style';
import DataPurgeWordsConfigContext from './context';
import { ActionsKind, toggleReducer } from './reducers';
import {
  findAllDataPurgeWordListRequest,
  addWordToDataPurgeWordListRequest,
  updateWordToDataPurgeWordListRequest,
  deleteWordsToDataPurgeWordListRequest,
} from '../../requests/country';
import Alert from '../../components/Toast/toast';
import Table from '../../components/Table';
import { EditIcon } from '../../assets/svgs/EditIcon';
import { DeleteIcon } from '../../assets/svgs/DeleteIcon';
import { ActionIcon } from '../../components/Table/style';
import { TableData } from '../../types/TableData';
import TextButton from '../../components/TextButton';
import { LoaderWrapper } from '../../components/Loader/style';
import Loader from '../../components/Loader';
import { Breadcrumbs } from '../../components/BreadCrumb';
import { BreadcrumbWrapper } from '../../components/BreadCrumb/style';
import { InlineCreate, InlineEdit } from '../EmailPermissions/components/InlineForm';

export const DataPurgeWordsConfigContent = () => {
  const [words, setWords] = useState<TableData[]>([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const { editState, dispatchEdit } = useContext(DataPurgeWordsConfigContext);

  const getWords = async () => {
    setLoading(true);
    const data = await findAllDataPurgeWordListRequest();
    if (data) {
      const dataWithId = data.map((word: string) => ({ _id: uuidv4(), word }));
      setWords(dataWithId);
    }
    setLoading(false);
  };

  useEffect(() => {
    getWords();
    return () => {
      setWords([]);
    };
  }, []);

  const WORDS_CONFIG_ADVICE = t('dataPurge.advice');
  const EMPTY_STATE_TEXTS = {
    EMPTY_FILTER: t('dataPurge.emptyFilter'),
    EMPTY_LIST: t('dataPurge.emptyList'),
  };

  const inlineEditHandler = async (word: string, currentWord: string) => {
    await updateWordToDataPurgeWordListRequest(word, currentWord);
    await getWords();
    Alert.SUCCESS(t('dataPurge.edit'));
  };

  const createHandler = async (word: string) => {
    await addWordToDataPurgeWordListRequest(word);
    await getWords();
    Alert.SUCCESS(t('dataPurge.create'));
  };

  const editHandler = async (data: TableData) => {
    if (editState.open) {
      return dispatchEdit({ type: ActionsKind.CLOSE });
    }
    return dispatchEdit({ type: ActionsKind.OPEN, payload: data._id });
  };

  const deleteHandler = async (...data: TableData[]) => {
    const wordsToDelete = data.map((item) => item.word);

    await deleteWordsToDataPurgeWordListRequest(wordsToDelete);
    await getWords();
    Alert.SUCCESS(t('dataPurge.delete'));
  };

  const render = (element: any) => [
    <InlineEdit
      open={editState.open && element._id === editState.data}
      defaultValue={element.word}
      handler={inlineEditHandler}
    />,
    <ActionsWrapper>
      <ActionIcon onClick={() => editHandler(element)}>
        <EditIcon />
      </ActionIcon>
      <ActionIcon onClick={() => deleteHandler(element)}>
        <DeleteIcon />
      </ActionIcon>
    </ActionsWrapper>,
  ];

  const headers = [
    {
      head: t('dataPurge.head'),
      key: 'word',
    },
  ];

  const renderActions = (selectedData: any[]) => [
    <TextButton onClick={() => deleteHandler(...selectedData)} disabled={selectedData.length === 0}>
      <span>{t('common.buttonDelete')}</span>
      <DeleteIcon fill={selectedData.length > 0 ? '#4C65B0' : '#979797'} />
    </TextButton>,
  ];

  return loading ? (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  ) : (
    <Table
      InlineCreate={InlineCreate}
      emptyStateTexts={EMPTY_STATE_TEXTS}
      render={render}
      data={words}
      headers={headers}
      advice={WORDS_CONFIG_ADVICE}
      createHandler={createHandler}
      renderActions={renderActions}
      hasInlineCreate
      searchKey="word"
    />
  );
};

const WordPermissions = () => {
  const [editState, dispatchEdit] = useReducer(toggleReducer, { open: false });
  const [createState, dispatchCreate] = useReducer(toggleReducer, { open: false });

  const context = useMemo(
    () => ({
      editState,
      dispatchEdit,
      createState,
      dispatchCreate,
    }),
    [editState, dispatchEdit, createState, dispatchCreate]
  );

  const { t } = useTranslation();

  return (
    <DataPurgeWordsConfigContext.Provider value={context}>
      <Container>
        <BreadcrumbWrapper>
          <Breadcrumbs />
        </BreadcrumbWrapper>
        <Title>{t('dataPurge.title')}</Title>

        <DataPurgeWordsConfigContent />
      </Container>
    </DataPurgeWordsConfigContext.Provider>
  );
};

export default WordPermissions;
