import { View, Text, Svg, Circle, Path } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../../style';

const DefaultRadio = () => (
  <View>
    <Svg width="18" height="18" viewBox="0 0 20 20">
      <Circle cx="7.5" cy="10" r="7" fill="white" stroke="#938C85" stroke-width="2" />
    </Svg>
  </View>
);

const CompletedRadio = () => (
  <View>
    <Svg width="18" height="18" viewBox="0 0 20 20">
      <Circle cx="7.5" cy="10" r="7" fill="#476BC3" stroke="#476BC3" stroke-width="2" />
    </Svg>
    <Svg style={styles.radio} width="10" height="7" viewBox="0 0 12 10">
      <Path
        d="M3.81353 7.43394L1.79093 5.35858C1.45355 5.0124 0.895637 5.0173 0.564394 5.36937C0.249945 5.70359 0.257921 6.22717 0.582405 6.55165L3.10642 9.07567C3.49695 9.46619 4.13011 9.46619 4.52064 9.07567L11.4154 2.18094C11.7353 1.86097 11.7274 1.33982 11.3978 1.02974C11.0836 0.734081 10.5917 0.740033 10.2847 1.04321L3.81353 7.43394Z"
        fill="white"
      />
    </Svg>
  </View>
);

const Radio = ({ title, choices, answer }: any) => (
  <View style={{ marginBottom: 10 }} wrap={false}>
    <Text style={styles.title}>{title}</Text>
    {choices.map((choice: any) => (
      <View style={styles.wrapper}>
        <View style={{ marginRight: 4 }}>
          {answer === (choice.title ?? choice) ? <CompletedRadio /> : <DefaultRadio />}
        </View>
        <View>
          <Text style={styles.choiceTitle}>{choice.title ?? choice}</Text>
        </View>
      </View>
    ))}
  </View>
);

export default Radio;
