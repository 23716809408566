import { createReducer, createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  setOtherFormsQuestionsToUseOnRules: ['data'],
  setQuestionsFromOtherFormsQuestionsToUseOnRules: ['data'],
});

const INITIAL_STATE = {
  otherFormsQuestionsToUseOnRules: undefined,
  questionsFromOtherFormsQuestionsToUseOnRules: { questionsIndexedByForm: {}, reverseIndex: {} },
};

interface DataReducerState {
  otherFormsQuestionsToUseOnRules?: any[];
  questionsFromOtherFormsQuestionsToUseOnRules: {
    questionsIndexedByForm: { [formWId: string]: any[] };
    reverseIndex: { [formWId: string]: { value: string; label: string } };
  };
}

const setOtherFormsQuestionsToUseOnRules = (state: DataReducerState, action: any) => ({
  ...(state ?? INITIAL_STATE),
  otherFormsQuestionsToUseOnRules: action.data,
});

const setQuestionsFromOtherFormsQuestionsToUseOnRules = (state: DataReducerState, action: any) => ({
  ...(state ?? INITIAL_STATE),
  questionsFromOtherFormsQuestionsToUseOnRules: action.data,
});

const HANDLERS = {
  [Types.SET_OTHER_FORMS_QUESTIONS_TO_USE_ON_RULES]: setOtherFormsQuestionsToUseOnRules,
  [Types.SET_QUESTIONS_FROM_OTHER_FORMS_QUESTIONS_TO_USE_ON_RULES]: setQuestionsFromOtherFormsQuestionsToUseOnRules,
};

export default createReducer(INITIAL_STATE, HANDLERS);
