import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import ReactPDF from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import FilterIconDashboard from '../../assets/svgs/FilterIconDashboard';
import { IconFormsChecked } from '../../assets/svgs/IconFormsChecked';
import { CustomPieChart } from '../../components/Charts/PieChart';
import { BoxLegend } from './components/BoxLegends';
import { ModalContent } from './components/ModalContent';
import { Creators as modalActions } from '../../redux/reducers/modal-reducer';
import {
  ActionsAndBar,
  Badge,
  BadgeWrapper,
  BoxColumn,
  Card,
  ChartWrapper,
  Client,
  Clients,
  ClientsColumn,
  ClientsWrapper,
  Company,
  Container,
  ContainerList,
  Footer,
  Header,
  HeaderClient,
  HeaderWrapper,
  IdProject,
  Infos,
  OpenForms,
  OpenModal,
  PieChartWrapper,
  Quantity,
  SearchWrapper,
  StyleSearch,
  Text,
  TextOpenForms,
  Title,
  TitleBarChart,
  TitleClients,
  User,
} from './style';
import { BarChart } from '../../components/Charts/BarChart';
import { SubTitleBarChart } from '../../components/Charts/BarChart/SubTitleBarChart';
import SearchBar from '../../components/SearchBar';
import { isNotEmptyArray } from '../../utils/array';
import { findAllDataAdminDashboard } from '../../requests/dashboard';
import ToastAlert from '../../components/Toast/toast';
import { DashboardAdminData } from './data';
import DefaultModalContent from '../../components/DefaultModalContent';
import TextButton from '../../components/TextButton';
import { purgeDataRequest } from '../../requests/dataPurge';
import ReportPdf from './components/ReportPdf';
import { LoaderWrapper } from '../../components/Loader/style';
import Loader from '../../components/Loader';
import { useJwtContext } from '../../JwtContext';
import { findAllUserFilterRestriction } from '../../requests/userFilterRestriction';

const FILTER_DEFAULT_STATE = {
  projectTypes: [],
  businessUnits: [],
  products: [],
  serviceOffers: [],
};

export const DashboardAdmin = () => {
  const [data, setData] = useState<DashboardAdminData>();
  const [loadingInfos, setLoadingInfos] = useState(0);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState<any>(FILTER_DEFAULT_STATE);
  const [products, setProducts] = useState<any[]>([]);
  const [businessUnits, setBusinessUnits] = useState<any[]>([]);
  const [serviceOffers, setServiceOffers] = useState<any[]>([]);
  const [projectTypes, setProjectTypes] = useState<any[]>([]);
  const dispatch = useDispatch();
  const { openModal, closeModal } = modalActions;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const getData = async () => {
    setLoadingInfos((stage) => stage + 1);
    try {
      const dashboardData = await findAllDataAdminDashboard();
      if (dashboardData) setData(dashboardData);
    } catch (err: any) {
      ToastAlert.ERROR(err?.response?.data?.message);
    }
    setLoadingInfos((stage) => stage - 1);
  };

  const handleModalFilter = (filterData: any) => {
    setFilter(filterData);
  };

  const searchClients = useMemo(() => {
    if (search === '') return data?.clients ?? [];

    return data?.clients.filter(
      (list) =>
        list?.projectId?.includes(search) ||
        list?.clientName?.includes(search) ||
        list?.projectTypeName?.includes(search) ||
        list?.businessUnitName?.includes(search) ||
        list?.productTypeName?.includes(search) ||
        list?.serviceOfferName?.includes(search)
    );
  }, [search, data]);

  const filteredData = useMemo(() => {
    if (
      !(
        isNotEmptyArray(filter.projectTypes) ||
        isNotEmptyArray(filter.businessUnits) ||
        isNotEmptyArray(filter.products) ||
        isNotEmptyArray(filter.serviceOffers)
      )
    )
      return searchClients;

    return searchClients?.filter(
      (list) =>
        filter?.projectTypes.includes(list.projectType) ||
        filter?.businessUnits.includes(list.businessUnit) ||
        filter?.products.includes(list.productType) ||
        filter?.serviceOffers.includes(list.serviceOffer)
    );
  }, [searchClients, filter]);

  const formatForSelect = (formDependency: { name: string; _id: number | string }) => ({
    name: formDependency.name,
    id: formDependency._id,
  });

  const fetchUserFilterRestriction = async () => {
    setLoadingInfos((stage) => stage + 1);
    const userFilterRestriction = await findAllUserFilterRestriction();

    const formattedServiceOffersToSelect = userFilterRestriction.serviceOffers.map(formatForSelect);
    await setServiceOffers(formattedServiceOffersToSelect);

    const formattedProductsToSelect = userFilterRestriction.products.map(formatForSelect);
    await setProducts(formattedProductsToSelect);

    const formattedProjectTypesToSelect = userFilterRestriction.projectTypes.map(formatForSelect);
    await setProjectTypes(formattedProjectTypesToSelect);

    const formattedBusinessUnitsToSelect = userFilterRestriction.businessUnits.map(formatForSelect);
    await setBusinessUnits(formattedBusinessUnitsToSelect);

    setLoadingInfos((stage) => stage - 1);
  };

  useEffect(() => {
    getData();
    fetchUserFilterRestriction();
  }, []);

  const redirectToClientPreview = (client: any) => {
    navigate(`/dashboard/preview/${client.projectId}`, { state: { clientName: client.clientName } });
  };

  const decodedJwt = useJwtContext();
  const greetingName = decodedJwt?.name;

  const greeting = `${t('common.hello')} ${greetingName}`;

  const downloadReport = async (anonymizedQuestions: any) => {
    const pdfBlob = await ReactPDF.pdf(<ReportPdf data={anonymizedQuestions} />).toBlob();
    saveAs(pdfBlob, `report-${new Date().toISOString().replace(':', '-').replace('.', '-')}.pdf`);
  };

  const anonymize = (projectId: any, clientCode: any) => {
    const onSubmitAnonymize = async () => {
      try {
        await purgeDataRequest(projectId, clientCode);
        getData();
        ToastAlert.SUCCESS(t('anonymization.requestCreated'));
        dispatch(closeModal());
      } catch (e: any) {
        ToastAlert.ERROR(e?.response?.data?.message);
      }
    };

    dispatch(
      openModal({
        content: (
          <DefaultModalContent
            onSubmit={onSubmitAnonymize}
            submitButtonTitle={t('anonymization.anonymizeData')}
            title={t('anonymization.alert')}
          />
        ),
      })
    );
  };

  return loadingInfos > 0 ? (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  ) : (
    <Container>
      <BoxColumn>
        <User>{greeting}</User>
        <PieChartWrapper>
          <div>
            <HeaderWrapper>
              <IconFormsChecked />
              <OpenForms>{data?.formsAmount.total}</OpenForms>
            </HeaderWrapper>
            <TextOpenForms>{t('dashboardAdmin.openForms')}</TextOpenForms>
          </div>
          <ChartWrapper>
            <CustomPieChart data={data?.formsAmount} />
          </ChartWrapper>
          {data && <BoxLegend data={data?.formsAmount} />}
        </PieChartWrapper>
        <Clients>
          <TitleClients>{t('dashboardAdmin.clients')}</TitleClients>
          {data?.clients.map((client) => (
            <ClientsWrapper key={`ClientWrapper-${client._id}`}>
              <>
                <Client>{client.clientName}</Client>
                <Quantity>{client.formsAmount}</Quantity>
              </>
            </ClientsWrapper>
          ))}
        </Clients>
        <Footer>{t('common.updateData')}</Footer>
      </BoxColumn>
      <ClientsColumn>
        <Header>
          <HeaderClient>{t('dashboardAdmin.clients')}</HeaderClient>
          <SearchWrapper>
            <StyleSearch>
              <SearchBar value={search} setValue={setSearch} />
            </StyleSearch>
            <OpenModal
              onClick={() =>
                dispatch(
                  openModal({
                    title: t('dashboardAdmin.filter'),
                    content: (
                      <ModalContent
                        products={products}
                        businessUnits={businessUnits}
                        serviceOffers={serviceOffers}
                        projectTypes={projectTypes}
                        onSubmit={handleModalFilter}
                        defaultValues={filter}
                      />
                    ),
                  })
                )
              }
            >
              <span>{t('dashboardAdmin.filter')}</span>
              <FilterIconDashboard />
            </OpenModal>
          </SearchWrapper>
        </Header>
        <ContainerList>
          {filteredData &&
            filteredData?.length > 0 &&
            filteredData?.map((client: any) => (
              <Card key={`Card-${client._id}`}>
                <Infos>
                  <IdProject>{client.projectId}</IdProject>
                  <Company
                    onClick={() => {
                      redirectToClientPreview(client);
                    }}
                  >
                    {client.clientName}
                  </Company>
                  <Title>{t('dashboardAdmin.info')}</Title>
                  <BadgeWrapper>
                    {client?.projectTypeName && (
                      <Badge>
                        <Text>{client?.projectTypeName}</Text>
                      </Badge>
                    )}
                    {client?.businessUnitName && (
                      <Badge>
                        <Text>{client?.businessUnitName}</Text>
                      </Badge>
                    )}
                    {client?.productTypeName && (
                      <Badge>
                        <Text>{client?.productTypeName}</Text>
                      </Badge>
                    )}
                    {client?.serviceOfferName && (
                      <Badge>
                        <Text>{client?.serviceOfferName}</Text>
                      </Badge>
                    )}
                  </BadgeWrapper>
                </Infos>
                <ActionsAndBar>
                  <TitleBarChart>{t('dashboardAdmin.progressForms')}</TitleBarChart>
                  <BarChart
                    data={{
                      pending: client.pending,
                      inProgress: client.inProgress,
                      finished: client.finished,
                    }}
                  />
                  <SubTitleBarChart
                    data={{
                      pending: client.pending,
                      inProgress: client.inProgress,
                      finished: client.finished,
                    }}
                  />
                  <div style={{ display: 'flex', flexWrap: 'wrap', gap: 4 }}>
                    {!client.anonymizationStatus && (
                      <TextButton onClick={() => anonymize(client.projectId, client.clientCode)}>
                        {t('anonymization.anonymization')}
                      </TextButton>
                    )}
                    {client.anonymizationStatus === 'IN_PROGRESS' && (
                      <Badge style={{ backgroundColor: '#F56A00' }}>
                        <Text>{t('anonymization.progress')}</Text>
                      </Badge>
                    )}

                    {client.anonymizationStatus === 'FINISHED' && (
                      <Badge style={{ backgroundColor: '#99E596' }}>
                        <Text style={{ color: '#057800' }}>{t('anonymization.anonymized')}</Text>
                      </Badge>
                    )}

                    {client.anonymizationStatus === 'FINISHED' && (
                      <TextButton onClick={() => downloadReport(client.anonymizedQuestions)}>
                        {t('anonymization.download')}
                      </TextButton>
                    )}
                  </div>
                </ActionsAndBar>
              </Card>
            ))}
        </ContainerList>
      </ClientsColumn>
    </Container>
  );
};
