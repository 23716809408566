import React from 'react';

export interface Ellipse16Props {
  width?: number;
  height?: number;
  className?: string;
}

export const Ellipse16 = ({ width = 172, height = 159, className }: Ellipse16Props) => (
  <svg
    id="el16"
    className={className}
    width={width}
    height={height}
    viewBox="0 0 172 159"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g style={{ mixBlendMode: 'soft-light' }}>
      <ellipse cx="16" cy="150.5" rx="150.5" ry="156" transform="rotate(90 16 150.5)" fill="#F1615D" />
    </g>
  </svg>
);
