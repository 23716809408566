import React from 'react';
import i18n from '../../config/i18n/config';

import { IconSettings } from '../../assets/svgs/IconSettings';
import { SideBarItem } from './data';

export const SidebarSettings: SideBarItem = {
  key: 'SidebarSettings1',
  title: i18n.t('navBar.settings'),
  icon: <IconSettings />,

  children: [
    {
      key: 'SidebarSettingsChildren1',
      title: i18n.t('navBar.users'),
      path: '/user-management',
    },
    {
      key: 'SidebarSettingsChildren2',
      title: i18n.t('navBar.permissions'),
      path: '/settings/email-permissions',
    },
    {
      key: 'SidebarSettingsChildren3',
      title: i18n.t('common.forms'),
      path: '/settings/forms',
    },
    {
      key: 'SidebarSettingsChildren4',
      title: i18n.t('navBar.questions'),
      path: '/settings/question',
    },
    {
      key: 'SidebarSettingsChildren5',
      title: i18n.t('navBar.variables'),
      path: '/settings/variables',
    },
    {
      key: 'SidebarSettingsChildren6',
      title: i18n.t('navBar.anonymization'),
      path: '/settings/data-purge-words',
    },
  ],
};
