import React from 'react';
import { useTranslation } from 'react-i18next';
import { Input, StyledLabel } from './style';
import UploadIcon from '../../assets/svgs/UploadIcon';

const UploadInput = ({ id, acceptFileFormats, onChange, props, disabled }: any) => {
  const { t } = useTranslation();

  return (
    <>
      <StyledLabel disabled={disabled} htmlFor={id}>
        <UploadIcon fill={disabled ? '#938c85' : '#fff'} />
        <p>{t('uploadInput.select')}</p>
      </StyledLabel>
      <Input id={id} {...props} accept={acceptFileFormats} onChange={onChange} />
    </>
  );
};

export default UploadInput;
