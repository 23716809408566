import React from 'react';

export interface IconAlertDashboardProps {
  width?: number;
  height?: number;
}

export const IconAlertDashboard = ({ width = 31, height = 19 }: IconAlertDashboardProps) => (
  <svg width={width} height={height} viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.00287 18.0025H19.5329C20.3029 18.0025 20.7829 17.1725 20.4029 16.5025L11.1329 0.5025C10.7429 -0.1675 9.78287 -0.1675 9.40287 0.5025L0.132872 16.5025C-0.247128 17.1725 0.232872 18.0025 1.00287 18.0025ZM11.2729 14.0025C11.2729 14.5548 10.8252 15.0025 10.2729 15.0025C9.72059 15.0025 9.27287 14.5548 9.27287 14.0025C9.27287 13.4502 9.72059 13.0025 10.2729 13.0025C10.8252 13.0025 11.2729 13.4502 11.2729 14.0025ZM9.27287 10.0025C9.27287 10.5525 9.72287 11.0025 10.2729 11.0025C10.8229 11.0025 11.2729 10.5525 11.2729 10.0025V8.0025C11.2729 7.4525 10.8229 7.0025 10.2729 7.0025C9.72287 7.0025 9.27287 7.4525 9.27287 8.0025V10.0025Z"
      fill="#fff"
    />
  </svg>
);
