import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  StyledIconEye,
  ActionIcon,
  Form,
  Title,
  Subtitle,
  Helper,
  StyledIconArrow,
  Label,
  InputContainer,
  StyledIconEyeSlash,
} from './style';

import { doLogin } from '../../../services/user.service';
import { UserRestriction } from '../../../types/UserRestriction';
import Input from '../../../components/Input';
import ErrorMessage from '../../../components/ErrorMessage';
import Button from '../../../components/Button';
import { removeFromLocalStorage } from '../../../utils/storageUtils';

interface LoginProps {
  label?: any;
  className?: string;
  setForm?: any;
}

const Login = ({ label, className, setForm }: LoginProps) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const [error, setError] = useState('');
  const [, setLoading] = useState<boolean>();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    try {
      setLoading(true);
      setError('');
      const [firstaccess, userType] = await doLogin(email.toLowerCase(), password);
      removeFromLocalStorage('selectedProject');
      if (firstaccess === UserRestriction.PENDING_FIRST_ACCESS) {
        setForm('firstaccess');
      } else {
        if (['SPONSOR', 'ANSWERER'].includes(userType)) {
          navigate('/project-selection');
          return;
        }
        navigate('/admin');
      }
    } catch (err: any) {
      // setError(t('login.errEmailOrPassword'));
      setError(t(err?.response?.data?.message));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form className={className}>
      <Title>
        {t('login.loginTitle')}
        <Subtitle>{t('login.loginSubTitle')}</Subtitle>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Title>
      <Label>{label}</Label>
      <Input
        error={error}
        label="Email"
        id="email"
        type="email"
        name="email"
        placeholder={t('login.inputEmail')}
        onChange={(event) => setEmail(event.target.value)}
        value={email}
      />
      <Label>{label}</Label>
      <InputContainer>
        <Input
          error={error}
          label={t('login.labelPassword')}
          id="password"
          type={passwordShown ? 'text' : 'password'}
          name="password"
          placeholder={t('login.inputPassword')}
          onChange={(event) => setPassword(event.target.value)}
          value={password}
        />
        <ActionIcon onClick={togglePassword}>{passwordShown ? <StyledIconEye /> : <StyledIconEyeSlash />}</ActionIcon>
      </InputContainer>
      <Helper onClick={() => setForm('forgot')}>{t('login.helper')}</Helper>
      <Button primary onClick={handleSubmit} full mt={20}>
        {t('login.loginButton')}
        <StyledIconArrow />
      </Button>
    </Form>
  );
};

export default Login;
