/* eslint-disable react/jsx-no-useless-fragment */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ReplyFormContext from '../../context';
import { FieldType } from '../../../Form/types/FieldType';
import AnswerField from '../AnswerField';
import formatReceivedFormData from '../../utils/formatReceivedData';
import { ReplyFormQuestionsContainer } from '../style';
import { EntryQuestionContainer } from './style';
import { useFormCustomRerender } from '../DefaultForm/useFormCustomRerender';
import Button from '../../../../components/Button';
import { useJwtContext } from '../../../../JwtContext';
import { useInternalVariableReplacer } from '../DefaultForm/useInternalVariableReplacer';

interface QuestionProps {
  entryIndex: number;
  entry: string;
  index: number;
  accessor: string;
  item: {
    wId: string;
    entry: any;
    rpaID: string;
    formID: string;
    type: FieldType;
    answer: string | string[];
    customTypeId: string;
    choices?: any[];
    rules: any;
    title: string;
    childOf?: string;
    expertMenu?: string;
    expertField?: string;
    files?: any[];
    supportedFileFormats?: string;
    questions?: any[];
    mask?: string;
    validationCode?: string;
    validationMessage?: string;
    customTypeName?: string;
  };
}

const Question = ({ index, item, entryIndex, entry, accessor }: QuestionProps) => {
  const { wId, type, questions, choices, files, supportedFileFormats } = item;
  const { mask, validationCode, validationMessage, customTypeName } = item;
  const { control, register, errors } = useContext(ReplyFormContext);
  const accessFieldsName = `${accessor}.answers[${index}]`;
  const decodedJwt = useJwtContext();
  const dateFormat = decodedJwt?.dateFormat;
  const shouldRender = useFormCustomRerender(item, accessFieldsName, dateFormat, { entryIndex });
  const replacedTitle = useInternalVariableReplacer(item, accessFieldsName, { entryIndex });
  if (!shouldRender) return <></>;
  return (
    <AnswerField
      formTypeIsBranch
      entry={entry}
      nestIndex={entryIndex}
      control={control}
      register={register}
      wId={wId}
      errors={errors}
      accessFieldsName={accessFieldsName}
      fieldType={type}
      {...{
        title: replacedTitle,
        questions,
        choices,
        files,
        supportedFileFormats,
        mask,
        validationCode,
        validationMessage,
        customTypeName,
      }}
    />
  );
};

const EntryItem = ({ entry, entryIndex, questions }: any) => {
  const { data, removeEntry, formDisabled } = useContext(ReplyFormContext);
  const variables = data.titleVariableAnswers;
  const formattedQuestions = formatReceivedFormData(questions, variables);
  const accessor = `entries[${entryIndex}]`;
  const { t } = useTranslation();

  return (
    <EntryQuestionContainer id={`entry-div-${entry}`}>
      {formattedQuestions.map((item: any, index: number) => (
        <Question
          accessor={accessor}
          entry={entry}
          entryIndex={entryIndex}
          index={index}
          key={`question-${entry}-${item.wId}`}
          item={item}
        />
      ))}
      <Button onClick={() => !formDisabled && removeEntry(entry)} style={{ alignSelf: 'end' }} disabled={formDisabled}>
        {t('questions.remove')}
      </Button>
    </EntryQuestionContainer>
  );
};

const RepeatingForm = ({ entries }: { entries: any[] }) => (
  <ReplyFormQuestionsContainer>
    {entries.map((entry: any, index) => (
      <EntryItem
        key={`repeatingEntry-${entry.entry}`}
        entry={entry.entry}
        entryIndex={index}
        questions={entry.questions}
      />
    ))}
  </ReplyFormQuestionsContainer>
);

export default RepeatingForm;
