import styled from 'styled-components';
import { IconAlert } from '../../assets/svgs/IconAlert';
import Grid from '../Grid';

export const AlertContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const ErrorMessage = styled(Grid)`
  margin: -30px 0 0 20px;
  left: 18px;
  right: 0;
  top: 0;
  bottom: 0;
  font-style: normal;
  font-weight: 450;
  font-size: 12px;
  line-height: 16px;
  color: #9c2514;
`;

export const StyledIconAlert = styled(IconAlert)`
  margin: 0 0 8px 0;
  left: 8.33%;
  right: 8.33%;
  top: 8.33%;
  bottom: 8.33%;
`;
