import React, { ReactNode, RefObject, useEffect, useRef } from 'react';

type Props = (ref: RefObject<any>, onClickAway: () => void) => void;

const useClickAwayListener: Props = (ref: RefObject<any>, onClickAway) => {
  useEffect(() => {
    function handleClickOutside(event: Event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickAway();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
};

interface ClickAwayListenerProps {
  children: ReactNode | ReactNode[];
  onClickAway: () => void;
}

function ClickAwayListener({ children, onClickAway }: ClickAwayListenerProps) {
  const wrapperRef = useRef(null);
  useClickAwayListener(wrapperRef, onClickAway);

  return <div ref={wrapperRef}>{children}</div>;
}

export default ClickAwayListener;
