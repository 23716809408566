import styled, { css } from 'styled-components';

export interface ButtonProps {
  primary?: boolean;
  disabled?: boolean;
  full?: boolean;
  loading?: boolean;
  mt?: number;
  mb?: number;
  ml?: number;
  mr?: number;
}

const LOADING_CSS = ({ loading }: ButtonProps) =>
  loading
    ? css`
        & {
          position: relative;
          pointer-events: none;
          user-select: none;
          cursor: none;
        }
        & > .children {
          opacity: 0;
        }
        & > .loader-container {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          opacity: 1;
        }
      `
    : css`
        & > .loader-container {
          display: none;
        }
      `;

const DEFAULT_DISABLED_STYLE = css`
  pointer-events: none;
  user-select: none;
  cursor: none;
`;

const PRIMARY_STYLE = css<ButtonProps>`
  background-color: #476bc3;
  border: none;
  color: #fff;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #cdc7c2;
    `}
`;

export const StyledButton = styled.button<ButtonProps>`
  background-color: #ffffff;
  border: 2px solid #5f86d8;

  ${({ full }) => full && 'width: 100%'};
  ${({ full }) => full && 'justify-content: center'};
  color: #324fa5;
  border-radius: 8px;
  height: 40px;
  padding: 12px 16px;
  display: flex;
  flex-grow: 0;
  cursor: pointer;

  white-space: nowrap;
  transition: background-color 0.2s;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;

  ${({ disabled }) =>
    disabled &&
    css`
      ${DEFAULT_DISABLED_STYLE};
      border: 2px solid #b2aba5;
      color: #938c85;
    `}

  ${({ primary }) => primary && PRIMARY_STYLE}
  ${({ mt }) => mt && `margin-top:${mt}px`};
  ${({ mb }) => mb && `margin-bottom:${mb}px`};
  ${({ ml }) => ml && `margin-left:${ml}px`};
  ${({ mr }) => mr && `margin-right:${mr}px`};
  ${LOADING_CSS}
`;
