import styled, { css } from 'styled-components';

export const Input = styled.input.attrs({
  type: 'file',
})`
  display: none;
`;

export const StyledLabel = styled.label<{ disabled: boolean }>`
  color: #fff;
  border: 1px solid #ccc;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;
  width: 200px;
  height: 40px;
  background: #476bc3;
  border-radius: 8px;
  box-sizing: border-box;
  ${({ disabled }) =>
    disabled &&
    css`
      .uploadIcon {
        fill: #000;
      }
      pointer-events: none;
      user-select: none;
      cursor: none;
      background: #f2f0ed;
      border: 1px solid #f2f0ed;
      color: #938c85;
    `}
`;
