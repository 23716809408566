/* eslint-disable react/jsx-no-useless-fragment */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ReplyFormContext from '../../context';
import { FieldType } from '../../../Form/types/FieldType';
import AnswerField from '../AnswerField';
import formatReceivedFormData from '../../utils/formatReceivedData';
import { ReplyFormQuestionsContainer } from '../style';
import { BranchQuestionContainer } from './style';
import { useFormCustomRerender } from '../DefaultForm/useFormCustomRerender';
import { useInternalVariableReplacer } from '../DefaultForm/useInternalVariableReplacer';
import { useJwtContext } from '../../../../JwtContext';

interface QuestionProps {
  branchIndex: number;
  branchId: string;
  index: number;
  item: {
    wId: string;
    branchId: any;
    rpaID: string;
    formID: string;
    type: FieldType;
    answer: string | string[];
    customTypeId: string;
    choices?: any[];
    rules: any;
    title: string;
    childOf?: string;
    expertMenu?: string;
    expertField?: string;
    files?: any[];
    supportedFileFormats?: string;
    questions?: any[];
    mask?: string;
    validationCode?: string;
    customTypeName?: string;
    validationMessage?: string;
  };
}

const Question = ({ index, item, branchIndex, branchId }: QuestionProps) => {
  const { wId, type, questions, choices, files, supportedFileFormats } = item;
  const { mask, validationCode, validationMessage, customTypeName } = item;
  const { control, register, errors } = useContext(ReplyFormContext);
  const accessFieldsName = `branches[${branchIndex}].answers[${index}]`;
  const decodedJwt = useJwtContext();
  const dateFormat = decodedJwt?.dateFormat;
  const shouldRender = useFormCustomRerender(item, accessFieldsName, dateFormat, { branchIndex });
  const replacedTitle = useInternalVariableReplacer(item, accessFieldsName, { branchIndex });
  if (!shouldRender) return <></>;
  return (
    <AnswerField
      formTypeIsBranch
      branchId={branchId}
      nestIndex={branchIndex}
      control={control}
      register={register}
      wId={wId}
      errors={errors}
      accessFieldsName={accessFieldsName}
      fieldType={type}
      {...{
        title: replacedTitle,
        questions,
        choices,
        files,
        supportedFileFormats,
        mask,
        validationCode,
        validationMessage,
        customTypeName,
      }}
    />
  );
};

const BranchItem = ({ branchId, branchIndex, questions, variables }: any) => {
  const formattedQuestions = formatReceivedFormData(questions, variables);
  const { t } = useTranslation();
  const name = `${t('form.branch')} ${branchIndex + 1}`;
  return (
    <BranchQuestionContainer>
      <h1>{name}</h1>
      {formattedQuestions.map((item: any, index: number) => (
        <Question
          branchId={branchId}
          branchIndex={branchIndex}
          index={index}
          key={`question-${branchId}-${item.wId}`}
          item={item}
        />
      ))}
    </BranchQuestionContainer>
  );
};

const BranchForm = ({ data }: any) => (
  <ReplyFormQuestionsContainer>
    {data.branches.map((item: any) => (
      <BranchItem
        key={`BranchForm-${item.branchIndex}`}
        branchId={item.branchId}
        branchIndex={item.branchIndex}
        questions={item.questions}
        variables={data.titleVariableAnswers}
      />
    ))}
  </ReplyFormQuestionsContainer>
);

export default BranchForm;
