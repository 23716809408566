import React from 'react';

export interface IconFormsProps {
  width?: number;
  height?: number;
  fill?: string;
}

export const IconForms = ({ width = 18, height = 20, fill = '#726c66' }: IconFormsProps) => (
  <svg width={width} height={height} viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 0V2H17.007C17.555 2 18 2.445 18 2.993V19.007C18 19.555 17.555 20 17.007 20H0.993C0.445 20 0 19.555 0 19.007V2.993C0 2.445 0.445 2 0.993 2H4V0H14ZM4 4H2V18H16V4H14V6H4V4ZM6 14V16H4V14H6ZM6 11V13H4V11H6ZM6 8V10H4V8H6ZM12 2H6V4H12V2Z"
      fill={fill}
    />
  </svg>
);
