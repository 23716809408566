import React from 'react';

interface ToastWarningIconProps {
  width?: number;
  height?: number;
}

const ToastWarningIcon = ({ width = 16, height = 20 }: ToastWarningIconProps) => (
  <svg width={width} height={height} viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5865 8.5V13.5L14.8765 14.79C15.5065 15.42 15.0565 16.5 14.1665 16.5H0.996477C0.106477 16.5 -0.333523 15.42 0.296477 14.79L1.58648 13.5V8.5C1.58648 5.42 3.21648 2.86 6.08648 2.18V1.5C6.08648 0.67 6.75648 0 7.58648 0C8.41648 0 9.08648 0.67 9.08648 1.5V2.18C11.9465 2.86 13.5865 5.43 13.5865 8.5ZM9.58648 17.5C9.58648 18.6 8.68648 19.5 7.58648 19.5C6.47648 19.5 5.58648 18.6 5.58648 17.5H9.58648Z"
      fill="#262321"
    />
  </svg>
);

export default ToastWarningIcon;
