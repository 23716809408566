import styled from 'styled-components';

export const Body = styled.div`
  width: 100%;
`;

export const FormTitle = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #262321;
`;

export const Box = styled.div`
  width: 100%;
  border-radius: 16px;
  padding: 24px;
  background: #f2f5ff;
  margin-bottom: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

export const BoxActions = styled.div`
  align-self: end;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 25px;
  gap: 12px;
  margin-bottom: 24px;

  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 16px;
  color: #393533;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 40px;
`;

export const InlineInputs = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  flex-wrap: wrap;
`;

interface InputWrapperProps {
  grow?: number;
}

export const InputWrapper = styled.div<InputWrapperProps>`
  flex-grow: ${({ grow }) => grow || 1};
`;

export const InputWrapperMultipleForm = styled.div<InputWrapperProps>`
  width: calc(20% - 40px);
`;

export const Form = styled.form`
  display: flex;
  gap: 24px;
  flex-direction: column;
  width: 100%;
`;
