import React from 'react';

interface SaveDraftIconProps {
  width?: number;
  height?: number;
}

const SaveDraftIcon = ({ width = 24, height = 24 }: SaveDraftIconProps) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 16H1V18H11V16Z" fill="#423E3B" />
    <path d="M7.25 11H1V13H7.25V11Z" fill="#423E3B" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 4V2H7V4H3.993C3.445 4 3 4.445 3 4.993V9H5V6H7V8H17V6H19V20H9H5H3V21.007C3 21.555 3.445 22 3.993 22H20.007C20.555 22 21 21.555 21 21.007V4.993C21 4.445 20.555 4 20.007 4H17ZM9 4H15V6H9V4Z"
      fill="#423E3B"
    />
  </svg>
);

export default SaveDraftIcon;
