import React from 'react';

export interface UserManagementContextProps {
  editState?: any;
  dispatchEdit?: any;
  createState?: any;
  dispatchCreate?: any;
}

const UserManagementContext = React.createContext<UserManagementContextProps>({});

export default UserManagementContext;
