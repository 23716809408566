import React from 'react';

export interface Ellipse19Props {
  width?: number;
  height?: number;
  className?: string;
}

export const Ellipse19 = ({ width = 230, height = 118, className }: Ellipse19Props) => (
  <svg
    id="el19"
    className={className}
    width={width}
    height={height}
    viewBox="0 0 230 118"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g style={{ mixBlendMode: 'soft-light' }}>
      <ellipse cx="59.5" cy="163" rx="163" ry="170.5" transform="rotate(90 59.5 163)" fill="#F1615D" />
    </g>
  </svg>
);
