export const MAX_WIDTHS = Object.freeze({
  sm: 540,
  md: 720,
  lg: 960,
  xl: 1366,
});

export const BREAKPOINTS = Object.freeze({
  sm: 576,
  md: 758,
  lg: 992,
  xl: 1200,
});
