import React from 'react';

interface OrderIconProps {
  width?: number;
  height?: number;
}

export const OrderIcon = ({ width = 13, height = 18 }: OrderIconProps) => (
  <svg width={width} height={height} viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4395 14.6525L9.64947 17.4325C9.45947 17.6225 9.13947 17.6225 8.94947 17.4225L6.15947 14.6425C5.83947 14.3325 6.05947 13.7925 6.50947 13.7925H8.29947V7.7825C8.29947 7.2325 8.74947 6.7825 9.29947 6.7825C9.84947 6.7825 10.2995 7.2325 10.2995 7.7825V13.8025H12.0895C12.5295 13.8025 12.7595 14.3425 12.4395 14.6525ZM3.64947 0.1425L6.43947 2.9225C6.75947 3.2325 6.53947 3.7725 6.08947 3.7725H4.29947V9.7825C4.29947 10.3325 3.84947 10.7825 3.29947 10.7825C2.74947 10.7825 2.29947 10.3325 2.29947 9.7825V3.7725L0.499472 3.7725C0.0594721 3.7725 -0.170527 3.2325 0.149472 2.9225L2.93947 0.1425C3.13947 -0.0475 3.44947 -0.0475 3.64947 0.1425Z"
      fill="#262321"
    />
  </svg>
);
