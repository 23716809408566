import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import CodeEditor from '@uiw/react-textarea-code-editor';
// @ts-ignore
import safeEval from 'safe-eval';
import { useTranslation } from 'react-i18next';
import FormError from '../../../../../../components/FormError';
import Input from '../../../../../../components/Input';
import { CodeLabel, InputContainer, StyledCodeEditor, StyledToogle, ToogleBtn, TwoColumns } from './style';
import { LightIcon } from '../../../../../../assets/svgs/LightIcon';
import { DarkIcon } from '../../../../../../assets/svgs/DarkIcon';
import MaskedInput from '../MaskedInput';
import { ValidateMessage } from '../../../../style';
import ErrorWithIcon from '../../../../../../components/ErrorWithIcon';

enum Theme {
  LIGHT = 'light',
  DARK = 'dark',
}

interface ToogleProps {
  theme: Theme;
  changeTheme: () => void;
}

interface ValidationProps {
  control: any;
  errors: any;
  watch: any;
  defaultCode?: string;
}

const Toogle = ({ theme, changeTheme }: ToogleProps) => {
  const icon = theme === Theme.LIGHT ? <LightIcon /> : <DarkIcon />;

  return (
    <StyledToogle>
      <ToogleBtn onClick={changeTheme}>{icon}</ToogleBtn>
    </StyledToogle>
  );
};

const Validation = ({ control, errors, watch, defaultCode }: ValidationProps) => {
  const [theme, setTheme] = useState<Theme>(Theme.LIGHT);
  const [code, setCode] = useState<string>('');
  const [maskedInputText, setMaskedInputText] = useState<string>('');
  const [validInput, setValidInput] = useState<boolean>(false);
  const [codeWithError, setCodeWithError] = useState<boolean>(false);
  const watchMask = watch('mask');
  const watchValidationCode = watch('validationCode');
  const watchValidationMessage = watch('validationMessage');
  useEffect(() => setCode(defaultCode ?? ''), [defaultCode]);
  const { t } = useTranslation();

  const testInputIsDisabled = !watchMask || !watchValidationCode || !watchValidationMessage;

  useEffect(() => {
    setMaskedInputText('');
  }, [watchMask, watchValidationCode, watchValidationMessage]);

  const changeTheme = () => {
    if (theme === Theme.LIGHT) {
      return setTheme(Theme.DARK);
    }

    return setTheme(Theme.LIGHT);
  };

  useEffect(() => {
    if (!code) return;

    try {
      const validateFunction = safeEval(code);
      const unsmakedText = maskedInputText.replace(/[^a-zA-Z0-9]/g, '');
      const result = validateFunction(maskedInputText, unsmakedText);
      setValidInput(Boolean(result));
      setCodeWithError(false);
    } catch (e) {
      setCodeWithError(true);
      console.error(e);
    }
  }, [maskedInputText]);

  return (
    <TwoColumns>
      <StyledCodeEditor theme={theme}>
        <Toogle changeTheme={changeTheme} theme={theme} />
        <CodeLabel>{t('questions.codeJS')}</CodeLabel>
        <Controller
          name="validationCode"
          rules={{
            required: t<string>('questions.requiredField'),
            validate: (stringFunction: string) =>
              typeof safeEval(stringFunction) === 'function' || t<string>('questions.invalidFunction'),
          }}
          control={control}
          render={({ field: { onChange, ...otherFieldProps } }) => (
            <CodeEditor
              onChange={(event) => {
                onChange(event.target.value);
                if (codeWithError) setCodeWithError(false);
                return setCode(event.target.value);
              }}
              {...otherFieldProps}
              language="js"
              padding={12}
              style={{
                fontSize: 15,
                border: 2,
                borderColor: '#938C85',
                borderStyle: 'solid',
              }}
            />
          )}
        />
        {codeWithError && <ErrorWithIcon message={t('questions.errorCode')} />}
        <FormError errors={errors} name="validationCode" />
      </StyledCodeEditor>
      <InputContainer>
        <div>
          <Controller
            name="mask"
            control={control}
            rules={{
              required: t<string>('questions.requiredMask'),
            }}
            render={({ field }) => <Input {...field} label={t('questions.labelMask')} />}
          />
          <FormError errors={errors} name="mask" />
        </div>
        <div>
          <Controller
            name="validationMessage"
            control={control}
            rules={{
              required: t<string>('questions.requiredMessage'),
            }}
            render={({ field }) => <Input {...field} label={t('questions.labelMessage')} />}
          />
          <FormError errors={errors} name="validationMessage" />
        </div>
        <div>
          <MaskedInput
            disabled={testInputIsDisabled}
            value={maskedInputText}
            label={t('questions.labelTest')}
            onChange={(value: string) => setMaskedInputText(value)}
            mask={watchMask}
          />
          {!testInputIsDisabled &&
            (validInput ? (
              <ValidateMessage success>{t('questions.valid')}</ValidateMessage>
            ) : (
              <ValidateMessage error>{t('questions.invalid')}</ValidateMessage>
            ))}
        </div>
      </InputContainer>
    </TwoColumns>
  );
};

export default Validation;
