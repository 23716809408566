import React from 'react';

interface EmptyStateIconProps {
  width?: number;
  height?: number;
}

const EmptyStateIcon = ({ width = 70, height = 70 }: EmptyStateIconProps) => (
  <svg width={width} height={height} viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M35 9.43359C29.9434 9.43338 25.0003 10.9326 20.7958 13.7418C16.5912 16.5509 13.3142 20.5438 11.3789 25.2154C9.44371 29.887 8.93726 35.0276 9.92362 39.9871C10.91 44.9466 13.3449 49.5022 16.9203 53.0778C20.4958 56.6534 25.0513 59.0885 30.0107 60.0751C34.9702 61.0616 40.1108 60.5554 44.7825 58.6204C49.4542 56.6854 53.4472 53.4084 56.2565 49.204C59.0658 44.9996 60.5653 40.0566 60.5653 35C60.5581 28.2218 57.8623 21.7232 53.0695 16.9302C48.2766 12.1372 41.7782 9.44112 35 9.43359ZM35 58.6523C30.322 58.6526 25.7489 57.2656 21.8592 54.6667C17.9694 52.0679 14.9377 48.374 13.1473 44.0521C11.3569 39.7302 10.8884 34.9745 11.8009 30.3863C12.7134 25.7981 14.966 21.5836 18.2738 18.2757C21.5816 14.9677 25.796 12.7149 30.3842 11.8022C34.9723 10.8895 39.7281 11.3578 44.05 13.148C48.372 14.9381 52.066 17.9697 54.665 21.8593C57.264 25.749 58.6512 30.322 58.6512 35C58.644 41.2706 56.1499 47.2823 51.716 51.7164C47.2822 56.1504 41.2706 58.6448 35 58.6523Z"
      fill="#979797"
    />
    <path
      d="M35 42.6436C34.1852 42.6437 33.3888 42.8854 32.7114 43.3381C32.034 43.7908 31.5061 44.4343 31.1944 45.187C30.8826 45.9398 30.8011 46.7681 30.9602 47.5672C31.1192 48.3663 31.5116 49.1003 32.0877 49.6764C32.6639 50.2524 33.3979 50.6447 34.1971 50.8037C34.9962 50.9626 35.8244 50.881 36.5772 50.5691C37.3299 50.2573 37.9733 49.7293 38.4259 49.0518C38.8786 48.3744 39.1201 47.5779 39.1201 46.7632C39.119 45.6708 38.6845 44.6236 37.9121 43.8512C37.1396 43.0789 36.0923 42.6446 35 42.6436ZM35 48.9687C34.5638 48.9686 34.1374 48.8392 33.7748 48.5967C33.4122 48.3543 33.1296 48.0098 32.9627 47.6068C32.7959 47.2038 32.7523 46.7603 32.8374 46.3325C32.9226 45.9047 33.1327 45.5118 33.4412 45.2034C33.7497 44.895 34.1426 44.685 34.5705 44.6C34.9983 44.5149 35.4417 44.5586 35.8447 44.7256C36.2477 44.8925 36.5921 45.1752 36.8344 45.5379C37.0768 45.9006 37.2061 46.327 37.2061 46.7632C37.2054 47.348 36.9727 47.9086 36.5591 48.3221C36.1455 48.7356 35.5848 48.9681 35 48.9687Z"
      fill="#979797"
    />
    <path
      d="M35 19.1172C33.9078 19.1183 32.8606 19.5527 32.0883 20.3251C31.3159 21.0974 30.8815 22.1446 30.8804 23.2368V36.2524C30.8929 37.3369 31.3324 38.3726 32.1037 39.1351C32.875 39.8975 33.9158 40.3251 35.0003 40.3251C36.0848 40.3251 37.1256 39.8975 37.8968 39.1351C38.6681 38.3726 39.1077 37.3369 39.1201 36.2524V23.2368C39.1188 22.1445 38.6843 21.0973 37.9119 20.325C37.1395 19.5527 36.0923 19.1183 35 19.1172ZM37.2055 36.2524C37.1964 36.8314 36.96 37.3836 36.5473 37.7899C36.1347 38.1961 35.5788 38.4238 34.9997 38.4238C34.4206 38.4238 33.8648 38.1961 33.4521 37.7899C33.0394 37.3836 32.803 36.8314 32.7939 36.2524V23.2368C32.803 22.6578 33.0394 22.1056 33.4521 21.6994C33.8648 21.2931 34.4206 21.0654 34.9997 21.0654C35.5788 21.0654 36.1347 21.2931 36.5473 21.6994C36.96 22.1056 37.1964 22.6578 37.2055 23.2368V36.2524Z"
      fill="#979797"
    />
  </svg>
);

export default EmptyStateIcon;
