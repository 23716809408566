import React from 'react';
import { DOTS, usePagination } from './usePagination';
import { Arrow, Dots, PaginationContainer, PaginationItem } from './style';

interface PaginationProps {
  onPageChange: (page: number) => void;
  totalCount: number;
  pageSize: number;
  currentPage: number;
  siblingCount?: number;
}

const Pagination = ({ onPageChange, totalCount, currentPage, pageSize, siblingCount = 1 }: PaginationProps) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (!paginationRange) return null;

  if (currentPage === 0 || paginationRange.length < 2) return null;

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];

  return (
    <PaginationContainer>
      <PaginationItem disabled={currentPage === 1} onClick={onPrevious} arrowItem>
        <Arrow side="left" />
      </PaginationItem>
      {paginationRange.map((pageNumber) => {
        if (pageNumber === DOTS) {
          return <Dots>&#8230;</Dots>;
        }

        if (!(typeof pageNumber === 'string')) {
          return (
            <PaginationItem
              selected={pageNumber === currentPage}
              onClick={() => onPageChange(pageNumber)}
              key={pageNumber}
            >
              {pageNumber}
            </PaginationItem>
          );
        }

        return null;
      })}
      <PaginationItem disabled={currentPage === lastPage} onClick={onNext} arrowItem>
        <Arrow side="right" />
      </PaginationItem>
    </PaginationContainer>
  );
};

export default Pagination;
