import { cloneElement, createElement } from 'react';
import MultipleChoice from './components/ChoicesFields/MultipleChoice';
import UniqueChoice from './components/ChoicesFields/UniqueChoice';
import InputField from './components/InputField';
import Panel from './components/Panel';
import Upload from './components/Upload';
import Download from './components/Download';
import LongText from './components/LongText';
import { FieldType } from '../../../Form/types/FieldType';
import AnswerSelect from './components/ChoicesFields/AnswerSelect';
import ValidationField from './components/ValidationField';
import InputDate from './components/InputDate';
import InputNumber from './components/InputNumber';
import InputPhone from './components/InputPhone';
import InputEmail from './components/InputEmail';

const elements = new Map();
elements.set(FieldType.TEXT, createElement(InputField, { type: 'text' }));
elements.set(FieldType.LONG_TEXT, createElement(LongText));
elements.set(FieldType.MULTIPLE_CHOICE, createElement(MultipleChoice));
elements.set(FieldType.SINGLE_CHOICE, createElement(UniqueChoice));
elements.set(FieldType.DOWNLOAD, createElement(Download));
elements.set(FieldType.PANEL, createElement(Panel));
elements.set(FieldType.UPLOAD, createElement(Upload));
elements.set(FieldType.EMAIL, createElement(InputEmail));
elements.set(FieldType.USER_NAME, createElement(InputField, { type: 'text' }));
elements.set(FieldType.USER_EMAIL, createElement(InputField, { type: 'text' }));
elements.set(FieldType.SELECT, createElement(AnswerSelect));

elements.set(FieldType.NUMBER, createElement(InputNumber));
elements.set(FieldType.VALIDATION, createElement(ValidationField));
elements.set(FieldType.DATE, createElement(InputDate));
elements.set(FieldType.PHONE, createElement(InputPhone));

interface AnswerFieldProps {
  nestIndex: number;
  control: any;
  register: any;
  errors: any;
  accessFieldsName: string;
  fieldType: FieldType | null;
  title: string;
  wId: string;
  formTypeIsBranch?: boolean;
  branchId?: string;
  entry?: string;
}

const AnswerField = ({ fieldType, ...props }: AnswerFieldProps) => {
  const element = elements.get(fieldType);
  return cloneElement(element, props);
};

export default AnswerField;
