import React from 'react';

interface RemoteIconProps {
  width?: number;
  height?: number;
}

const RotateIcon = ({ width = 16, height = 16 }: RemoteIconProps) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.66602 11.9997L2.66601 1.99968C2.66601 1.82286 2.73625 1.65329 2.86128 1.52827C2.9863 1.40325 3.15587 1.33301 3.33268 1.33301L7.99935 1.33301L7.99935 2.66634L3.99935 2.66634L3.99935 11.9997L5.99935 11.9997L3.33268 15.333L0.666015 11.9997L2.66602 11.9997ZM13.3327 3.99967L13.3327 13.9997C13.3327 14.1765 13.2624 14.3461 13.1374 14.4711C13.0124 14.5961 12.8428 14.6663 12.666 14.6663L7.99935 14.6663L7.99935 13.333L11.9993 13.333L11.9993 3.99967L9.99935 3.99967L12.666 0.666341L15.3327 3.99967L13.3327 3.99967Z"
      fill="#324FA5"
    />
  </svg>
);

export default RotateIcon;
