import styled from 'styled-components';
import { IconArrow } from '../../../assets/svgs/IconArrow';
import Grid from '../../../components/Grid';

export const Form = styled.form`
  width: 100%;
  position: relative;
`;

export const Label = styled(Grid)`
  font-style: normal;
  font-weight: 450;
  font-size: 13px;
  line-height: 16px;
  margin-top: 20px;
  color: #262321;
`;

export const TextButton = styled(Grid)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0 10px;
`;

export const Title = styled(Grid)`
  font-style: normal;
  font-weight: 450;
  font-size: 32px;
  line-height: 38px;
  color: #262321;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0 0 20px 0;
`;

export const Subtitle = styled(Grid)`
  font-style: normal;
  font-weight: 450;
  font-size: 13px;
  line-height: 16px;
  color: #262321;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 4px 0;
`;

export const StyledIconArrow = styled(IconArrow)`
  margin: 0 0 -3px 10px;
  left: 16.67%;
  right: 18.41%;
  top: 16.67%;
  bottom: 20.06%;
`;
