import { cloneElement } from 'react';
import { FieldType } from '../../types/FieldType';

interface CreatableFieldProps {
  elements: any;
  fieldType: FieldType | null;
  accessFieldsName: string;
  nestIndex: number;
}

const CreatableField = ({ elements, fieldType, ...props }: CreatableFieldProps) => {
  const element = elements.get(fieldType);
  return cloneElement(element, props);
};

export default CreatableField;
