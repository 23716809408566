import axios from 'axios';
import i18n from 'i18next';
import getEnvironment from './getEnvironment';
import { getFromLocalStorage } from '../utils/storageUtils';
import './interceptor';

const baseURL = getEnvironment();

function getToken(token?: string) {
  const savedToken = getFromLocalStorage('token');
  return `Bearer ${token ?? savedToken}`;
}

type AxiosMethod = 'get' | 'post' | 'put' | 'patch' | 'delete';
const executeRequest = (method: AxiosMethod, url: string, data?: any, options?: any, isPublic = false) => {
  // axios.defaults.headers.patch['Content-Type'] = 'application/merge-patch+json';
  axios.defaults.headers[method]['Accept-Language'] = i18n.language || 'pt';
  if (!isPublic) {
    axios.defaults.headers[method].Authorization = getToken();
    const selectedProject = getFromLocalStorage('selectedProject');
    if (selectedProject) axios.defaults.headers[method]['Wizard-Project-Id'] = selectedProject;
  }
  if (method === 'delete' && data) {
    return axios.delete(`${baseURL}${url}`, { data });
  }
  return axios[method](`${baseURL}${url}`, data, options);
};

const api = {
  get: (url: string, isPublic = false) => executeRequest('get', url, undefined, isPublic),
  post: (url: string, data?: any, options?: any, isPublic = false) =>
    executeRequest('post', url, data, options, isPublic),
  put: (url: string, data: any) => executeRequest('put', url, data),
  patch: (url: string, data: any) => executeRequest('patch', url, JSON.stringify(data)),
  delete: (url: string, data?: any) => executeRequest('delete', url, JSON.stringify(data)),
};

export default api;
