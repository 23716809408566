import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Title } from '../style';
import Form from '../components/Form';
import { updateQuestionSettingsRequest } from '../../../requests/questionsSettings';
import Alert from '../../../components/Toast/toast';
import { TableData } from '../../../types/TableData';
import { Breadcrumbs } from '../../../components/BreadCrumb';
import { BreadcrumbWrapper } from '../../../components/BreadCrumb/style';

const QuestionSettingsEdit = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { questionData } = location.state as TableData;
  const { t } = useTranslation();

  const onSubmit = async (data: any) => {
    const id = questionData._id;
    const formData = { ...data, type: data.type.value };

    try {
      await updateQuestionSettingsRequest(formData, id);
      Alert.SUCCESS(t('questions.update'));
      navigate(`/settings/question`);
    } catch (e: any) {
      Alert.ERROR(e.response.data.message);
    }
  };

  return (
    <Container>
      <BreadcrumbWrapper>
        <Breadcrumbs />
      </BreadcrumbWrapper>
      <Title>{t('questions.settingsQuestions')}</Title>
      <Form defaultValues={questionData} onSubmit={onSubmit} />
    </Container>
  );
};

export default QuestionSettingsEdit;
