import React, { useContext, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FieldTitle } from '../../../style';
import { ChoicesContainer, StyledChoice } from '../style';
import RadioButton from '../../../../../../../components/MarkableButton/RadioButton';
import FormError from '../../../../../../../components/FormError';
import ReplyFormContext from '../../../../../context';

const Choice = ({ onChange, selected, setSelected, title, index, prependToKey, defaultSelected }: any) => {
  const checked = (selected !== null && selected === index) || defaultSelected === title;
  const { formDisabled } = useContext(ReplyFormContext);

  const handleChange = (e: any) => {
    if (formDisabled) return;
    const { value } = e.target;
    setSelected(index);
    onChange(value);
  };

  return (
    <StyledChoice disabled={formDisabled}>
      <RadioButton
        value={title}
        checked={checked}
        onChange={handleChange}
        id={`${prependToKey}-RadioButton${title}${index}`}
        disabled={formDisabled}
      />
      <p>{title}</p>
    </StyledChoice>
  );
};

interface ChoiceListProps {
  watchKey: string;
  control: any;
  selected: number | null;
  setSelected: any;
  onChange: any;
  choices: { title: string }[];
  prependToKey: string;
}

const ChoiceList = ({ choices, control, selected, setSelected, onChange, prependToKey, watchKey }: ChoiceListProps) => {
  const { watch } = useContext(ReplyFormContext);
  const value = watch(watchKey);
  return (
    <>
      {choices.map(({ title: choiceTitle }, index: number) => (
        <Choice
          // eslint-disable-next-line react/no-array-index-key
          key={`Choice-${choiceTitle}${index}`}
          onChange={onChange}
          title={choiceTitle}
          control={control}
          defaultSelected={value}
          index={index}
          selected={selected}
          setSelected={setSelected}
          prependToKey={prependToKey}
        />
      ))}
    </>
  );
};

export interface UniqueChoiceProps {
  control: any;
  errors: any;
  accessFieldsName: string;
  watchKey: string;
  register: any;
  wId: string;
  title: string;
  choices: { title: string }[];
}

const UniqueChoice = ({ errors, control, accessFieldsName, register, wId, title, choices }: UniqueChoiceProps) => {
  const [selected, setSelected] = useState<number | null>(null);
  const { t } = useTranslation();

  return (
    <ChoicesContainer>
      <FieldTitle>{title}</FieldTitle>
      <input type="hidden" name={`${accessFieldsName}.wId`} {...register(`${accessFieldsName}.wId`)} value={wId} />
      <Controller
        control={control}
        rules={{
          required: t<string>('common.required'),
        }}
        name={`${accessFieldsName}.answer`}
        render={({ field: { onChange } }) => (
          <ChoiceList
            onChange={onChange}
            choices={choices}
            control={control}
            watchKey={`${accessFieldsName}.answer`}
            selected={selected}
            setSelected={setSelected}
            prependToKey={accessFieldsName}
          />
        )}
      />
      <FormError errors={errors} name={`${accessFieldsName}.answer`} />
    </ChoicesContainer>
  );
};

export default UniqueChoice;
