import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import Alert from '../../../../components/Toast/toast';
import { deleteBusinessUnitRequest, updateBusinessUnitRequest } from '../../../../requests/businessUnit';
import { TableData } from '../../../../types/TableData';
import { Display, Footer, FormWrapper, InputWrapper, Title } from '../style';
import { Creators as modalActions } from '../../../../redux/reducers/modal-reducer';
import ModalContent from '../../../../components/DeleteModal';
import { ActionIcon } from '../../style';
import { DeleteIcon } from '../../../../assets/svgs/DeleteIcon';
import { Breadcrumbs } from '../../../../components/BreadCrumb';
import { BreadcrumbWrapper } from '../../../../components/BreadCrumb/style';

export function EditBusinessUnitSettings() {
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = location.state as TableData;
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { openModal, closeModal } = modalActions;
  const closeHandler = () => dispatch(closeModal());

  const returnHandler = () => {
    navigate('/settings/forms?selectedTab=2');
  };

  const editHandler = async (formData: any) => {
    try {
      const id = data._id;
      await updateBusinessUnitRequest(formData, id);
      Alert.SUCCESS(t('editBusiness.edit'));
      returnHandler();
    } catch (error: any) {
      const { message } = error.response.data;
      Alert.ERROR(message);
    }
  };

  const deleteHandler = async (...deleteData: TableData[]) => {
    const deleteSubmit = async () => {
      await deleteBusinessUnitRequest(deleteData);
      Alert.SUCCESS(t('editBusiness.delete'));
      closeHandler();
      returnHandler();
    };
    dispatch(
      openModal({
        content: <ModalContent title={t('editBusiness.alert')} deleteSubmit={deleteSubmit} />,
      })
    );
  };

  const { control, handleSubmit } = useForm({
    defaultValues: {
      name: data.name,
    },
  });

  const onSubmit = (formData: any) => {
    editHandler(formData);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormWrapper>
        <BreadcrumbWrapper>
          <Breadcrumbs />
        </BreadcrumbWrapper>
        <Title>{data.name}</Title>
        <Display>
          <InputWrapper>
            <Controller
              name="name"
              control={control}
              rules={{
                required: t<string>('editBusiness.required'),
              }}
              render={({ field }) => (
                <Input {...field} label={t('editBusiness.label')} placeholder={t('common.type')} />
              )}
            />
            <ActionIcon onClick={() => deleteHandler(data)}>
              <DeleteIcon fill="#324fa5" />
            </ActionIcon>
          </InputWrapper>
          <Footer>
            <Button onClick={() => returnHandler()}>{t('common.back')}</Button>
            <Button type="submit" primary>
              {t('common.saveEditions')}
            </Button>
          </Footer>
        </Display>
      </FormWrapper>
    </form>
  );
}
