import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { InputContainer, Label, StyledInput } from './style';

interface InputProps {
  pattern?: string;
  id?: string;
  label?: any;
  type?: string;
  name?: string;
  value?: any;
  placeholder?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => any;
  className?: string;
  error?: string | boolean;
  disabled?: boolean;
}

const Input = React.forwardRef(
  (
    {
      id,
      label,
      type,
      name,
      placeholder,
      onChange,
      value,
      error = false,
      disabled,
      className,
      pattern,
      ...props
    }: InputProps,
    ref
  ) => {
    const { t } = useTranslation();

    return (
      <InputContainer className={className}>
        <Label>{label}</Label>
        <StyledInput
          maxLength={500}
          disabled={disabled}
          ref={ref}
          error={error}
          id={id}
          type={type}
          name={name}
          placeholder={placeholder || t('common.type')}
          onChange={onChange}
          value={value}
          {...props}
        />
      </InputContainer>
    );
  }
);

export default Input;
