import styled from 'styled-components';
import { MAX_WIDTHS } from '../../../../constants/screen';
import { TableRow } from '../../../../components/Table/style';

export const InlineFormWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: ${MAX_WIDTHS.sm}px) {
    flex-direction: column;
    align-items: flex-start;
  } ;
`;

export const InlineFormButton = styled.button.attrs({
  type: 'submit',
})`
  background: none;
  border: none;
  cursor: pointer;

  color: #324fa5;
  font-family: 'Circular20', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const InlineFormInput = styled.input.attrs({
  type: 'text',
})<{ errors: boolean }>`
  font-family: 'Circular20', sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 16px;
  border: 1px solid #476bc3;
  padding: 5px;
  width: 250px;

  ${({ errors }) => errors && 'border-color: red'};

  @media (max-width: ${MAX_WIDTHS.sm}px) {
    flex-direction: column;
    align-items: flex-start;
    width: 160px;
  } ;
`;

export const DisabledCheckbox = styled.div`
  width: 20px;
  height: 20px;
  background: #ffffff;
  border: 2px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 6px;
`;

export const AnimatedTableRow = styled(TableRow)`
  animation-name: show;
  animation-duration: 0.5s;
  position: relative;

  @keyframes show {
    0% {
      top: -54px;
    }
    100% {
      top: 0;
    }
  }
`;
