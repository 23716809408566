import React from 'react';
import { ErrorMessage, StyledIconAlert } from './style';

interface AlertProps {
  children?: string;
}

const Alert = ({ children }: AlertProps) => (
  <div>
    <StyledIconAlert />
    <ErrorMessage
      // eslint-disable-next-line react/no-children-prop
      children={children}
    />
  </div>
);

export default Alert;
