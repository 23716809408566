import React from 'react';

export interface NodeTreeIconProps {
  width?: number;
  height?: number;
}

export const NodeTreeIcon = ({ width = 16, height = 18 }: NodeTreeIconProps) => (
  <svg width={width} height={height} viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.33333 0.666016C6.79333 0.666016 7.16667 1.03935 7.16667 1.49935V4.83268C7.16667 5.29268 6.79333 5.66602 6.33333 5.66602H4.66667V7.33268H8.83333V6.49935C8.83333 6.03935 9.20667 5.66602 9.66667 5.66602H14.6667C15.1267 5.66602 15.5 6.03935 15.5 6.49935V9.83268C15.5 10.2927 15.1267 10.666 14.6667 10.666H9.66667C9.20667 10.666 8.83333 10.2927 8.83333 9.83268V8.99935H4.66667V13.9993H8.83333V13.166C8.83333 12.706 9.20667 12.3327 9.66667 12.3327H14.6667C15.1267 12.3327 15.5 12.706 15.5 13.166V16.4993C15.5 16.9594 15.1267 17.3327 14.6667 17.3327H9.66667C9.20667 17.3327 8.83333 16.9594 8.83333 16.4993V15.666H3.83333C3.37333 15.666 3 15.2927 3 14.8327V5.66602H1.33333C0.873333 5.66602 0.5 5.29268 0.5 4.83268V1.49935C0.5 1.03935 0.873333 0.666016 1.33333 0.666016H6.33333ZM13.8333 13.9993H10.5V15.666H13.8333V13.9993ZM13.8333 7.33268H10.5V8.99935H13.8333V7.33268ZM5.5 2.33268H2.16667V3.99935H5.5V2.33268Z"
      fill="#476BC3"
    />
  </svg>
);
