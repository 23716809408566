import { ChangeEvent } from 'react';
import styled, { css } from 'styled-components';

interface StyledInputProps {
  type?: string;
  name?: string;
  value?: string;
  placeholder?: string;
  error?: string | boolean;
  onClick?: (event: ChangeEvent<HTMLInputElement>) => any;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => any;
  ref: any;
}

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const Label = styled.div`
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 16px;
  color: #262321;
`;

export const DISABLED_STYLE = css`
  background: #f2f0ed;
  border: 2px solid #cdc7c2;
  color: #938c85;
  &::placeholder {
    color: #938c85;
  }
`;

export const StyledInput = styled.input<StyledInputProps>`
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 1px solid #938c85;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0 0 0 10px;
  ${({ error }) => error && 'border-color: red'}
  ${({ disabled }) => disabled && DISABLED_STYLE}
`;
