import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Input from '../../components/Input';
import Alert from '../../components/Toast/toast';
import { Breadcrumbs } from '../../components/BreadCrumb';
import { findAllProductRequest } from '../../requests/product';
import { findAllProjectTypeRequest } from '../../requests/projectType';
import { findAllBusinessUnitRequest } from '../../requests/businessUnit';
import { findAllServiceOfferRequest } from '../../requests/serviceOffer';
import { SelectOptions } from '../../types/SelectOptions';
import FormError from '../../components/FormError';
import { InlineInputs, InputWrapper } from '../Form/GenericFormFields/style';
import Select from '../../components/Select';
import { FORM_TYPE_VARIABLE_VALUES } from '../Form/constants';
import { Title } from '../EmailPermissions/style';
import { Display, FormWrapper } from '../SettingsForms/components/style';
import Loader from '../../components/Loader';
import { LoaderWrapper } from '../../components/Loader/style';
import { RootState } from '../../redux/store';
import { fetchOtherFormsQuestionsToUseOnRules } from '../../redux/fetchActions/dataReducer';
import { addVariableRequest, findVariableTypeByIdRequest, updateVariableRequest } from '../../requests/variables';
import Button from '../../components/Button';
import { FooterWrapper } from './styles';
import Grid from '../../components/Grid';
import { TableData } from '../../types/TableData';
import { BreadcrumbWrapper } from '../../components/BreadCrumb/style';

const parseDefaultValues = (
  defaultValues: any,
  params: {
    productType: SelectOptions[];
    projectType: SelectOptions[];
    businessUnit: SelectOptions[];
    serviceOffer: SelectOptions[];
    formType: SelectOptions[];
    formWId: SelectOptions[];
    questionWId: SelectOptions[];
  }
) =>
  Object.keys(defaultValues).reduce(
    (acc, cur) => ({
      ...acc,
      [cur]: Object.keys(params).includes(cur)
        ? // @ts-ignore
          (params[cur] as SelectOptions[]).find((option: SelectOptions) => defaultValues[cur] === option.value)
        : defaultValues[cur],
    }),
    {}
  );
export function VariablesEdit() {
  const [products, setProducts] = useState<SelectOptions[]>([]);
  const [businessUnits, setBusinessUnits] = useState<SelectOptions[]>([]);
  const [serviceOffers, setServiceOffers] = useState<SelectOptions[]>([]);
  const [projectTypes, setProjectTypes] = useState<SelectOptions[]>([]);
  const [conditions, setConditions] = useState<any>();
  const [variable, setVariable] = useState<any>();
  const [saving, setSaving] = useState<any>();
  const [loadingRefetchForms, setLoadingRefetchForms] = useState(false);
  const [loading, setLoading] = useState(true);
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  const forms = useSelector((state: RootState) => state?.data?.otherFormsQuestionsToUseOnRules ?? []).filter(
    (form) => form.value !== 'CURRENT_FORM'
  );

  const formMethods = useForm<any>({
    mode: 'onChange',
  });
  const {
    watch,
    formState: { errors },
    reset,
    control,
    handleSubmit,
    setValue,
  } = formMethods;
  const formatForSelect = (data: { name: string; _id: number | string }) => ({ value: data._id, label: data.name });

  const watchProjectType = watch('projectType');
  const watchBusinessUnit = watch('businessUnit');
  const watchServiceOffer = watch('serviceOffer');
  const watchProductType = watch('productType');
  const watchFormType = watch('formType');

  const dispatch = useDispatch();

  const fetchProducts = async () => {
    const productsData = await findAllProductRequest();
    const formattedProductsToSelect = productsData.map(formatForSelect);
    setProducts(formattedProductsToSelect);
    return formattedProductsToSelect;
  };

  const fetchProjects = async () => {
    const projectTypesData = await findAllProjectTypeRequest();
    const formattedProjectTypesToSelect = projectTypesData.map(formatForSelect);
    setProjectTypes(formattedProjectTypesToSelect);
    return formattedProjectTypesToSelect;
  };

  const fetchBusinessUnits = async () => {
    const businessUnitsData = await findAllBusinessUnitRequest();
    const formattedBusinessUnitsToSelect = businessUnitsData.map(formatForSelect);
    setBusinessUnits(formattedBusinessUnitsToSelect);
    return formattedBusinessUnitsToSelect;
  };

  const fetchServiceOffers = async () => {
    const serviceOffersData = await findAllServiceOfferRequest();
    const formattedServiceOffersToSelect = serviceOffersData.map(formatForSelect);
    setServiceOffers(formattedServiceOffersToSelect);
    return formattedServiceOffersToSelect;
  };

  const fetchVariable = async () => {
    if (!id) return;
    const fetchedVariable = await findVariableTypeByIdRequest(id);
    setVariable(fetchedVariable);
    return fetchedVariable;
  };

  const fetchAll = async () =>
    Promise.all([fetchProducts(), fetchProjects(), fetchBusinessUnits(), fetchServiceOffers()]);

  useEffect(() => {
    if (loading) return;
    setLoadingRefetchForms(true);
    setValue(`formWId`, null, { shouldValidate: true });
    setValue(`questionWId`, null, { shouldValidate: true });
    fetchOtherFormsQuestionsToUseOnRules(dispatch, {
      projectType: watchProjectType,
      businessUnit: watchBusinessUnit,
      serviceOffer: watchServiceOffer,
      productType: watchProductType,
      wId: 'A random wId to not replace the login inside the function and make use of it',
      formType: watchFormType,
    }).finally(() => setLoadingRefetchForms(false));
  }, [watchProjectType, watchBusinessUnit, watchServiceOffer, watchProductType, watchFormType]);

  useEffect(() => {
    const executePrimaryFetch = async () => {
      setLoading(true);
      const [fetchedVariable, parameters] = await Promise.all([fetchVariable(), fetchAll()]);
      // @ts-ignore
      const [otherFormsQuestionsToUseOnRules, { questionsIndexedByForm }] = await fetchOtherFormsQuestionsToUseOnRules(
        dispatch,
        {
          projectType: fetchedVariable?.projectType,
          businessUnit: fetchedVariable?.businessUnit,
          serviceOffer: fetchedVariable?.serviceOffer,
          productType: fetchedVariable?.productType,
          wId: 'A random wId to not replace the login inside the function and make use of it',
          formType: fetchedVariable?.formType,
        }
      );
      if (!fetchedVariable) return;
      setConditions(questionsIndexedByForm[fetchedVariable.formWId]);
      const parsedDefaultValues = parseDefaultValues(fetchedVariable, {
        productType: parameters[0],
        projectType: parameters[1],
        businessUnit: parameters[2],
        serviceOffer: parameters[3],
        formType: FORM_TYPE_VARIABLE_VALUES(),
        formWId: otherFormsQuestionsToUseOnRules as SelectOptions[],
        questionWId: questionsIndexedByForm[fetchedVariable.formWId],
      });
      reset(parsedDefaultValues);
    };
    executePrimaryFetch().then(() => {
      setLoading(false);
    });
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  const { name } = (location?.state ?? {}) as any;

  const returnHandler = () => {
    navigate('/settings/variables');
  };

  const onSubmit = async (formData: any) => {
    const reduced = Object.keys(formData).reduce(
      (acc, cur) => ({ ...acc, [cur]: formData[cur]?.value ?? formData[cur] ?? null }),
      {} as TableData
    );
    setSaving(true);
    try {
      if (id) {
        await updateVariableRequest(reduced, id);
        navigate('/settings/variables');
      } else {
        await addVariableRequest(reduced);
        navigate('/settings/variables');
      }
      Alert.SUCCESS(t('editVariables.edit'));
    } catch (err: any) {
      Alert.ERROR(`${t('editVariables.error')} ${err.response.data.message}`);
    } finally {
      setSaving(false);
    }
  };
  const selectedFormHolder = watch(`formWId`);
  const selectedForm = selectedFormHolder?.value;
  const { questionsIndexedByForm } = useSelector(
    (state: RootState) => state?.data?.questionsFromOtherFormsQuestionsToUseOnRules
  );

  const fetchFormQuestion = () => {
    if (!selectedForm) return;

    if (!questionsIndexedByForm?.[selectedForm]) return;
    setConditions(questionsIndexedByForm[selectedForm]);
  };

  useEffect(() => {
    if (loading) return;
    fetchFormQuestion();
    setValue(`questionWId`, null, { shouldValidate: true });
  }, [selectedForm]);
  return loading ? (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  ) : (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormWrapper>
        <BreadcrumbWrapper>
          <Breadcrumbs />
        </BreadcrumbWrapper>
        <Title>
          {t('editVariables.title')}
          {name ? `: ${name}` : ''}
        </Title>
        <Display>
          <Grid item container xs={12} direction="column" align="unset">
            <InlineInputs>
              <InputWrapper>
                <Controller
                  name="name"
                  control={control}
                  rules={{
                    required: t<string>('editVariables.requiredName'),
                    validate: (value) => !/(?:^\s+|\s+$)/.test(value) || t<string>('editVariables.validateName'),
                  }}
                  render={({ field }) => <Input {...field} label={t('editVariables.labelName')} />}
                />
                {errors.name?.message && <FormError errors={errors} name="name" />}
              </InputWrapper>
              <InputWrapper>
                <Controller
                  rules={{
                    required: t<string>('editVariables.requiredCode'),
                    validate: {
                      onlyNumbers: (value) =>
                        /^[a-zA-Z0-9][a-zA-Z0-9_]*[a-zA-Z0-9]$/.test(value) || t<string>('editVariables.validateCode'),
                    },
                  }}
                  name="code"
                  control={control}
                  render={({ field }) => <Input {...field} label={t('editVariables.labelCode')} />}
                />
                {errors.code?.message && <FormError errors={errors} name="code" />}
              </InputWrapper>
            </InlineInputs>
            <Grid xsMargin="24px 0 0 0" />

            <InlineInputs>
              <InputWrapper>
                <Controller
                  name="productType"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      isDisabled={variable !== undefined}
                      label={t('editVariables.labelProduct')}
                      options={products}
                    />
                  )}
                />
              </InputWrapper>
              <InputWrapper>
                <Controller
                  name="projectType"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      isDisabled={variable !== undefined}
                      label={t('editVariables.labelProject')}
                      options={projectTypes}
                    />
                  )}
                />
              </InputWrapper>
              <InputWrapper>
                <Controller
                  name="businessUnit"
                  control={control}
                  render={({ field }) => (
                    <Select
                      isDisabled={variable !== undefined}
                      {...field}
                      label={t('editVariables.labelBusiness')}
                      options={businessUnits}
                    />
                  )}
                />
              </InputWrapper>
              <InputWrapper>
                <Controller
                  name="serviceOffer"
                  control={control}
                  render={({ field }) => (
                    <Select
                      isDisabled={variable !== undefined}
                      {...field}
                      label={t('editVariables.labelService')}
                      options={serviceOffers}
                    />
                  )}
                />
              </InputWrapper>
              <InputWrapper>
                <Controller
                  name="formType"
                  control={control}
                  rules={{
                    required: t<string>('editVariables.requiredForm'),
                  }}
                  render={({ field }) => (
                    <Select
                      isDisabled={variable !== undefined}
                      {...field}
                      options={FORM_TYPE_VARIABLE_VALUES()}
                      label={t('editVariables.labelForm')}
                    />
                  )}
                />
                <FormError errors={errors} name="formType" />
              </InputWrapper>
            </InlineInputs>
            <Grid xsMargin="24px 0 0 0" />
            <InlineInputs>
              <InputWrapper>
                <Controller
                  name="formWId"
                  control={control}
                  rules={{
                    required: t<string>('editVariables.requiredSelect'),
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={forms}
                      isDisabled={loadingRefetchForms}
                      placeholder={t('common.selectForm')}
                      label={t('common.selectForm')}
                    />
                  )}
                />
                {errors.formWId?.message && <FormError errors={errors} name="formWId" />}
              </InputWrapper>
              <InputWrapper>
                <Controller
                  name="questionWId"
                  control={control}
                  rules={{
                    required: t<string>('editVariables.requiredQuestion'),
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      label={t('editVariables.labelQuestion')}
                      placeholder={t('editVariables.placeholderQuestion')}
                      isDisabled={loadingRefetchForms || !selectedForm}
                      options={conditions}
                      isOptionSelected={(option: any, selectValue: any[]) => selectValue[0]?.value.id === option.value}
                    />
                  )}
                />
                {errors.questionWId?.message && <FormError errors={errors} name="questionWId" />}
              </InputWrapper>
            </InlineInputs>

            <FooterWrapper>
              <Button onClick={returnHandler}>{t('common.back')}</Button>
              <Button primary type="submit" loading={saving}>
                {t('common.save')}
              </Button>
            </FooterWrapper>
          </Grid>
        </Display>
      </FormWrapper>
    </form>
  );
}
