/* eslint-disable eqeqeq */
import React, { useContext, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AmountBadge, ProgressBarContainer, ProgressBarInfo, ProgressBarLine, ProgressBarLineContainer } from './style';
import ReplyFormContext from '../../context';

const isInvalidAnswer = (answer: any, panel?: any) => {
  if (panel?.hidden) return false;
  if (Array.isArray(answer.answer) && !answer.shouldBeIgnored && !answer.hidden) {
    if (answer.answer?.length === 0) return true;
    if (!answer.answer.some(Boolean)) return true;
  }
  return !answer?.answer && !answer?.shouldBeIgnored && !answer.hidden;
};

const countIncompleteAnswers = (acc: number, curr: any) => {
  if (Array.isArray(curr?.answers)) {
    const numberOfNestedCompletedQuestions = curr?.answers.reduce(
      (nestedPrev: any, nestedCurr: any) => (isInvalidAnswer(nestedCurr, curr) ? nestedPrev + 1 : nestedPrev),
      0
    );
    return acc + numberOfNestedCompletedQuestions;
  }
  return isInvalidAnswer(curr) ? acc + 1 : acc;
};

const isValidAnswer = (answer: any) => {
  if (Array.isArray(answer.answer) && !answer.shouldBeIgnored && !answer.hidden) {
    if (answer.answer?.length === 0) return false;
    if (!answer.answer.some(Boolean)) return false;
  }
  return answer?.answer && !answer?.shouldBeIgnored && !answer.hidden;
};

const countAnswers = (acc: number, curr: any) => {
  if (Array.isArray(curr?.answers)) {
    const numberOfNestedCompletedQuestions = curr?.answers.reduce(
      (nestedPrev: any, nestedCurr: any) => (isValidAnswer(nestedCurr) ? nestedPrev + 1 : nestedPrev),
      0
    );
    return acc + numberOfNestedCompletedQuestions;
  }
  return isValidAnswer(curr) ? acc + 1 : acc;
};

const countCompletedQuestionsTypeIsBranchOrRepeating = (items: any[]) =>
  items.reduce((total: number, item: any) => total + item.answers.reduce(countAnswers, 0), 0);

const ProgressBar = () => {
  const { data, control } = useContext(ReplyFormContext);
  const formTypeIsBranch = data?.formType === 'BRANCH';
  const formTypeRepeating = data?.repeating === true;
  const { t } = useTranslation();
  const answers = useWatch({ control, name: 'answers' });
  const branches = useWatch({ control, name: 'branches' });
  const entries = useWatch({ control, name: 'entries' });

  const tabsAnswers: Array<any> = [];

  if (data?.multipleForm == 1) {
    answers.forEach((tab: any) => {
      const tabContent: Array<Object> = [];
      if (tab) {
        Object.keys(tab).forEach((key: any) => {
          if (!Number.isNaN(key) && !Number.isNaN(parseInt(key, 10))) {
            tabContent.push(tab[key]);
          }
        });
        tabsAnswers.push(tabContent);
      }
    });
  } else {
    tabsAnswers.push(...answers);
  }

  const amountCompletedQuestions = useMemo(() => {
    if (formTypeIsBranch && branches) {
      return countCompletedQuestionsTypeIsBranchOrRepeating(branches);
    }
    if (formTypeRepeating && entries) {
      return countCompletedQuestionsTypeIsBranchOrRepeating(entries);
    }
    let count = 0;

    if (data?.multipleForm == 1 && Array.isArray(tabsAnswers[0])) {
      tabsAnswers.forEach((tab: any) => {
        count += tab.reduce(countAnswers, 0);
      });
    } else {
      count += tabsAnswers.reduce(countAnswers, 0);
    }

    return count;
  }, [answers, branches, entries]);

  const amountIncompletedQuestions = useMemo(() => {
    let count = 0;

    if (data?.multipleForm == 1 && Array.isArray(tabsAnswers[0])) {
      tabsAnswers.forEach((tab: any) => {
        count += tab.reduce(countIncompleteAnswers, 0);
      });
    } else {
      count += tabsAnswers.reduce(countIncompleteAnswers, 0);
    }

    return count;
  }, [answers, branches, entries]);

  const amountOfQuestionsThatShouldBeAnswer = amountCompletedQuestions + amountIncompletedQuestions;

  const amountBadgeTitle = `${amountCompletedQuestions}/${amountOfQuestionsThatShouldBeAnswer}`;

  return (
    <ProgressBarContainer>
      <ProgressBarInfo>
        <p>{t('questions.completed')}</p>
        <AmountBadge>{amountBadgeTitle}</AmountBadge>
      </ProgressBarInfo>
      <ProgressBarLineContainer>
        <ProgressBarLine percentage={(amountCompletedQuestions * 100) / amountOfQuestionsThatShouldBeAnswer} />
      </ProgressBarLineContainer>
    </ProgressBarContainer>
  );
};

export default ProgressBar;
