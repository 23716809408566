import React from 'react';
import { components, DropdownIndicatorProps } from 'react-select';
import { CustomPlaceholder, DropdownWrapper, OptionLabel, StyledSelect } from './style';
import { DropdownIcon } from '../../assets/svgs/DropdownIcon';
import Checkbox from '../MarkableButton/Checkbox';

const Option = (props: any) => {
  const { isSelected, label } = props;

  return (
    <components.Option {...props}>
      <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
        <Checkbox checked={isSelected} onChange={() => null} /> <OptionLabel selected={isSelected}>{label}</OptionLabel>
      </div>
    </components.Option>
  );
};

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  // eslint-disable-next-line react/destructuring-assignment
  const { menuIsOpen } = props.selectProps;

  const dropdownClassname = menuIsOpen ? 'dropdown-icon open' : 'dropdown-icon';

  return (
    <components.DropdownIndicator {...props}>
      <DropdownWrapper menuIsOpen={menuIsOpen}>
        <DropdownIcon className={dropdownClassname} />
      </DropdownWrapper>
    </components.DropdownIndicator>
  );
};

const CustomValueContainer = ({ children, hasValue, ...props }: any) => (
  <components.ValueContainer {...props}>
    <components.Placeholder {...props} isFocused={props.isFocused}>
      <CustomPlaceholder hasValue={hasValue}>{props.selectProps.placeholder}</CustomPlaceholder>
    </components.Placeholder>
    {React.Children.map(children, (child) => (child && child.type !== components.Placeholder ? child : null))}
  </components.ValueContainer>
);

const MultiSelect = ({ options, ...props }: any) => (
  <StyledSelect
    options={options}
    isMulti
    isClearable={false}
    isSearchable={false}
    classNamePrefix="Select"
    closeMenuOnSelect={false}
    hideSelectedOptions={false}
    components={{
      DropdownIndicator,
      IndicatorSeparator: () => null,
      MultiValue: () => null,
      Option,
      ValueContainer: CustomValueContainer,
    }}
    {...props}
  />
);

export default MultiSelect;
