import styled, { css } from 'styled-components';
import Grid from '../../components/Grid';
import { MAX_WIDTHS } from '../../constants/screen';
import { FormStatus } from './constants';
import Button from '../../components/Button';
import CheckIcon from '../../assets/svgs/check.svg';

export const Header = styled.div`
  box-sizing: border-box;
  display: flex;
  padding: 24px;
  width: 100%;
  background: #fff;
  border: 1px solid #e3dfda;
  border-radius: 8px;
  margin: 16px 0;

  @media (max-width: 600px) {
    border: none;
    display: flex;
    flex-direction: column;
    padding: 0;
  } ;
`;

export const User = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #414141;
  flex: none;

  display: flex;
  align-items: center;
`;

export const Container = styled(Grid).attrs({
  container: true,
})`
  flex-direction: column;
  padding: 0 40px;
  margin: 80px 0;

  @media (max-width: ${MAX_WIDTHS.md}px) {
    padding: 7%;
    margin-top: 56px;
  } ;
`;

export const PieChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  width: 422px;
  background: #fff;
  border: 1px solid #e3dfda;
  border-radius: 8px;
  color: #fff;
  gap: 10px;

  @media (max-width: 800px) {
    display: none;
  } ;
`;

export const TitleForms = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  color: #262321;
`;

export const Forms = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  width: 100%;
  background: #fff;
  border: 1px solid #e3dfda;
  border-radius: 8px;

  @media (max-width: ${MAX_WIDTHS.md}px) {
    width: 100%;
  } ;
`;

export const Content = styled.div`
  width: 100%;
  margin-top: 24px;

  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 30px;
  }

  ::-webkit-scrollbar-thumb {
    background: #e3dfda;
    border-radius: 30px;
  }
`;

export const HeaderForm = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;

  @media (max-width: ${MAX_WIDTHS.lg}px) {
    flex-direction: column;
  } ;
`;

export const SelectWrapper = styled.div`
  display: flex;
  gap: 8px;

  @media (max-width: ${MAX_WIDTHS.sm}px) {
    display: none;
  } ;
`;

export const IconWrapper = styled.div`
  .arrow_filter_button {
    transform: rotate(270deg);
  }
`;

export const ResponsiveSelectWrapper = styled.div`
  display: none;
  margin-top: 24px;
  gap: 24px;

  @media (max-width: ${MAX_WIDTHS.sm}px) {
    display: flex;
    flex-direction: column;
  } ;
`;

export const ResponsiveFilterButton = styled.button`
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  background: none;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #324fa5;
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const Footer = styled.div`
  height: 24px;
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 24px;
  color: #979797;
`;

export const TitleChart = styled.div`
  width: 100%;
  height: 32px;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  color: #fff;
  display: flex;
  gap: 6px;
`;

export const TitleBarChart = styled.div`
  width: 256px;
  height: 32px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #000;
  display: flex;
  gap: 6px;
`;

export const Text = styled.div`
  width: 185px;
  align-self: baseline;
  font-style: normal;
  font-weight: 450;
  font-size: 12px;
  line-height: 24px;
  color: #fff;
`;

export const Alert = styled.div`
  width: 100%;
  gap: 16px;
  display: flex;
  align-items: center;
  background: #e6b25d;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 10px;

  @media (max-width: ${MAX_WIDTHS.sm}px) {
    height: 100px;
    padding: 10px;
  }
`;

export const TextAlert = styled.div`
  display: flex;
  align-items: center;

  height: 24px;
  width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #fff;

  @media (max-width: ${MAX_WIDTHS.lg}px) {
    font-size: 16px;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  gap: 20px;
`;

export const BarChartWrapper = styled.div`
  display: none;
  @media (max-width: 800px) {
    box-sizing: border-box;
    display: block;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 16px;
    width: 100%;
    left: 32px;
    top: 100px;
    background: #fff;
    border: 1px solid #e3dfda;
    border-radius: 8px;
    margin-top: 25px;
  } ;
`;

export const Title = styled.p<{ disabled?: boolean }>`
  font-size: 16px;
  text-align: start;
  line-height: 16px;
  font-weight: normal;
  color: #262321;

  ${({ disabled }) => disabled && `color: #979797`}
`;

export const ListContainer = styled.div`
  width: 100%;
`;

export const ListWrapper = styled.div`
  width: 100%;
`;

export const ListBody = styled.div`
  transition: all 1s ease;
`;

interface ListRowProps {
  selected?: boolean;
}

export const ListRow = styled.div<ListRowProps>`
  background-color: ${({ selected }) => selected && '#E8EFFA'};
`;

export const ListData = styled.div`
  border-bottom: 1px solid #d8d8d8;
`;

interface ListDataWrapperProps {
  gap?: number;
  justify?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
  disabled?: boolean;
}

export const ListDataWrapper = styled.div<ListDataWrapperProps>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 0;
  justify-content: space-between;
  gap: 16px;
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};

  @media (max-width: ${MAX_WIDTHS.md}px) {
    flex-direction: column;
    align-items: flex-start;
  } ;
`;

interface ButtonProps {
  status: FormStatus;
}

const FORM_STATUS_COLOR = {
  [FormStatus.NOT_INITIATED]: '#f1615d',
  [FormStatus.IN_PROGRESS]: '#577ef4',
  [FormStatus.FINISHED]: '#99e596',
};

export const DashboardBadge = styled.div<ButtonProps>`
  padding: 4px 8px;
  border-radius: 8px;
  border: none;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;

  background: ${({ status }) => FORM_STATUS_COLOR[status]};
`;

export const CompanyBadge = styled.div`
  padding: 4px 8px;
  border-radius: 8px;
  border: none;

  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  background: #725df1;
`;

export const BadgeWrapper = styled.div`
  display: flex;
  gap: 24px;
`;

export const TextButton = styled.div`
  width: 76px;
  height: 16px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #fff;
`;

interface ResponsiveMenuProps {
  open: boolean;
}

export const ResponsiveFilterWrapper = styled.div``;

export const ResponsiveSubmitButton = styled(Button).attrs({
  primary: true,
})`
  position: absolute;
  bottom: 70px;
  right: 16px;
`;

export const ResponsiveActionMenu = styled.div<ResponsiveMenuProps>`
  position: fixed;
  overflow: hidden;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: none;
  background-color: #fff;

  ${({ open }) =>
    open &&
    css`
      display: flex;
      flex-direction: column;
    `}
`;

export const ResponsiveMenuButton = styled.button.attrs({
  type: 'button',
})<{ selected?: boolean }>`
  cursor: pointer;
  display: flex;
  gap: 24px;
  align-items: center;
  background: none;
  outline: none;
  border: none;
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
  text-decoration: none;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #726c66;

  &.active {
    color: #324fa5;

    svg path {
      fill: #324fa5;
    }
  }

  &:hover {
    background-color: #f2f5ff;
    color: #324fa5;

    svg path {
      fill: #324fa5;
    }
  }

  .arrow_responsive_button {
    transform: rotate(90deg);
  }

  ${({ selected }) =>
    selected &&
    css`
      background: #e8effa;
      position: relative;

      &:before {
        display: block;
        position: absolute;
        content: ' ';
        right: 30px;
        background-image: url(${CheckIcon});
        background-size: 14px 18px;
        height: 18px;
        width: 14px;
      }
    `}
`;

export const PdfDownload = styled(Button)`
  align-self: flex-start;
  &:hover {
    filter: none;
  }

  & > div {
    display: flex;
    width: 100%;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
  }
`;

export const PreviewAlert = styled.div`
  position: relative;
  top: 56px;
  background: #324fa5;
  height: 40px;

  padding-left: 40px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;

  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;

  color: #ffffff;
`;

export const PreviewBackButton = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #025c00;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  padding: 3px 11px 3px 4px;
  background: #99e596;
  border-radius: 4px;

  .arrow_preview {
    height: 8px;
    transform: rotate(90deg);
  }

  .arrow_preview > path {
    fill: #025c00;
  }
`;

export const ChartWrapper = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
`;
