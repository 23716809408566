import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Creators as modalActions } from '../../../../redux/reducers/modal-reducer';
import Checkbox from '../../../../components/MarkableButton/Checkbox';
import { Container, Content, Footer, Label, Options, OptionWrapper, Title } from './style';
import Button from '../../../../components/Button';

export const UserModalContent = ({ user, defaultFilters, onSubmit }: any) => {
  const [saving, setSaving] = useState(false);
  const { watch, register, handleSubmit } = useForm<{
    projectTypes: any[];
    businessUnits: any[];
    products: any[];
    serviceOffers: any[];
  }>({
    defaultValues: {
      projectTypes: user.userFilterRestriction.projectType,
      businessUnits: user.userFilterRestriction.businessUnit,
      products: user.userFilterRestriction.productType,
      serviceOffers: user.userFilterRestriction.serviceOffer,
    },
  });
  const { t } = useTranslation();

  const filledProjectTypes = watch('projectTypes');
  const filledBusinessUnits = watch('businessUnits');
  const filledProducts = watch('products');
  const filledServiceOffers = watch('serviceOffers');

  const dispatch = useDispatch();
  const { closeModal } = modalActions;

  const closeHandler = () => dispatch(closeModal());
  const filterHandler = async (data: any) => {
    const filteredData = {
      projectType: data.projectTypes.filter(Boolean),
      businessUnit: data.businessUnits.filter(Boolean),
      productType: data.products.filter(Boolean),
      serviceOffer: data.serviceOffers.filter(Boolean),
    };
    setSaving(true);
    try {
      await onSubmit(user._id, filteredData);
      closeHandler();
    } catch (error) {
      console.log(error);
    } finally {
      setSaving(false);
    }
  };

  const { projectTypes } = defaultFilters;
  const { businessUnits } = defaultFilters;
  const { products } = defaultFilters;
  const { serviceOffers } = defaultFilters;

  return (
    <Container>
      <Content>
        <Title>{t('modal.product')}</Title>
        <Options>
          {products.length > 0 &&
            products.map((item: any) => (
              <OptionWrapper>
                <Checkbox
                  {...register(`products`)}
                  checked={filledProducts?.includes(item._id)}
                  id={`products-${item._id}`}
                  value={item._id}
                />
                <Label>{item.name}</Label>
              </OptionWrapper>
            ))}
        </Options>
        <Title>{t('modal.project')}</Title>
        <Options>
          {projectTypes.length > 0 &&
            projectTypes.map((item: any) => (
              <OptionWrapper>
                <Checkbox
                  {...register(`projectTypes`)}
                  checked={filledProjectTypes?.includes(item._id)}
                  id={`projectTypes-${item._id}`}
                  value={item._id}
                />
                <Label>{item.name}</Label>
              </OptionWrapper>
            ))}
        </Options>
        <Title>{t('modal.business')}</Title>
        <Options>
          {businessUnits.length > 0 &&
            businessUnits.map((item: any) => (
              <OptionWrapper>
                <Checkbox
                  {...register(`businessUnits`)}
                  checked={filledBusinessUnits?.includes(item._id)}
                  id={`businessUnits-${item._id}`}
                  value={item._id}
                />
                <Label>{item.name}</Label>
              </OptionWrapper>
            ))}
        </Options>
        <Title>{t('modal.service')}</Title>
        <Options>
          {serviceOffers.length > 0 &&
            serviceOffers.map((item: any) => (
              <OptionWrapper>
                <Checkbox
                  {...register(`serviceOffers`)}
                  checked={filledServiceOffers?.includes(item._id)}
                  id={`serviceOffers-${item._id}`}
                  value={item._id}
                />
                <Label>{item.name}</Label>
              </OptionWrapper>
            ))}
        </Options>
      </Content>
      <Footer>
        <Button onClick={handleSubmit(filterHandler)} primary loading={saving}>
          {t('common.save')}
        </Button>
      </Footer>
    </Container>
  );
};
