import React, { useState } from 'react';
import { FieldTitle } from '../../../style';
import { ChoicesContainer, StyledChoice } from '../style';
import RadioButton from '../../../../../MarkableButton/RadioButton';
import { ChoiceFieldProps, ChoiceProps } from '../types';

interface UniqueChoiceProps {
  id: string;
  selectedChoice: any;
  setSelectedChoice: (choice: any) => void;
}

const Choice = ({ title, id, selectedChoice, setSelectedChoice }: ChoiceProps & UniqueChoiceProps) => (
  <StyledChoice>
    <RadioButton id={id} checked={id === selectedChoice} onChange={() => setSelectedChoice(id)} />
    <p>{title}</p>
  </StyledChoice>
);

const UniqueChoice = ({ title, choices }: ChoiceFieldProps) => {
  const [selectedChoice, setSelectedChoice] = useState<string>();

  return (
    <ChoicesContainer>
      <FieldTitle>{title}</FieldTitle>
      {choices.map(({ title: choiceTitle }, index: number) => (
        <Choice
          id={`RadioButton${title}${index}`}
          title={choiceTitle}
          index={index}
          selectedChoice={selectedChoice}
          setSelectedChoice={setSelectedChoice}
        />
      ))}
    </ChoicesContainer>
  );
};

export default UniqueChoice;
