import styled from 'styled-components';

interface BarChartProps {
  percentage: number;
}

export const Box = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 22px;
  margin-top: 20px;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #000;
`;
export const Text = styled.div`
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: #476bc3;
`;

export const FormsWrapper = styled.div`
  box-sizing: border-box;
  margin-top: 6px;
  display: flex;
  align-items: flex-start;
  padding: 4px 8px;
  gap: 4px;
  width: 93px;
  background: #fff;
  border: 1px solid #a8c1ff;
  border-radius: 8px;
`;

export const Forms = styled.div`
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: #476bc3;
`;

export const Bar = styled.div`
  position: relative;
  margin-top: 5px;
  width: 100%;
`;

export const ProgressBarLineContainer = styled.div`
  height: 10px;
  width: 100%;
  background: #f2f5ff;
  border-radius: 9999px;
`;

export const LegendWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;

export const BarWarning = styled.div<BarChartProps>`
  background-color: #f1615d;
  height: 10px;
  border-radius: 9999px;
  width: ${({ percentage }) => percentage}%;
`;

export const BarProgress = styled.div<BarChartProps>`
  background-color: #577ef4;
  height: 10px;
  border-radius: 9999px;
  width: ${({ percentage }) => percentage}%;
`;

export const BarSuccess = styled.div<BarChartProps>`
  background-color: #99e596;
  height: 10px;
  border-radius: 9999px;
  width: ${({ percentage }) => percentage}%;
`;

export const InfosWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BadgePercentage = styled.div<BarChartProps>`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  padding: 4px 0;
  position: absolute;
  color: #fff;
  width: 45px;
  top: -36px;
  left: calc(${({ percentage }) => percentage}% - ${({ percentage }) => (percentage >= 90 ? 45 : 20)}px);
  background: #f1615d;
  box-shadow: 0 4px 4px rgba(38, 35, 33, 0.16);
  border-radius: 8px;
`;

export const BadgeWarning = styled(BadgePercentage)`
  background: #f1615d;
`;

export const BadgeProgress = styled(BadgePercentage)<BarChartProps>`
  background: #577ef4;
`;

export const BadgeSuccess = styled(BadgePercentage)<BarChartProps>`
  background: #99e596;
`;

export const BadgeText = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
`;

export const BadgeTextSuccess = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #057800;
`;
