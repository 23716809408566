import React from 'react';

export interface IconSettingsProps {
  width?: number;
  height?: number;
}

export const IconSettings = ({ width = 20, height = 22 }: IconSettingsProps) => (
  <svg width={width} height={height} viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 0L19.5 5.5V16.5L10 22L0.5 16.5V5.5L10 0ZM10 2.311L2.5 6.653V15.347L10 19.689L17.5 15.347V6.653L10 2.311ZM10 15C8.93913 15 7.92172 14.5786 7.17157 13.8284C6.42143 13.0783 6 12.0609 6 11C6 9.93913 6.42143 8.92172 7.17157 8.17157C7.92172 7.42143 8.93913 7 10 7C11.0609 7 12.0783 7.42143 12.8284 8.17157C13.5786 8.92172 14 9.93913 14 11C14 12.0609 13.5786 13.0783 12.8284 13.8284C12.0783 14.5786 11.0609 15 10 15ZM10 13C10.5304 13 11.0391 12.7893 11.4142 12.4142C11.7893 12.0391 12 11.5304 12 11C12 10.4696 11.7893 9.96086 11.4142 9.58579C11.0391 9.21071 10.5304 9 10 9C9.46957 9 8.96086 9.21071 8.58579 9.58579C8.21071 9.96086 8 10.4696 8 11C8 11.5304 8.21071 12.0391 8.58579 12.4142C8.96086 12.7893 9.46957 13 10 13Z"
      fill="#726C66"
    />
  </svg>
);
