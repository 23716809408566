import styled from 'styled-components';

export const StyledFormError = styled.div`
  display: flex;
  gap: 8px;
  margin: 4px 0;

  svg,
  path {
    fill: #de4635;
  }
`;

export const FormErrorMessage = styled.p`
  color: #9c2514;
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 16px;
`;
