import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ActionsWrapper, Badge, Container, TextWrapper, Title } from './style';
import { ActionIcon } from '../../../components/Table/style';
import { EditIcon } from '../../../assets/svgs/EditIcon';
import { TableData } from '../../../types/TableData';
import { Creators as modalActions } from '../../../redux/reducers/modal-reducer';
import DeleteModal from '../../../components/DefaultModalContent';
import Alert from '../../../components/Toast/toast';
import { blockFormRequest, deleteDraftRequest, findAllFormsRequest, unblockFormRequest } from '../../../requests/forms';
import TextButton from '../../../components/TextButton';
import BlockFormIcon from '../../../assets/svgs/BlockFormIcon';
import UnblockFormIcon from '../../../assets/svgs/UnblockFormIcon';
import FormTable from './components/FormTable';
import { Breadcrumbs } from '../../../components/BreadCrumb';
import { LoaderWrapper } from '../../../components/Loader/style';
import Loader from '../../../components/Loader';
import { BreadcrumbWrapper } from '../../../components/BreadCrumb/style';
import { DraftIcon } from '../../../assets/svgs/DraftIcon';
import { DeleteIcon } from '../../../assets/svgs/DeleteIcon';
import { Tooltip } from '../../../components/Tooltip';
import { isNotEmptyArray } from '../../../utils/array';
import { ModalContent } from '../../DashboardAdmin/components/ModalContent';
import { findAllUserFilterRestriction } from '../../../requests/userFilterRestriction';

const FILTER_DEFAULT_STATE = {
  projectTypes: [],
  businessUnits: [],
  products: [],
  serviceOffers: [],
};

const FormList = () => {
  const [forms, setForms] = useState<TableData[]>([]);
  const [loadingInfos, setLoadingInfos] = useState(0);

  const [filter, setFilter] = useState<any>(FILTER_DEFAULT_STATE);
  const [products, setProducts] = useState<any[]>([]);
  const [businessUnits, setBusinessUnits] = useState<any[]>([]);
  const [serviceOffers, setServiceOffers] = useState<any[]>([]);
  const [projectTypes, setProjectTypes] = useState<any[]>([]);

  const dispatch = useDispatch();
  const { openModal } = modalActions;
  const { t } = useTranslation();

  const navigate = useNavigate();

  const getForms = async () => {
    setLoadingInfos((stage) => stage + 1);
    const data = await findAllFormsRequest();
    setForms(data);
    setLoadingInfos((stage) => stage - 1);
  };

  const handleModalFilter = (filterData: any) => {
    setFilter(filterData);
  };

  const filteredForms = useMemo(() => {
    if (
      !(
        isNotEmptyArray(filter.projectTypes) ||
        isNotEmptyArray(filter.businessUnits) ||
        isNotEmptyArray(filter.products) ||
        isNotEmptyArray(filter.serviceOffers)
      )
    )
      return forms;

    return forms?.filter(
      (list) =>
        (filter?.projectTypes.length > 0 ? filter?.projectTypes.includes(list.projectType) : true) &&
        (filter?.businessUnits.length > 0 ? filter?.businessUnits.includes(list.businessUnit) : true) &&
        (filter?.products.length > 0 ? filter?.products.includes(list.productType) : true) &&
        (filter?.serviceOffers.length > 0 ? filter?.serviceOffers.includes(list.serviceOffer) : true)
    );
  }, [forms, filter]);

  const viewHandler = (data: any) => {
    if (data.status !== 'INACTIVE' && !data.draftId) navigate(`/forms/view/${data.wId}`);
  };

  const editHandler = (data: any) => {
    navigate(`/forms/edit/${data.wId}`);
  };

  const blockHandler = async (...data: any[]) => {
    const blockSubmit = async () => {
      const filteredData = data.filter((item) => item.status === 'ACTIVE' && !item.draftId);

      try {
        await blockFormRequest(filteredData);
        const ids = filteredData.map((item) => item.wId);
        setForms((prevState) =>
          prevState.map((form) => (ids.includes(form.wId) ? { ...form, status: 'INACTIVE', draftId: undefined } : form))
        );
        const alert = filteredData.length > 1 ? t('form.blockFormPlural') : t('form.blockForm');
        Alert.SUCCESS(alert);
      } catch (e: any) {
        Alert.ERROR(e?.response?.data?.message);
      }
    };

    const message = data.length > 1 ? t('form.messageBlockPlural') : t('form.messageBlock');

    dispatch(
      openModal({
        content: <DeleteModal onSubmit={blockSubmit} title={message} submitButtonTitle={t('common.block')} />,
      })
    );
  };

  const unblockHandler = async (...data: any[]) => {
    const unblockSubmit = async () => {
      const filteredData = data.filter((item) => item.status === 'INACTIVE' && !item.draftId);

      try {
        await unblockFormRequest(filteredData);
        const ids = filteredData.map((item) => item.wId);
        setForms((prevState) =>
          prevState.map((form) => (ids.includes(form.wId) ? { ...form, status: 'ACTIVE' } : form))
        );
        const alert = filteredData.length > 1 ? t('form.unblockFormPlural') : t('form.unblockForm');
        Alert.SUCCESS(alert);
      } catch (e: any) {
        Alert.ERROR(e?.response?.data?.message);
      }
    };

    const message = data.length > 1 ? t('form.messageunBlockPlural') : t('form.messageunBlock');

    dispatch(
      openModal({
        content: <DeleteModal onSubmit={unblockSubmit} title={message} submitButtonTitle={t('common.unlock')} />,
      })
    );
  };

  const deleteDraftHandler = async (...data: any[]) => {
    const deleteDraftSubmit = async () => {
      const filteredData = data.filter((item) => item.draftId);
      try {
        await deleteDraftRequest(filteredData);
        const ids = filteredData.map((item) => item.wId);
        setForms((prevState) => prevState.filter((form) => !ids.includes(form.wId)));
        Alert.SUCCESS(t('form.draftDeleted'));
      } catch (e: any) {
        Alert.ERROR(e?.response?.data?.message);
      }
    };

    const message = t('form.deleteSelectedDraft');

    dispatch(
      openModal({
        content: <DeleteModal onSubmit={deleteDraftSubmit} title={message} submitButtonTitle={t('form.deleteDraft')} />,
      })
    );
  };

  const createHandler = async () => {
    navigate('/forms/create');
  };

  const getDeleteOrUnBlockIcon = (element: TableData) => {
    if (element.draftId)
      return (
        <ActionIcon onClick={() => deleteDraftHandler(element)}>
          <DeleteIcon fill="#324fa5" />
        </ActionIcon>
      );

    return element.status === 'INACTIVE' ? (
      <ActionIcon onClick={() => unblockHandler(element)}>
        <BlockFormIcon />
      </ActionIcon>
    ) : (
      <ActionIcon onClick={() => blockHandler(element)}>
        <UnblockFormIcon />
      </ActionIcon>
    );
  };

  const render = (element: TableData) => [
    <TextWrapper
      isDraft={element.draftId}
      disabled={element.status === 'INACTIVE'}
      onClick={() => viewHandler(element)}
    >
      {element.title}
    </TextWrapper>,
    <ActionsWrapper>
      {element.draftId && <Badge>{t('form.draft')}</Badge>}
      {element.draftId && element.newForm && (
        <Tooltip content={t('form.noPublishedVersion')}>
          <Badge>SVP</Badge>
        </Tooltip>
      )}
      <ActionIcon disabled={element.status === 'INACTIVE'} onClick={() => editHandler(element)}>
        {element.draftId ? <DraftIcon /> : <EditIcon />}
      </ActionIcon>

      {getDeleteOrUnBlockIcon(element)}
    </ActionsWrapper>,
  ];

  const headers = [
    {
      key: 'title',
      head: t('common.forms'),
    },
  ];

  const TABLE_ADVICE = t('form.advice');
  const EMPTY_STATE_TEXTS = {
    EMPTY_FILTER: t('form.emptyFilter'),
    EMPTY_LIST: t('form.emptyList'),
  };

  const renderActions = (selectedData: any[]) => [
    <TextButton
      key="BlockForm"
      onClick={() => blockHandler(...selectedData)}
      disabled={
        selectedData.length === 0 ||
        !selectedData.some(
          (selectedElement) =>
            (!selectedElement.draftId && !selectedElement.status) || selectedElement.status === 'ACTIVE'
        )
      }
    >
      <span>{t('common.block')}</span>
      <UnblockFormIcon fill={selectedData.length > 0 ? '#4C65B0' : '#979797'} />
    </TextButton>,
    <TextButton
      key="DesblockForm"
      onClick={() => unblockHandler(...selectedData)}
      disabled={
        selectedData.length === 0 || !selectedData.some((selectedElement) => selectedElement.status === 'INACTIVE')
      }
    >
      <span>{t('common.unlock')}</span>
      <BlockFormIcon fill={selectedData.length > 0 ? '#4C65B0' : '#979797'} />
    </TextButton>,
  ];

  const onFilterClick = async () => {
    dispatch(
      openModal({
        title: t('dashboardAdmin.filter'),
        content: (
          <ModalContent
            products={products}
            businessUnits={businessUnits}
            serviceOffers={serviceOffers}
            projectTypes={projectTypes}
            onSubmit={handleModalFilter}
            defaultValues={filter}
          />
        ),
      })
    );
  };

  const fetchUserFilterRestriction = async () => {
    const formatForSelect = (formDependency: { name: string; _id: number | string }) => ({
      name: formDependency.name,
      id: formDependency._id,
    });

    setLoadingInfos((stage) => stage + 1);
    const userFilterRestriction = await findAllUserFilterRestriction();

    const formattedServiceOffersToSelect = userFilterRestriction.serviceOffers.map(formatForSelect);
    await setServiceOffers(formattedServiceOffersToSelect);

    const formattedProductsToSelect = userFilterRestriction.products.map(formatForSelect);
    await setProducts(formattedProductsToSelect);

    const formattedProjectTypesToSelect = userFilterRestriction.projectTypes.map(formatForSelect);
    await setProjectTypes(formattedProjectTypesToSelect);

    const formattedBusinessUnitsToSelect = userFilterRestriction.businessUnits.map(formatForSelect);
    await setBusinessUnits(formattedBusinessUnitsToSelect);

    setLoadingInfos((stage) => stage - 1);
  };

  useEffect(() => {
    getForms();
    fetchUserFilterRestriction();
  }, []);

  return (
    <Container>
      <BreadcrumbWrapper>
        <Breadcrumbs />
      </BreadcrumbWrapper>
      <Title>{t('form.forms')}</Title>
      {loadingInfos > 0 ? (
        <LoaderWrapper>
          <Loader text={t('form.loading')} />
        </LoaderWrapper>
      ) : (
        <FormTable
          data={filteredForms}
          render={render}
          headers={headers}
          emptyStateTexts={EMPTY_STATE_TEXTS}
          advice={TABLE_ADVICE}
          createHandler={createHandler}
          renderActions={renderActions}
          searchKey="title"
          onFilterClick={onFilterClick}
        />
      )}
    </Container>
  );
};

export default FormList;
