import React from 'react';

interface DeleteIconProps {
  width?: number;
  height?: number;
  className?: string;
}

export const DropdownIcon = ({ width = 12, height = 7, className }: DeleteIconProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 12 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.10382 0.704616C1.49309 0.315343 2.12412 0.314999 2.51382 0.703847L6.39844 4.58L10.2831 0.703847C10.6728 0.314999 11.3038 0.315343 11.6931 0.704616C12.0826 1.09419 12.0826 1.72581 11.6931 2.11538L7.10554 6.70289C6.71502 7.09342 6.08185 7.09342 5.69133 6.70289L1.10382 2.11538C0.714249 1.72581 0.714249 1.09419 1.10382 0.704616Z"
      fill="#262321"
    />
  </svg>
);
