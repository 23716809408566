import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionsContainer, StyledEmptyState } from './style';
import Alert from '../../../../../components/Toast/toast';
import FormContext from '../../context';
import QuestionPreview from './components/QuestionPreview';
import QuestionBox from './components/QuestionBox';
import EmptyStateIcon from '../../../../../assets/svgs/EmptyStateIcon';
import { FormViewContext } from '../../../FormView/context';

const EmptyState = () => {
  const { t } = useTranslation();

  return (
    <StyledEmptyState>
      <EmptyStateIcon />
      <p>{t('form.noFound')}</p>
    </StyledEmptyState>
  );
};

const Question = ({ questionAmount, deleteHandler, dragHandleProps, index, id }: any) => {
  const { viewMode } = useContext(FormViewContext);
  const { getValues } = useContext(FormContext);
  const {
    saved,
    files,
    choices,
    questions,
    title,
    type,
    supportedFileFormats,
    blockTypeChange,
    blockDeletion,
    blockRulesChange,
    wId,
  } = getValues(`questions[${index}]`);

  const fieldType = type?.value ?? type;
  const customFieldTypeProps = type?.props;

  return viewMode || saved ? (
    <QuestionPreview
      dragHandleProps={dragHandleProps}
      deleteHandler={deleteHandler}
      index={index}
      id={id}
      fieldType={fieldType}
      {...{
        title,
        questions,
        choices,
        files,
        supportedFileFormats,
        blockDeletion,
        customFieldTypeProps,
      }}
    />
  ) : (
    <QuestionBox
      deleteHandler={deleteHandler}
      questionAmount={questionAmount}
      id={wId}
      index={index}
      {...{ blockTypeChange, blockDeletion, blockRulesChange }}
    />
  );
};

export const QuestionsList = ({ questions }: any) => {
  const { remove, getValues, move } = useContext(FormContext);
  const { t } = useTranslation();

  const deleteHandler = async (index: number) => {
    const question = questions[index];
    const id = question?.wId ?? question?.id;

    const hasDependentsQuestions = questions.some((q: any) => {
      const ids = q?.rules?.conditions.map((condition: any) => condition?.id?.value ?? condition?.id);
      return ids?.includes(id);
    });

    if (hasDependentsQuestions) {
      Alert.INFO(t('questions.removeAssociatedQuestions'));
      return;
    }

    const internalVariables = getValues?.('internalVariables');
    const dependentInternalVariable = internalVariables?.find((iv: { wId: string }) => iv.wId === id);

    if (dependentInternalVariable) {
      Alert.INFO(`${t('questions.questionIsAssociatedToInternalVar')}${dependentInternalVariable.code}`);
      return;
    }

    remove(index);
    Alert.SUCCESS(t('form.remove'));
  };

  return questions.map((item: any, index: number) => (
    <Question
      deleteHandler={deleteHandler}
      questionAmount={questions.length}
      id={item.wId}
      key={`Question-${item.wId}`}
      index={index}
      dragHandleProps={{
        up: {
          onClick: () => {
            move(index, index - 1);
          },
          show: index !== 0,
        },
        down: {
          onClick: () => {
            move(index, index + 1);
          },
          show: index !== questions.length - 1,
        },
      }}
    />
  ));
};

const Questions = ({ questions }: any) => (
  <QuestionsContainer>
    {questions.length > 0 ? <QuestionsList questions={questions} /> : <EmptyState />}
  </QuestionsContainer>
);

export default Questions;
